import styled from 'styled-components';
import Button2Stroke from '../button2Stroke';
import { Media } from '../../../styles/media';

const { tablet } = Media;

type ButtonProps = {
  secondary: string;
};

const Button2Tertiary = styled(Button2Stroke)<ButtonProps>`
  padding: 0;
  border: none;
  box-shadow: none;
  color: ${(secondary) => secondary && 'white'};
  height: auto;
  text-align: ${(props) => {
    if (props.right) return 'right';
    if (props.left) return 'left';
    return 'center';
  }};

  &:hover,
  a&:hover,
  &:focus,
  &:active {
    box-shadow: none;
    background-color: transparent;
  }

  &:disabled {
    &,
    &:hover {
      background-color: transparent;
    }
  }

  ${tablet`
    padding: 0;
  `};
`;

export default Button2Tertiary;
