import { Record } from 'immutable';
import { Reducer } from 'redux';
import {
  EventActions,
  SUBSCRIBE_EVENT,
  UNSUBSCRIBE_EVENT,
} from '../actions/interfaces/IEventActions';
import { IEventModel } from '../models/Event';

export interface IEventState {
  events: Array<IEventModel>;
}

export interface IEventStateRecord extends Record<IEventState>, IEventState {}

export const EventState = Record<IEventState>({
  events: [],
});

const initialState = EventState();

export const eventReducer: Reducer<IEventStateRecord, EventActions> = (
  state = initialState,
  action: EventActions,
) => {
  const { type, payload } = action;

  switch (type) {
    case SUBSCRIBE_EVENT:
      const currentEvents = state.get('events');
      return state.set('events', [...currentEvents, payload]);
    case UNSUBSCRIBE_EVENT:
      const { eventName: eventNameToRemove, callBack: callBackToRemove } = payload;
      const events = state
        .get('events')
        .filter(
          ({ eventName, callBack }) =>
            eventName !== eventNameToRemove && callBack !== callBackToRemove,
        );

      return state.set('events', events);
    default:
      return state;
  }
};
