import styled from 'styled-components';
import { Body6, BodyMed } from '../../../../shared/typography';
import { NavItem } from '../../../../containers/mobileNavigation/mobileNavigationStyled';

export const CoachContainer = styled.div`
  display: flex;
`;

export const CoachInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 16px;
`;

export const Profile = styled(Body6)`
  color: ${({ theme }) => theme.medGray};
  text-transform: uppercase;
  margin: 0;
`;

export const UserName = styled(BodyMed)`
  margin: 0;
`;

export const Email = styled(Body6)`
  color: ${({ theme }) => theme.medGray};
  margin: 0;
`;

export const CoachNavItem = styled(NavItem)`
  margin-top: 15px;
  border-top: 1px solid ${({ theme }) => theme.lighterGray};
`;
