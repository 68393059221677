/** @jsx h */
import { FunctionalComponent, h } from 'preact';
import { connect } from 'react-redux';
import { IMenuItemModel } from '../../models/Menu';
import { IItemModel } from '../../models/Item';
import { AppState } from '../../interfaces/appState';
import { ICartModel } from '../../models/Cart';
import { modulesByKeySelector } from '../../selectors/modulesSelectors';
import { CartBadge, CartBadgeText, CartContainer, CartIcon } from './cartStyled';
import AppLink from '../appLink';
import { hasLoadedCartSelector } from '../../selectors/cartSelector';
import { navigationRedirectActionCreator } from '../../actions/navigationActions';
import { E2E, getTestID, useE2ETesting } from '../../providers/e2eTestingProvider';

const ALT_CART = 'cart';
export interface ICartProps {
  cart: ICartModel;
  item: IMenuItemModel;
  hasLoaded: boolean;
  redirect: typeof navigationRedirectActionCreator;
}

export const Cart: FunctionalComponent<ICartProps> = ({ cart, item, hasLoaded, redirect }) => {
  const { itemsCount = 0, isEnabled } = cart;
  const itemModel = item as IItemModel;
  const { menuImageUrl, parsedLink = '' } = itemModel;
  const { testIdPrefix } = useE2ETesting();
  const testIdValue = getTestID(testIdPrefix, E2E.component.icon, 'cart');

  return isEnabled ? (
    <AppLink item={itemModel} onClick={() => redirect(parsedLink)} data-testid={testIdValue}>
      <CartContainer>
        {!!itemsCount && hasLoaded && (
          <CartBadge>
            <CartBadgeText>{itemsCount.toString().substring(0, 2)}</CartBadgeText>
          </CartBadge>
        )}
        <CartIcon alt={ALT_CART} src={menuImageUrl} />
      </CartContainer>
    </AppLink>
  ) : null;
};

const mapStateToProps = (state: AppState) => ({
  cart: modulesByKeySelector(state, 'cart'),
  hasLoaded: hasLoadedCartSelector(state),
});

const mapDispatchToProps = {
  redirect: navigationRedirectActionCreator,
};

export default connect(mapStateToProps, mapDispatchToProps)(Cart);
