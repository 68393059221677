import { AppIdEnum } from '../enums/main';

export const ALGOLIA_ANALYTICS_TAGS = {
  platform: 'bodi-unif',
};
export const FOOTER_ID_DNS = 'footerDns';
export const FOOTER_ID_MANAGE_COOKIES = 'footerManageCookies';
export const CONTENT_TYPE = 'content-type';
export const BEARER = 'Bearer';
export const PDF_EXTENSION = '.pdf';
export const HUNDRED_PERCENT = '100%';
export const REGEX_DOMAIN_ONLY = /^(?:.*?\.)?(\w{3,}\.(?:\w{2,8}|\w{2,4}\.\w{2,4}))$/;
export const REGEX_SPECIAL_CHARACTERS = /[\u0300-\u036f]|['"]+|(-)+|\s/g;
export const NFD = 'NFD';
export const CHROMIUM_REGEX = /chrom(e|ium)/;
export const ANDROID_REGEX = /Android/i;
export const IOS_REGEX = /iPhone|iPad|iPod/i;
export const CONTENT_TYPES = {
  CONTENT_TYPE: 'content-type',
  JSON: 'application/json',
};
export const BODWEB = 'true';
export const HTTP_CODES = {
  OK: 200,
  BAD_REQUEST: 400,
};

export const ALLOWED_CUSTOM_NAVIGATION = [AppIdEnum.bod];

export const PROMO_BANNER_ELEMENT_ID = 'unification-promo-banner';
export const COACH_BANNER_ELEMENT_ID = 'unification-coach-banner';
export const SECONDARY_NAV_ELEMENT_ID = 'unification-secondary-nav';
