/** @jsx h */
import { FunctionalComponent, h } from 'preact';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import qs from 'query-string';
import AutoSuggestion from '../../components/autoSuggestion';
import { modulesByKeySelector } from '../../selectors/modulesSelectors';
import { AppState } from '../../interfaces/appState';
import { ILocaleModel } from '../../models/Locale';
import { GlobalSearchLocale } from './locale';
import { menuLocationSelector } from '../../selectors/topNavSelectors';
import { SEARCH_PLACEHOLDER_LOCATION } from '../../enums/topNav';
import { IMenuLocationModel } from '../../models/MenuLocation';
import { IItemModel } from '../../models/Item';

const SEARCH_PATH_REGEX = /\bsearch\b/;
const searchPlaceholderSelector = menuLocationSelector(SEARCH_PLACEHOLDER_LOCATION);

export const getDecodedQuery = (pathname: string, search: string): string => {
  if (!SEARCH_PATH_REGEX.test(pathname)) {
    return '';
  }
  const { query = '' } = qs.parse(search);
  return decodeURIComponent(query as string);
};

export interface IGlobalSearchProps {
  isSearchVisible: boolean;
  locale: ILocaleModel;
  menuLocation?: IMenuLocationModel;
  setIsSearchVisible: (value: boolean) => void;
}

export const GlobalSearch: FunctionalComponent<IGlobalSearchProps> = ({
  isSearchVisible,
  locale,
  menuLocation,
  setIsSearchVisible,
}) => {
  const { menuItemList: [searchItem] = [] } = menuLocation || {};
  const { anchorText: searchPlaceholder } = (searchItem || {}) as IItemModel;
  const { t } = useTranslation();
  const toggleSearch = () => {
    setIsSearchVisible(false);
  };

  const decodedQuery = getDecodedQuery(location.pathname, location.search.slice(1));

  return (
    <AutoSuggestion
      locale={locale.selectedLocale}
      initialValue={decodedQuery}
      isVisible={isSearchVisible}
      onHide={toggleSearch}
      placeholder={searchPlaceholder || t(GlobalSearchLocale.search)}
    />
  );
};

type IMapStateToProps = {
  locale: ILocaleModel;
  menuLocation: IMenuLocationModel;
};
export const mapStateToProps = (state: AppState): IMapStateToProps => ({
  locale: modulesByKeySelector(state, 'locale') as ILocaleModel,
  menuLocation: searchPlaceholderSelector(state) as IMenuLocationModel,
});

export default connect(mapStateToProps)(GlobalSearch);
