import styled from 'styled-components';
import { ITypography } from '../../interfaces/typography';
import Body1, { Body1Template } from '../body1';

export const Body2Template: ITypography = {
  ...Body1Template,
  fontWeight: '400',
};

const { fontWeight, lineHeight, letterSpacing } = Body2Template;

type TextProps = {
  italic: boolean;
};

const Body2 = styled(Body1)<TextProps>`
  font-weight: ${fontWeight};
  font-style: ${({ italic }) => (italic ? 'italic' : 'normal')};
  line-height: ${lineHeight};
  letter-spacing: ${letterSpacing};
`;

export default Body2;
