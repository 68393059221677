import { Record } from 'immutable';
import { Reducer } from 'redux';
import Waiver, { IWaiverModel } from '../models/Waiver';
import {
  WaiverActions,
  SET_USER_WAIVER_PROFILE,
  SET_NO_WAIVER_REQUIRED,
  SET_IS_WAIVER_ANONYMOUS_USERS_REQUIRED,
} from '../actions/interfaces/IWaiverActions';

export interface IWaiverState {
  readonly userWaiverProfile: IWaiverModel;
  readonly hasLoaded: boolean;
}

export interface IWaiverStateRecord extends Record<IWaiverState>, IWaiverState {}

export const WaiverState = Record<IWaiverState>({
  userWaiverProfile: Waiver.createFromRaw(),
  hasLoaded: false,
});

const initialState = WaiverState();

export const waiverReducer: Reducer<IWaiverStateRecord, WaiverActions> = (
  state = initialState,
  action,
) => {
  const { type, payload } = action;

  switch (type) {
    case SET_USER_WAIVER_PROFILE: {
      return state.set('userWaiverProfile', payload as IWaiverModel).set('hasLoaded', true);
    }
    case SET_NO_WAIVER_REQUIRED: {
      const userWaiverProfile = state.get('userWaiverProfile');
      const noWaiverRequiredList = userWaiverProfile.get('noWaiverRequiredList') || [];
      return state
        .set(
          'userWaiverProfile',
          userWaiverProfile.set('noWaiverRequiredList', [
            ...noWaiverRequiredList,
            payload as string,
          ]),
        )
        .set('hasLoaded', true);
    }
    case SET_IS_WAIVER_ANONYMOUS_USERS_REQUIRED: {
      const userWaiverProfile = state.get('userWaiverProfile');

      return state.set(
        'userWaiverProfile',
        userWaiverProfile.set('isWaiverAnonymousUsersRequired', payload as boolean),
      );
    }
    default:
      return state;
  }
};
