import styled from 'styled-components';

export const MenusContainer = styled.ul`
  grid-column: 2;
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 24px;
  justify-self: center;
  padding-left: 0;
  margin: 0;
`;
