/** @jsx h */
import { FunctionalComponent, h } from 'preact';
import { IMenuModel } from '../../../../models/Menu';
import { IMenuItemModel } from '../../../../models/Menu';
import { DropdownMenu } from '../../../dropdownMenu';
import { Dropdown } from '../../../dropdown';
import SubNavLink from '../subNavLink';
import { connect } from 'react-redux';
import { AppState } from '../../../../interfaces/appState';
import { isSubNavItemActiveSelector } from '../../../../selectors/activeItemSelectors';

export interface ISubNavMenuProps {
  item: IMenuItemModel;
  isActive: boolean;
}

const SubNavMenu: FunctionalComponent<ISubNavMenuProps> = ({ item, isActive }) => {
  const menu = item as IMenuModel;
  const { parentItem = {} } = menu;

  return (
    <Dropdown
      openComponent={(isOpen, handleMouseEnter, handleMouseLeave, wrapperRef) => (
        <SubNavLink
          isActive={isActive}
          item={parentItem as IMenuItemModel}
          isDropdown
          isOpen={isOpen}
          handleMouseEnter={handleMouseEnter}
          handleMouseLeave={handleMouseLeave}
          wrapperRef={wrapperRef}
        />
      )}
      top="26px"
    >
      <DropdownMenu menu={menu} />
    </Dropdown>
  );
};

const mapStateToProps = (state: AppState, { item }: ISubNavMenuProps) => {
  const { id } = item as IMenuModel;
  return {
    isActive: isSubNavItemActiveSelector(state, id),
  };
};

export default connect(mapStateToProps)(SubNavMenu);
