import { IUnificationScriptInitialConfig } from '../interfaces/IUnificationScript';
import { ModulesState } from '../reducers/modulesReducer';
import CartClient from '../gateways/modulesStorage/CartClient';
import SsoClient from '../gateways/modulesStorage/SsoClient';
import SearchClient from '../gateways/modulesStorage/SearchClient';
import LocaleClient from '../gateways/modulesStorage/LocaleClient';
import { IEventStateRecord } from '../reducers/eventReducer';
import { ThunkDispatch } from 'redux-thunk';
import { IInitializeModulesAction } from '../actions/interfaces/IModulesActions';
import { AppState } from '../interfaces/appState';
import { getEventHandler } from '../middlewares/event';
import { SupportedEvents } from '../enums/event';
import { getLocaleFromDashedLanguage } from '../utils/locale';

import { getCartSummary } from '../actions/cartActions';
import { getUserGroupsActionCreator } from '../actions/groupActions';
import { getUserEntitlements } from '../actions/ssoActions';
import { IModulesStateRecord } from '../interfaces/IModules';
import MainClient from '../gateways/modulesStorage/MainClient';

export const getAllFromStorage = async (): Promise<IModulesStateRecord> => {
  const cart = await CartClient.get();
  const sso = await SsoClient.get();
  const locale = await LocaleClient.get();
  const search = await SearchClient.get();
  const main = await MainClient.get();

  return ModulesState({
    main,
    cart,
    sso,
    locale,
    search,
  });
};

export const saveAllToStorage = ({
  main,
  cart,
  sso,
  search,
  locale,
}: IModulesStateRecord): Promise<void[]> =>
  Promise.all([
    MainClient.set(main),
    CartClient.set(cart),
    SsoClient.set(sso),
    LocaleClient.set(locale),
    SearchClient.set(search),
  ]);

export const mergeAllModules = (
  fromConfig: IUnificationScriptInitialConfig,
  fromStorage: IModulesStateRecord,
): IModulesStateRecord => {
  const { main, sso, cart, search, locale: storageLocale } = fromStorage;
  const {
    topNav,
    subNav,
    footer,
    appId,
    isDebugEnabled,
    userIP,
    isCustomNavigationEnabled,
  } = fromConfig;

  const referrerAppId = main.getReferrerAppId(appId);

  const locale = fromConfig.locale
    ? storageLocale.mergeFromConfig(fromConfig.locale)
    : storageLocale;
  const mergedLocale = locale.mergeFromConfig({
    selectedLocale: getLocaleFromDashedLanguage(locale.selectedLocale),
  });

  return fromStorage.merge({
    main: main?.merge({
      isCustomNavigationEnabled,
      topNav,
      subNav,
      footer,
      appId,
      userIP,
      isDebugEnabled,
      referrerAppId,
    }),
    sso: fromConfig.sso ? sso.mergeFromConfig(fromConfig.sso) : sso,
    cart: fromConfig.cart ? cart.mergeFromConfig(fromConfig.cart) : cart,
    search: fromConfig.search ? search.mergeFromConfig(fromConfig.search) : search,
    locale: mergedLocale,
  });
};

export const mergeFromStorage = (
  fromStorage: IModulesStateRecord,
  fromState: IModulesStateRecord,
): IModulesStateRecord => {
  const {
    cart: { itemsCount },
    sso,
    locale: { selectedLocale },
  } = fromStorage;

  return fromStorage.merge({
    main: fromState.main,
    search: fromState.search,
    sso: fromState.sso.mergeFromStorage(sso),
    cart: fromState.cart.merge({ itemsCount }),
    locale: fromState.locale.merge({ selectedLocale }),
  });
};

export const triggerSideEffectActions = (
  modules: IModulesStateRecord,
  dispatch: ThunkDispatch<AppState, null, IInitializeModulesAction>,
): void => {
  dispatch(getCartSummary(modules));
  dispatch(getUserGroupsActionCreator(modules.sso));
  dispatch(getUserEntitlements(modules.sso));
};

export const triggerSideEffectEvents = (
  fromStorage: IModulesStateRecord,
  fromState: IModulesStateRecord,
  events: IEventStateRecord,
): void => {
  const { dispatchEvent } = getEventHandler(events.get('events'));

  if (fromStorage.locale.selectedLocale !== fromState.locale.selectedLocale)
    dispatchEvent(SupportedEvents.onLocaleChange, fromStorage.locale);
};
