import { IWaiverModel } from '../models/Waiver';
import { ILocaleModel } from '../models/Locale';
import { ISsoModel } from '../interfaces/ISso';
import { postWaiverStatus } from '../services/waiverService';
import { getWaiverContent } from '../services/legalService';
import { modulesByKeySelector } from '../selectors/modulesSelectors';
import {
  IGetUserWaiverContentActionCreator,
  ISetUserWaiverAcceptedActionCreator,
  ISetUserWaiverAction,
  ISetNoWaiverRequiredAction,
  SET_NO_WAIVER_REQUIRED,
  SET_USER_WAIVER_PROFILE,
  SET_IS_WAIVER_ANONYMOUS_USERS_REQUIRED,
  ISetIsWaiverAnonymousUsersRequiredAction,
} from './interfaces/IWaiverActions';

export const setUserWaiverProfile = (waiver: IWaiverModel): ISetUserWaiverAction => ({
  type: SET_USER_WAIVER_PROFILE,
  payload: waiver,
});

export const setNoWaiverRequiredAction = (videoGuid: string): ISetNoWaiverRequiredAction => ({
  type: SET_NO_WAIVER_REQUIRED,
  payload: videoGuid,
});

export const setIsWaiverAnonymousUsersRequiredAction = (
  waiverRequired: boolean,
): ISetIsWaiverAnonymousUsersRequiredAction => ({
  type: SET_IS_WAIVER_ANONYMOUS_USERS_REQUIRED,
  payload: waiverRequired,
});

export const getUserWaiverContentActionCreator: IGetUserWaiverContentActionCreator = () => async (
  dispatch,
  getState,
) => {
  const { selectedLocale } = modulesByKeySelector(getState(), 'locale') as ILocaleModel;

  const waiverResponse = await getWaiverContent(selectedLocale);
  return dispatch(setUserWaiverProfile(waiverResponse));
};

export const setUserWaiverAcceptedActionCreator: ISetUserWaiverAcceptedActionCreator = (
  videoGuid: string,
  brandCode?: string,
) => async (dispatch, getState) => {
  const { selectedLocale } = modulesByKeySelector(getState(), 'locale') as ILocaleModel;
  const { accessToken = '', userInfo: { guid = '' } = {} } = modulesByKeySelector(
    getState(),
    'sso',
  ) as ISsoModel;

  if (guid && accessToken) {
    await postWaiverStatus(guid, videoGuid, selectedLocale, accessToken, brandCode);
  }

  return dispatch(setNoWaiverRequiredAction(videoGuid));
};
