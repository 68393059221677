/** @jsx h */
import { FunctionalComponent, h } from 'preact';
import Checkbox from '../../shared/components/checkbox';
import { CheckboxContainer } from './videoWaiverStyled';

export interface IVideoWaiverCheckbox {
  isChecked: boolean;
  onChange: (isChecked: boolean) => void;
}

const VideoWaiverCheckbox: FunctionalComponent<IVideoWaiverCheckbox> = ({
  children,
  isChecked,
  onChange,
}) => {
  return (
    <CheckboxContainer>
      <Checkbox isChecked={isChecked} onChange={onChange}>
        {children}
      </Checkbox>
    </CheckboxContainer>
  );
};

export default VideoWaiverCheckbox;
