import { SEARCH_TABS } from '../../enums/algolia';
import { SearchRoutes } from '../../enums/routes';

const { SEARCH } = SearchRoutes;
const {
  ALL,
  LESSONS,
  OTHER,
  PRODUCT,
  PROGRAMS,
  PROGRAM_MATERIALS,
  RECIPES,
  WORKOUTS,
} = SEARCH_TABS;

export const TEALIUM_PAGE_NAMES = {
  [`${SEARCH}/${ALL}`]: 'search-all',
  [`${SEARCH}/${LESSONS}`]: 'search-lessons',
  [`${SEARCH}/${OTHER}`]: 'search-other',
  [`${SEARCH}/${PRODUCT}`]: 'search-product',
  [`${SEARCH}/${PROGRAMS}`]: 'search-programs',
  [`${SEARCH}/${PROGRAM_MATERIALS}`]: 'search-program-materials',
  [`${SEARCH}/${RECIPES}`]: 'search-recipes',
  [`${SEARCH}/${WORKOUTS}`]: 'search-workouts',
};
