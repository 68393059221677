import IKeyValue from '../interfaces/IKeyValue';

export enum MapSourceLanguages {
  EN_US = 'en-us',
  ES_US = 'es-us',
  FR_FR = 'fr-fr',
  FR_CA = 'fr-ca',
  EN_GB = 'en-gb',
  EN_CA = 'en-ca',
}

enum ViolatorLanguages {
  SP = 'workout_violator_spanish',
  FR = 'workout_violator_french',
}

export const SourceLanguage: IKeyValue<ViolatorLanguages> = {
  [MapSourceLanguages.ES_US]: ViolatorLanguages.SP,
  [MapSourceLanguages.FR_CA]: ViolatorLanguages.FR,
  [MapSourceLanguages.FR_FR]: ViolatorLanguages.FR,
};

export enum SupportedLanguages {
  EN_US = 'en_us',
  ES_US = 'es_us',
  FR_FR = 'fr_fr',
  FR_CA = 'fr_ca',
  EN_GB = 'en_gb',
  EN_CA = 'en_ca',
}

export enum CountryList {
  US = 'US',
  FR = 'FR',
  CA = 'CA',
  GB = 'GB',
}

// These locales don't go through the standard flow for redirects
export const SPECIFIC_LOCALES = [SupportedLanguages.ES_US, SupportedLanguages.FR_FR];

export const DEFAULT_LOCALE = SupportedLanguages.EN_US;

export const localeByContryCode: IKeyValue<SupportedLanguages> = {
  CA: SupportedLanguages.EN_CA,
  GB: SupportedLanguages.EN_GB,
  US: SupportedLanguages.EN_US,
  FR: SupportedLanguages.FR_FR,
  // All other EU countries default to EN_GB
  AT: SupportedLanguages.EN_GB,
  BE: SupportedLanguages.EN_GB,
  BG: SupportedLanguages.EN_GB,
  HR: SupportedLanguages.EN_GB,
  CY: SupportedLanguages.EN_GB,
  CZ: SupportedLanguages.EN_GB,
  DK: SupportedLanguages.EN_GB,
  EE: SupportedLanguages.EN_GB,
  EL: SupportedLanguages.EN_GB,
  FI: SupportedLanguages.EN_GB,
  DE: SupportedLanguages.EN_GB,
  GR: SupportedLanguages.EN_GB,
  HU: SupportedLanguages.EN_GB,
  IE: SupportedLanguages.EN_GB,
  IT: SupportedLanguages.EN_GB,
  LV: SupportedLanguages.EN_GB,
  LT: SupportedLanguages.EN_GB,
  LU: SupportedLanguages.EN_GB,
  MT: SupportedLanguages.EN_GB,
  NL: SupportedLanguages.EN_GB,
  PL: SupportedLanguages.EN_GB,
  PT: SupportedLanguages.EN_GB,
  RO: SupportedLanguages.EN_GB,
  SK: SupportedLanguages.EN_GB,
  SI: SupportedLanguages.EN_GB,
  ES: SupportedLanguages.EN_GB,
  SE: SupportedLanguages.EN_GB,
};

export const localeByLanguageCode: IKeyValue<string> = {
  EN: SupportedLanguages.EN_US,
  ES: SupportedLanguages.ES_US,
  FR: SupportedLanguages.FR_FR,
};

export const countryCodeByLocale: IKeyValue<CountryList> = {
  [SupportedLanguages.EN_US]: CountryList.US,
  [SupportedLanguages.ES_US]: CountryList.US,
  [SupportedLanguages.FR_FR]: CountryList.FR,
  [SupportedLanguages.FR_CA]: CountryList.CA,
  [SupportedLanguages.EN_CA]: CountryList.CA,
  [SupportedLanguages.EN_GB]: CountryList.GB,
};

export const contryCodeByLanguageCode: IKeyValue<string> = {
  ga: 'IE',
  sv: 'SE',
  sl: 'SI',
  cs: 'CZ',
  da: 'DK',
  et: 'EE',
  lb: 'LU',
};
