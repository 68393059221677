/** @jsx h */
import { FunctionalComponent, h } from 'preact';
import { useCallback } from 'preact/hooks';
import { SupportedEvents } from '../../../../enums/event';
import { useEventHandler } from '../../../../providers/eventHandlerProvider';
import { IItemModel } from '../../../../models/Item';
import { ItemIcon, ItemLink, CoachLoginLink, ItemContainer } from '../../desktopNavStyled';

interface ICoachLoginProps {
  item: IItemModel;
}

const CoachLogin: FunctionalComponent<ICoachLoginProps> = ({ item }) => {
  const { anchorText, menuImageUrl } = item;

  const { dispatchEvent } = useEventHandler();

  const onLogin = useCallback(
    (event: Event) => {
      event.preventDefault();

      dispatchEvent(SupportedEvents.onLogIn);
    },
    [dispatchEvent],
  );

  return (
    <ItemContainer>
      <ItemLink item={item} onClick={onLogin}>
        {menuImageUrl && <ItemIcon alt={anchorText} src={menuImageUrl} hasContent={!!anchorText} />}
        <CoachLoginLink isBold>{anchorText}</CoachLoginLink>
      </ItemLink>
    </ItemContainer>
  );
};

export default CoachLogin;
