import { SupportedLanguages } from '../enums/locale';
const { EN_CA, EN_GB, FR_CA, FR_FR, EN_US, ES_US: ES_US_LOCALE } = SupportedLanguages;

export const ES_US = {
  aria_label_show_submenus: 'Presione la tecla de Shift + Barra espaciadora para mostrar submenus.',
  search_nav: 'Buscar',
  search_categories_connector: 'en',
  all: 'Todo {{nbHits}}',
  product: 'Tienda {{nbHits}}',
  program: 'Programa',
  programs: 'Programas {{nbHits}}',
  recipes: 'Recetas {{nbHits}}',
  workouts: 'Entrenamientos {{nbHits}}',
  lessons: 'Lecciones {{nbHits}}',
  other: 'Otro {{nbHits}}',
  programMaterials: 'Materiales del programa {{nbHits}}',
  search_results: "Buscar resultados para '{{- query}}'",
  search_noResults: 'No se han encontrado resultados',
  search_recentSearches: 'Búsquedas Recientes',
  search_clearAll: 'Limpiar todo',
  search_see_all: 'Ver todo',
  search_shopNow: 'Comprar Ahora',
  search_topResult: 'Resultado principal',
  search_viewProgram: 'Ver Programa',
  search_stats_result: '{{nbHits}} resultado',
  search_stats_results: '{{nbHits}} resultados',
  search_fitnesslevel_beginner: 'Principiante',
  search_fitnesslevel_intermediate: 'Intermedio',
  search_fitnesslevel_advanced: 'Avanzado',
  search_categories: 'Categorias',
  search_backToCategories: '< Volver a categorias',
  search_sortby_label: 'Ordenar por:',
  search_sort_title: 'A-Z',
  search_sort_featured: 'Destacado',
  search_sort_popularity: 'Popularidad',
  search_sort_newest: 'Lo más reciente',
  search_gender: 'Género',
  search_size: 'Talla',
  search_style: 'Estilo',
  search_subcategories: 'Subcategoría',
  search_filters: 'Filtros',
  search_shop_title: 'Tienda',
  search_chooseCategory: 'Escoge una categoría',
  search_applyFilters: 'Aplicar filtros',
  apparel: 'Apparel',
  gear: 'Gear',
  flavor: 'Flavor',
  gearType: 'Type of Gear',
  nutrition: 'Nutrición',
  sale: 'Sale',
  shakeology: 'Shakeology',
  challengePacks: 'Challenge Packs',
  tsp: 'Total Solution Packs',
  trainer: 'Entrenador / Experto',
  more_details: 'Más detalles',
  search_clearRefinements_clearAll: 'Limpiar todo',
  videoWaiver_waiverAgreementHeading: 'warning!',
  videoWaiver_waiverAgreementBody: `<p style="text-align:center; font-weight: bold;">&#9888;<span style="text-decoration: underline;">¡ADVERTENCIA!</span>&#9888;</p>
  <p style="text-align:center; font-weight: bold; text-decoration: underline;">¡Queremos mantenerte a salvo!</p>
  <p style="text-align:center; font-weight: bold; text-decoration: underline;">Consulta con tu médico, evalúa tu nivel de fitness y sigue todas las instrucciones de seguridad antes de comenzar el uso de cualquier programa de ejercicios, plan de nutrición y/o equipo.</p>
  <p>Beachbody brinda una variedad de programas de ejercicios, algunos de los cuales son de gran exigencia física y de alta intensidad. Por esta razón, debes escuchar a tu cuerpo, usar el sentido común, tomar descansos e hidratarte cuando sea necesario para evitar lesiones. Si en algún momento sientes alguna molestia, dolor, mareo, aturdimiento, falta de aire o náuseas, detén el ejercicio inmediatamente y consulta a tu médico. Un entrenamiento incorrecto o excesivo puede provocar lesiones graves o la muerte.</p>
  <p>Cuando realices cualquier rutina de ejercicios o actividad al aire libre, ten en cuenta tus alrededores y evita cualquier cosa que pueda hacerte daño o interrumpir tu ejercicio, especialmente si caminas o corres por áreas públicas, como aceras y calles.</p>
  <p>Si tienes alguna condición médica única o particular, como diabetes, asma o embarazo, o tienes antecedentes de problemas de rodilla, tobillo, cadera, hombro o columna vertebral (espalda o cuello), debes consultar con tu médico para entender todos los posibles riesgos y complicaciones del uso de nuestros programas de ejercicios, planes de nutrición y/o equipo, y debes recibir su aprobación antes de comenzar. No hacerlo podría resultar en una lesión significativa para ti y los demás (incluyendo, en su caso, a tu hijo nonato). <span style="font-weight:bold;">Al utilizar cualquier programa de ejercicios, plan de nutrición y/o equipo, asumes todos los peligros y riesgos de lesión o muerte.</span></p>
  <p style="text-align: center; font-weight: bold; text-decoration: underline; margin-bottom: 0; font-size: larger;">USO SEGURO DEL EQUIPO</p>
  <p style="text-align: center; font-weight: bold; text-decoration: underline; margin-top: 0;">FITNESS EQUIPMENT PRESENTS HAZARDS WHICH, IF NOT AVOIDED, EL EQUIPO DE FITNESS PRESENTA PELIGROS QUE, SI NO SE EVITAN, PUEDEN CAUSAR LESIONES GRAVES O LA MUERTE</p>
  <p>Algunos programas podrían utilizar equipo tal como la bicicleta de fitness Myx, cuerdas para saltar, ligas de resistencia, bandas, barras para elevaciones de barbilla, bancas, pelotas de estabilidad, discos de deslizamiento, pesas y otros equipos que, si no se usan correctamente, podrían provocar lesiones graves o la muerte. Revisa cuidadosamente todas las instrucciones y manuales de seguridad, cuidado y uso antes de empezar.</p>
  <p style="margin-bottom: 0;">Sigue estas recomendaciones para tu seguridad:</p>
  <ul style="margin-top: 0;">
    <li>Usa cualquier equipo que haya aparecido en las rutinas <span style="text-decoration:underline;">solo</span> como se ha demostrado, utilizando la postura adecuada.</li>
    <li>Inspecciona cualquier equipo en busca de desgaste o daños antes de cada uso y abstente de utilizar cualquier equipo que luzca dañado, desgastado o defectuoso.</li>
    <li>Mantén a los niños, las mascotas y cualquier otro obstáculo alejados del equipo y del área de ejercicio en todo momento.</li>
    <li>Utiliza siempre un anclaje seguro, adecuado y estable para cualquier equipo que requiera estar sujetado o en suspensión.</li>
    <li>Siempre toma precauciones durante el uso de cualquier equipo para asegurarte de no perder el agarre o el control, como, por ejemplo, cerciorarte de no tener las manos mojadas o sudorosas.</li>
    <li>Asegúrate de que las ligas de ejercicio estén firmemente sujetas y montadas para evitar que se deslicen, se rompan o se retraigan y te lesionen a ti o cualquier otra persona.</li>
    <li>Asegúrate de que la banca para rutinas y la barra para elevaciones de barbilla sean estables, lo suficientemente fuertes como para sostener tu peso, y que no se muevan durante su uso. Si crees que no puedes hacer ejercicios de forma segura usando tu banca o tu barra para elevaciones de barbilla, o si no tienes el equipo apropiado, debes hacer las versiones modificadas.</li>
  </ul>
  <p style="text-align: center; font-weight: bold; text-decoration: underline; font-size: larger;">USO SEGURO DE LA BICICLETA MYX</p>
  <p style="margin-bottom: 0;">Además de las advertencias anteriores, para tu seguridad al utilizar la bicicleta de fitness Myx, la tableta y los accesorios relacionados (la "bicicleta"), debes:</p>
  <ul style="margin-top: 0;">
    <li>Antes de empezar, revisar y seguir atentamente el manual del producto y los tutoriales instructivos en video, para garantizar la configuración, el uso y el funcionamiento correctos y seguros de la bicicleta.</li>
    <li>Utilizar la bicicleta solo después de haber fijado bien el asiento y el manillar en su sitio.</li>
    <li>Mantener a los niños, las manos, las mascotas, la ropa suelta, los cordones de zapatos, las joyas y cualquier otro objeto alejado de las piezas móviles.</li>
    <li>Tener cuidado al montar y desmontar.</li>
    <li>No utilizar, almacenar ni operar la bicicleta al aire libre ni en lugares húmedos o mojados.</li>
    <li>Utilizar la bicicleta en una superficie sólida y nivelada.</li>
    <li>Respetar todas las limitaciones de peso: el peso del usuario no debe superar 350 libras (158 kg).</li>
    <li>Respetar todas las restricciones de altura: los usuarios deben medir entre 4'11" y 6'8" (entre 1.5 m y 2.03 m).</li>
    <li>Respetar las restricciones de edad, tal y como se indica en el manual del producto.</li>
    <li>Respetar el espacio libre mínimo requerido alrededor de la bicicleta, tal y como se indica en el manual del producto.</li>
    <li>Hacer las rutinas de la bicicleta Myx <span style="text-decoration: underline;">solo con</span> la bicicleta Myx (y nunca con una bicicleta de calle, de carretera o de cualquier otro tipo).</li>
  </ul>
  <p>Consulta a tu médico antes de comenzar un entrenamiento de frecuencia cardíaca, especialmente si estás embarazada, eres menor de 18 años, tienes alguna enfermedad cardíaca, pulmonar o de otro tipo, o no sabes si debes elevar tu frecuencia cardíaca al máximo. Si eres menor de 18 años, también debes obtener el permiso de tus padres o tutores antes de empezar. La frecuencia cardíaca, las calorías, la cadencia y otras métricas y valores mostrados en la bicicleta durante su uso pueden ser inexactos y deben utilizarse e interpretarse solo como referencia.</p>
  <p>En la máxima medida permitida por la ley, Beachbody, LLC y sus empresas matrices, afiliadas y subsidiarias no serán responsables ante ninguna persona o entidad por ninguna lesión, muerte, daño o pérdida causada, o presuntamente causada, directa o indirectamente por cualquier programa de ejercicios, rutina, suplemento o plan de nutrición, equipo (incluyendo, entre otros, la bicicleta de fitness Myx) o consejo, o por cualquier otro producto, servicio o material.</p>`,
  videoWaiver_waiverAgreementCheckboxLabel:
    'Al marcar la casilla y hacer clic en "Aceptar", reconozco que he leído, entiendo y estoy de acuerdo con esta advertencia.',
  videoWaiver_waiverAgreementAcceptButtonLabel: 'aceptar',
  videoWaiver_waiverAgreementDeclineButtonLabel: 'rechazar',
  videoPlayer_closeButton: 'Close',
  workout_pdf: '{{duration}}  |  {{pdfCount}} PDF',
  workout_pdfs: '{{duration}}  |  {{pdfCount}} PDFs',
  workout_type: '{{duration}}  |  {{workoutType}}',
  workout_type_and_date: '{{duration}}  |  {{workoutType}}  |  {{filmDate}}',
  sign_in: 'Iniciar Sesión Como',
  add_new: 'Agregar Nuevo',
  remove: 'Eliminar',
  coach_id: 'ID de Partner: {{coachId}}',
  my_coach: 'Mi partner de BODi',
  backToCategories: 'Regresar a Categorías',
  title: 'Programas',
  subtitle: '¡Hacer ejercicio es mejor en equipo!',
  joinAGroup: 'Únete a un grupo.',
  filters: 'Filtros',
  placeholder: 'Buscar Programas',
  programType: 'Tipo de Programa',
  duration: 'Duración de Programa',
  apply: 'Aplicar filtros',
  noResultsDescription:
    'Lamentablemente, no pudimos devolver ninguno que coincida con tu búsqueda.',
  noResultsTitle: 'Beachbody se trata de resultados.',
  beginner: 'Principiante',
  intermediate: 'Intermedio',
  advanced: 'Avanzado',
  findProgram: 'Encuentra un programa completo aquí.',
  workoutType: 'Tipo de Entrenamiento',
  bodyPart: 'Parte del cuerpo',
  noEquipment: 'Sin equipo',
  fitnessLevel: 'Nivel de fitness',
  workouts_noResultsDescription:
    'Intenta aflojar tu búsqueda y comienza a ver resultados al instante.',
  workouts_noResultsTitle: 'Nos has estirado demasiado.',
  workouts_title: 'Entrenamientos',
  workouts_subtitle: '¿Necesitas más orientación?',
  workouts_duration: 'Duración',
  workouts_placeholder: 'Buscar Entrenamientos',
  recipes_title: 'Videos de recetas',
  meal: 'Comida',
  dish: 'Plato',
  mainIngredient: 'Ingrediente principal',
  recipes_noResultsDescription:
    'Intenta cambiar tus criterios de búsqueda y encuentra algo para comer.',
  recipes_subtitle:
    'Para acceder a nuevas recetas deliciosas todos los meses, planes de alimentación, listas de compra y una comunidad exclusiva que te brinda apoyo, inscríbete en ',
  recipes_link: 'Nutrition+.',
  recipes_linkURL:
    'https://www.teambeachbody.com/shop/us/d/NutritionPlusDigitalStreaming?locale=es_US&destPage=d%2fNutritionPlusDigitalStreaming%3fICID%3dTBB_HERO_SHOPNOW_NUTPLUS',
  recipes_noResultsTitle: '¿Hambriento de algunos resultados?',
  recipes_definitions_title: 'Definiciones para la sección “Apto para”',
  recipes_definitions_paleo_friendly_title: 'Paleo',
  recipes_definitions_paleo_friendly_description:
    'Estas recetas incluyen solamente carnes, pescado, huevos, vegetales, frutas, frutos secos, semillas, grasas saludables y aceites. No contienen granos, legumbres, lácteos, azúcares refinadas, alimentos procesados ni ingredientes que en el pasado no se hubieran obtenido de la caza y recolección.',
  recipes_definitions_gluten_free_title: 'Sin gluten',
  recipes_definitions_gluten_free_description:
    'Estas recetas fueron diseñadas sin gluten y, por tanto, no contienen gluten. Si llevas una dieta sin gluten, recuerda mirar siempre las etiquetas nutricionales para confirmar que los ingredientes que usas sean 100 % libres de gluten, ya que muchos alimentos se suelen procesar en instalaciones donde también se procesan trigo y otros granos.',
  recipes_definitions_vegetarian_title: 'Vegetariano',
  recipes_definitions_vegetarian_description:
    'Estas recetas son ovolactovegetarianas y no contienen carne de ave, res o pescado. Lee las etiquetas de los productos para ver todos los ingredientes y asegurarte de que este sea el caso.',
  recipes_definitions_vegan_title: 'Vegano',
  recipes_definitions_vegan_description:
    'Estas recetas no contienen productos animales. Lee las etiquetas de los productos para ver todos los ingredientes y asegurarte de que este sea el caso.',
  recipes_definitions_high_fiber_title: 'Rico en fibra',
  recipes_definitions_high_fiber_description:
    'Estas recetas contienen al menos un 20 % del valor diario recomendado de fibra.',
  recipes_definitions_no_dairy_title: 'Sin lácteos',
  recipes_definitions_no_dairy_description: 'Estas recetas no contienen ingredientes lácteos.',
  recipes_definitions_timed_nutrition_title: 'Nutrición programada',
  recipes_definitions_timed_nutrition_description:
    'Las recetas con el ícono de [NP] fueron diseñadas para usarse con el sistema de nutrición programada de Ultimate Portion Fix (UPF). Las porciones equivalentes de los recipientes de esta receta coinciden o consisten de un subconjunto de recipientes que coinciden con al menos una de las comidas de nutrición programada de UPF. Revisa tu plan específico para asegurarte de que funciona con tus recipientes.',
  recipes_definitions_high_protein_title: 'Rico en proteína',
  recipes_definitions_high_protein_description:
    'Estas recetas contienen al menos un 20 % del valor diario recomendado de proteína.',
  recipes_definitions_quick_easy_title: 'Fácil y rápido',
  recipes_definitions_quick_easy_description:
    'Estas recetas fueron diseñadas para prepararse en 15 minutos o menos, y requieren poca o ninguna cocción.',
  worksWellFor: 'Funciona bien para',
  containers: 'Contenedores',
  programsMaterials_title: 'Materiales del programa',
  other_title: 'Otro',
  faq: 'https://faq.es.beachbody.com/app/answers/list/kw/{{query}}/search/1',
  faq_title: 'Buscar ‘{{query}}’ en nuestros FAQs',
  faq_cancel: 'Cancelar',
  faq_cancelAccout: 'Cancelar Cuenta',
  [EN_CA]: 'Canada (English)',
  [FR_CA]: 'Canada (Français)',
  [FR_FR]: 'France (Français)',
  [EN_US]: 'U.S.A (English)',
  [ES_US_LOCALE]: 'U.S.A (Español)',
  [EN_GB]: 'United Kingdom (English)',
  countryAndLanguage: 'País & Idioma',
  localeChangeNote: 'Ten en cuenta que se eliminarán los artículos de tu carrito de compras.',
  currentShopIn: 'Parece que estás haciendo compras en:',
  newShopIn: '¿Deseas hacer compras en',
  yes: 'Sí',
  no: 'No',
  ok: 'Ok',
  countryAndLanguageUpdated: 'País e idioma actualizados',
  viewOnlyText:
    'Estás entrando a Team Beachbody <image /> {{locale}} en modo de visualización. Si deseas hacer cambios a la configuración de tu perfil para comprar en este país, llama a Servicio a coaches',
  others: 'Otro',
  signUp_redirectModal_text:
    'To sign up for Beachbody On Demand®, we need to send you to our Team Beachbody Shop. Proceed?',
  signUp_redirectModal_freeRegText: 'Create an account sign up is free. No credit card required.',
  signUp_redirectModal_button_yes: 'Yes',
  signUp_redirectModal_button_no: 'No',
  signUp_redirectModal_shopPath: '/shop/fr{{- extraPath}}/bod',
  signUp_unavailableModal_text: 'We are sorry this program is currently unavailable.',
  signUp_unavailableModal_button_close: 'Close',
  search_currentRefinements_rangeMins: '{{min}}-{{max}} Mins',
  search_rangeSlider_summary: '{{min}} - {{max}} min',
  search_moreResults: 'Más',
  your_coach: 'Tu partner de BODi, {{firstName}} {{lastName}}',
  search_exit: 'Salir de la Busqueda',
  top_categories: 'Categorías Principales',
  withTrainer: 'con {{trainer}}',
  rating_title: '¡Califica tu experiencia!',
  rating_placeholder: 'Cuéntanos más sobre tu experiencia',
  rating_submit: 'Enviar',
  rating_skip: 'Saltar',
  rating_howWasIt: '¿Qué te pareció?',
  product_card_modal_buy_now: 'Comprar Ahora',
  currency: '${{price}}',
  workout_violator_spanish: 'ES',
  workout_violator_french: 'FR',
  workouts_all_levels: 'Todos los niveles',
};
