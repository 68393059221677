import { IUtagData } from '../../interfaces/ITealium';

export const LINK_TRACKING = 'link_tracking';

export const LINK_TRACKING_EVENT_TEMPLATE = ({
  appId,
  component,
  siteSection,
  country,
  path,
}: IUtagData): IUtagData => ({
  link_tracking: {
    event: `${appId}:${siteSection}:${country}:${component}:${path}`,
  },
});

export default LINK_TRACKING_EVENT_TEMPLATE;
