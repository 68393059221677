import CrossDomainStorageClient from '../crossDomainStorageClient';
import { ICrossDomainStorageUser } from '../interfaces/ICrossDomainStorage';
import Main, { IMain, IMainModel, IRawMain } from '../../models/Main';

const MAIN_KEY = 'main';

const { get, set, del } = CrossDomainStorageClient<IRawMain>();

const MainClient: ICrossDomainStorageUser<IMainModel> = {
  get: async () => {
    return Main.createFromRaw((await get(MAIN_KEY)) as IMain);
  },
  set: (main) => set(MAIN_KEY, main.serialize()),
  del: () => del(MAIN_KEY),
};

export default MainClient;
