import styled, { css, DefaultTheme } from 'styled-components';
import { Section } from '../autoComplete/suggestionContainerStyled';
import { Media, textTruncate } from '../../styles/media';
import { Body1, Body3, Body4 } from '../../shared/typography';

const { tabletUp } = Media;

export const Container = styled.div`
  display: flex;
  flex-direction: ${({ isMobileColumn }) => (isMobileColumn ? 'column' : 'row')};

  ${tabletUp`
    flex-direction: column;
  `};
`;

export const TopResultSection = styled(Section)`
  border-bottom: ${({ theme }) => `1px solid ${theme.lighterGray}`};
  display: block;

  ${tabletUp`
    ${css<DefaultTheme>`
      border-bottom: 0;
      border-left: 1px solid ${({ theme }) => theme.lighterGray};
      display: flex;
      flex-direction: column;
      padding-top: 25px;
    `};
  `};
`;

export const Image = styled.img`
  height: 83px;
  width: 130px;
  cursor: pointer;

  ${tabletUp`
    height: 127px;
    width: 200px;
  `};
`;

export const Description = styled(Body4)`
  color: ${({ theme }) => theme.medGray};
  margin: 0 16px;
  min-height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 166px;
  ${textTruncate(3)};

  ${tabletUp`
    width: 198px;
    margin: 8px 0;
    ${textTruncate(2)};
  `};
`;

export const Link = styled(Body3).attrs({
  as: 'a',
})`
  color: ${({ theme }) => theme.oneAppBlue};
  cursor: pointer;
  margin: 8px 16px;

  ${tabletUp`
    margin: 8px 0 0;
  `};
`;

export const Title = styled(Body1)`
  margin: 8px 16px 0;
`;
