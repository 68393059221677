import { useState, useCallback } from 'preact/hooks';
import useWindowDimensions from './useWindowDimensions';
import useScrollbarDimensions from './useScrollbarDimensions';

interface IUseRightAlignOnViewportOverflow {
  translate: number | null;
  ref: (node?: HTMLElement) => void;
}

// Used to add `ref` to an element. When its position or the viewport changes, this hook returns how
// far the element should be translated from its original position through the `translate` property
export default function useRightAlignOnViewportOverflow(): IUseRightAlignOnViewportOverflow {
  const [translate, setTranslate] = useState<number | null>(null);
  const [prevWindowWidth, setPrevWindowWidth] = useState(0);
  const { width: windowWidth } = useWindowDimensions();
  const { width: scrollbarWidth } = useScrollbarDimensions();

  const ref = useCallback(
    (node?: HTMLElement) => {
      if (!node) return;
      if (translate !== null && windowWidth === prevWindowWidth) return;

      const { right } = node.getBoundingClientRect();
      const newTranslate = right - windowWidth + 10 + scrollbarWidth + (translate || 0);
      setTranslate(newTranslate > 0 ? newTranslate : 0);
      setPrevWindowWidth(windowWidth);
    },
    [prevWindowWidth, translate, scrollbarWidth, windowWidth],
  );

  return { translate, ref };
}
