import { Record } from 'immutable';
import { IModelStatic } from '../interfaces/IModel';
import { IHit } from '../interfaces/ISearch';
import { SearchResponse } from '@algolia/client-search';
import Workout, { IWorkoutModel } from './Workout';
import Program, { IProgramModel } from './Program';
import Lesson from './Lesson';
import { ALGOLIA_SEARCH_INDICES, INDICES_LABEL, SEARCH_TABS } from '../enums/algolia';
import Product, { IProductModel, IRawProduct } from './Product';
import { IRawProgram } from '../interfaces/IProgram';
import { IRawWorkout } from '../interfaces/IWorkout';
import { IRawRecipe } from '../interfaces/IRecipe';
import Recipe, { IRecipeModel } from './Recipe';
import { IRawProgramMaterial } from '../interfaces/IProgramMaterial';
import ProgramMaterial, { IProgramMaterialModel } from './ProgramMaterial';

export interface ISearchResponseModel extends Record<SearchResponse<IHit>>, SearchResponse<IHit> {}
export type IGetHitByType =
  | IProgramModel
  | IWorkoutModel
  | IProductModel
  | IRecipeModel
  | IProgramMaterialModel;

const defaultProps: SearchResponse<IHit> = {
  hits: [],
  nbHits: 0,
  hitsPerPage: 0,
  page: 0,
  nbPages: 0,
  processingTimeMS: 0,
  exhaustiveNbHits: false,
  query: '',
  params: '',
};

const { PROGRAMS } = ALGOLIA_SEARCH_INDICES;

const validateIndexName = (indexName: string, indexSuffix: keyof INDICES_LABEL | string) =>
  indexName.toLowerCase().includes(indexSuffix.toString().toLowerCase());

interface ISearchResponseStaticModel
  extends IModelStatic<ISearchResponseModel, SearchResponse<IHit>> {
  getHitByType: (rawHit: IHit, index: string) => IGetHitByType;
}

const SearchResponseModel: ISearchResponseStaticModel = class
  extends Record<SearchResponse<IHit>>(defaultProps, 'SearchResponse')
  implements ISearchResponseModel {
  static createFromRaw(rawSearchResponse?: SearchResponse<IHit>): ISearchResponseModel {
    const hits =
      rawSearchResponse &&
      rawSearchResponse.hits?.map((hit: IHit) =>
        this.getHitByType(hit, rawSearchResponse?.index || PROGRAMS),
      );
    return new this({
      ...(rawSearchResponse || {}),
      hits,
    });
  }

  static getHitByType(rawHit: IHit, index: string): IGetHitByType {
    if (validateIndexName(index, INDICES_LABEL.WORKOUTS)) {
      const workoutHit = rawHit as IRawWorkout;
      return Workout.createFromRaw(workoutHit);
    }

    if (validateIndexName(index, INDICES_LABEL.PRODUCT)) {
      const productHit = rawHit as IRawProduct;
      return Product.createFromRaw(productHit);
    }

    if (validateIndexName(index, INDICES_LABEL.LESSONS)) {
      const workoutHit = rawHit as IRawWorkout;
      return Lesson.createFromRaw(workoutHit);
    }

    if (validateIndexName(index, INDICES_LABEL.OTHER)) {
      const workoutHit = rawHit as IRawWorkout;
      return Workout.createFromRaw(workoutHit);
    }

    if (validateIndexName(index, INDICES_LABEL.RECIPES)) {
      const recipeHit = rawHit as IRawRecipe;
      return Recipe.createFromRaw(recipeHit);
    }

    if (validateIndexName(index, SEARCH_TABS.PROGRAM_MATERIALS)) {
      const programMaterialHit = rawHit as IRawProgramMaterial;
      return ProgramMaterial.createFromRaw(programMaterialHit);
    }

    const programHit = rawHit as IRawProgram;
    return Program.createFromRaw(programHit);
  }
};

export default SearchResponseModel;
