import { Record } from 'immutable';
import ItemModel, { IItemModel, ItemTypes } from '../models/Item';
import { PlaceholderEnum } from '../models/Placeholder';

import DefaultItem from '../components/desktopNav/items/defaultItem';
import Groups from '../components/desktopNav/items/groups';
import Logout from '../components/desktopNav/items/logout';
import PrimaryButton from '../components/desktopNav/items/primaryButton';
import SecondaryButton from '../components/desktopNav/items/secondaryButton';
import UserProfileInfo from '../components/desktopNav/items/userProfileInfo';
import MenuModel, { IMenuItemModel } from '../models/Menu';
import Item from '../components/dropdownItem';
import Menu from '../components/desktopNav/items/menu';
import LoginButton from '../components/desktopNav/items/loginButton';
import SignUpButton from '../components/desktopNav/items/signUpButton';
import CoachLogin from '../components/desktopNav/items/coachLogin';
import AccountSwitch from '../components/desktopNav/items/accountSwitch';

export type DropdownItemComponent =
  | typeof DefaultItem
  | typeof PrimaryButton
  | typeof SecondaryButton
  | typeof UserProfileInfo
  | typeof Groups
  | typeof Logout
  | typeof Item
  | typeof Menu;

interface IDropdownItemMapper {
  [itemTypeName: string]: DropdownItemComponent;
}

export interface IDropdownMenuFactory {
  mapDropdownItem(item: IMenuItemModel): DropdownItemComponent;
}

const MenuDropdownFactory = (): IDropdownMenuFactory => {
  const dropdownItemMapper: IDropdownItemMapper = {
    [ItemTypes.default]: DefaultItem,
    [ItemTypes.placeholder]: DefaultItem,
    [ItemTypes.subItem]: DefaultItem,
    [ItemTypes.featuredLink]: DefaultItem,
    [ItemTypes.buttonPrimary]: PrimaryButton,
    [ItemTypes.buttonSecondary]: SecondaryButton,
  };

  const menuItemFactoryMapper: IDropdownItemMapper = {
    [MenuModel.displayName]: Menu,
    [ItemModel.displayName]: Item,
  };

  const dropdownItemPlaceholderMapper: IDropdownItemMapper = {
    [PlaceholderEnum.profileInfo]: UserProfileInfo,
    [PlaceholderEnum.accountSwitch]: AccountSwitch,
    [PlaceholderEnum.groups]: Groups,
    [PlaceholderEnum.logOut]: Logout,
    [PlaceholderEnum.loginButton]: LoginButton,
    [PlaceholderEnum.signUpButton]: SignUpButton,
    [PlaceholderEnum.coachLogin]: CoachLogin,
  };

  return {
    mapDropdownItem(item) {
      const { itemType, placeholder: { type: placeholderType = '' } = {} } = item as IItemModel;
      const componentType = Record.getDescriptiveName(item);

      return (
        dropdownItemPlaceholderMapper[placeholderType] ||
        dropdownItemMapper[itemType] ||
        menuItemFactoryMapper[componentType]
      );
    },
  };
};

export default MenuDropdownFactory;
