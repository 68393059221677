import { Record } from 'immutable';
import { Reducer } from 'redux';
import type { VideoPlayerProps } from 'web-video-player/dist/typings';
import {
  SET_VIDEO_PLAYER_ACTIONS_MAP,
  SET_VIDEO_PLAYER_OPEN,
  SET_VIDEO_PLAYER_PROPS,
  SET_IS_WAIVER_CHECKED,
  SET_IS_WAIVER_REQUIRED,
  SET_IS_RATING_MODAL_OPEN,
  SET_IS_PROMO_SCREEN_OPEN,
  SET_IS_WAIVER_LOADING,
} from '../actions/interfaces/IVideoPlayerActions';

export type IVideoPlayerProps = Partial<VideoPlayerProps> & {
  config?: Partial<VideoPlayerProps['config']>;
  videoBrandCode?: string;
  isAnonymousUser?: boolean;
  isWorkoutVideo?: boolean;
  isMobile?: boolean;
  skipPromoScreen?: boolean;
};

export interface IVideoPlayerState {
  isOpen: boolean;
  isWaiverChecked: boolean;
  isWaiverRequired: boolean;
  isWaiverLoading: boolean;
  isRatingModalOpen: boolean;
  isPromoScreenOpen: boolean;
  actionsMap: unknown;
  playerProps: IVideoPlayerProps | null;
}

export interface IVideoPlayerStateRecord extends Record<IVideoPlayerState>, IVideoPlayerState {}

export const VideoPlayerState = Record<IVideoPlayerState>({
  isOpen: false,
  isWaiverChecked: false,
  isWaiverRequired: true,
  isWaiverLoading: false,
  isRatingModalOpen: false,
  isPromoScreenOpen: false,
  actionsMap: null,
  playerProps: null,
});

const initialState = VideoPlayerState();

export const videoPlayerReducer: Reducer<IVideoPlayerStateRecord> = (
  state = initialState,
  action,
) => {
  const { type, payload } = action;

  switch (type) {
    case SET_VIDEO_PLAYER_OPEN: {
      return state.set('isOpen', payload);
    }
    case SET_VIDEO_PLAYER_ACTIONS_MAP: {
      return state.set('actionsMap', payload);
    }
    case SET_VIDEO_PLAYER_PROPS: {
      return state.set('playerProps', payload);
    }
    case SET_IS_WAIVER_CHECKED: {
      return state.set('isWaiverChecked', payload);
    }
    case SET_IS_WAIVER_REQUIRED: {
      return state.set('isWaiverRequired', payload);
    }
    case SET_IS_WAIVER_LOADING: {
      return state.set('isWaiverLoading', payload);
    }
    case SET_IS_RATING_MODAL_OPEN: {
      return state.set('isRatingModalOpen', payload);
    }
    case SET_IS_PROMO_SCREEN_OPEN: {
      return state.set('isPromoScreenOpen', payload);
    }
    default:
      return state;
  }
};
