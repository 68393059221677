import fetch from 'cross-fetch';
import { InMemoryCache } from '@apollo/client/cache';
import { ApolloClient, HttpLink, NormalizedCacheObject } from '@apollo/client/core';
import IKeyValue from '../../../interfaces/IKeyValue';
import defaultConfig from '../../../../config.default.json';
import config from '../../../../config.json';
import IConfig from '../../../interfaces/IConfig';
import { SupportedLanguages } from '../../../enums/locale';

const parsedConfig: IConfig = {
  ...defaultConfig,
  ...config,
  RELATIVE_PATH: '',
};

const { API_URL, API_KEY } = parsedConfig.APPSYNC;
const APPSYNC_HEADERS: IKeyValue<string> = { 'x-api-key': API_KEY };

export const getApolloClient = (
  locale?: string,
  withCache = false,
): ApolloClient<NormalizedCacheObject> =>
  new ApolloClient({
    uri: API_URL,
    link: new HttpLink({
      uri: API_URL,
      fetch,
      headers: {
        ...APPSYNC_HEADERS,
        'Accept-Language': locale || SupportedLanguages.EN_US,
        ...(!withCache ? { 'force-cache-behavior': 'no-cache' } : {}),
      },
    }),
    cache: new InMemoryCache(),
  });
