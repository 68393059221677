export enum GlobalSearchLocale {
  search = 'search_nav',
  searchResults = 'search_results',
  product = 'product',
  programs = 'programs',
  recipes = 'recipes',
  workouts = 'workouts',
  lessons = 'lessons',
  other = 'others',
  programMaterials = 'programMaterials',
  exitSearch = 'search_exit',
}
