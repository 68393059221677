import styled from 'styled-components';
import { Intro1Template } from '../intro1';

export const Intro2Template = {
  ...Intro1Template,
  fontWeight: 'normal',
};

const { fontSize, fontWeight, lineHeight, letterSpacing } = Intro2Template;

const Intro2 = styled.p`
  font-size: ${fontSize};
  font-weight: ${fontWeight};
  line-height: ${lineHeight};
  letter-spacing: ${letterSpacing};
`;

export default Intro2;
