import { FunctionalComponent, h } from 'preact';

import getOptimizedImage from '../../../utils/getOptimizedImage';

import { Container, Image, Title } from './promoCardStyled';

export interface IPromoCardProps {
  title: string;
  imageUrl: string;
  imageAlt?: string;
}

const PromoCard: FunctionalComponent<IPromoCardProps> = ({ title, imageUrl, imageAlt }) => (
  <Container>
    <Image src={getOptimizedImage({ imageUrl, width: '600' })} alt={imageAlt || ''} />
    <Title>{title}</Title>
  </Container>
);

export default PromoCard;
