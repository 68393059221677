import { SupportedEvents } from '../enums/event';
import { LoggedStatus } from '../enums/sso';
import GlobalUserEntitlement from '../gateways/globalUserEntitlements/globalUserEntitlements';
import { IConfigSso, ISso, ISsoModel } from '../interfaces/ISso';
import { modulesByKeySelector, modulesStateSelector } from '../selectors/modulesSelectors';
import { triggerSideEffectActions } from '../services/modulesService';
import { wipeCart } from './cartActions';
import CoachInfo from '../models/CoachInfo';
import Sso from '../models/Sso';
import {
  IGetUserEntitlementList,
  ILogInUserActionCreator,
  ILogoutUserActionCreator,
  IUpdateAccessTokenActionCreator,
  IUpdateAccountActionCreator,
  IUpdateAccountsActionCreator,
  IUpdateCoachInfoActionCreator,
  IUpdateHasGroupsActionCreator,
  IUpdateSsoConfigActionCreator,
  IUpdateUserInfoActionCreator,
  IUpdateUserLoginStatusActionCreator,
  UPDATE_LOGIN_STATUS,
  UPDATE_USER_INFO,
  UPDATE_COACH_INFO,
  UPDATE_SSO_CONFIG,
  UPDATE_ACCESS_TOKEN,
  UPDATE_HAS_GROUPS,
  GET_USER_ENTITLEMENTS,
  UPDATE_SSO_CONFIG_WITH_SIDE_EFFECTS,
  LOGIN_USER,
  LOGOUT_USER,
  UPDATE_ACCOUNTS,
} from './interfaces/ISsoAction';

// Actions requiring SsoClient.set
export const SAVE_TO_STORE_ACTIONS = [
  GET_USER_ENTITLEMENTS,
  UPDATE_ACCESS_TOKEN,
  UPDATE_COACH_INFO,
  UPDATE_HAS_GROUPS,
  UPDATE_LOGIN_STATUS,
  UPDATE_SSO_CONFIG,
  UPDATE_SSO_CONFIG_WITH_SIDE_EFFECTS,
  UPDATE_USER_INFO,
  LOGIN_USER,
  LOGOUT_USER,
  UPDATE_ACCOUNTS,
];

const mergeSsoModule = (sso: ISsoModel, newData: IConfigSso): ISsoModel =>
  sso.mergeFromConfig(newData);

export const updateConfig: IUpdateSsoConfigActionCreator = (updatedConfig) => (
  dispatch,
  getState,
) => {
  const sso = modulesByKeySelector(getState(), 'sso') as ISsoModel;

  return dispatch({
    type: UPDATE_SSO_CONFIG,
    payload: mergeSsoModule(sso, updatedConfig as ISso),
  });
};

export const updateConfigWithSideEffects: IUpdateSsoConfigActionCreator = (updatedConfig) => (
  dispatch,
  getState,
) => {
  const modules = modulesStateSelector(getState());
  const { sso } = modules;

  const updatedSso = mergeSsoModule(sso, updatedConfig as ISso);

  triggerSideEffectActions(modules.set('sso', updatedSso), dispatch);

  return dispatch({
    type: UPDATE_SSO_CONFIG_WITH_SIDE_EFFECTS,
    payload: updatedSso,
  });
};

export const updateUserInfo: IUpdateUserInfoActionCreator = (userInfo) => (dispatch) => {
  return dispatch({
    type: UPDATE_USER_INFO,
    payload: userInfo,
  });
};

export const updateCoachInfo: IUpdateCoachInfoActionCreator = (coachInfo) => (dispatch) => {
  const coachInformation = CoachInfo.createFromRaw(coachInfo);

  return dispatch({
    type: UPDATE_COACH_INFO,
    payload: coachInformation,
  });
};

export const getUserEntitlements: IGetUserEntitlementList = (sso, forceUpdate = false) => async (
  dispatch,
) => {
  const { accessToken = '', userLoginStatus, idToken = '', userInfo } = sso;
  const { guid, entitlementList = [] } = userInfo || {};
  const shouldTrigger =
    userLoginStatus === LoggedStatus.loggedIn &&
    !!accessToken &&
    !!idToken &&
    !!guid &&
    (!entitlementList.length || forceUpdate);

  const userEntitlementList = shouldTrigger
    ? await GlobalUserEntitlement.get({
        accessToken,
        userGuid: userInfo?.guid,
        emailAddress: userInfo?.email,
        idToken,
      })
    : entitlementList;

  return dispatch({
    type: GET_USER_ENTITLEMENTS,
    payload: mergeSsoModule(sso, {
      ...sso,
      isEnabled: sso.isEnabled,
      userInfo: userInfo?.set('entitlementList', userEntitlementList),
    } as ISso),
  });
};

export const updateAccessToken: IUpdateAccessTokenActionCreator = (accessToken, idToken) => (
  dispatch,
  getState,
) => {
  const modules = modulesStateSelector(getState());
  const { sso } = modules;

  const updatedSso = mergeSsoModule(sso, {
    isEnabled: sso.isEnabled,
    accessToken,
    idToken,
  } as ISso);

  triggerSideEffectActions(modules.set('sso', updatedSso), dispatch);

  return dispatch({
    type: UPDATE_ACCESS_TOKEN,
    payload: updatedSso,
  });
};

export const updateHasGroups: IUpdateHasGroupsActionCreator = (hasGroups) => (dispatch) => {
  return dispatch({
    type: UPDATE_HAS_GROUPS,
    payload: hasGroups,
  });
};

export const updateAccountList: IUpdateAccountsActionCreator = (accountList) => (
  dispatch,
  getState,
) => {
  const sso = modulesByKeySelector(getState(), 'sso') as ISsoModel;

  return dispatch({
    type: UPDATE_ACCOUNTS,
    payload: mergeSsoModule(sso, {
      isEnabled: sso.isEnabled,
      accountList: accountList,
    } as ISso),
  });
};

export const removeAccount: IUpdateAccountActionCreator = (account) => (dispatch, getState) => {
  const sso = modulesByKeySelector(getState(), 'sso') as ISsoModel;
  const accounts = sso.accountList?.filter(({ coachId }) => coachId !== account.coachId);
  return dispatch({
    type: UPDATE_ACCOUNTS,
    event: {
      postEventAction: SupportedEvents.onAccountRemoved,
      payload: account,
    },
    payload: mergeSsoModule(sso, {
      isEnabled: sso.isEnabled,
      accountList: accounts,
    } as ISso),
  });
};

export const logoutUser: ILogoutUserActionCreator = (shouldRunPreEventAction = true) => (
  dispatch,
  getState,
) => {
  const sso = modulesByKeySelector(getState(), 'sso') as ISsoModel;
  dispatch(wipeCart());

  const loggedOutSso = Sso.createFromRaw({
    isEnabled: sso.isEnabled,
    userLoginStatus: LoggedStatus.loggedOut,
    isLoggingOut: true,
  } as IConfigSso);

  return dispatch({
    type: LOGOUT_USER,
    event: shouldRunPreEventAction
      ? {
          preEventAction: SupportedEvents.onLogOut,
        }
      : undefined,
    payload: loggedOutSso,
  });
};

export const logInUser: ILogInUserActionCreator = (shouldTriggerEvent = true) => (
  dispatch,
  getState,
) => {
  const sso = modulesByKeySelector(getState(), 'sso') as ISsoModel;

  const updatedSso = Sso.createFromRaw({
    isEnabled: sso.isEnabled,
    isLoggingOut: false,
  } as IConfigSso);

  return dispatch({
    type: LOGIN_USER,
    event: {
      postEventAction: shouldTriggerEvent ? SupportedEvents.onLogIn : undefined,
    },
    payload: updatedSso,
  });
};

export const updateUserLoginStatus: IUpdateUserLoginStatusActionCreator = (userLoginStatus) => (
  dispatch,
  getState,
) => {
  if (userLoginStatus === LoggedStatus.loggedOut) {
    const logoutUserAction = logoutUser(false);

    return logoutUserAction(dispatch, getState, null);
  }

  const sso = modulesByKeySelector(getState(), 'sso') as ISsoModel;

  return dispatch({
    type: UPDATE_LOGIN_STATUS,
    payload: mergeSsoModule(sso, {
      isEnabled: sso.isEnabled,
      userLoginStatus,
    } as ISso),
  });
};

export default {
  updateConfig,
  updateConfigWithSideEffects,
  updateUserInfo,
  updateCoachInfo,
  getUserEntitlements,
  updateAccessToken,
  updateHasGroups,
  updateAccountList,
  removeAccount,
  logoutUser,
  logInUser,
  updateUserLoginStatus,
};
