import { useEffect, useState } from 'preact/hooks';
import debounce from 'lodash/debounce';
import { Breakpoints } from '../enums/breakpoints';

const useMatchMedia = (
  breakpoint: Breakpoints,
  defaultState: boolean,
  customDebounce = 400,
): boolean => {
  const [mediaMatches, setMediaMatches] = useState<boolean>(defaultState);

  useEffect(() => {
    function updateMatchState(): void {
      if (window && window.matchMedia) {
        const { matches } = window.matchMedia(`screen and (min-width: ${breakpoint}px)`);
        setMediaMatches(matches);
      }
    }

    updateMatchState();
    const debouncedUpdate = debounce(updateMatchState, customDebounce);
    window.addEventListener('resize', debouncedUpdate);

    return () => {
      window.removeEventListener('resize', debouncedUpdate);
    };
  }, [breakpoint, customDebounce]);

  return mediaMatches;
};

export default useMatchMedia;
