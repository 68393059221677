import { Record } from 'immutable';
import { IModelStatic } from '../interfaces/IModel';
import { IRawResource } from '../interfaces/IWorkout';
import { isPDF } from '../utils/common';

export enum AssociatedLinkTypes {
  PDF = 'pdf',
  LINK = 'link',
}

export interface IAssociatedLink {
  id: string;
  title: string;
  type: AssociatedLinkTypes;
  url: string;
}

export interface IAssociatedLinkModel extends Record<IAssociatedLink>, IAssociatedLink {}

const defaultProps = {
  id: '',
  title: '',
  type: AssociatedLinkTypes.LINK,
  url: '',
};

const AssociatedLink: IModelStatic<IAssociatedLinkModel, IRawResource> = class
  extends Record<IAssociatedLink>(defaultProps, 'AssociatedLink')
  implements IAssociatedLinkModel {
  static createFromRaw(rawResource?: IRawResource): IAssociatedLinkModel {
    const { attachment, url: rawUrl } = rawResource || {};
    const url = attachment?.url || rawUrl;
    const id = attachment?.ID || '';
    const type = isPDF(url || '') ? AssociatedLinkTypes.PDF : AssociatedLinkTypes.LINK;

    return new this({ ...(rawResource || {}), url, id, type });
  }
};

export default AssociatedLink;
