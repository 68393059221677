/** @jsx h */
import { FunctionalComponent, h, Fragment } from 'preact';
import { useCallback, useEffect, useRef } from 'preact/hooks';
import { connect } from 'react-redux';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { AppState } from '../../interfaces/appState';
import { Overlay, MobileNav } from './mobileNavigationStyled';
import {
  mobileMenuLocationListSelector,
  nextLocaleChangeModalSelector,
  customDefaultMenuLocationSelector,
} from '../../selectors/topNavSelectors';
import { activeMenuMobileSelector } from '../../selectors/activeItemSelectors';
import { IMenuLocationModel } from '../../models/MenuLocation';
import NavigationFactory from '../../components/sideNavLocation';
import useClickOutside from '../../hooks/useClickOutside';
import { IMenuModel } from '../../models/Menu';
import { ILocaleModel } from '../../models/Locale';
import { setActiveMenuMobile, setNextLocaleChangeModal } from '../../actions/headerActions';
import { updateLocale } from '../../actions/localeActions';
import LocaleChangeModal from '../../components/localeChangeModal';
import SubNav from './subNav';

export interface IMobileNavigationProps {
  activeMenu: IMenuModel;
  isOpen: boolean;
  menuLocationList: Array<IMenuLocationModel>;
  onClose: () => void;
  parentActiveMenu: IMenuModel;
  setActiveMenu: typeof setActiveMenuMobile;
  setNextLocaleChangeModal: typeof setNextLocaleChangeModal;
  nextLocale: ILocaleModel | null;
  updateLocale: typeof updateLocale;
  customDefaultMenuLocation: IMenuModel | null;
}

export const MobileNavigation: FunctionalComponent<IMobileNavigationProps> = ({
  activeMenu,
  isOpen,
  menuLocationList,
  onClose,
  setActiveMenu,
  setNextLocaleChangeModal,
  updateLocale,
  nextLocale,
  customDefaultMenuLocation,
}) => {
  const ref = useRef<HTMLElement | null>(null);
  const auxRef = useRef<HTMLElement | null>(null);
  const shouldShowLocaleChangeModal = !!nextLocale;

  const handleOnClose = useCallback(() => {
    onClose();
    setActiveMenu({} as IMenuModel);
  }, [onClose, setActiveMenu]);

  useEffect(() => {
    if (ref.current) {
      disableBodyScroll(ref.current);
      auxRef.current = ref.current;
    }

    ref.current = shouldShowLocaleChangeModal ? null : auxRef.current;

    return () => clearAllBodyScrollLocks();
  }, [shouldShowLocaleChangeModal]);

  useClickOutside({ onClose: handleOnClose, ref });

  useEffect(() => {
    if (customDefaultMenuLocation) {
      setActiveMenu(customDefaultMenuLocation as IMenuModel);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      <Overlay>
        <MobileNav isOpen={isOpen} ref={ref}>
          <Fragment>
            {!activeMenu?.parentItem ? (
              menuLocationList.map((menuLocation, index: number) => (
                <NavigationFactory
                  key={index}
                  menuLocation={menuLocation}
                  onClose={handleOnClose}
                />
              ))
            ) : (
              <SubNav onClose={handleOnClose} />
            )}
          </Fragment>
        </MobileNav>
      </Overlay>
      {shouldShowLocaleChangeModal && (
        <LocaleChangeModal
          newLocale={nextLocale?.selectedLocale}
          onCancel={() => {
            ref.current = auxRef.current;
            setNextLocaleChangeModal();
          }}
          onAccept={() => {
            if (nextLocale?.selectedLocale) {
              updateLocale(nextLocale.selectedLocale);
            }

            setNextLocaleChangeModal();
            handleOnClose();
          }}
        />
      )}
    </Fragment>
  );
};

const mapStateToProps = (state: AppState) => ({
  activeMenu: activeMenuMobileSelector(state),
  menuLocationList: mobileMenuLocationListSelector(state),
  nextLocale: nextLocaleChangeModalSelector(state),
  customDefaultMenuLocation: customDefaultMenuLocationSelector(state),
});

const mapDispatchToProps = {
  setActiveMenu: setActiveMenuMobile,
  setNextLocaleChangeModal,
  updateLocale,
};

export default connect(mapStateToProps, mapDispatchToProps)(MobileNavigation);
