/** @jsx h */
import { FunctionalComponent, h } from 'preact';
import { IItemModel } from '../../../../models/Item';
import { IMenuItemModel } from '../../../../models/Menu';
import { Icon as BeachBodyIcon } from '../../menuLocations/topNavIcons/topNavIconsStyled';
import { StyledAppLink } from './logoStyled';

export interface ILogoProps {
  item: IMenuItemModel;
  isTablet: boolean;
}

const Logo: FunctionalComponent<ILogoProps> = ({ item }) => {
  const { menuImageUrl, itemName } = item as IItemModel;

  return (
    <StyledAppLink item={item}>
      <BeachBodyIcon alt={itemName} src={menuImageUrl} />
    </StyledAppLink>
  );
};

export default Logo;
