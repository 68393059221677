import { Record } from 'immutable';
import { IMenuItem, MenuItemTypes } from '../interfaces/IMenuItem';
import { IModelStatic } from '../interfaces/IModel';

export enum PlaceholderEnum {
  featuredProduct = 'menuPlaceholder.featuredProduct',
  cart = 'menuPlaceholder.cart',
  coach = 'menuPlaceholder.profileInfoCoach',
  columnBreak = 'menuPlaceholder.columnBreak',
  divider = 'menuPlaceholder.divider',
  groups = 'menuPlaceholder.bodGroups',
  hamburger = 'menuPlaceholder.hamburger',
  locale = 'menuPlaceholder.locale.currentLocale',
  logo = 'menuPlaceholder.logo',
  logOut = 'menuPlaceholder.profile.logout',
  myAccount = 'menuPlaceholder.profile.myAccount',
  myOrders = 'menuPlaceholder.profile.myOrders',
  profileInfo = 'menuPlaceholder.profileInfoUser',
  accountSwitch = 'menuPlaceholder.accountSwitcher',
  mobileAccountSwitch = 'mobile.menuPlaceholder.accountSwitcher',
  search = 'menuPlaceholder.search',
  user = 'menuPlaceholder.profileIconUser',
  loginDropdown = 'menuPlaceholder.loginDropdown',
  loginButton = 'menuPlaceholder.loginButton',
  signUpButton = 'menuPlaceholder.signUpButton',
  coachLogin = 'menuPlaceholder.coachLogin',
  subNavLogo = 'menuPlaceholder.subNavLogo',
  enUs = 'menuPlaceholder.locale.en-us',
  esUs = 'menuPlaceholder.locale.es-us',
  frFR = 'menuPlaceholder.locale.fr-fr',
  enCA = 'menuPlaceholder.locale.en-ca',
  frCA = 'menuPlaceholder.locale.fr-ca',
  enGB = 'menuPlaceholder.locale.en-gb',
  DoNotSellMyInfo = 'menuPlaceholder.footer.doNotSellMyInfo',
  ManageCookies = 'menuPlaceholder.footer.manageCookies',
}

export interface IPlaceholder extends IMenuItem {
  type: PlaceholderEnum;
}
export interface IRawPlaceholder {
  type: PlaceholderEnum;
  locale: string;
  __typename?: string;
}

export interface IPlaceholderModel extends Record<IPlaceholder>, IPlaceholder {}

const defaultProps = {
  type: PlaceholderEnum.columnBreak,
  __typename: MenuItemTypes.placeholder,
  id: '',
  locale: '',
};

const Placeholder: IModelStatic<IPlaceholderModel, IRawPlaceholder> = class
  extends Record<IPlaceholder>(defaultProps, 'Placeholder')
  implements IPlaceholderModel {
  static createFromRaw(rawPlaceholder?: IRawPlaceholder): IPlaceholderModel {
    return new this(rawPlaceholder || {});
  }
};

export default Placeholder;
