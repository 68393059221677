import styled from 'styled-components';

const CardTitle = styled.p`
  font-size: 24px;
  font-weight: 900;
  font-style: italic;
  line-height: 1.083em;
  letter-spacing: 0.008em;
`;

export default CardTitle;
