import { store } from '../configureStore';
import { DomainNotAllowedError } from '../errors/AppErrors';
import IUnificationScript from '../interfaces/IUnificationScript';
import { isDomainAllowed } from '../utils/common';
import getMainModule from './main';

export const getModules = (): IUnificationScript => {
  if (isDomainAllowed()) {
    return { ...getMainModule(store) };
  }
  throw new DomainNotAllowedError();
};
