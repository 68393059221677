import AppLink from '../../../appLink';
import styled, { css, DefaultTheme } from 'styled-components';
import { Media } from '../../../../styles/media';
import { Body1Template } from '../../../../shared/typography/body1';
import { Body2Template } from '../../../../shared/typography/body2';

const { tabletUp } = Media;

interface NavLinkTheme extends DefaultTheme {
  isActive: boolean;
  isOpen: boolean;
}

export const baseInputStyles = css<NavLinkTheme>`
  font-weight: ${Body2Template.fontWeight};
  font-size: ${Body2Template.fontSize};
  cursor: pointer;
  text-decoration: none;
  white-space: nowrap;
  position: relative;
  width: max-content;
  color: ${({ theme, isOpen }) => (isOpen ? theme.oneAppBlue : theme.darkGray)};
  &:hover {
    color: ${({ theme }) => theme.oneAppBlue};
  }
  ${({ isActive }) =>
    isActive &&
    css<DefaultTheme>`
      font-weight: ${Body1Template.fontWeight};
      &:after {
        background: ${({ theme }) => theme.oneAppBlue};
        bottom: -10px;
        content: '';
        height: 4px;
        position: absolute;
        right: 0;
        width: 100%;
      }
    `};
`;

export const Link = styled(AppLink)`
  ${baseInputStyles};
`;

export const ItemLink = styled.a`
  ${baseInputStyles};
`;

export const Container = styled.div`
  position: relative;
  ${Link} {
    ${tabletUp`
      font-size: ${Body1Template.fontSize};
      display: flex;
    `};
  }
`;
