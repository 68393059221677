/** @jsx h */
import { FunctionalComponent, VNode } from 'preact';
import { createPortal } from 'preact/compat';
import { SupportedEvents } from '../../enums/event';
import { useEventHandler } from '../../providers/eventHandlerProvider';

export interface PortalProps {
  children: VNode;
  containerId: string;
}

const Portal: FunctionalComponent<PortalProps> = ({ children, containerId }) => {
  const { dispatchEvent } = useEventHandler();
  const containerNode = document.getElementById(containerId);

  if (!containerNode)
    dispatchEvent(SupportedEvents.onError, `The elementId ${containerId} does not exist.`);

  return !!containerNode ? createPortal(children as VNode, containerNode as HTMLElement) : null;
};

export default Portal;
