import { v4 as UUID } from 'uuid';

import RestClient from '../restClient';
import IKeyValue from '../../interfaces/IKeyValue';
import { loadFromConfig } from '../../utils/configLoader';
import { BEARER, CONTENT_TYPES } from '../../utils/constants';
import VHS, { IVHSModel } from '../../models/Vhs';

const { VHS_API, VHS_API_KEY } = loadFromConfig('VIDEO_PLAYER');
export const VHS_LIMIT = '100';

const BASE_HEADERS: IKeyValue<string> = {
  bodweb: 'true',
  platform: 'unification',
  'x-api-key': VHS_API_KEY,
  'Content-Type': CONTENT_TYPES.JSON,
};

interface IVHSItem {
  videoGuid: string;
  percentageViewed: string;
  timestampStart: Date;
}

export interface IVHSResponse {
  count: number;
  items?: Array<IVHSItem>;
}

export interface IVHSClient {
  getVideoHistory: (accessToken: string, versionName: string) => Promise<Array<IVHSModel>>;
}

const VHSClient = (userId: string): IVHSClient => {
  const { get } = RestClient<IVHSResponse>(`${VHS_API}/${userId}`, BASE_HEADERS);

  return {
    getVideoHistory: async (accessToken, versionName) => {
      const queryParams = { limit: VHS_LIMIT };
      const headers = {
        authorization: `${BEARER} ${accessToken}`,
        'platform-version': versionName,
        'x-transaction-id': UUID(),
      };
      const { json } = await get<IVHSResponse>({ queryParams, headers });

      return !!json.count && json.items ? json.items.map((item) => VHS.createFromRaw(item)) : [];
    },
  };
};

export default VHSClient;
