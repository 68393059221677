/** @jsx h */
import { FunctionalComponent, h } from 'preact';
import { useCallback, useState } from 'preact/hooks';
import { connect } from 'react-redux';
import { HeaderContainer, Wrapper } from './topNavStyled';
import { topNavMenuLocationListSelector } from '../../selectors/topNavSelectors';
import { AppState } from '../../interfaces/appState';
import { modulesByKeySelector } from '../../selectors/modulesSelectors';
import { ISsoModel } from '../../interfaces/ISso';
import { IMenuLocationModel } from '../../models/MenuLocation';
import useMatchMedia from '../../hooks/useMatchMedia';
import { Breakpoints } from '../../enums/breakpoints';
import TopNavLocation from '../../components/topNavLocation';
import { getIsCoachBannerVisibleSelector } from '../../selectors/coachBannerSelector';
import { ICoachInfoModel } from '../../models/CoachInfo';
import { ILocaleModel } from '../../models/Locale';
import GlobalBanner from '../globalBanner';
import { IMainModel } from '../../models/Main';
import SubNav from '../subNav';
import CoachBanner from '../../components/coachBanner';
import GlobalSearch from '../search/globalSearch';

export interface ITopNavComponentProps {
  menuLocationList: Array<IMenuLocationModel>;
  sso: ISsoModel;
  locale: ILocaleModel;
  main: IMainModel;
  showCoachBanner: boolean;
}

export const TopNav: FunctionalComponent<ITopNavComponentProps> = ({
  menuLocationList,
  sso,
  main: { subNav },
  showCoachBanner,
}) => {
  const [isSearchFieldVisible, setIsSearchFieldVisible] = useState<boolean>(false);
  const isDesktop = useMatchMedia(Breakpoints.xlarge, true, 10);
  const isTablet = useMatchMedia(Breakpoints.large, false, 10);

  const handleOnSearch = useCallback(() => {
    setIsSearchFieldVisible(true);
  }, []);

  return (
    <Wrapper>
      <GlobalBanner />
      {showCoachBanner && isTablet ? (
        <CoachBanner coachInfo={sso.coachInfo as ICoachInfoModel} />
      ) : null}
      <HeaderContainer isTablet={!isDesktop} isVisible={!isSearchFieldVisible}>
        <GlobalSearch
          isSearchVisible={isSearchFieldVisible}
          setIsSearchVisible={setIsSearchFieldVisible}
        />
        {menuLocationList.map((menuLocation) => (
          <TopNavLocation
            key={menuLocation.slug}
            handleOnSearch={handleOnSearch}
            isDesktop={isDesktop}
            isTablet={isTablet}
            menuLocation={menuLocation}
          />
        ))}
      </HeaderContainer>
      {subNav?.isEnabled ? <SubNav /> : null}
    </Wrapper>
  );
};

const mapStateToProps = (state: AppState) => ({
  sso: modulesByKeySelector(state, 'sso'),
  locale: modulesByKeySelector(state, 'locale'),
  main: modulesByKeySelector(state, 'main'),
  menuLocationList: topNavMenuLocationListSelector(state),
  showCoachBanner: getIsCoachBannerVisibleSelector(state),
});

export default connect(mapStateToProps)(TopNav);
