/** @jsx h */
import { FunctionalComponent, h } from 'preact';
import { useRef, useState, useCallback } from 'preact/hooks';
import { useTranslation } from 'react-i18next';
import { PromoContainer, PromoText, PromoEllipsis, PromoLink } from './promoBannerStyled';
import { PromoModal } from '../promoModal';
import { IBannerModel } from '../../models/Banner';
import { PromoBannerLocale } from './locale';
import { modulesByKeySelector } from '../../selectors/modulesSelectors';
import { AppState } from '../../interfaces/appState';
import { connect } from 'react-redux';
import { IMainModel } from '../../models/Main';
import { DeviceTypes } from '../../utils/device';
import { PROMO_BANNER_ELEMENT_ID } from '../../utils/constants';

export interface IPromoBannerProps {
  promoBanner: IBannerModel;
  main: IMainModel;
}

export const PromoBanner: FunctionalComponent<IPromoBannerProps> = ({
  promoBanner: { cta, text, textColor, backgroundColor, fontWeight },
  main: { device },
}) => {
  const { anchorText = '', parsedLink = '' } = cta || {};
  const { t } = useTranslation();
  const promoRef = useRef<HTMLInputElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const showMoreDetails = [DeviceTypes.mobile, DeviceTypes.tablet].includes(device);

  const handleClick = useCallback(
    (event: Event) => {
      if (showMoreDetails) {
        event.preventDefault();
        setIsOpen((isOpen) => !isOpen);
      }
    },
    [showMoreDetails],
  );

  return (
    <PromoContainer id={PROMO_BANNER_ELEMENT_ID} backgroundColor={backgroundColor}>
      <PromoText>
        <PromoEllipsis ref={promoRef} fontWeight={fontWeight} textColor={textColor}>
          {text}
        </PromoEllipsis>
        <PromoLink
          href={parsedLink}
          onClick={handleClick}
          ctaTextColor={cta?.textColor}
          ctaFontWeight={cta?.fontWeight}
        >
          {showMoreDetails ? t(PromoBannerLocale.moreDetails) : anchorText}
        </PromoLink>
      </PromoText>
      <PromoModal
        promoText={text}
        promoCodeText={anchorText}
        onClose={() => setIsOpen((isOpen) => !isOpen)}
        promoUrl={parsedLink}
        isOpen={isOpen}
      />
    </PromoContainer>
  );
};

const mapStateToProps = (state: AppState) => ({
  main: modulesByKeySelector(state, 'main'),
});

export default connect(mapStateToProps)(PromoBanner);
