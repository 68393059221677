import { Record } from 'immutable';
import { IModelStatic } from '../interfaces/IModel';

export interface ISearchHistory {
  origin: string;
  createdAt: number;
}

export interface IRawSearchHistory {
  origin: string;
  createdAt: number;
}

const defaultProps = {
  origin: '',
  createdAt: 0,
};

export interface ISearchHistoryModel extends Record<ISearchHistory>, ISearchHistory {}

const SearchHistory: IModelStatic<ISearchHistoryModel, IRawSearchHistory> = class
  extends Record<ISearchHistory>(defaultProps, 'SearchHistory')
  implements ISearchHistoryModel {
  static createFromRaw(data: IRawSearchHistory): ISearchHistoryModel {
    return new this(data);
  }
};

export default SearchHistory;
