/** @jsx h */
import { FunctionalComponent, h } from 'preact';
import { IMenuItemModel } from '../../../../models/Menu';
import { IMenuModel } from '../../../../models/Menu';
import DropdownItem from '../../../../components/dropdownItem';
import { SubMenuContainer, MenuContainer } from './menuStyled';

export interface IMenuProps {
  item: IMenuItemModel;
}

const Menu: FunctionalComponent<IMenuProps> = ({ item }) => {
  const { parentItem, menuItemList } = item as IMenuModel;

  return (
    <MenuContainer>
      {parentItem ? <DropdownItem item={parentItem} /> : null}
      <SubMenuContainer>
        {menuItemList.map((subMenuItem) => (
          <DropdownItem isSubItem item={subMenuItem} key={subMenuItem.id} />
        ))}
      </SubMenuContainer>
    </MenuContainer>
  );
};

export default Menu;
