import CartSummary from '../gateways/cartAPI/cartSummary';
import { SupportedLanguages } from '../enums/locale';
import Cart from '../models/Cart';
import { ICartModel } from '../models/Cart';

export const recoverCartForLocaleOrDefault = async (
  accessToken: string,
  selectedLocale: SupportedLanguages,
): Promise<ICartModel> => {
  try {
    return await CartSummary.get(accessToken, selectedLocale);
  } catch (e) {
    console.error(
      `Unable to recover the cart for the locale: ${selectedLocale}. Falling back to an empty one.`,
    );
    return Cart.createFromRaw({
      isEnabled: true,
      itemsCount: 0,
      hasLoaded: false,
    });
  }
};
