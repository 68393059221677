import { ThunkAction } from 'redux-thunk';
import { ActionCreatorsMapObject } from 'redux';
import { IAction } from './IAction';
import { AppState } from '../../interfaces/appState';
import { ISearchState } from '../../interfaces/ISearchState';

export const NAVIGATION_REDIRECT = 'NAVIGATION/REDIRECT';
export const NAVIGATION_REPLACE = 'NAVIGATION/REPLACE';

export type INavigationAction = ThunkAction<IAction, AppState, null, IAction>;
interface INavigationItems {
  [key: string]: string | string[] | number | number[] | null | boolean;
}

type NavigationParams = ISearchState | INavigationItems | undefined;

export interface INavigationRedirectActionCreator {
  (url: string, sameWindow?: boolean): INavigationAction;
}

export interface INavigationReplaceActionCreator {
  (url: string, state?: NavigationParams, isLocaleNavigation?: boolean): INavigationAction;
}

export interface INavigationActionCreators extends ActionCreatorsMapObject {
  redirect: INavigationRedirectActionCreator;
  replace: INavigationReplaceActionCreator;
}
