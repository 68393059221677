import styled from 'styled-components';
import Body2 from '../../shared/typography/body2';
import Button1Fill from '../../shared/buttons/button1Fill';
import closeIcon from '../../assets/close.svg';
import { resolveRelativeUrl } from '../../utils/device';

type ModalProps = {
  isOpen: boolean;
};

export const Background = styled.div<ModalProps>`
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 1;
  padding: 30px;
  z-index: 9999;
  position: fixed;
  animation: keyframes-show-0-2-5 0.5s ease;
  overflow-x: hidden;
  overflow-y: auto;
  align-items: center;
  justify-content: center;
`;

export const Container = styled.div`
  background-color: ${({ theme }) => theme.whiteBG};
  border-radius: 2px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);
`;

export const WhiteBox = styled.div`
  padding: 38px 24px;
  position: relative;
`;

export const PromoText = styled(Body2)`
  color: ${({ theme }) => theme.medGray};
  margin: 0;
`;

export const GrayBox = styled.div`
  background-color: ${({ theme }) => theme.pearlGray};
  padding: 24px;
  display: flex;
  justify-content: flex-end;
`;

export const PromoButton = styled(Button1Fill)`
  margin: 0;
`;

export const Close = styled.img.attrs({ src: `${resolveRelativeUrl(closeIcon)}` })`
  position: absolute;
  top: 20px;
  right: 20px;
  height: 14px;
  width: 14px;
  border: none;
`;
