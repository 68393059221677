import styled from 'styled-components';
import { ITypography } from '../../interfaces/typography';
import { Media } from '../../../styles/media';

const { tablet } = Media;

export const Editorial4dTemplate: ITypography = {
  fontSize: '18px',
  fontWeight: '300',
};

export const Editorial4Template: ITypography = {
  fontSize: '14px',
  fontWeight: '300',
};

const { fontSize, fontWeight } = Editorial4Template;

type TextProps = {
  bold: boolean;
  fixedSize: boolean;
};

const Editorial4 = styled.h3<TextProps>`
  font-size: ${fontSize};
  font-weight: ${({ bold }) => (bold ? 'bold' : `${fontWeight}`)};
  ${({ fixedSize }) =>
    !fixedSize &&
    tablet`
    font-size: 20px;
  `};
`;

export default Editorial4;
