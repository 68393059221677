import WaiverClient from '../gateways/waiver/waiverClient';
import Waiver, { IWaiverModel } from '../models/Waiver';
import { SupportedLanguages } from '../enums/locale';
import { IRefreshAccessToken } from '../interfaces/ISso';

const ERROR = 'Something went wrong while consuming the video waiver service';

export const getWaiverStatus = async (
  userId: string,
  videoGuid: string,
  locale: SupportedLanguages,
  accessToken: string,
  brandCode?: string,
  refreshAccessToken?: IRefreshAccessToken,
): Promise<IWaiverModel> => {
  const defaultWaiverStatus = Waiver.createFromRaw({ requirewaiver: true });

  if (!accessToken) return defaultWaiverStatus;

  function request(accessToken: string) {
    return WaiverClient.getWaiverStatus(userId, videoGuid, locale, accessToken, brandCode);
  }

  function handleError(error: Error) {
    console.error(`${ERROR}: GET ${error?.message}`);
    return defaultWaiverStatus;
  }

  return await request(accessToken).catch(async (error) => {
    if (refreshAccessToken) {
      const refreshedToken = await refreshAccessToken();
      return await request(refreshedToken || accessToken).catch(handleError);
    } else {
      return handleError(error);
    }
  });
};

export const postWaiverStatus = async (
  userId: string,
  videoGuid: string,
  locale: SupportedLanguages,
  accessToken: string,
  brandCode?: string,
): Promise<boolean> => {
  try {
    return await WaiverClient.postWaiverStatus(userId, videoGuid, locale, accessToken, brandCode);
  } catch (error) {
    console.error(`${ERROR}: POST ${(error as Error)?.message}`);
    return false;
  }
};
