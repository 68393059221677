import { Record } from 'immutable';
import { Reducer } from 'redux';
import { IEntitlementModel } from '../models/Entitlement';
import { IAccessPolicyModel } from '../models/AccessPolicy';
import {
  EntitlementActions,
  SET_GROUPS_ENTITLEMENTS,
  SET_RAMS_ENTITLEMENTS,
  SET_ACCESS_POLICIES,
} from '../actions/interfaces/IEntitlement';

export interface IEntitlementState {
  readonly groupEntitlements: Array<IEntitlementModel>;
  readonly ramEntitlements: Array<string>;
  readonly accessPolicies: Array<IAccessPolicyModel>;
}

export interface IEntitlementStateRecord extends Record<IEntitlementState>, IEntitlementState {}

export const EntitlementState = Record<IEntitlementState>({
  groupEntitlements: [],
  ramEntitlements: [],
  accessPolicies: [],
});

const initialState = EntitlementState();

export const entitlementReducer: Reducer<IEntitlementStateRecord, EntitlementActions> = (
  state = initialState,
  action,
) => {
  const { type, payload } = action;

  switch (type) {
    case SET_GROUPS_ENTITLEMENTS: {
      return state.set('groupEntitlements', payload as Array<IEntitlementModel>);
    }
    case SET_RAMS_ENTITLEMENTS: {
      return state.set('ramEntitlements', payload as Array<string>);
    }
    case SET_ACCESS_POLICIES: {
      return state.set('accessPolicies', payload as Array<IAccessPolicyModel>);
    }
    default:
      return state;
  }
};
