import { SupportedLanguages } from '../enums/locale';
const { EN_CA, FR_CA, EN_GB: EN_GB_LOCALE, FR_FR, EN_US, ES_US } = SupportedLanguages;

export const EN_GB = {
  aria_label_show_submenus: 'Press the Shift key + Space key to show submenus.',
  search_nav: 'Search',
  search_categories_connector: 'in',
  all: 'All {{nbHits}}',
  product: 'Shop {{nbHits}}',
  program: 'Program {{nbHits}}',
  programs: 'Programs {{nbHits}}',
  recipes: 'Recipes {{nbHits}}',
  workouts: 'Workouts {{nbHits}}',
  lessons: 'Lessons {{nbHits}}',
  other: 'Other {{nbHits}}',
  programMaterials: 'Program Materials {{nbHits}}',
  search_results: "Search results for '{{- query}}'",
  search_noResults: 'No results found',
  search_recentSearches: 'Recent Searches',
  search_clearAll: 'Clear all',
  search_see_all: 'See all',
  search_shopNow: 'Shop Now',
  search_topResult: 'Top Result',
  search_viewProgram: 'View Program',
  search_stats_result: '{{nbHits}} result',
  search_stats_results: '{{nbHits}} results',
  search_fitnesslevel_beginner: 'Beginner',
  search_fitnesslevel_intermediate: 'Intermediate',
  search_fitnesslevel_advanced: 'Advanced',
  search_categories: 'Categories',
  search_backToCategories: '< Back to categories',
  search_sortby_label: 'Sort by:',
  search_sort_title: 'A-Z',
  search_sort_featured: 'Featured',
  search_sort_popularity: 'Most Popular',
  search_sort_newest: 'Newest',
  search_gender: 'Gender',
  search_size: 'Size',
  search_style: 'Style',
  search_subcategories: 'Subcategory',
  search_filters: 'Filters',
  search_shop_title: 'Shop',
  search_chooseCategory: 'Choose a Category',
  search_applyFilters: 'Apply filters',
  apparel: 'Apparel',
  gear: 'Gear',
  flavor: 'Flavor',
  gearType: 'Type of Gear',
  nutrition: 'Nutrition',
  sale: 'Sale',
  shakeology: 'Shakeology',
  challengePacks: 'Challenge Packs',
  tsp: 'Total Solution Packs',
  trainer: 'Trainer / Expert',
  more_details: 'More details',
  search_clearRefinements_clearAll: 'Clear all',
  videoWaiver_waiverAgreementHeading: 'warning!',
  videoWaiver_waiverAgreementBody: `<p style="text-align:center; font-weight: bold;">&#9888;<span style="text-decoration: underline;">WARNING!</span>&#9888;</p>
  <p style="text-align:center; font-weight: bold; text-decoration: underline;">We want to keep you safe!</p>
  <p style="text-align:center; font-weight: bold; text-decoration: underline;">Please consult your physician, assess your fitness level, and follow all safety instructions before beginning any exercise program, nutrition plan, and/or using any equipment.</p>
  <p>Beachbody provides a variety of exercise programs, some of which are physically demanding and high-intensity in nature. For this reason, you must listen to your body, use common sense, take breaks, and hydrate as needed to avoid injury. If at any time you feel any discomfort, pain, dizziness, light-headedness, shortness of breath, or nausea, stop exercising immediately and consult your physician. Incorrect or excessive training can result in serious injury or death.</p>
  <p>When doing any outdoor workouts or activities, be aware of your surroundings and avoid anything that could hurt you or disrupt your exercise, especially when walking or running in public areas such as sidewalks and streets.</p>
  <p>If you have any unique or special medical conditions, such as if you're pregnant, have diabetes or asthma, or if you have a history of knee, ankle, hip, shoulder or spinal (back or neck) problems, you must consult your physician to understand all potential risks and complications of using our exercise programs, nutrition plans, and/or equipment, and receive approval from them to proceed before beginning. Failure to do so could result in significant injury to you and others (including, if applicable, your unborn child). <span style="font-weight:bold;">By engaging in any exercise program, nutrition plan, and/or using any equipment,
  you assume all dangers, hazards and risks of injury or death.</span></p>
  <p style="text-align: center; font-weight: bold; text-decoration: underline; margin-bottom: 0; font-size: larger;">SAFE USE OF EQUIPMENT</p>
  <p style="text-align: center; font-weight: bold; text-decoration: underline; margin-top: 0;">FITNESS EQUIPMENT PRESENTS HAZARDS WHICH, IF NOT AVOIDED, COULD CAUSE SERIOUS INJURY OR DEATH</p>
  <p>Certain programs may utilize equipment, such as the Myx fitness bike, jump ropes, resistance bands, loops, pull up bars, benches, stability balls, sliding discs, weights and other equipment which, if not used correctly, could lead to serious injury or death. Carefully review all safety, care and use instructions and manuals prior to beginning.</p>
  <p style="margin-bottom: 0;">For your safety, you must:</p>
  <ul style="margin-top: 0;">
    <li>Use any equipment shown in the workouts <span style="text-decoration:underline;">only</span> as demonstrated, using proper form.</li>
    <li>Inspect any equipment for wear or damage prior to each use and refrain from using any equipment that appears damaged, worn or defective.</li>
    <li>Keep children, pets and any other obstacles away from equipment and exercise area at all times.</li>
    <li>Always use a secure, proper, and stable anchor for any equipment that requires hanging or attaching.</li>
    <li>Always exercise caution during use of any equipment to make sure you do not lose your grip or control, such as making sure your hands are not wet or sweaty.</li>
    <li>Ensure exercise bands are firmly secure and assembled to prevent slipping, snapping, recoiling and injury to yourself or anyone else.</li>
    <li>Ensure your workout bench and pull up bar is stable, strong enough to hold your weight and does not move during use. If you do not think you can safely perform exercises with your bench or pull up bar, or you do not have the proper equipment, you should do the modifier exercises instead.</li>
  </ul>
  <p style="text-align: center; font-weight: bold; text-decoration: underline; font-size: larger;">SAFE USE OF THE MYX FITNESS BIKE</p>
  <p style="margin-bottom: 0;">In addition to the warnings above, for your safety when using the Myx fitness bike, tablet and related accessories ("bike"), you must:</p>
  <ul style="margin-top: 0;">
    <li>Carefully review and follow the Product Manual and instructional video tutorials before beginning to ensure proper and safe set-up, use and operation of the bike.</li>
    <li>Use the bike only after securely locking the seat and handlebars into place.</li>
    <li>Keep children, hands, pets, loose clothing, shoelaces, jewelry and anything else away from any moving parts.</li>
    <li>Use caution when mounting and dismounting.</li>
    <li>Not use, store or operate the bike outdoors or in damp or wet locations.</li>
    <li>Operate the bike on a solid level surface.</li>
    <li>Adhere to all weight limitations &#8211; user weight must not exceed 350 pounds (158 kg).</li>
    <li>Adhere to all height restrictions &#8211; users must be between 4'11" and 6'8" tall (1.5 m and 2.03 m tall).</li>
    <li>Adhere to any age restrictions, as stated in the Product Manual.</li>
    <li>Adhere to the minimum clearance required around the bike, as stated in the Product Manual.</li>
    <li>Perform Myx bike workouts <span style="text-decoration: underline;">only with</span> the Myx bike (and never with any street, road or any other type of bike).</li>
  </ul>
  <p>Please consult your physician before engaging in heart rate training, especially if you are pregnant, are younger than 18 years old, have any underlying heart, lung, or any other medical condition, or are unsure about elevating your heart rate to the max. If you are younger than 18 years old, please also get your parent or guardian's permission before beginning. Heart rate, calorie, cadence and other metrics and values displayed on the bike during use may be inaccurate and must be used and interpreted for reference only.</p>
  <p>To the fullest extent permitted by law, Beachbody, LLC and its parent, affiliate, and subsidiary companies will not be liable to any person or entity for any injury, death, damage or loss caused or alleged to be caused directly or indirectly by any exercise programs, workouts, nutritional supplements or plans, equipment (including without limitation the Myx fitness bike), advice or any other products, services or materials.</p>`,
  videoWaiver_waiverAgreementCheckboxLabel:
    ' By checking the box and clicking "Accept", I acknowledge I have read, understand, and agree with this warning.',
  videoWaiver_waiverAgreementAcceptButtonLabel: 'accept',
  videoWaiver_waiverAgreementDeclineButtonLabel: 'decline',
  videoPlayer_closeButton: 'Close',
  workout_pdf: '{{duration}}  |  {{pdfCount}} PDF',
  workout_pdfs: '{{duration}}  |  {{pdfCount}} PDFs',
  workout_type: '{{duration}}  |  {{workoutType}}',
  workout_type_and_date: '{{duration}}  |  {{workoutType}}  |  {{filmDate}}',
  sign_in: 'Sign In As',
  add_new: 'Add New',
  remove: 'Remove',
  coach_id: 'Partner ID: {{coachId}}',
  my_coach: 'My BODi Partner',
  backToCategories: 'Back to Categories',
  title: 'Programs',
  subtitle: 'Working out is better together!',
  joinAGroup: 'Join a group.',
  filters: 'Filters',
  placeholder: 'Search Programs',
  programType: 'Program Type',
  duration: 'Program Duration',
  apply: 'Apply Filters',
  noResultsDescription: 'Unfortunately, we couldn’t return any matching your search.',
  noResultsTitle: 'Beachbody is all about results.',
  beginner: 'Beginner',
  intermediate: 'Intermediate',
  advanced: 'Advanced',
  findProgram: 'Find a complete program here.',
  workoutType: 'Workout Type',
  bodyPart: 'Body Part',
  noEquipment: 'No Equipment',
  fitnessLevel: 'Fitness Level',
  workouts_noResultsDescription: 'Try to loosen up your search and start seeing results instantly.',
  workouts_noResultsTitle: 'You’ve stretched us too thin.',
  workouts_title: 'Workouts',
  workouts_subtitle: 'Need more guidance?',
  workouts_placeholder: 'Search Workouts',
  workouts_duration: 'Duration',
  recipes_title: 'Recipe Videos',
  recipes_subtitle:
    'For delicious new recipes every month, along with meal plans, grocery lists and access to an exclusive community for support, sign up for',
  recipes_link: 'Nutrition+.',
  recipes_linkURL:
    'https://www.teambeachbody.com/shop/us/d/NutritionPlusDigitalStreaming?locale=en_US&destPage=d/NutritionPlusDigitalStreaming&ICID=TBB_HERO_SHOPNOW_NUTPLUS',
  recipes_definitions_title: '“Works Well For” Definitions',
  recipes_definitions_paleo_friendly_title: 'Paleo Friendly',
  recipes_definitions_paleo_friendly_description:
    'These recipes include only meats, fish, eggs, vegetables, fruits, nuts, seeds, and healthy fats and oils. They are void of grains, legumes, dairy, refined sugars, processed foods, and other ingredients that in the past could not be obtained by hunting and gathering.',
  recipes_definitions_gluten_free_title: 'Gluten Free',
  recipes_definitions_gluten_free_description:
    'These recipes are designed to be Gluten-Free and contain no gluten. If you are following a gluten-free diet, remember to check all labels to confirm your ingredients are 100% gluten-free, since foods are often processed at facilities that also process wheat and other grains.',
  recipes_definitions_vegetarian_title: 'Vegetarian',
  recipes_definitions_vegetarian_description:
    'These recipes are Lacto-Ovo Vegetarian and contain no poultry, meat, or fish. Please read product labels for each ingredient to ensure this to be the case.',
  recipes_definitions_vegan_title: 'Vegan',
  recipes_definitions_vegan_description:
    'These recipes contain no animal products. Please read product labels for each ingredient to ensure this to be the case.',
  recipes_definitions_high_fiber_title: 'High in Fiber',
  recipes_definitions_high_fiber_description:
    'These recipes contain at least 20% of the Recommended Daily Value for fiber.',
  recipes_definitions_no_dairy_title: 'No Dairy',
  recipes_definitions_no_dairy_description: 'These recipes are not made with dairy ingredients.',
  recipes_definitions_timed_nutrition_title: 'Timed Nutrition',
  recipes_definitions_timed_nutrition_description:
    'Recipes containing the [TN] icon are designed to fit into the Ultimate Portion Fix (UPF) Timed Nutrition path. The container equivalents for this recipe either match or consist of a subset of containers that match at least one of the meals in UPF Timed Nutrition. Be sure to check your specific plan to make sure it works with your container counts.',
  recipes_definitions_high_protein_title: 'High in Protein',
  recipes_definitions_high_protein_description:
    'These recipes contain at least 20% of the Recommended Daily Value for protein.',
  recipes_definitions_quick_easy_title: 'Quick n’ Easy',
  recipes_definitions_quick_easy_description:
    'These recipes are designed to be made in 15 minutes or less and require little to no cooking.',
  meal: 'Meal',
  dish: 'Dish',
  mainIngredient: 'Main Ingredient',
  recipes_noResultsDescription:
    'Try shaking up your search criteria and find yourself something to eat.',
  recipes_noResultsTitle: 'Hungry for some results?',
  recipes_placeholder: 'Search Recipes',
  worksWellFor: 'Works Well For',
  containers: 'Containers',
  programsMaterials_title: 'Program Materials',
  other_title: 'Other',
  faq: 'https://faq.beachbody.co.uk/app/answers/list/kw/{{query}}/search/1',
  faq_title: 'Search ‘{{query}}’ in our FAQs',
  faq_cancel: 'Cancel',
  faq_cancelAccout: 'Cancel Account',
  [EN_CA]: 'Canada (English)',
  [FR_CA]: 'Canada (Français)',
  [FR_FR]: 'France (Français)',
  [EN_US]: 'U.S.A (English)',
  [ES_US]: 'U.S.A (Español)',
  [EN_GB_LOCALE]: 'United Kingdom (English)',
  countryAndLanguage: 'Country & Language',
  localeChangeNote: 'Please note, the items in your cart will be cleared.',
  currentShopIn: 'We see that you are shopping in:',
  newShopIn: 'Would you like to shop in:',
  yes: 'Yes',
  no: 'No',
  ok: 'Ok',
  countryAndLanguageUpdated: 'Country & Language Updated',
  viewOnlyText:
    'You are entering Team Beachbody <image /> {{locale}} in view only mode. If you would like to change your profile settings and shop this country, please contact Coach Relations',
  others: 'Other',
  signUp_redirectModal_text:
    'To sign up for Beachbody On Demand®, we need to send you to our Team Beachbody Shop. Proceed?',
  signUp_redirectModal_freeRegText: 'Create an account sign up is free. No credit card required.',
  signUp_redirectModal_button_yes: 'Yes',
  signUp_redirectModal_button_no: 'No',
  signUp_redirectModal_shopPath: '/shop/fr{{- extraPath}}/bod',
  signUp_unavailableModal_text: 'We are sorry this program is currently unavailable.',
  signUp_unavailableModal_button_close: 'Close',
  search_currentRefinements_rangeMins: '{{min}}-{{max}} Mins',
  search_rangeSlider_summary: '{{min}} - {{max}} min',
  search_moreResults: 'More',
  your_coach: 'Your BODi Partner, {{firstName}} {{lastName}}',
  search_exit: 'Exit Search',
  top_categories: 'Top Categories',
  withTrainer: 'with {{trainer}}',
  rating_title: 'Rate Your Experience!',
  rating_placeholder: 'Tell us more about your experience',
  rating_submit: 'Submit',
  rating_skip: 'Skip',
  product_card_modal_buy_now: 'BUY NOW',
  currency: '£ {{price}}',
  workout_violator_spanish: 'SP',
  workout_violator_french: 'FR',
  workouts_all_levels: 'All Levels',
};
