import LINK_TRACKING_EVENT_TEMPLATE, { LINK_TRACKING } from '../components/appLink/tealium';
import PROGRAM_MATERIALS_EVENT_TEMPLATE from '../components/hitProgramMaterials/tealium';
import { PLAY_BUTTON_TEMPLATE } from '../containers/videoPlayer/tealium';
import { sendTealiumLinkEvent } from '../services/tealiumService';
import { modulesByKeySelector } from '../selectors/modulesSelectors';
import { IMainModel } from '../models/Main';
import { IItemModel } from '../models/Item';
import { ILocaleModel } from '../models/Locale';
import { findTemplate, getTealiumPageName } from '../gateways/tealium/tealium';
import { IUtagData } from '../interfaces/ITealium';
import {
  ISendLinkTrackingEventActionCreator,
  ISendEventAction,
  SEND_TEALIUM_EVENT,
  ISendVideoPlayerEventActionCreator,
  ISendResourceEventActionCreator,
} from './interfaces/ITealiumActions';
import { AppIdEnum } from '../enums/main';
import { TealiumKeys, TopNavComponents } from '../enums/tealium';
import { NO_ACTION } from './interfaces/IAction';

const CLEAN_PATH_REGEX = / \([\s\S]*?\)/g;
const HEADER = 'header';

const { DOWNLOAD_KEY_SEARCH, PLAY_BUTTON_SEARCH } = TealiumKeys;

export const sendTealiumEvent = (data: IUtagData): ISendEventAction => ({
  type: SEND_TEALIUM_EVENT,
  payload: data,
});

export const sendLinkTrackingEvent: ISendLinkTrackingEventActionCreator = (
  item: IItemModel,
  component: TopNavComponents,
) => async (dispatch, getState) => {
  const state = getState();
  const { itemName, anchorText } = item;
  const { appId } = modulesByKeySelector(state, 'main') as IMainModel;

  if (appId === AppIdEnum.bod && itemName.toLowerCase().includes(HEADER)) {
    const { selectedLocale } = modulesByKeySelector(state, 'locale') as ILocaleModel;
    const [, countryCode] = selectedLocale.split('_');
    const itemList = itemName.trim().split(' - ').slice(2);

    // removes the last item from the list when there is more
    // than 1 level and replace it with the item name.
    const linkPath = itemList.length > 1 ? itemList.slice(0, -1).join(':') : itemList.join(':');
    const path = linkPath.replace(CLEAN_PATH_REGEX, '');

    const tealiumData = await sendTealiumLinkEvent(
      findTemplate(LINK_TRACKING),
      LINK_TRACKING_EVENT_TEMPLATE({
        appId: appId.toLocaleLowerCase(),
        component,
        country: countryCode,
        siteSection: itemList[0] && itemList[0].toLowerCase(),
        path: itemList.length > 1 ? `${path}:${anchorText}` : path,
      }),
    );

    return dispatch(sendTealiumEvent(tealiumData));
  }

  return dispatch({
    type: NO_ACTION,
  });
};

export const sendVideoPlayerEvent: ISendVideoPlayerEventActionCreator = ({
  workout,
  from,
  searchState,
  state,
}) => async (dispatch) => {
  const tealiumPageName = getTealiumPageName(location.pathname);
  const { id, isBODi, title, video, program, position, objectID } = workout;

  const tealiumData = await sendTealiumLinkEvent(
    findTemplate(PLAY_BUTTON_SEARCH),
    PLAY_BUTTON_TEMPLATE({
      eventType: PLAY_BUTTON_SEARCH,
      from,
      guid: isBODi ? id : video?.guid || '',
      objectID,
      position,
      programName: program?.slug,
      searchState,
      state,
      tabName: tealiumPageName,
      title,
    }),
  );

  return dispatch(sendTealiumEvent(tealiumData));
};

export const sendResourceEvent: ISendResourceEventActionCreator = ({
  hit,
  link,
  searchState,
  state,
}) => async (dispatch) => {
  const { objectID, position, queryID } = hit;
  const { title, url } = link;
  const tealiumPageName = getTealiumPageName(location.pathname);

  const tealiumData = await sendTealiumLinkEvent(
    findTemplate(DOWNLOAD_KEY_SEARCH),
    PROGRAM_MATERIALS_EVENT_TEMPLATE({
      eventType: DOWNLOAD_KEY_SEARCH,
      objectID,
      pageName: tealiumPageName,
      position,
      queryID,
      searchState,
      title,
      type: state,
      url,
    }),
  );

  return dispatch(sendTealiumEvent(tealiumData));
};
