import styled from 'styled-components';
import Body2 from '../../shared/typography/body2';
import { Media } from '../../styles/media';

const { tabletUp } = Media;

export const SearchContainer = styled.button`
  background-color: transparent;
  border: none;
  padding: 2px 5px 2px 0;
  margin: 0;
  flex: 1;
  text-align: right;
`;

export const SearchButton = styled(Body2)`
  background: transparent;
  border: none;
  color: ${({ theme }) => theme.darkGray};
  cursor: pointer;
  height: 18px;
  margin: 0;
  max-width: 128px;
  overflow: hidden;
  padding-left: 0;
  padding-right: 0;
  position: relative;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: normal;

  ${tabletUp`
    padding-left: 23px;
  `};
`;

export const SearchIcon = styled.img`
  height: 18px;
  width: 18px;
  left: 0;
  vertical-align: unset;

  ${tabletUp`
    height: 16px;
    width: 16px;
    position: absolute;
    top: 2px;
    left: 5px;
  `};
`;
