/** @jsx h */
import { FunctionalComponent, h } from 'preact';
import { useTranslation } from 'react-i18next';
import { LocaleChangeModal } from '../locale';
import { SupportedLanguages } from '../../../enums/locale';
import { LocaleIcons } from '../../../assets';
import {
  Background,
  Close,
  Container,
  WhiteBox,
  BodyText,
  GrayBox,
  AcceptButton,
  CancelButton,
  BodyContainer,
  Heading,
  IconContainer,
  LanguageText,
  NoteText,
} from '../localeChangeModalStyled';

export interface ILocaleChangeConfirmationModalProps {
  onCancel(): void;
  onAccept(): void;
  newLocale: SupportedLanguages;
  selectedLocale: SupportedLanguages;
  isLoggedIn: boolean;
}

const LocaleChangeConfirmationModal: FunctionalComponent<ILocaleChangeConfirmationModalProps> = ({
  onCancel,
  onAccept,
  newLocale,
  selectedLocale,
  isLoggedIn,
}) => {
  const newLocaleIcon = LocaleIcons[newLocale];
  const selectedLocaleIcon = LocaleIcons[selectedLocale];
  const { t } = useTranslation();
  const newLocaleKey = LocaleChangeModal[newLocale];
  const currentLocaleKey = LocaleChangeModal[selectedLocale];

  return (
    <Background>
      <Container>
        <WhiteBox>
          <Close onClick={onCancel} />
          <Heading>{t(LocaleChangeModal.countryAndLanguage)}</Heading>
          {isLoggedIn && (
            <BodyContainer>
              <BodyText>{t(LocaleChangeModal.currentShopIn)}</BodyText>
              <IconContainer>
                <img src={selectedLocaleIcon} />
                <LanguageText>{t(currentLocaleKey)}</LanguageText>
              </IconContainer>
            </BodyContainer>
          )}
          <BodyContainer>
            <BodyText>{t(LocaleChangeModal.newShopIn)}</BodyText>
            <IconContainer>
              <img src={newLocaleIcon} />
              <LanguageText>{t(newLocaleKey)}</LanguageText>
            </IconContainer>
          </BodyContainer>
          <NoteText>{t(LocaleChangeModal.note)}</NoteText>
        </WhiteBox>
        <GrayBox>
          <CancelButton onClick={onCancel}>{t(LocaleChangeModal.no)}</CancelButton>
          <AcceptButton onClick={onAccept}>{t(LocaleChangeModal.yes)}</AcceptButton>
        </GrayBox>
      </Container>
    </Background>
  );
};

export default LocaleChangeConfirmationModal;
