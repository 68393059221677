import { Record } from 'immutable';
import { DefaultTheme } from 'styled-components';
import { IMenuItem, MenuItemTypes } from '../interfaces/IMenuItem';
import { IModelStatic } from '../interfaces/IModel';
import Item, { IItemComposed, IRawItem } from './Item';
import { theme } from '../styles/theme';
import { BANNER_FONT_WEIGHT_DEFAULT } from '../enums/styles';
export interface IBanner extends IMenuItem {
  title: string;
  text: string;
  textColor?: string;
  backgroundColor?: string;
  fontWeight?: number;
  cta?: IItemComposed;
}

export interface IRawBanner {
  id: string;
  locale: string;
  __typename?: string;
  title: string;
  text: string;
  textColor: string;
  backgroundColor: string;
  fontWeight: number;
  cta?: IRawItem;
}

export interface IBannerModel extends Record<IBanner>, IBanner {}
interface ITheme extends DefaultTheme {
  darkGray: string;
  nutritionalsYellow: string;
}

export const defaultProps = {
  id: '',
  locale: '',
  __typename: MenuItemTypes.banner,
  title: '',
  text: '',
  textColor: (theme as ITheme).darkGray,
  backgroundColor: (theme as ITheme).nutritionalsYellow,
  fontWeight: BANNER_FONT_WEIGHT_DEFAULT,
  cta: undefined,
};

const Banner: IModelStatic<IBannerModel, IRawBanner> = class
  extends Record<IBanner>(defaultProps, 'Banner')
  implements IBannerModel {
  static createFromRaw(rawBanner?: IRawBanner): IBannerModel {
    const { cta: rawCta } = rawBanner || {};

    const cta = rawCta ? Item.createFromRaw(rawCta) : undefined;
    return new this({ ...(rawBanner || {}), cta });
  }
};

export default Banner;
