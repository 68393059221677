/** @jsx h */
import { FunctionalComponent, h } from 'preact';
import { connect } from 'react-redux';
import { setOpenSsoAccount } from '../../actions/headerActions';
import DefaultAvatar from '../../assets/avatar.svg';
import { AppState } from '../../interfaces/appState';
import { IMenuItemModel, IMenuModel } from '../../models/Menu';
import { ISsoModel } from '../../interfaces/ISso';
import { modulesByKeySelector } from '../../selectors/modulesSelectors';
import { shouldShowSsoAccountsSelector } from '../../selectors/topNavSelectors';
import DropdownAccounts from '../desktopNav/items/accountSwitch/dropDownAccounts';
import { Dropdown } from '../dropdown';
import { DropdownMenu } from '../dropdownMenu';
import { AvatarButton, AvatarIcon, Container } from './userAvatarStyled';
import { HUNDRED_PERCENT } from '../../utils/constants';
import { resolveRelativeUrl } from '../../utils/device';
import { E2ETestingProvider, useE2ETesting } from '../../providers/e2eTestingProvider';

const ALT_USER_AVATAR = 'user-avatar';

export interface IUserAvatarProps {
  item: IMenuItemModel;
  sso: ISsoModel;
  showSsoAccounts: boolean;
  setOpenSsoAccount: typeof setOpenSsoAccount;
}

export const UserAvatar: FunctionalComponent<IUserAvatarProps> = ({
  item,
  sso,
  showSsoAccounts,
  setOpenSsoAccount,
}) => {
  const { isEnabled, userInfo: { avatarUrl, roleList } = {}, accountList: ssoAccounts } = sso;
  const { testIdPrefix } = useE2ETesting();

  return isEnabled ? (
    <E2ETestingProvider testIdPrefix={testIdPrefix} testIdSuffix="userAccount">
      <Dropdown
        isParentElement
        onMouseLeaveCustom={() => setOpenSsoAccount(false)}
        openComponent={(isOpen) => (
          <AvatarButton>
            <AvatarIcon
              alt={ALT_USER_AVATAR}
              isOpen={isOpen}
              src={avatarUrl || `${resolveRelativeUrl(DefaultAvatar)}`}
            />
          </AvatarButton>
        )}
        top="40px"
        right="0px"
      >
        <Container showAccounts={showSsoAccounts}>
          {showSsoAccounts && <DropdownAccounts roleList={roleList} accounts={ssoAccounts} />}
          <DropdownMenu
            containerWidth={HUNDRED_PERCENT}
            columnWidth={HUNDRED_PERCENT}
            menu={item as IMenuModel}
          />
        </Container>
      </Dropdown>
    </E2ETestingProvider>
  ) : null;
};

const mapStateToProps = (state: AppState) => ({
  sso: modulesByKeySelector(state, 'sso'),
  showSsoAccounts: shouldShowSsoAccountsSelector(state),
});

export default connect(mapStateToProps, { setOpenSsoAccount })(UserAvatar);
