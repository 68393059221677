/** @jsx h */
import { FunctionalComponent, h } from 'preact';
import { useCallback } from 'preact/hooks';
import { IItemModel, ItemTypes } from '../../../../models/Item';
import { ItemIcon, ItemLinkText, ItemContainer } from '../../desktopNavStyled';
import { useDropdown } from '../../../../providers/dropdownProvider';
import { StyledAppLink } from './defaultItemStyled';
import {
  E2E,
  getDataTestIdSuffix,
  getTestID,
  useE2ETesting,
} from '../../../../providers/e2eTestingProvider';

interface IDefaultItemProps {
  item: IItemModel;
}

const { default: defaultType, featuredLink, subItem } = ItemTypes;

const DefaultItem: FunctionalComponent<IDefaultItemProps> = ({ item }) => {
  const { anchorText, itemName, menuImageUrl, itemType } = item;
  const isBold = !![defaultType, featuredLink].includes(itemType);
  const isSubItem = itemType === subItem;
  const { onBlurCallback, focusedMenuRef } = useDropdown();
  const handleOnBlur = useCallback(
    (event: FocusEvent) => {
      if (focusedMenuRef && !focusedMenuRef.current?.contains(event.relatedTarget as Node)) {
        onBlurCallback?.current && onBlurCallback.current(event);
      }
    },
    [focusedMenuRef, onBlurCallback],
  );
  const { testIdPrefix } = useE2ETesting();
  const e2eComponent = isSubItem ? E2E.component.menuItem : E2E.component.menu;
  const testIdValue = getTestID(testIdPrefix, e2eComponent, getDataTestIdSuffix(itemName));

  return (
    <ItemContainer data-testid={testIdValue}>
      <StyledAppLink item={item} isSubItem={isSubItem} onBlur={handleOnBlur}>
        {menuImageUrl && (
          <ItemIcon alt={itemName || anchorText} src={menuImageUrl} hasContent={!!anchorText} />
        )}
        <ItemLinkText isBold={isBold} dangerouslySetInnerHTML={{ __html: anchorText }} />
      </StyledAppLink>
    </ItemContainer>
  );
};

export default DefaultItem;
