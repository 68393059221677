/** @jsx h */
import { FunctionalComponent, h } from 'preact';
import { useTranslation } from 'react-i18next';
import { ICoachInfoModel } from '../../models/CoachInfo';
import { COACH_BANNER_ELEMENT_ID } from '../../utils/constants';
import { CoachEmail, CoachText, Container, CoachAvatar } from './coachBannerStyled';
import DefaultAvatar from '../../assets/avatar.svg';
import { CoachBannerLocale } from './locale';
import { resolveRelativeUrl } from '../../utils/device';

export interface ICoachBanner {
  coachInfo: ICoachInfoModel;
}

const CoachBanner: FunctionalComponent<ICoachBanner> = ({
  coachInfo: { firstName, lastName, avatarUrl, email, guid },
}) => {
  const { t } = useTranslation();

  return (
    <Container id={COACH_BANNER_ELEMENT_ID}>
      <CoachAvatar
        alt={guid || firstName}
        src={avatarUrl || `${resolveRelativeUrl(DefaultAvatar)}`}
      />
      <CoachText>{t(CoachBannerLocale.your_coach, { firstName, lastName })}</CoachText>
      <CoachEmail href={`mailto:${email}`}>{email}</CoachEmail>
    </Container>
  );
};

export default CoachBanner;
