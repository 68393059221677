/** @jsx h */
import { FunctionalComponent, h } from 'preact';
import { useCallback } from 'preact/hooks';
import { useTranslation } from 'react-i18next';
import { Description, Container, Image, TopResultSection, Link, Title } from './topResultStyled';
import { CategoryTitle } from '../autoComplete/suggestionContainerStyled';
import useNavigation from '../../hooks/useNavigation';
import { TopResultLocale } from './locale';

export interface ITopResultProps {
  description?: string;
  imageUrl?: string;
  isMobile: boolean;
  linkText: string;
  redirectUrl: string;
  title?: string;
}

export const TopResult: FunctionalComponent<ITopResultProps> = ({
  description,
  imageUrl,
  isMobile,
  linkText,
  redirectUrl,
  title,
}) => {
  const { t } = useTranslation();
  const { redirect } = useNavigation();
  const redirectTo = useCallback(() => {
    redirect(redirectUrl);
  }, [redirect, redirectUrl]);

  return (
    <TopResultSection>
      {isMobile && <CategoryTitle>{t(TopResultLocale.topResult)}</CategoryTitle>}
      <Container>
        <Image onClick={redirectTo} src={imageUrl} />
        <Container isMobileColumn>
          {description && <Description>{description}</Description>}
          {title && <Title>{title}</Title>}
          <Link onClick={redirectTo}>{linkText}</Link>
        </Container>
      </Container>
    </TopResultSection>
  );
};

export default TopResult;
