import { IRawTranslation } from '../../models/Translation';
import AppSyncClient from '../../tools/loadMenuContent/graphql/appSyncClient';
import TranslationsBySlug from '../gql/queries/TranslationsBySlug';

export interface ITranslationsFilters {
  slugs: Array<string>;
}

interface ITranslationsClient {
  get(filters: ITranslationsFilters): Promise<Array<IRawTranslation>>;
}

export interface QueryResponse {
  data: {
    getTranslationsBySlug: Array<IRawTranslation>;
  };
}

const TranslationsClient = (locale: string, withCache = false): ITranslationsClient => {
  const gqlClient = AppSyncClient<IRawTranslation>(locale, withCache);

  return {
    async get({ slugs }) {
      const { data } = await gqlClient.runQuery<ITranslationsFilters, QueryResponse>(
        TranslationsBySlug,
        { slugs },
      );

      const { getTranslationsBySlug: rawTranslationList } = data;

      return rawTranslationList || [];
    },
  };
};

export default TranslationsClient;
