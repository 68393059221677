/** @jsx h */
import { FunctionalComponent, h } from 'preact';
import SubNavItem from '../../components/subNavItem';
import { IMenuLocationModel } from '../../models/MenuLocation';
import { Container, SubNav, SubNavHeader } from './subNavStyled';
import DropdownProvider from '../../providers/dropdownProvider';
import { SECONDARY_NAV_ELEMENT_ID } from '../../utils/constants';
import { E2ETestingProvider, E2E, getTestID } from '../../providers/e2eTestingProvider';

export interface ISubNavigationProps {
  menuLocation: IMenuLocationModel;
}

export const SubNavigation: FunctionalComponent<ISubNavigationProps> = ({
  menuLocation: { menuItemList, parentItem },
}) => {
  return (
    <E2ETestingProvider testIdPrefix={getTestID(E2E.project.suffix, E2E.section.subNav)}>
      <DropdownProvider>
        <Container id={SECONDARY_NAV_ELEMENT_ID}>
          {parentItem ? (
            <SubNavHeader>
              <SubNavItem item={parentItem} />
            </SubNavHeader>
          ) : null}
          <SubNav>
            {menuItemList.map((subMenuItem) => (
              <SubNavItem key={subMenuItem.id} item={subMenuItem} />
            ))}
          </SubNav>
        </Container>
      </DropdownProvider>
    </E2ETestingProvider>
  );
};

export default SubNavigation;
