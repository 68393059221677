export enum SHOP_REDIRECT_TERMS {
  bevvy = 'Bevvy',
  collagen = 'Collagen',
  dayRefresh = '3 Day Refresh',
  energize = 'Energize',
  shakeology = 'Shakeology',
}

export const SHOP_TERMS = [
  SHOP_REDIRECT_TERMS.bevvy,
  SHOP_REDIRECT_TERMS.collagen,
  SHOP_REDIRECT_TERMS.dayRefresh,
  SHOP_REDIRECT_TERMS.energize,
  SHOP_REDIRECT_TERMS.shakeology,
];
