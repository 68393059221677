/** @jsx h */
import { FunctionalComponent, h } from 'preact';
import { useCallback } from 'preact/hooks';
import { useEventHandler } from '../../../../providers/eventHandlerProvider';
import { IItemModel } from '../../../../models/Item';
import SecondaryButton from '../secondaryButton';
import { SupportedEvents } from '../../../../enums/event';

const BUTTON_MARGIN = '0';
export interface ISignUpProps {
  item: IItemModel;
}

const SignUpButton: FunctionalComponent<ISignUpProps> = ({ item }) => {
  const { dispatchEvent } = useEventHandler();

  const onSignUp = useCallback(
    (event: Event) => {
      event.preventDefault();

      dispatchEvent(SupportedEvents.onSignUp);
    },
    [dispatchEvent],
  );

  return <SecondaryButton item={item} margin={BUTTON_MARGIN} onClick={onSignUp} />;
};

export default SignUpButton;
