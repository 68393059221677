import root from 'window-or-global';
import { applyMiddleware, createStore, Store, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { AppState } from './interfaces/appState';
import { eventMiddleware } from './middlewares/eventMiddleware';
import { loggingMiddleware } from './middlewares/loggingMiddleware';
import { storeMiddleware } from './middlewares/storeMiddleware';
import rootReducer from './reducers';

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

export default function configureStore(): Store<AppState> {
  const composeEnhancers =
    process.env.NODE_ENV === 'development'
      ? (root.window?.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ as typeof compose) || compose
      : compose;
  const middlewares = [loggingMiddleware, thunkMiddleware, storeMiddleware];
  const middlewareEnhancer = composeEnhancers(applyMiddleware(...middlewares, eventMiddleware));
  const store = createStore(rootReducer, middlewareEnhancer);

  return store;
}

export const store = configureStore();
