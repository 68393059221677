import { Record } from 'immutable';
import root from 'window-or-global';
import { DeviceTypes, getDeviceType } from '../utils/device';
import { AppIdEnum } from '../enums/main';
import { IModelStatic } from '../interfaces/IModel';
import { loadFromConfig } from '../utils/configLoader';
import { IElementConfig } from '../interfaces/IConfig';

const ELEMENT_ID_MAP = loadFromConfig('ELEMENT_ID_MAP');
const appDomainList = loadFromConfig('APP_DOMAIN_LIST');
const appDomainsMap = Object.entries(appDomainList);
const deviceType = getDeviceType();

export const CRAWLER_USER_AGENTS_PATTERN =
  '(googlebot/|bot|Googlebot-Mobile|Googlebot-Image|Google favicon|Mediapartners-Google|Applebot|FacebookBot|facebookexternalhit.*|facebookcatalog.*|Twitterbot)';

const { bod } = AppIdEnum;
export interface IBaseMain {
  isEnabled: boolean;
  elementId: string;
}

export interface IRawMain {
  referrerAppId?: AppIdEnum;
}

export interface IMain {
  topNav?: IBaseMain;
  subNav?: IBaseMain;
  footer?: IBaseMain;
  appId: AppIdEnum;
  isDebugEnabled?: boolean;
  device: DeviceTypes;
  userIP?: string;
  referrerAppId?: AppIdEnum;
  route?: string;
  isCustomNavigationEnabled?: boolean;
  versionName?: string;
}

const defaultProps: IMain = {
  topNav: { isEnabled: false, elementId: '' },
  subNav: { isEnabled: false, elementId: '' },
  footer: { isEnabled: false, elementId: '' },
  appId: bod,
  isDebugEnabled: false,
  device: deviceType,
  userIP: '',
  referrerAppId: undefined,
  route: '/',
  isCustomNavigationEnabled: false,
  versionName: '',
};

export interface IMainModel extends Record<IMain>, IMain {
  validateAppId(rules: string[]): boolean;
  validateDevice(rules: string[]): boolean;
  validateReferrer(): boolean;
  getReferrerAppId(newAppId: AppIdEnum): AppIdEnum | undefined;
  serialize(): IRawMain;
}

export interface IMainModelStatic extends IModelStatic<IMainModel, IMain> {
  getInitialConfig(): IMain;
}

const Main: IMainModelStatic = class
  extends Record<IMain>(defaultProps, 'Main')
  implements IMainModel {
  static createFromRaw(rawMain: IMain): IMainModel {
    return new this(rawMain);
  }

  static getInitialConfig(): IMain {
    const currentDomain = `${location.protocol}//${location.host}`;
    const [id] = appDomainsMap.find(([, domain]) => currentDomain === domain) || [bod];
    const appId = id as AppIdEnum;
    const { topNav, footer } = ELEMENT_ID_MAP[appId] as IElementConfig;

    const initialConfig: IMain = {
      appId,
      device: deviceType,
      topNav: { isEnabled: true, elementId: topNav },
      footer: { isEnabled: true, elementId: footer },
    };

    return initialConfig;
  }

  serialize(): IRawMain {
    const { referrerAppId } = this;

    return { referrerAppId };
  }

  getReferrerAppId(newAppId: AppIdEnum): AppIdEnum {
    const { referrer } = root.document;
    const { referrerAppId: referrerAppIdFromStorage, appId } = this;
    const crawlerUserAgentRegex = new RegExp(CRAWLER_USER_AGENTS_PATTERN, 'i');
    const [id] =
      appDomainsMap.find(
        ([key, domain]) => referrer.includes(domain as string) && key !== AppIdEnum.co,
      ) || [];
    const referrerAppId = id as AppIdEnum;
    const filteredAppIdList = Object.values(AppIdEnum).filter(
      (currentAppId) => currentAppId !== appId && currentAppId !== AppIdEnum.co,
    );
    if (referrerAppId === AppIdEnum.tbb && crawlerUserAgentRegex.test(root?.navigator?.userAgent)) {
      return AppIdEnum.bod;
    }

    if (filteredAppIdList.includes(referrerAppId)) return referrerAppId;

    return referrerAppIdFromStorage || newAppId;
  }

  validateAppId(rules: string[]): boolean {
    return rules.includes(this.appId.toLowerCase());
  }

  validateDevice(rules: string[]): boolean {
    return rules.includes(this.device.toLowerCase());
  }

  validateReferrer(): boolean {
    const { referrerAppId, appId } = this;

    return (
      !referrerAppId || referrerAppId === appId || !Object.values(AppIdEnum).includes(referrerAppId)
    );
  }
};

export default Main;
