import styled from 'styled-components';

export const IconImage = styled.img`
  margin-top: 15px;
  margin-bottom: 7px;
  width: 100%;
  max-width: fit-content;
  height: auto;
`;

export const Close = styled.img`
  height: 18px;
  position: absolute;
  right: 24px;
  top: 24px;
  width: 18px;
`;
