import styled from 'styled-components';

export const MenuNavigationContainer = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  background-color: ${({ theme }) => theme.pearlGray};
  margin-top: ${({ shouldHavePadding }) => shouldHavePadding && '15px'};
  border-top: ${({ shouldHavePadding, theme }) =>
    shouldHavePadding && `1px solid ${theme.lighterGray}`};
`;
