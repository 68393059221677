import { createSelector } from 'reselect';
import { AppState } from '../interfaces/appState';
import { IEventModel } from '../models/Event';
import { IEventStateRecord } from '../reducers/eventReducer';

const eventSelector = (state: AppState): IEventStateRecord => state.events;

export const eventsSelector = createSelector(
  eventSelector,
  (events: IEventStateRecord): Array<IEventModel> => events.get('events'),
);
