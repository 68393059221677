/** @jsx h */
import { FunctionalComponent, h } from 'preact';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { AvatarIcon } from '../../../userAvatar/userAvatarStyled';
import { AppState } from '../../../../interfaces/appState';
import { ISsoModel } from '../../../../interfaces/ISso';
import { modulesByKeySelector } from '../../../../selectors/modulesSelectors';
import {
  CoachContainer,
  CoachInfoContainer,
  CoachNavItem,
  Profile,
  UserName,
  Email,
} from './coachStyled';
import DefaultAvatar from '../../../../assets/avatar.svg';
import { Roles } from '../../../../enums/sso';
import { resolveRelativeUrl } from '../../../../utils/device';
import { Locales } from './locales';

export interface ICoachProps {
  sso: ISsoModel;
}

const Coach: FunctionalComponent<ICoachProps> = ({ sso }) => {
  const { t } = useTranslation();
  const { coachInfo: { email, firstName, lastName, avatarUrl, guid, coachType } = {} } = sso;

  return !!guid && coachType != Roles.CORP ? (
    <CoachNavItem>
      <CoachContainer>
        <AvatarIcon src={avatarUrl || `${resolveRelativeUrl(DefaultAvatar)}`} />
        <CoachInfoContainer>
          <Profile dangerouslySetInnerHTML={{ __html: t(Locales.my_coach) }} />
          <UserName className="notranslate" isBold>
            {firstName} {lastName}
          </UserName>
          <Email>{email}</Email>
        </CoachInfoContainer>
      </CoachContainer>
    </CoachNavItem>
  ) : null;
};

const mapStateToProps = (state: AppState) => ({
  sso: modulesByKeySelector(state, 'sso'),
});

export default connect(mapStateToProps)(Coach);
