/** @jsx h */
import { FunctionalComponent, h } from 'preact';
import { useMemo } from 'preact/hooks';
import desktopFactory from '../../factories/DesktopFactory';
import { E2ETestingProvider, E2E, getTestID } from '../../providers/e2eTestingProvider';
import { IMenuLocationModel } from '../../models/MenuLocation';

export interface ITopNavLocationProps {
  menuLocation: IMenuLocationModel;
  handleOnSearch?(): void;
  isDesktop: boolean;
  isTablet: boolean;
}

const TopNavLocation: FunctionalComponent<ITopNavLocationProps> = ({
  menuLocation,
  handleOnSearch,
  isDesktop,
  isTablet,
}) => {
  const Component = useMemo(() => desktopFactory.mapMenuLocation(menuLocation), [menuLocation]);

  return Component ? (
    <E2ETestingProvider testIdPrefix={getTestID(E2E.project.suffix, E2E.section.topNav)}>
      <Component
        menuLocation={menuLocation}
        isTablet={isTablet}
        isDesktop={isDesktop}
        handleOnSearch={handleOnSearch}
      />
    </E2ETestingProvider>
  ) : null;
};

export default TopNavLocation;
