/** @jsx h */
import { FunctionalComponent, h } from 'preact';
import { connect } from 'react-redux';
import { setActiveMenuMobile } from '../../../../actions/headerActions';
import { NavItem } from '../../../../containers/mobileNavigation/mobileNavigationStyled';
import { AppState } from '../../../../interfaces/appState';
import { IItemModel } from '../../../../models/Item';
import { ILocaleModel } from '../../../../models/Locale';
import { IMenuModel } from '../../../../models/Menu';
import { modulesByKeySelector } from '../../../../selectors/modulesSelectors';
import { getSelectedLocaleItemFromList } from '../../../../utils/locale';
import { RightArrow } from '../menu/menuStyled';
import { LocaleContainer, LocaleIcon, SelectedLocale } from './localeStyled';

export interface ILocaleProps {
  locale: ILocaleModel;
  item: IMenuModel;
  setActiveMenu: typeof setActiveMenuMobile;
}

export const Locale: FunctionalComponent<ILocaleProps> = ({ locale, item, setActiveMenu }) => {
  const { selectedLocale } = locale;
  const { menuItemList } = item;
  const selectedLocaleItem = getSelectedLocaleItemFromList(
    menuItemList as Array<IItemModel>,
    selectedLocale,
  );

  return (
    <NavItem onClick={() => setActiveMenu(item as IMenuModel)}>
      <LocaleContainer>
        <LocaleIcon src={selectedLocaleItem?.menuImageUrl} />
        <SelectedLocale dangerouslySetInnerHTML={{ __html: selectedLocaleItem?.anchorText }} />
      </LocaleContainer>
      <RightArrow />
    </NavItem>
  );
};

const mapStateToProps = (state: AppState) => ({
  locale: modulesByKeySelector(state, 'locale'),
});

const mapDispatchToProps = {
  setActiveMenu: setActiveMenuMobile,
};

export default connect(mapStateToProps, mapDispatchToProps)(Locale);
