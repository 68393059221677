/** @jsx h */
import { FunctionalComponent, h, VNode } from 'preact';
import { useRef, useCallback, MutableRef } from 'preact/hooks';
import { DropdownContainer, DropdownWrapper } from './dropdownStyled';
import useDropdown from './hooks/useDropdown';
import { useDropdown as useDropdownProvider } from '../../providers/dropdownProvider';
import useRightAlignOnViewportOverflow from '../../hooks/useRightAlignOnViewportOverflow';
import { E2E, getTestID, useE2ETesting } from '../../providers/e2eTestingProvider';

export interface IDropdown {
  bottom?: string;
  children: VNode | null;
  isParentElement?: boolean;
  left?: string;
  right?: string;
  top?: string;
  width?: string;
  onMouseLeaveCustom?: () => void;
  openComponent(
    isOpen: boolean,
    handleMouseEnter: () => void,
    handleMouseLeave: () => void,
    wrapperRef: MutableRef<HTMLElement | undefined>,
  ): VNode | null;
}

export const Dropdown: FunctionalComponent<IDropdown> = ({
  children,
  bottom,
  isParentElement = false,
  left,
  openComponent,
  right,
  top,
  width,
  onMouseLeaveCustom,
}) => {
  const { focusedMenuRef } = useDropdownProvider();
  const wrapperRef = useRef<HTMLElement>();
  const { isOpen, handleOnMouseEnter, handleOnMouseLeave } = useDropdown(wrapperRef);
  const { translate, ref: containerRef } = useRightAlignOnViewportOverflow();
  const { testIdPrefix, testIdSuffix } = useE2ETesting();
  const testIdValue = testIdSuffix
    ? getTestID(testIdPrefix, E2E.component.dropdown, testIdSuffix)
    : '';

  const handleMouseLeave = useCallback(() => {
    if (onMouseLeaveCustom) {
      onMouseLeaveCustom();
    }

    if (focusedMenuRef) {
      focusedMenuRef.current = null;
    }

    handleOnMouseLeave();
  }, [onMouseLeaveCustom, focusedMenuRef, handleOnMouseLeave]);

  const handleMouseEnter = useCallback(() => {
    (document.activeElement as HTMLElement).blur();

    handleOnMouseEnter();
  }, [handleOnMouseEnter]);

  return (
    <DropdownWrapper
      data-testid={testIdValue}
      isParentElement={isParentElement}
      ref={wrapperRef}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {openComponent(isOpen, handleOnMouseEnter, handleMouseLeave, wrapperRef)}
      {isOpen && children ? (
        <DropdownContainer
          ref={containerRef}
          bottom={bottom}
          left={left}
          right={right}
          top={top}
          translate={translate}
          width={width}
        >
          {children}
        </DropdownContainer>
      ) : null}
    </DropdownWrapper>
  );
};
