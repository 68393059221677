import { DefaultTheme } from 'styled-components';

export interface IThemeProps {
  theme: DefaultTheme;
}

export const theme: DefaultTheme = {
  // white
  whiteBG: '#FFFFFF',
  pearlGray: '#F7F8FA',
  dawnGray: '#F0F2F7',
  customSilverGray: '#C1C1C1',
  // grey colors
  lightGray: '#D9D9D9',
  lighterGray: '#E8EAEE',
  disabledGray: '#A09EA3',
  skyGray: '#D7D9DE',
  containerGray: '#BDBDBD',
  veryLightGray: '#cdcdcd',
  buttersGray: '#bdbdbd',
  silverGray: '#969499',
  fordSilverGray: '#cccccc',
  borderGray: '#e7e7e7',
  // black
  medGray: '#706F73',
  carbonGray: '#4A4B4D',
  boxGray: '#2D2F30',
  darkGray: '#161819',
  whisperGray: '#E4E4E4',
  black: '#000000',
  blackTransparent: 'rgba(0, 0, 0, 0.3)',
  // purple
  notesPurple: '#8a55bb',
  containerPurple: '#7D659C',
  progressPhotoPurple: '#cb63d1',
  progressPhotoBGPurple: '#fef0ff',
  notesBGPurple: '#f2eff5',
  // blue
  blueBG: '#E5F9FA',
  creamBlue: '#E8F4FF',
  skyBlue: '#D1E8FF',
  oneAppBlue50: '#91C7FF',
  oneAppBlue: '#2490FF',
  darkBlue: '#004992',
  containerBlue: '#0060A7',
  sneaker: '#007aff',
  blueHover: '#1252b3',
  bluePressed: '#0f3699',
  trackingWaterBlue: '#006db9',
  bbBlue: '#006FB5',
  measurementBlue: '#e8fbff',
  cornFlowerBlue: '#59aaff',
  waterBlue: '#01ccd1',
  bbLogoBlue: '#006fb6',
  // orange
  containerOrange: '#F68B1F',
  hoverPressedEnterpriseOrange: '#E06E00',
  enterpriseOrange: '#f59331',
  trackingPhotosOrange: '#fb6064',
  mealsBgOrange: '#fff2e0',
  mealsOrange: '#ff9147',
  // red
  errorRed: '#D50000',
  containerRed: '#D2232A',
  messageRed: '#dd2c00',
  // yellow
  yellowBG: '#FFFBD9',
  frenchYellow: '#FFFDE6',
  butterYellow: '#FFFACC',
  pollenYellow: '#FFF380',
  congratsYellow: '#FFE600',
  containerYellow: '#FFCF01',
  nutritionalsYellow: '#f6bf26',
  // green
  touchGreen: '#E8FAF2',
  teaGreen: '#D1F5E5',
  nutriGreen50: '#8DE6BD',
  nutriGreen: '#1BCD7B',
  halfNutriGreen: '#8de6bd',
  superTrainerGreen: '#17a666',
  shamrockGreen: '#19AA66',
  containerGreen: '#00A651',
  shakeologyGreen: '#6dc24b',
  measurementsGreen: '#1089a1',
  weightGreen: '#e3faf6',
  darkWeightGreen: '#11ab91',
  shakeBGGreen: '#f0f8ed',
  // box-shadows
  boxShadowOne: '0px 1px 2px 0px rgba(0, 0, 0, 0.50)',
  boxShadowTwo: '0px 2px 4px 0px rgba(0, 0, 0, 0.30)',
  boxShadowThree: '0px 4px 6px 1px rgba(0, 0, 0, 0.20)',
  boxShadowFour: '0 2px 2px 0 rgba(0, 0, 0, 0.24)',
  boxShadowFive: '0 0 2px 0 rgba(0, 0, 0, 0.12)',
  boxShadowSix: '0 -4px 6px 0 rgba(0, 0, 0, 0.2)',
  // misc
  backgroundModalOverlayOne: 'rgba(27, 31, 35, 0.05)',
  backgroundModalOverlayTwo: 'rgba(138, 138, 138, 0.75)',
  backgroundModalOverlayThree: 'rgba(0, 0, 0, 0.1)',
  backgroundModalOverlayFour: 'rgba(0, 0, 0, 0.5)',
  backgroundModalOverlayFive: 'rgba(22, 24, 25, 0.6)',
  // max widths
  canvasWidth: '1366px',
  contentSearchWidth: '1320px',
  contentWellWidth: '1024px',
  contentFullWidth: '100%',
  subContentWellWidth: '960px',
  groupCreationModalContentWidth: '504px',
  groupContainerWidth: '677px',
  // content gutters
  gutterSmall: '16px',
  gutterMedium: '24px',
  gutterLarge: '35px',
};
