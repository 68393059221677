import { IRawSso, ISsoModel } from '../../interfaces/ISso';
import Sso from '../../models/Sso';
import LocalStorageClient, { ILocalStorageClientUser } from '../localStorageClient';

const SSO_KEY = 'sso';

const { get, set, del } = LocalStorageClient<IRawSso>();

const SsoClient: ILocalStorageClientUser<ISsoModel> = {
  get: () => {
    const savedData = get(SSO_KEY);

    return Sso.createFromRaw(savedData?.data || undefined);
  },
  set: (sso) => {
    set(SSO_KEY, {
      data: sso.serialize(),
    });
  },
  del: () => del(SSO_KEY),
};

export default SsoClient;
