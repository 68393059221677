/** @jsx h */
import { FunctionalComponent, h } from 'preact';
import { IItemModel } from '../../models/Item';
import { LocaleContainer } from './dropdownLocaleStyled';
import MenuItemLocale from './menuItemLocale';
import { E2E, getTestID, useE2ETesting } from '../../providers/e2eTestingProvider';
import { SupportedLanguages } from '../../enums/locale';

export interface IDropdownLocaleProps {
  selectedLocale: SupportedLanguages;
  localeList: Array<IItemModel>;
  updateLocale(newLocale: SupportedLanguages): void;
}

const buildMenus = (
  menus: Array<IItemModel>,
  selectedLocale: SupportedLanguages,
  selectLocale: (newLocale: SupportedLanguages) => void,
) =>
  menus.map((item) => (
    <MenuItemLocale
      key={item.id}
      selectedLocale={selectedLocale}
      item={item}
      updateLocale={selectLocale}
    />
  ));

export const DropdownLocale: FunctionalComponent<IDropdownLocaleProps> = ({
  selectedLocale,
  localeList,
  updateLocale,
}) => {
  const { testIdPrefix } = useE2ETesting();

  return (
    <LocaleContainer data-testid={getTestID(testIdPrefix, E2E.component.dropdown, 'locale')}>
      {buildMenus(localeList, selectedLocale, updateLocale)}
    </LocaleContainer>
  );
};

export default DropdownLocale;
