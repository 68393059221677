import styled from 'styled-components';
import Button1Stroke from '../button1Stroke';
import { setAllLinkStates } from '../../../styles/media';

export default styled(Button1Stroke)`
  border: none;
  white-space: nowrap;
  letter-spacing: 0.05em;

  ${setAllLinkStates`
    box-shadow: none;
  `}

  &:hover,
  a:hover& {
    border: none;
  }

  &:disabled,
  &:disabled:hover {
    border: none;
    background-color: ${({ theme }) => theme.whiteBG};
  }
`;
