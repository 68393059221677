import HttpMethod from '../../enums/httpMethod';
import { SupportedLanguages } from '../../enums/locale';
import MenuLocation, { IMenuLocationModel, IRawMenuLocation } from '../../models/MenuLocation';
import { loadFromConfig } from '../../utils/configLoader';
import { request } from '../restClient';
import MENU_DATA_BY_LOCALE from '../../assets/content';

const relativePath = loadFromConfig('RELATIVE_PATH').replace('script', '');

const MenuData = {
  get: async (selectedLocale: SupportedLanguages): Promise<Array<IMenuLocationModel>> =>
    request<never, Array<IRawMenuLocation>>({
      method: HttpMethod.GET,
      path: `${relativePath}${selectedLocale}.json`,
    })
      // parses the data coming from the s3 bucket
      .then(({ json }) =>
        json.map((rawMenuLocation) => MenuLocation.createFromRaw(rawMenuLocation)),
      )

      // fallback to use the static files from the bundle
      .catch(async () => {
        const data = await MENU_DATA_BY_LOCALE[selectedLocale];

        return ((data as unknown) as Array<IRawMenuLocation>).map((rawMenuLocation) =>
          MenuLocation.createFromRaw(rawMenuLocation),
        );
      }),
};

export default MenuData;
