import styled, { css, DefaultTheme } from 'styled-components';
import { Body5 } from '../../shared/typography';
import { Media } from '../../styles/media';

const { tabletUp, desktopUp } = Media;

export const CartBadge = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.oneAppBlue};
  border: solid 1px ${({ theme }) => theme.whiteBG};
  border-radius: 50%;
  color: ${({ theme }) => theme.whiteBG};
  display: flex;
  font-size: 10px;
  justify-content: center;
  position: absolute;
  top: -10px;
  right: -10px;
  height: 16px;
  width: 17px;
  ${tabletUp`
    top: -14px;
    right: -8px;
    height: 19px;
    width: 19px;
  `};
`;

export const CartBadgeText = styled(Body5)`
  margin: 0;
  color: ${({ theme }) => theme.whiteBG};
`;

export const CartContainer = styled.div`
  align-items: center;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  display: flex;
  justify-content: center;
  position: relative;
  height: 17px;
  width: 17px;
  box-sizing: border-box;

  ${tabletUp`
    ${css<DefaultTheme>`
      height: unset;
      width: unset;
    `};
  `};
`;

export const CartIcon = styled.img`
  height: 17px;
  margin-right: 2px;
  width: 20px;
  ${desktopUp`
    height: 19px;
    margin-right: 0;
    margin-top: 2px;
    width: 22px;
    max-width: unset;
  `};
`;
