/** @jsx h */
import { FunctionalComponent } from 'preact';
import { useEffect } from 'preact/hooks';
import { connectStats } from 'react-instantsearch-dom';

export interface IResultsProps {
  nbHits: number;
  setNbHits: (nbHits: number) => void;
}

export const Results: FunctionalComponent<IResultsProps> = ({ nbHits, setNbHits }) => {
  useEffect(() => {
    setNbHits(nbHits);
  }, [nbHits, setNbHits]);
  return null;
};

export default connectStats(Results);
