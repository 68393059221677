import { Record } from 'immutable';
import get from 'lodash/get';
import { VIDEO_PROGRAM_TYPES, HIT_TYPES, BODi } from '../enums/algolia';
import { IModelStatic } from '../interfaces/IModel';
import { IHit } from '../interfaces/ISearch';
import {
  IRawFitnessLevel,
  IRawMpxVideo,
  IRawWorkout,
  IRawWorkoutCommerce,
} from '../interfaces/IWorkout';
import Program, { IProgramModel } from './Program';
import AssociatedContent, { IAssociatedContentModel } from './AssociatedContent';
import VideoModalSection, { IVideoModalSectionModel } from './VideoModalSection';
import Violator, { IViolatorModel } from './Violator';
import { SourceLanguage } from '../enums/locale';

export interface IWorkout extends IHit {
  accessPolicies?: string[];
  commerce?: IRawWorkoutCommerce;
  associatedContent?: IAssociatedContentModel;
  brandCode: string;
  description: string;
  duration: string;
  equipments?: IVideoModalSectionModel;
  groupName: string;
  id: string;
  imageUrl: string;
  isBODi: boolean;
  musicGenre?: string[];
  fitnessLevels?: Array<IRawFitnessLevel>;
  pdfCount: number;
  position: number;
  program?: IProgramModel;
  programTitle: string;
  queryID: string;
  subNavSlug: string;
  tier?: string;
  title: string;
  trainer: string;
  sourceLanguage?: string;
  slug: string;
  type: string;
  video?: IRawMpxVideo;
  violator?: IViolatorModel;
  workoutLevel: string;
  workoutType: string;
  workoutTypes?: IVideoModalSectionModel;
  filmDate?: string;
}

export interface IWorkoutModel extends Record<IWorkout>, IWorkout {}

const defaultProps = {
  accessPolicies: [],
  commerce: undefined,
  associatedContent: undefined,
  brandCode: '',
  description: '',
  duration: '',
  equipments: undefined,
  groupName: '',
  id: '',
  imageUrl: '',
  isBODi: false,
  musicGenre: [],
  fitnessLevels: undefined,
  objectID: '',
  pdfCount: 0,
  position: 0,
  program: undefined,
  programTitle: '',
  queryID: '',
  slug: '',
  sourceLanguage: undefined,
  subNavSlug: '',
  tier: undefined,
  title: '',
  trainer: '',
  type: VIDEO_PROGRAM_TYPES.WORKOUT,
  violator: undefined,
  video: undefined,
  workoutLevel: '',
  workoutType: '',
  workoutTypes: undefined,
  filmDate: undefined,
  __hitType: HIT_TYPES.WORKOUT,
};

const Workout: IModelStatic<IWorkoutModel, IRawWorkout> = class
  extends Record<IWorkout>(defaultProps, 'Workout')
  implements IWorkoutModel {
  static createFromRaw(rawWorkout?: IRawWorkout): IWorkoutModel {
    const {
      accessPolicies,
      commerce,
      brandCode,
      description,
      equipments: rawEquipments,
      groupName,
      id,
      imageUrl,
      mpxVideo: video,
      musicGenre,
      fitnessLevels,
      objectID,
      program: rawProgram,
      programTitle,
      slug,
      subNavSlug,
      tier,
      title,
      trainer: rawTrainer,
      resources,
      resourceGroupTitle,
      sourceLanguage: rawSourceLanguage,
      videoDurationMinutes,
      violator: rawViolator,
      workoutTypes: rawWorkoutTypes,
      workoutType,
      filmDate,
      __position: position = 0,
      __queryID: queryID,
    } = rawWorkout || {};

    const isBODi = tier === BODi;
    const optionalViolatorText = rawEquipments?.optionalViolatorText;
    const duration = videoDurationMinutes ? `${videoDurationMinutes}m` : '0';
    const trainer = get(rawTrainer, '[0].name', '');
    const sourceLanguage = SourceLanguage[rawSourceLanguage || ''];

    const associatedContent = AssociatedContent.createFromRaw({
      links: resources,
      title: resourceGroupTitle || '',
    });
    const equipments = VideoModalSection.createFromRaw({
      headlineText: rawEquipments?.headlineText,
      optionalViolatorText: optionalViolatorText ? `* ${optionalViolatorText}` : '',
      items: [
        ...(rawEquipments?.requiredEquipments || []),
        ...(rawEquipments?.recommendedEquipments?.map((equipment: string) => `${equipment}*`) ||
          []),
      ],
    });

    const workoutTypes = VideoModalSection.createFromRaw({
      headlineText: rawWorkoutTypes?.title,
      items: rawWorkoutTypes?.items || [],
      subcategories: rawWorkoutTypes?.subcategories,
    });

    const pdfCount = associatedContent?.links.length || 0;
    const program = rawProgram ? Program.createFromRaw(rawProgram) : undefined;
    const violator = rawViolator && Violator.createFromRaw(rawViolator?.meta_box);

    return new this({
      accessPolicies,
      commerce,
      associatedContent,
      brandCode,
      description,
      duration,
      equipments,
      groupName,
      id,
      imageUrl,
      isBODi,
      objectID,
      pdfCount,
      position,
      program,
      programTitle,
      queryID,
      slug,
      subNavSlug,
      sourceLanguage,
      tier,
      title,
      trainer,
      video,
      violator,
      workoutType,
      workoutTypes,
      filmDate,
      musicGenre,
      fitnessLevels,
    });
  }
};

export default Workout;
