import { Record } from 'immutable';
import { ICartSummaryResponse } from '../interfaces/ICartSummary';
import { IModel, IModelStatic } from '../interfaces/IModel';

export interface ICart {
  isEnabled?: boolean;
  itemsCount?: number;
  hasLoaded?: boolean;
}

export interface IRawCart {
  itemsCount: number;
  hasLoaded: boolean;
}

// types
export interface ICartModel extends Record<ICart>, ICart, IModel<ICartModel, ICart, IRawCart> {}

// values
const defaultProps = {
  isEnabled: false,
  itemsCount: undefined,
  hasLoaded: false,
};

interface ICartModelStatic extends IModelStatic<ICartModel, ICart> {
  createFromSummary(cartSummary: ICartSummaryResponse): ICartModel;
}

const Cart: ICartModelStatic = class
  extends Record<ICart>(defaultProps, 'Cart')
  implements ICartModel {
  static createFromRaw(rawCart: ICart): ICartModel {
    return new this(rawCart);
  }

  serialize(): IRawCart {
    const { itemsCount = 0, hasLoaded } = this;

    return { itemsCount, hasLoaded: !!hasLoaded };
  }

  mergeFromConfig({ isEnabled, itemsCount }: ICart): ICartModel {
    return this.merge({
      isEnabled,
      itemsCount: itemsCount !== undefined ? itemsCount : this.itemsCount,
      hasLoaded: this.hasLoaded,
    });
  }

  static createFromSummary({
    order: { totalCommerceItemCount },
  }: ICartSummaryResponse): ICartModel {
    return new this({ itemsCount: totalCommerceItemCount });
  }
};

export default Cart;
