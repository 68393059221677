import styled from 'styled-components';
import { Media } from '../../../styles/media';
import { Body1Template } from '../../../shared/typography/body1';

const { mobile } = Media;

export const Container = styled.div`
  max-width: 17.5rem;
  margin-bottom: 1.25rem;

  ${mobile`
    margin-bottom: 0;
  `};
`;

export const Image = styled.img`
  width: 100%;
  max-height: 10.25rem;
  margin-bottom: 0.75rem;

  ${mobile`
    margin-bottom: 0;
  `};
`;

export const Title = styled.h3`
  color: ${({ theme }) => theme?.whiteBG};
  font-size: 1.25rem;
  font-weight: ${Body1Template.fontWeight};
  align-self: center;

  ${mobile`
    font-size: 0.875rem;
    font-weight: 800;
    margin-bottom: 0;
  `};
`;
