/** @jsx h */
import { FunctionalComponent, h } from 'preact';
import { IMenuModel } from '../../models/Menu';
import { Container, Column, Columns, Footer, FooterContainer } from './dropdownMenuStyled';
import DropdownItem from '../dropdownItem';
import FeaturedProduct from '../desktopNav/items/featuredProduct';

export interface IDropdownMenuProps {
  menu: IMenuModel;
  columnWidth?: string;
  containerWidth?: string;
  columnPadding?: string;
}

export const DropdownMenu: FunctionalComponent<IDropdownMenuProps> = ({
  menu: { menuSections },
  columnWidth,
  containerWidth,
  columnPadding,
}) => {
  const { columnList, footerColumnList, featureProductList } = menuSections;
  const [featuredProduct] = featureProductList;

  return (
    <Container containerWidth={containerWidth}>
      <Columns>
        {columnList.map((menuColumn, index) => (
          <Column columnWidth={columnWidth} columnPadding={columnPadding} key={index}>
            {menuColumn.map((menuItem, index) => (
              <DropdownItem key={index} item={menuItem} />
            ))}
          </Column>
        ))}
        {featuredProduct ? (
          <Column columnWidth={columnWidth} columnPadding={columnPadding} key="featuredProduct">
            <FeaturedProduct featuredProduct={featuredProduct} />
          </Column>
        ) : null}
      </Columns>
      {!!footerColumnList?.length &&
        footerColumnList.map((footerItemList, index) => (
          <FooterContainer key={index}>
            <Footer>
              {footerItemList.map((footerItem, index) => (
                <DropdownItem key={index} item={footerItem} />
              ))}
            </Footer>
          </FooterContainer>
        ))}
    </Container>
  );
};
