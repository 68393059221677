import styled from 'styled-components';
import { Media } from '../../styles/media';

const { desktopUp, desktop, tabletUp } = Media;

export const HeaderContainer = styled.header`
  align-items: center;
  background-color: ${({ theme }) => theme.whiteBG};
  border-bottom: ${({ theme }) => `solid 1px ${theme.whisperGray}`};
  display: grid;
  height: ${({ isTablet }) => (isTablet ? '50px' : '63px')};
  justify-content: space-between;
  padding: 0 24px;
  grid-auto-flow: column;

  position: relative;
  transition: opacity 0.5s ease-in, transform 0.5s ease-in;

  ${tabletUp`
    grid-template-columns: 1fr 1fr 1fr;
  `};

  ${desktop`
    padding: 0;
  `};

  ${desktopUp`
    padding: 0 24px;
  `};
`;

export const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  width: auto;
`;

export const MenusContainer = styled.div`
  grid-column: 2;
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 24px;
`;

export const MenuOptions = styled.div`
  align-items: center;
  display: grid;
  grid-column: 3;
  grid-auto-flow: column;
  grid-column-gap: 16px;
`;

export const Backdrop = styled.div`
  background-color: ${({ theme }) => theme.backgroundModalOverlayFour};
  display: ${({ isVisible }) => (isVisible ? 'flex' : 'none')};
  height: 100vh;
  width: 100vw;
  left: 0;
  opacity: 0;
  position: fixed;
  top: 0;
  right: 0;
  z-index: ${({ isFullCovered }) => (isFullCovered ? '2000' : '299')};

  ${tabletUp`
    opacity: 1;
  `};
`;

export const Wrapper = styled.div`
  z-index: 1000;
`;
