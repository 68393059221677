/** @jsx h */
import { Fragment, FunctionalComponent, h } from 'preact';
import { connect } from 'react-redux';
import { AppState } from '../../interfaces/appState';
import {
  ActiveMenu,
  ActiveMenuContainer,
  GoBack,
  MainMenu,
  NavContainer,
} from './mobileNavigationStyled';
import {
  activeMenuMobileSelector,
  parentActiveMenuMobileSelector,
} from '../../selectors/activeItemSelectors';
import { IMenuModel } from '../../models/Menu';
import SideNavItem from '../../components/sideNavItem';
import { LeftArrow } from '../../components/mobileNav/items/menu/menuStyled';
import { setActiveMenuMobile } from '../../actions/headerActions';

export interface ISubNavProps {
  activeMenu: IMenuModel;
  parentMenu: IMenuModel;
  setActiveMenu: typeof setActiveMenuMobile;
  onClose: () => void;
}

export const SubNav: FunctionalComponent<ISubNavProps> = ({
  activeMenu,
  parentMenu,
  setActiveMenu,
  onClose,
}) => {
  return (
    <Fragment>
      <ActiveMenuContainer>
        <GoBack
          onClick={() => {
            setActiveMenu(parentMenu);
          }}
        >
          <LeftArrow />
          <MainMenu
            dangerouslySetInnerHTML={{ __html: parentMenu?.parentItem?.anchorText || 'Main Menu' }}
          />
        </GoBack>
        <ActiveMenu dangerouslySetInnerHTML={{ __html: activeMenu?.parentItem?.anchorText }} />
      </ActiveMenuContainer>
      {activeMenu.menuItemList && (
        <NavContainer>
          {activeMenu.menuItemList.map((item) => (
            <SideNavItem key={item.id} item={item} onClose={onClose} />
          ))}
        </NavContainer>
      )}
    </Fragment>
  );
};

const mapStateToProps = (state: AppState) => ({
  activeMenu: activeMenuMobileSelector(state),
  parentMenu: parentActiveMenuMobileSelector(state),
});

const mapDispatchToProps = {
  setActiveMenu: setActiveMenuMobile,
};

export default connect(mapStateToProps, mapDispatchToProps)(SubNav);
