import { FunctionalComponent, h } from 'preact';
import { useCallback } from 'preact/hooks';
import { connect } from 'react-redux';
import PromoScreen from '../../components/promoScreen';
import { AppState } from '../../interfaces/appState';
import { promoTranslationSelector } from '../../selectors/translationSelector';
import { ITranslationItems } from '../../models/Translation';
import { BODRoutes } from '../../enums/routes';

export interface IPromoScreen {
  onClose: () => void;
  onRedirectToPromoPage: (route: string) => void;
  content: ITranslationItems;
}

const Promo: FunctionalComponent<IPromoScreen> = ({ onClose, onRedirectToPromoPage, content }) => {
  const onRedirect = useCallback(() => {
    const promoRoute = content?.translations?.primaryActionProgramBrandCodeDestination;

    onRedirectToPromoPage(`${BODRoutes.PROGRAMS}/${promoRoute}`);
  }, [content, onRedirectToPromoPage]);

  return <PromoScreen onClose={onClose} onRedirect={onRedirect} content={content} />;
};

const mapStateToProps = (state: AppState) => ({
  content: promoTranslationSelector(state),
});

export default connect(mapStateToProps)(Promo);
