import styled, { css, DefaultTheme } from 'styled-components';
import { setAllLinkStates } from '../../../styles/media';

type ButtonProps = {
  lowercase: boolean;
};

const Button3FillLinkStates = css<DefaultTheme>`
  background: ${({ theme }) => theme.oneAppBlue50};
  color: ${({ theme }) => theme.whiteBG};
`;

const Button3Fill = styled.button<ButtonProps>`
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  border: none;
  border-radius: 2em;
  padding: 0.5em 2em 0.6em;
  width: ${(responsive) => responsive && '100%'};
  vertical-align: middle;
  text-align: center;
  text-overflow: ellipsis;
  background: ${({ theme }) => theme.oneAppBlue50};
  color: ${({ theme }) => theme.whiteBG};
  font-size: 16px;
  font-weight: 400;
  text-transform: ${(lowercase) => (lowercase ? 'none' : 'uppercase')};
  text-decoration: none;
  line-height: 1;
  outline: none;
  cursor: pointer;
  transition: box-shadow 0.25s, background-color 0.25s ease-out, color 0.25s ease-out;

  ${setAllLinkStates`${Button3FillLinkStates}`}

  &:hover {
    background: ${({ theme }) => theme.skyBlue};
    color: ${({ theme }) => theme.oneAppBlue};
  }
`;

export default Button3Fill;
