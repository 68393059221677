/** @jsx h */
import { FunctionalComponent, h } from 'preact';
import { useCallback, useMemo, useState } from 'preact/hooks';
import { connect } from 'react-redux';
import { AppState } from '../../interfaces/appState';
import { IItemModel } from '../../models/Item';
import { ILocaleModel } from '../../models/Locale';
import { IMenuModel } from '../../models/Menu';
import { modulesByKeySelector } from '../../selectors/modulesSelectors';
import { getSelectedLocaleItemFromList } from '../../utils/locale';
import { Dropdown } from '../dropdown';
import DropdownLocale from '../dropdownLocale';
import { LocaleContainer, LocaleIcon, Wrapper } from './selectedLocaleStyled';
import { E2E, getTestID, useE2ETesting } from '../../providers/e2eTestingProvider';
import { updateLocale } from '../../actions/localeActions';
import LocaleChangeModal from '../localeChangeModal';
import useModal from '../../hooks/useModal';
import { SupportedLanguages } from '../../enums/locale';

export interface ISelectedLocaleProps {
  locale: ILocaleModel;
  item: IMenuModel;
  updateLocale: typeof updateLocale;
}

export const SelectedLocale: FunctionalComponent<ISelectedLocaleProps> = ({
  locale,
  item,
  updateLocale,
}) => {
  const { selectedLocale } = locale;
  const { menuItemList } = item;

  const selectedLocaleItem = useMemo(
    () => getSelectedLocaleItemFromList(menuItemList as Array<IItemModel>, selectedLocale),
    [menuItemList, selectedLocale],
  );

  const { testIdPrefix } = useE2ETesting();
  const { isOpen, openModal, closeModal } = useModal();
  const [newLocale, setNewLocale] = useState<SupportedLanguages>(SupportedLanguages.EN_US);

  const handleOnOpenModal = useCallback(
    (newLocale: SupportedLanguages): void => {
      setNewLocale(newLocale);
      openModal();
    },
    [openModal],
  );

  const handleOnAccept = useCallback((): void => {
    updateLocale(newLocale);
    closeModal();
  }, [updateLocale, newLocale, closeModal]);

  return (
    <Wrapper data-testid={getTestID(testIdPrefix, E2E.component.dropdown, 'locale')}>
      <Dropdown
        isParentElement
        openComponent={(isOpen) => (
          <LocaleContainer isOpen={isOpen}>
            <LocaleIcon
              alt={selectedLocaleItem?.anchorText}
              src={selectedLocaleItem?.menuImageUrl}
            />
          </LocaleContainer>
        )}
        top="40px"
        right="0px"
      >
        <DropdownLocale
          selectedLocale={selectedLocale}
          updateLocale={handleOnOpenModal}
          localeList={menuItemList as Array<IItemModel>}
        />
      </Dropdown>
      {isOpen && (
        <LocaleChangeModal newLocale={newLocale} onCancel={closeModal} onAccept={handleOnAccept} />
      )}
    </Wrapper>
  );
};

const mapStateToProps = (state: AppState) => ({
  locale: modulesByKeySelector(state, 'locale'),
});

export default connect(mapStateToProps, { updateLocale })(SelectedLocale);
