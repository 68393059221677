import { getLocalStorage, isStorageAvailable } from './crossDomainStorageUtils';
import ICrossDomainStorageClient from './interfaces/ICrossDomainStorage';
import {
  loadFromLocalStorage,
  removeFromLocalStorage,
  saveToLocalStorage,
} from '../utils/localStorage';

const LOCAL_STORAGE_WARN = 'Falling back to LocalStorage instead of CrossStorage';
const CROSS_ID = 'cross_storage_';
const storage = getLocalStorage();
const connectionPromise = storage?.onConnect();

const checkAccessMiddleware = async <T>(
  next: () => Promise<T | undefined>,
  nextLocalStorage?: () => Promise<T | undefined>,
) => {
  try {
    if (!(await isStorageAvailable(storage, connectionPromise))) {
      return nextLocalStorage ? await nextLocalStorage() : Promise.resolve(undefined);
    }
    return await next();
  } catch (err) {
    console.error(err);
    return Promise.resolve(undefined);
  }
};

const CrossDomainStorageClient = <T>(): ICrossDomainStorageClient<T> => ({
  get: async (key) =>
    checkAccessMiddleware<T>(
      async () => {
        const value = await storage?.get(key);
        return JSON.parse(value);
      },
      async () => {
        console.log(LOCAL_STORAGE_WARN);
        return loadFromLocalStorage<T>(`${CROSS_ID}${key}`)?.data;
      },
    ),
  set: async (key, value) =>
    checkAccessMiddleware(
      async () => {
        return storage?.set(key, JSON.stringify(value));
      },
      async () => {
        console.log(LOCAL_STORAGE_WARN);
        saveToLocalStorage(`${CROSS_ID}${key}`, {
          id: key,
          createdAt: Date.now(),
          expiresAt: Date.now(),
          data: value,
        });
      },
    ),
  del: async (key) =>
    checkAccessMiddleware(
      async () => {
        return storage?.del(key);
      },
      async () => removeFromLocalStorage(`${CROSS_ID}${key}`),
    ),
  getKeys: async () => {
    if (!storage || !(await isStorageAvailable(storage, connectionPromise))) {
      return Promise.resolve([]);
    }
    return storage?.getKeys();
  },
  clear: async () =>
    checkAccessMiddleware(async () => {
      return storage?.clear();
    }),
});

export default CrossDomainStorageClient;
