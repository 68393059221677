/** @jsx h */
import { FunctionalComponent, h } from 'preact';
import { IFeaturedProductModel } from '../../../../models/FeaturedProduct';
import AppLink from '../../../appLink';
import { Container, Description, Image, Link } from './featuredProductStyled';

export interface IFeaturedProductProps {
  featuredProduct: IFeaturedProductModel;
}

export const FeaturedProduct: FunctionalComponent<IFeaturedProductProps> = ({
  featuredProduct: { menuImageUrl, parentItem, text },
}) =>
  parentItem ? (
    <Container>
      <AppLink item={parentItem}>
        {menuImageUrl ? <Image alt={parentItem.anchorText} src={menuImageUrl} /> : null}
        <Description>{text.getContentValue()}</Description>
        <Link>{parentItem.anchorText}</Link>
      </AppLink>
    </Container>
  ) : null;

export default FeaturedProduct;
