import i18n, { i18n as II18n } from 'i18next';

import { initReactI18next } from 'react-i18next';
import { SupportedLanguages } from '../enums/locale';
import { LOCALE_RESOURCES } from '.';

export const initializeI18n = (selectedLocale: SupportedLanguages): II18n => {
  i18n.use(initReactI18next)?.init({
    resources: LOCALE_RESOURCES,
    lng: selectedLocale || SupportedLanguages.EN_US,
    interpolation: {
      escapeValue: false,
    },
  });

  return i18n;
};
