import styled from 'styled-components';
import { Media } from '../../../styles/media';
import { ITypography } from '../../interfaces/typography';
import Body4, { Body4Template } from '../body4';

const { tablet } = Media;

export const Body4dTemplate: ITypography = {
  ...Body4Template,
  fontSize: '14px',
};
const { fontSize, fontWeight, lineHeight, letterSpacing } = Body4Template;

type TextProps = {
  italic: boolean;
};

const Body4d = styled(Body4)<TextProps>`
  font-weight: ${fontWeight};
  font-style: ${({ italic }) => (italic ? 'italic' : 'normal')};
  line-height: ${lineHeight};
  letter-spacing: ${letterSpacing};

  ${tablet`
    font-size: ${fontSize};
  `};
`;

export default Body4d;
