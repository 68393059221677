import { LAST_ROUTE_STATE_KEY } from '../../enums/algolia';
import SearchHistory, { ISearchHistory, ISearchHistoryModel } from '../../models/SearchHistory';
import CrossDomainStorageClient from '../crossDomainStorageClient';
import { ICrossDomainStorageUser } from '../interfaces/ICrossDomainStorage';

const { get, set, del } = CrossDomainStorageClient<ISearchHistory>();

const SearchHistoryClient: ICrossDomainStorageUser<ISearchHistoryModel> = {
  get: async () => {
    const rawItem = await get(LAST_ROUTE_STATE_KEY);
    return SearchHistory.createFromRaw(rawItem);
  },
  set: (searchHistory) => set(LAST_ROUTE_STATE_KEY, searchHistory),
  del: () => del(LAST_ROUTE_STATE_KEY),
};

export default SearchHistoryClient;
