import { AnyAction, Dispatch, MiddlewareAPI, Middleware } from 'redux';
import { AppState } from '../interfaces/appState';
import { getEventHandler } from './event';

export const eventMiddleware: Middleware<AppState> = (state: MiddlewareAPI<Dispatch, AppState>) => (
  next: Dispatch<AnyAction>,
) => (action: AnyAction): AnyAction => {
  const { event, payload } = action;

  if (!event) return next(action);
  const { preEventAction, postEventAction, payload: eventPayload = undefined } = event;
  const { events } = state.getState();

  if (preEventAction)
    getEventHandler(events.get('events')).dispatchEvent(preEventAction, eventPayload || payload);

  const result = next(action);

  if (postEventAction)
    getEventHandler(events.get('events')).dispatchEvent(postEventAction, eventPayload || payload);

  return result;
};
