/** @jsx h */
import { Fragment, FunctionalComponent, h } from 'preact';
import { useCallback } from 'preact/hooks';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Roles } from '../../../../enums/sso';
import { removeAccount } from '../../../../actions/ssoActions';
import { NavItem } from '../../../../containers/mobileNavigation/mobileNavigationStyled';
import { SupportedEvents } from '../../../../enums/event';
import { AppState } from '../../../../interfaces/appState';
import { ISsoModel } from '../../../../interfaces/ISso';
import { setActiveMenuMobile } from '../../../../actions/headerActions';
import { IMenuModel } from '../../../../models/Menu';
import { useEventHandler } from '../../../../providers/eventHandlerProvider';
import { modulesByKeySelector } from '../../../../selectors/modulesSelectors';
import AccountItem from '../../../desktopNav/items/accountSwitch/accountItem';
import { AddNewLink } from '../../../desktopNav/items/accountSwitch/accountSwitchStyled';
import { CoachAccountsLocale } from '../../../desktopNav/items/accountSwitch/locale';

export interface IMobileAccountSwitchProps {
  sso: ISsoModel;
  removeAccount: typeof removeAccount;
  setActiveMenu: typeof setActiveMenuMobile;
  onClose: () => void;
}

export const MobileAccountSwitch: FunctionalComponent<IMobileAccountSwitchProps> = ({
  sso,
  removeAccount,
  setActiveMenu,
  onClose,
}) => {
  const { accountList: ssoAccounts = [], userInfo: { roleList = [] } = {} } = sso;
  const { t } = useTranslation();
  const { dispatchEvent } = useEventHandler();
  const shouldShowCoachId = !roleList.includes(Roles.preferredCustomer);

  const handleAddAccountAdd = useCallback(
    (event: Event) => {
      event.preventDefault();
      setActiveMenu({} as IMenuModel);
      onClose();
      dispatchEvent(SupportedEvents.onAccountAdd);
    },
    [dispatchEvent, setActiveMenu, onClose],
  );

  return (
    <Fragment>
      {ssoAccounts.map((account) => (
        <NavItem key={account.coachId}>
          <AccountItem
            key={account.coachId}
            account={account}
            onClose={onClose}
            removeAccount={removeAccount}
            shouldShowCoachId={shouldShowCoachId}
          />
        </NavItem>
      ))}
      <NavItem onClick={handleAddAccountAdd}>
        <AddNewLink>+ {t(CoachAccountsLocale.addNew)}</AddNewLink>
      </NavItem>
    </Fragment>
  );
};

const mapStateToProps = (state: AppState) => ({
  sso: modulesByKeySelector(state, 'sso'),
});

const mapDispatchToProps = {
  removeAccount,
  setActiveMenu: setActiveMenuMobile,
};

export default connect(mapStateToProps, mapDispatchToProps)(MobileAccountSwitch);
