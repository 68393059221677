import { bindActionCreators, Store } from 'redux';
import { ICartActionCreators, ICartActions } from '../../actions/interfaces/ICartAction';
import cartActions from '../../actions/cartActions';
import { ICart, ICartModel } from '../../models/Cart';
import { modulesByKeySelector } from '../../selectors/modulesSelectors';
import { log } from '../../utils/logging';

export interface ICartModule {
  getCartConfig(): Promise<ICart>;
  updateConfig(cartConfig: ICart): Promise<ICart>;
  updateItemsCount(itemsCount: number): Promise<ICart>;
}

export const getCartModule = (store: Store): ICartModule => {
  const { dispatch, getState } = store;
  const { updateConfig, updateItemsCount } = bindActionCreators<ICartActionCreators, ICartActions>(
    cartActions,
    dispatch,
  );

  return {
    getCartConfig() {
      log('cart', 'getCartConfig');
      const cart = modulesByKeySelector(getState(), 'cart') as ICartModel;

      return Promise.resolve(cart.toObject());
    },
    async updateConfig(cartConfig) {
      log('cart', 'updateConfig', cartConfig);
      const { payload: cart } = await updateConfig(cartConfig);

      return cart.toObject();
    },
    async updateItemsCount(itemsCount) {
      log('cart', 'updateItemsCount', itemsCount);
      const { payload: cart } = await updateItemsCount(itemsCount);

      return cart.toObject();
    },
  };
};
