export enum MobileNavigationTypes {
  MAIN = 'headerMenu.mainNav.mobile',
  TOP = 'headerMenu.leftNav.mobile',
  BOTTOM = 'headerMenu.rightNav.mobile',
}

export const GLOBAL_BANNER_LOCATION = 'headerMenu.globalMessageBanner';
export const SEARCH_PLACEHOLDER_LOCATION = 'headerMenu.searchBox';
export const SUB_NAV = 'headerMenu.subnav';
export const MOBILE_CUSTOM_DEFAULT = 'headerMenu.customDefault.mobile';

export enum TopNavTypes {
  MAIN = 'headerMenu.mainNav',
  LEFT = 'headerMenu.leftNav',
  RIGHT = 'headerMenu.rightNav',
}

export const MENU_LOCATION_DESKTOP_ORDER = [TopNavTypes.LEFT, TopNavTypes.MAIN, TopNavTypes.RIGHT];

export const MENU_LOCATION_MOBILE_ORDER = [
  MobileNavigationTypes.TOP,
  MobileNavigationTypes.MAIN,
  MobileNavigationTypes.BOTTOM,
];
