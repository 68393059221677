/** @jsx h */
import { FunctionalComponent, h } from 'preact';
import { IMenuLocationModel } from '../../../../models/MenuLocation';
import { TopNavigationContainer } from './topNavigationStyled';
import SideNavItem from '../../../sideNavItem';

export interface ITopNavigationProps {
  menuLocation: IMenuLocationModel;
  onClose: () => void;
}

export const TopNavigation: FunctionalComponent<ITopNavigationProps> = ({
  menuLocation,
  onClose,
}) => (
  <TopNavigationContainer>
    {menuLocation.menuItemList.map((menu) => (
      <SideNavItem item={menu} key={menu.id} onClose={onClose} />
    ))}
  </TopNavigationContainer>
);

export default TopNavigation;
