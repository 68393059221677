import styled from 'styled-components';
import { ITypography } from '../../interfaces/typography';
import { Media } from '../../../styles/media';

const { tablet } = Media;

export const Editorial3dTemplate: ITypography = {
  fontSize: '20px',
  fontWeight: '300',
};

export const Editorial3Template: ITypography = {
  fontSize: '16px',
  fontWeight: '300',
};

const { fontSize, fontWeight } = Editorial3Template;

type TextProps = {
  bold: boolean;
  fixedSize: boolean;
};

const Editorial3 = styled.h3<TextProps>`
  font-size: ${fontSize};
  font-weight: ${({ bold }) => (bold ? 'bold' : `${fontWeight}`)};
  ${({ fixedSize }) =>
    !fixedSize &&
    tablet`
    font-size: 20px;
  `};
`;

export default Editorial3;
