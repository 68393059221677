/** @jsx h */
import { FunctionalComponent, h } from 'preact';
import { useCallback } from 'preact/hooks';
import { connect } from 'react-redux';
import { logInUser } from '../../../../actions/ssoActions';
import { IItemModel } from '../../../../models/Item';
import NavLink from '../navLink';

export interface ITopNavLoginButtonProps {
  item: IItemModel;
  logInUser: typeof logInUser;
}

export const TopNavLoginButton: FunctionalComponent<ITopNavLoginButtonProps> = ({
  item,
  logInUser,
}) => {
  const onLogin = useCallback(
    (event: Event) => {
      event.preventDefault();

      logInUser();
    },
    [logInUser],
  );

  return <NavLink item={item} isActive={false} onClick={onLogin} />;
};

export default connect(null, { logInUser })(TopNavLoginButton);
