import CrossDomainStorageClient from '../crossDomainStorageClient';
import { ICrossDomainStorageUser } from '../interfaces/ICrossDomainStorage';
import Search, { IRawSearch, ISearchModel } from '../../models/Search';

const SEARCH_KEY = 'search';

const { get, set, del } = CrossDomainStorageClient<IRawSearch>();

const SearchClient: ICrossDomainStorageUser<ISearchModel> = {
  get: async () => {
    const rawSearchItem = await get(SEARCH_KEY);

    return Search.createFromRaw(rawSearchItem);
  },
  set: (search) => set(SEARCH_KEY, search.serialize()),
  del: () => del(SEARCH_KEY),
};

export default SearchClient;
