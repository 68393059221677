export enum AutoCompleteLocale {
  connector = 'search_categories_connector',
  programs = 'programs',
  search = 'search_nav',
  recipes = 'recipes',
  workouts = 'workouts',
  lessons = 'lessons',
  other = 'others',
  programMaterials = 'programMaterials',
  noResults = 'search_noResults',
  recentSearches = 'search_recentSearches',
  clearAll = 'search_clearAll',
  faqTitle = 'faq_title',
  faq = 'faq',
  topCategories = 'top_categories',
}
