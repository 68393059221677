import styled from 'styled-components';

type TextProps = {
  bold: boolean;
};

const Text8 = styled.p<TextProps>`
  font-size: 16px;
  font-weight: ${({ bold }) => (bold ? 700 : 400)};
`;

export default Text8;
