import { createSelector } from 'reselect';
import includes from 'lodash/includes';
import get from 'lodash/get';
import { AppState } from '../interfaces/appState';
import { IProgramModel } from '../models/Program';
import { IWorkoutModel } from '../models/Workout';
import {
  clubProgramsSelector,
  freeContentSelector,
  ramsEntitlementsSelector,
  accessPoliciesSelector,
} from './entitlementSelector';
import { isMobileSelector, ssoModuleSelector, mainModuleSelector } from './modulesSelectors';
import { Roles } from '../enums/sso';
import { BODi, VIDEO_TYPE_TO_PATH } from '../enums/algolia';
import { loadFromConfig } from '../utils/configLoader';
import { BBRoutes, BODRoutes } from '../enums/routes';
import { stringify } from 'query-string';
import { selectedLocaleSelector } from './localeSelector';
import { SPECIFIC_LOCALES, SupportedLanguages } from '../enums/locale';
import { getBODiQueryParams, getUrlWithPersistedParams } from '../utils/lockContent';
import { AppIdEnum } from '../enums/main';
import { NOT_AVAIL } from '../hooks/useLockContent';
import { FEATURE_FLAGS } from '../enums/featureFlags';
import { getFeatureFlagValue } from '../utils/featureFlags';

const isBodiVideosDeepLinkingEnabledFlag = () =>
  getFeatureFlagValue(FEATURE_FLAGS.BODI_VIDEOS_DEEP_LINKING_ENABLED_FLAG);

export const URL_ORIGIN_REGEX = /^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:\/\n?]+)/;
export const FREE_WORKOUT_TYPE = 'free-workout';
export const PROMO_WORKOUT_TYPE = 'promo';
const ANNONYMOUS = 'annonymous';

const { club, ukclub } = Roles;
const { DOWNLOAD_APP, REGISTER_FREE, OFFERS_BOD_FULL_PATH, BODI, PROGRAMS } = BODRoutes;

const BOD_CHECKOUT_URL = loadFromConfig('BOD_CHECKOUT_URL');
const { ENTITLEMENT } = loadFromConfig('BODI');
const { BOD, TBB, BB } = loadFromConfig('APP_DOMAIN_LIST');

const { BOD_CHECKOUT: BOD_CHECKOUT_BB } = BBRoutes;

export const userSelector = createSelector(ssoModuleSelector, ({ userInfo }) => userInfo);

const freeContentArraySelector = createSelector(freeContentSelector, (freeContent) => {
  const { videos = [], programs = [] } = freeContent || {};

  return { videos, programs };
});

const programSelector = (state: AppState, workout: IWorkoutModel) =>
  (workout?.program || {}) as IProgramModel;
const workoutSelector = (state: AppState, workout: IWorkoutModel) =>
  (workout || {}) as IWorkoutModel;

export const isUserAuthenticatedSelector = createSelector(
  userSelector,
  (userInfo) => !!userInfo && !!userInfo?.guid,
);

export const hasRamsEntitlementSelector = createSelector(
  ramsEntitlementsSelector,
  programSelector,
  (ramsEntitlements, { brandCode }) => includes(ramsEntitlements, brandCode),
);

export const hasAccessPolicySelector = createSelector(
  accessPoliciesSelector,
  workoutSelector,
  (accessPolicies, workout) => {
    if (!workout.accessPolicies?.length) return true;
    return accessPolicies.some(
      ({ slug, isEntitled }) => isEntitled && workout?.accessPolicies?.includes(slug),
    );
  },
);

export const hasBODiEntitlementSelector = createSelector(
  userSelector,
  workoutSelector,
  (user, { tier }) => includes(user?.entitlementList, tier === BODi ? ENTITLEMENT : tier),
);

export const isFreeVideoSelector = createSelector(
  freeContentArraySelector,
  workoutSelector,
  ({ videos }, { id }) => includes(videos, +id),
);

export const isClubProgramSelector = createSelector(
  clubProgramsSelector,
  programSelector,
  (clubPrograms, { id }) => includes(clubPrograms, id),
);

export const isFreeProgramSelector = createSelector(
  freeContentArraySelector,
  programSelector,
  ({ programs }, { id }) => includes(programs, id),
);

export const isPremiumProgramSelector = createSelector(
  isClubProgramSelector,
  isFreeProgramSelector,
  (isClubProgram, isFreeProgram) => !isClubProgram && !isFreeProgram,
);

export const isFreeWorkoutSelector = createSelector(
  isFreeVideoSelector,
  workoutSelector,
  (isFreeVideo, { video }) => {
    const { videoTypes = [] } = video || {};

    return (
      isFreeVideo &&
      !!videoTypes.find((type) =>
        [FREE_WORKOUT_TYPE, PROMO_WORKOUT_TYPE].includes(type.toLowerCase()),
      )
    );
  },
);

export const isPromoWorkoutSelector = createSelector(workoutSelector, ({ video }) => {
  const { videoTypes = [] } = video || {};

  return !!videoTypes.find((videoType) => videoType.toLowerCase() === PROMO_WORKOUT_TYPE);
});

export const isClubAuthorizedSelector = createSelector(
  isClubProgramSelector,
  userSelector,
  (isClubProgram, user) => {
    const { roleList = [] } = user || {};

    return isClubProgram && (includes(roleList, club) || includes(roleList, ukclub));
  },
);

export const hasUserEntitlementSelector = createSelector(
  isClubAuthorizedSelector,
  isFreeProgramSelector,
  hasRamsEntitlementSelector,
  (isClubAuthorized, isFreeProgram, hasRamsEntitlement) =>
    isClubAuthorized || isFreeProgram || hasRamsEntitlement,
);

export const isProgramMaterialAvailableSelector = createSelector(
  isUserAuthenticatedSelector,
  hasUserEntitlementSelector,
  (isUserAuthenticated, hasEntitlements) => isUserAuthenticated && hasEntitlements,
);

export const isVideoContentAvailableSelector = createSelector(
  workoutSelector,
  hasBODiEntitlementSelector,
  hasAccessPolicySelector,
  isFreeWorkoutSelector,
  hasUserEntitlementSelector,
  ({ isBODi }, hasBODiEntitlement, hasAccessPolicy, isFreeWorkout, hasEntitlement) =>
    (isBODi && hasBODiEntitlement && hasAccessPolicy) || isFreeWorkout || hasEntitlement,
);

export const shouldRedirectToFreeRegSelector = createSelector(
  isUserAuthenticatedSelector,
  isFreeProgramSelector,
  isFreeWorkoutSelector,
  (isUserAuthenticated, isFreeProgram, isFreeWorkout) =>
    !isUserAuthenticated && (isFreeProgram || isFreeWorkout),
);

export const isUserAuthorizedSelector = createSelector(
  isFreeWorkoutSelector,
  isProgramMaterialAvailableSelector,
  (isFreeWorkout, isProgramMaterialAvailable) => isFreeWorkout || isProgramMaterialAvailable,
);

export const bodiPurchaseUrlSelector = createSelector(
  workoutSelector,
  isMobileSelector,
  isVideoContentAvailableSelector,
  isUserAuthenticatedSelector,
  selectedLocaleSelector,
  mainModuleSelector,
  (
    { commerce, trainer, video },
    isMobile,
    isVideoContentAvailable,
    isUserAuthenticated,
    selectedLocale,
    { appId },
  ) => {
    const [language, countryCode] = selectedLocale.split('_');
    const formattedLocale = `${language}_${countryCode.toUpperCase()}`;
    const isSpecificLocale = SPECIFIC_LOCALES.includes(
      formattedLocale.toLowerCase() as SupportedLanguages,
    );

    if (isBodiVideosDeepLinkingEnabledFlag() && appId === AppIdEnum.tbb)
      return `${BOD}${BODI}?videoGuid=${video?.guid}`;

    if (isMobile) return `${BOD}${DOWNLOAD_APP}`;

    // if available the redirect url should be empty
    if (isVideoContentAvailable) return undefined;

    // if the commerce property is defined, use its purchase url
    if (commerce) {
      return commerce?.purchaseAction?.url;
    }

    const BODiQueryParams = getBODiQueryParams(formattedLocale, trainer);

    // video content is not available so we need to redirect the user somewhere
    const tbbUrl = getUrlWithPersistedParams(
      `${TBB}${BOD_CHECKOUT_URL}`.replace('{{countryCode}}', countryCode),
      BODiQueryParams,
    );

    const bbUrl = `${BB}${BOD_CHECKOUT_BB}?${stringify(BODiQueryParams, {
      skipEmptyString: true,
    })}`;

    // for non authenticated users we need to select either the tbb or bb checkout
    // for authenticated users without bodi access we send the to buy it on tbb
    return isSpecificLocale || isUserAuthenticated ? tbbUrl : bbUrl;
  },
);

export const freeRegPurchaseUrlSelector = createSelector(programSelector, ({ brandCode }) => {
  const path = `${location.pathname}${location.search}`;

  return `${BOD}${REGISTER_FREE}/?${stringify({
    redirect: path,
    referralProgramId: brandCode,
  })}`;
});

export const stateSelector = (state: AppState): AppState => state;
export const purchaseUrlSelector = createSelector(
  workoutSelector,
  programSelector,
  userSelector,
  isUserAuthorizedSelector,
  isPromoWorkoutSelector,
  isPremiumProgramSelector,
  isUserAuthenticatedSelector,
  shouldRedirectToFreeRegSelector,
  selectedLocaleSelector,
  stateSelector,
  (
    workout,
    { brandCode, programState, programPurchaseLinks },
    user,
    isUserAuthorized,
    isPromoWorkout,
    isPremiumProgram,
    isUserAuthenticated,
    shouldRedirectToFreeReg,
    selectedLocale,
    state,
  ) => {
    const { isBODi } = workout;
    const { roleList = [] } = user || {};
    const [language, countryCode] = selectedLocale.split('_');
    const { userStates = [] } = programState || {};
    const formattedLocale = `${language}_${countryCode.toUpperCase()}`;

    const getNowLink = userStates.find(
      ({ role }) => roleList.includes(role.toUpperCase() as Roles) || role === ANNONYMOUS,
    );

    const purchaseUrlLink =
      get(programPurchaseLinks, countryCode.toLowerCase(), '') ||
      get(getNowLink, 'purchaseButtonLink');

    // handling all of the BODi scenarios
    if (isBODi) return bodiPurchaseUrlSelector(state, workout);

    // non-BODi starts here
    // if promo workout or user is authorized return undefined
    if (isPromoWorkout || isUserAuthorized) return undefined;

    // for free videos and LO users
    if (shouldRedirectToFreeReg) return freeRegPurchaseUrlSelector(state, workout);

    // user is not authorized for LI status
    if (isUserAuthenticated) {
      // if program requires a higher subscription than club

      if (purchaseUrlLink && isPremiumProgram) {
        const tbbUrl = purchaseUrlLink.replace(URL_ORIGIN_REGEX, TBB);
        const parameters = {
          locale: formattedLocale,
          referralProgramId: brandCode,
        };

        return getUrlWithPersistedParams(tbbUrl, parameters);
      }

      // if program is early access NCMS should send the link
      if (!purchaseUrlLink) {
        console.warn(`Sorry, you did not add purchaseUrls for: ${brandCode} .`);
      }

      // if a program is for club users
      const url = `${TBB}${BOD_CHECKOUT_URL}`.replace('{{countryCode}}', countryCode);
      const parameters = {
        referralProgramId: brandCode,
        locale: formattedLocale,
      };

      return getUrlWithPersistedParams(url, parameters);
    }

    // logged out user trying to watch a club or premium video
    return getUrlWithPersistedParams(`${BOD}${OFFERS_BOD_FULL_PATH}`, {
      locale: formattedLocale,
      referralProgramId: brandCode,
    });
  },
);

export const purchaseUrlByAppSelector = createSelector(
  purchaseUrlSelector,
  workoutSelector,
  programSelector,
  mainModuleSelector,
  (purchaseUrl, { video, isBODi }, { slug }, { appId }) => {
    if (!purchaseUrl && appId === AppIdEnum.tbb) {
      if (!isBodiVideosDeepLinkingEnabledFlag() && isBODi) return `${BOD}${BODI}`;

      const [videoType] = video?.videoTypes || [];

      const startPath = VIDEO_TYPE_TO_PATH[videoType] || VIDEO_TYPE_TO_PATH.DEFAULT;
      return `${BOD}${PROGRAMS}/${slug}${startPath}?videoGuid=${video?.guid}`;
    }

    return purchaseUrl;
  },
);

export const isWorkoutUnavailableSelector = createSelector(
  programSelector,
  isVideoContentAvailableSelector,
  isProgramMaterialAvailableSelector,
  (program, isVideoContentAvailable, isProgramMaterialAvailable) => {
    const { programState } = program || {};
    const { slug: programStateSlug } = programState || {};

    return (
      programStateSlug === NOT_AVAIL && !isVideoContentAvailable && !isProgramMaterialAvailable
    );
  },
);
