import styled from 'styled-components';
import { Media } from '../../../styles/media';

const { tabletUp } = Media;

type TextProps = {
  fixedSize: boolean;
};

export const Headline3dmTemplate = {
  fontSize: '20px',
  fontWeight: 900,
  lineHeight: '1.3em',
  letterSpacing: '0.015em',
};

const { fontSize, fontWeight, lineHeight, letterSpacing } = Headline3dmTemplate;

const Headline3dm = styled.h3<TextProps>`
  max-width: ${({ theme }) => theme.contentWellWidth};
  font-size: ${fontSize};
  font-weight: ${fontWeight};
  line-height: ${lineHeight};
  letter-spacing: ${letterSpacing};
  ${({ fixedSize }) =>
    !fixedSize &&
    tabletUp`
    font-size: 28px;
    line-height: 1.286em;
    letter-spacing: 0.011em;
  `};
`;

export default Headline3dm;
