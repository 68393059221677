import styled from 'styled-components';
import { Media } from '../../../styles/media';

const { desktop } = Media;

const Text6 = styled.p`
  font-size: 16px;
  font-weight: 400;

  ${desktop`
    font-size: 24px;
  `};
`;

export default Text6;
