import styled from 'styled-components';

export const TopNavigationContainer = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

export const Icon = styled.img`
  height: 18px;
  padding-top: 15px;
  padding-bottom: 7px;
  width: 164px;
`;

export const Close = styled.img`
  height: 18px;
  position: absolute;
  right: 24px;
  top: 24px;
  width: 18px;
`;
