import styled, { css, DefaultTheme } from 'styled-components';
import Button1Fill from '../button1Fill';
import { setAllLinkStates } from '../../../styles/media';

const Button1StrokeLinkStates = css<DefaultTheme>`
  color: ${({ theme }) => theme.oneAppBlue};
  text-decoration: none;
`;

export default styled(Button1Fill)`
  color: ${({ theme }) => theme.oneAppBlue};
  border: solid 1px ${({ theme }) => theme.oneAppBlue};
  background-color: transparent;
  box-shadow: none;

  a& {
    ${setAllLinkStates`${Button1StrokeLinkStates}`};
  }

  &:hover,
  a:hover& {
    color: ${({ theme }) => theme.blueHover};
    border-color: ${({ theme }) => theme.blueHover};
    background: ${({ theme }) => theme.whiteBG}
      radial-gradient(circle, transparent 1%, ${({ theme }) => theme.whiteBG} 1%) center/15000%;
  }

  &:active {
    background-color: ${({ theme }) => theme.skyBlue};
    background-size: 100%;
    transition: background 0s;
  }

  &:disabled {
    background-color: transparent;
    color: ${({ theme }) => theme.disabledGray};
    border-color: ${({ theme }) => theme.disabledGray};
  }
`;
