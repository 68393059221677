import { Action, ActionCreatorsMapObject } from 'redux';
import { INoAction, ThunkResult } from './IAction';
import { IItemModel } from '../../models/Item';
import { IWorkoutModel } from '../../models/Workout';
import { IAssociatedLinkModel } from '../../models/AssociatedLink';
import { IHit } from '../../interfaces/ISearch';
import { IUtagData } from '../../interfaces/ITealium';
import { ISearchState } from '../../interfaces/ISearchState';
import { TopNavComponents } from '../../enums/tealium';

export const SEND_TEALIUM_EVENT = 'TEALIUM/SEND_EVENT';

export interface ISendEventAction extends Action<typeof SEND_TEALIUM_EVENT> {
  payload: IUtagData;
}

export interface ISendLinkTrackingEventActionCreator {
  (item: IItemModel, component: TopNavComponents): ThunkResult<ISendEventAction | INoAction>;
}

export interface IVideoPlayerPropsEvent {
  workout: IWorkoutModel;
  from: string;
  searchState?: ISearchState;
  state: string;
}

export interface ISendVideoPlayerEventActionCreator {
  (payload: IVideoPlayerPropsEvent): ThunkResult<ISendEventAction | INoAction>;
}

export interface IResourceEvent {
  hit: IHit;
  link: IAssociatedLinkModel;
  searchState?: ISearchState;
  state: string;
}

export interface ISendResourceEventActionCreator {
  (payload: IResourceEvent): ThunkResult<ISendEventAction | INoAction>;
}

export interface ITealiumActions extends ActionCreatorsMapObject {
  sendLinkTrackingEvent(item: IItemModel, component: TopNavComponents): Promise<IUtagData>;
}

export interface ITealiumThunkActions extends ActionCreatorsMapObject {
  sendLinkTrackingEvent: ISendLinkTrackingEventActionCreator;
}
