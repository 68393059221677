import styled from 'styled-components';
import { Body3 } from '../../../../shared/typography';
import { Media } from '../../../../styles/media';

const { tabletUp } = Media;

export const ContainerSwitch = styled.li`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Container = styled.div`
  background-color: ${({ theme }) => theme.pearlGray};
  min-width: 183px;
  margin: -23px 0px -23px;
  padding: 23px 0px 0px 23px;
  display: flex;
  flex-direction: column;
`;

export const AccountContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${tabletUp`
    ${`
      margin-bottom: 15px;
    `};
  `};
`;

export const ItemText = styled.span`
  color: ${({ theme }) => theme.medGray};
  margin-bottom: 2px;
`;

export const Link = styled(Body3).attrs({ as: 'a' })`
  cursor: pointer;
  color: ${({ theme }) => theme.oneAppBlue};
  margin: 0 0px 15px;
`;

export const Title = styled.span`
  color: ${({ theme }) => theme.medGray};
  margin-bottom: 13px;
  text-transform: uppercase;
`;

export const AccountName = styled.a`
  color: ${({ theme }) => theme.oneAppBlue};
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 3px;
`;

export const Accounts = styled.div`
  overflow-y: auto;
  height: 285px;
  margin-bottom: 20px;
`;

export const RemoveLink = styled.u`
  color: ${({ theme }) => theme.medGray};
  cursor: pointer;
  font-size: 12px;
`;

export const AddNewLink = styled.a`
  color: ${({ theme }) => theme.darkGray};
  margin-top: 10px;
  font-weight: bold;
  cursor: pointer;
`;
