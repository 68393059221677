import { Record } from 'immutable';
import { Reducer } from 'redux';
import { IMenuModel } from '../models/Menu';
import { ILocaleModel } from '../models/Locale';
import {
  HeaderActions,
  SET_ACTIVE_MENU_MOBILE,
  SET_MENUS,
  SET_OPEN_SSO_ACCOUNTS,
  SET_NEXT_LOCALE_CHANGE_MODAL,
} from '../actions/headerActions';
import { IMenuLocationModel } from '../models/MenuLocation';

export interface ITopNavState {
  readonly activeMenuMobile: IMenuModel;
  readonly activeMenuTopNav?: IMenuModel;
  readonly openSsoAccounts: boolean;
  readonly nextLocale: ILocaleModel | null;
  readonly menuLocationList: Array<IMenuLocationModel>;
}

export interface ITopNavStateRecord extends Record<ITopNavState>, ITopNavState {}

export const TopNavState = Record<ITopNavState>({
  activeMenuMobile: {} as IMenuModel,
  openSsoAccounts: false,
  nextLocale: null,
  menuLocationList: [],
});

const initialState = TopNavState();

export const topNavReducer: Reducer<ITopNavStateRecord, HeaderActions> = (
  state = initialState,
  action,
) => {
  const { type, payload } = action;

  switch (type) {
    case SET_MENUS:
      return state.set('menuLocationList', payload);
    case SET_ACTIVE_MENU_MOBILE:
      return state.set('activeMenuMobile', payload);
    case SET_OPEN_SSO_ACCOUNTS:
      return state.set('openSsoAccounts', payload);
    case SET_NEXT_LOCALE_CHANGE_MODAL:
      return state.set('nextLocale', payload);
    default:
      return state;
  }
};
