import { Action, AnyAction } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { AppState } from '../../interfaces/appState';

export const NO_ACTION = 'NO_ACTION';

export interface IAction extends Action {
  event?: { preEventAction?: string; postEventAction?: string };
}

export type INoAction = Action;

export type ThunkResult<A extends AnyAction, E = null> = ThunkAction<Promise<A>, AppState, E, A>;

export type SyncThunkResult<A extends AnyAction, E = null> = ThunkAction<A, AppState, E, A>;

export type ThunkResultDispatch<A extends AnyAction, E = null> = ThunkDispatch<AppState, E, A>;
