import TopNavFactory, { TopNavLocationComponent, TopNavItemComponent } from './TopNavFactory';
import MenuDropdownFactory, { IDropdownMenuFactory } from './MenuDropdownFactory';
import SubNavFactory from './SubNavFactory';
import { IFactory } from '../interfaces/factory';

export interface IDesktopFactory
  extends IFactory<TopNavLocationComponent, TopNavItemComponent>,
    IDropdownMenuFactory {}

const topNavFactory = TopNavFactory();
const menuDropdownFactory = MenuDropdownFactory();
export const subNavDesktopFactory = SubNavFactory();

const DesktopFactory: IDesktopFactory = {
  ...topNavFactory,
  ...menuDropdownFactory,
};

export default DesktopFactory;
