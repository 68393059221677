import HttpMethod from '../../enums/httpMethod';
import { IGlobalUserEntitlements } from '../../interfaces/IGlobalUserEntitlements';
import Sso from '../../models/Sso';
import { loadFromConfig } from '../../utils/configLoader';
import { request } from '../restClient';

const { URL, API_KEY } = loadFromConfig('ENTITLEMENTS_API');

export enum DefaultParameters {
  country = 'US',
  callingEBSUser = 'ISGUSER',
  callingModule = 'TBB',
}

export interface IGlobalUserEntitlement {
  get: ({
    idToken,
    accessToken,
    userGuid,
  }: {
    idToken: string;
    accessToken: string;
    userGuid?: string;
    emailAddress?: string;
  }) => Promise<Array<string>>;
}

const GlobalUserEntitlement: IGlobalUserEntitlement = {
  get: async ({ idToken, accessToken, userGuid, emailAddress }) => {
    const { json } = await request<unknown, IGlobalUserEntitlements>({
      path: URL,
      headers: {
        'x-api-key': API_KEY,
        'api-key': `AGENT:US:${userGuid}`,
        id_token: idToken,
        access_token: accessToken,
      },
      queryParams: {
        ...DefaultParameters,
        guid: userGuid as string,
        emailAddress: emailAddress as string,
      },
      method: HttpMethod.GET,
    });
    const {
      Program_Entitlements: programEntitlementList,
      Product_Entitlements: productEntitlement,
    } = json;

    const programList = Array.isArray(programEntitlementList) ? programEntitlementList : [];

    return Sso.createUserEntitlementsFromRaw({
      productList: productEntitlement.ORD_DETAIL_TAB?.ORD_DETAIL_TAB_ITEM || [],
      programList: programList,
    });
  },
};

export default GlobalUserEntitlement;
