import isEqual from 'lodash/isEqual';
import { Store, Unsubscribe } from 'redux';
import { setVideoPlayerOpen, setVideoPlayerProps } from '../../actions/videoPlayerActions';
import { IVideoPlayerState } from '../../reducers/videoPlayerReducer';

interface ReactLike {
  useEffect: (cb: () => unknown, deps: unknown[]) => void;
}

type Listener = (args: { state: IVideoPlayerState }) => void;

export interface IVideoPlayerModule {
  open: (playerProps: IVideoPlayerState['playerProps']) => void;
  update: (playerProps: Partial<IVideoPlayerState['playerProps']>) => void;
  close: () => void;
  getActionsMap: () => void;
  getState: Store['getState'];
  state: IVideoPlayerState;
  stateSubscription: Unsubscribe;
  listeners: Array<Listener>;
  addListener: (listener: Listener) => void;
  removeListener: (listener: Listener) => void;
  notifyListeners: () => void;
  useVideoPlayerFactory: (react: ReactLike) => unknown;
}

export const getVideoPlayerModule = (store: Store): IVideoPlayerModule => {
  const module: IVideoPlayerModule = {
    open(playerProps) {
      store.dispatch(setVideoPlayerProps(playerProps));
      store.dispatch(setVideoPlayerOpen(!!playerProps));
    },
    update(playerProps) {
      const currentProps = store.getState().videoPlayer.playerProps;
      store.dispatch(setVideoPlayerProps({ ...currentProps, ...playerProps }));
    },
    close() {
      store.dispatch(setVideoPlayerOpen(false));
      store.dispatch(setVideoPlayerProps(null));
    },
    getActionsMap() {
      return store.getState().videoPlayer.actionsMap;
    },
    state: store.getState().videoPlayer,
    stateSubscription: store.subscribe(() => {
      const state = store.getState().videoPlayer;
      const changed = !isEqual(module.state, state);
      if (changed) module.notifyListeners();
      module.state = state;
    }),
    listeners: [],
    addListener(listener) {
      if (module.listeners.some((l) => l !== listener) || typeof listener !== 'function') return;
      module.listeners.push(listener);
      return listener;
    },
    removeListener(listener) {
      module.listeners = module.listeners.filter((l) => l !== listener);
    },
    notifyListeners() {
      module.listeners.forEach((l) => l({ state: store.getState().videoPlayer }));
    },
    getState: store.getState,
    useVideoPlayerFactory(react: ReactLike) {
      const { open, close } = this;
      return function useVideoPlayer(
        isOpen: boolean,
        playerProps: IVideoPlayerState['playerProps'],
      ) {
        react.useEffect(() => {
          if (isOpen) open(playerProps);
          else close();
        }, [isOpen, playerProps]);
      };
    },
  };

  return module;
};
