/** @jsx h */
import { connect } from 'react-redux';
import { FunctionalComponent, h } from 'preact';
import { useCallback } from 'preact/hooks';
import { useTranslation } from 'react-i18next';
import { SupportedEvents } from '../../../../enums/event';
import { ISsoAccount } from '../../../../models/SsoAccount';
import { setActiveMenuMobile } from '../../../../actions/headerActions';
import { IMenuModel } from '../../../../models/Menu';
import { useEventHandler } from '../../../../providers/eventHandlerProvider';
import { AccountContainer, AccountName, ItemText, RemoveLink } from './accountSwitchStyled';
import { CoachAccountsLocale } from './locale';

export interface IAccountItemProps {
  account: ISsoAccount;
  removeAccount(account: ISsoAccount): void;
  shouldShowCoachId: boolean;
  setActiveMenu?: typeof setActiveMenuMobile;
  onClose?: () => void;
}

export const AccountItem: FunctionalComponent<IAccountItemProps> = ({
  account,
  removeAccount,
  shouldShowCoachId,
  setActiveMenu,
  onClose,
}) => {
  const { t } = useTranslation();
  const { email, firstName, lastName, coachId } = account;
  const { dispatchEvent } = useEventHandler();

  const handleAccountClick = useCallback(
    (event: Event) => {
      event.preventDefault();
      onClose && onClose();
      setActiveMenu && setActiveMenu({} as IMenuModel);
      dispatchEvent(SupportedEvents.onAccountClick, account);
    },
    [dispatchEvent, account, setActiveMenu, onClose],
  );

  return (
    <AccountContainer>
      <AccountName onClick={handleAccountClick}>
        {firstName} {lastName}
      </AccountName>
      <ItemText>{email}</ItemText>
      {shouldShowCoachId && <ItemText>{t(CoachAccountsLocale.coachId, { coachId })}</ItemText>}
      <RemoveLink onClick={() => removeAccount(account)}>
        {t(CoachAccountsLocale.remove)}
      </RemoveLink>
    </AccountContainer>
  );
};

const mapDispatchToProps = {
  setActiveMenu: setActiveMenuMobile,
};

export default connect(null, mapDispatchToProps)(AccountItem);
