/** @jsx h */
import { FunctionalComponent, h } from 'preact';
import SideNavItem from '../../../sideNavItem';
import { IMenuLocationModel } from '../../../../models/MenuLocation';
import { FooterContainer } from './footerNavigationStyled';

export interface IFooterNavigationProps {
  menuLocation: IMenuLocationModel;
}

export const FooterNavigation: FunctionalComponent<IFooterNavigationProps> = ({ menuLocation }) => (
  <FooterContainer>
    {menuLocation.menuItemList.map((menu) => (
      <SideNavItem item={menu} key={menu.id} />
    ))}
  </FooterContainer>
);

export default FooterNavigation;
