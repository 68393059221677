/** @jsx h */
import { FunctionalComponent, h, Fragment } from 'preact';
import { connect } from 'react-redux';
import { updateLocale } from '../../../../actions/localeActions';
import { NavItem } from '../../../../containers/mobileNavigation/mobileNavigationStyled';
import { AppState } from '../../../../interfaces/appState';
import { IItemModel } from '../../../../models/Item';
import { setActiveMenuMobile, setNextLocaleChangeModal } from '../../../../actions/headerActions';
import { parentActiveMenuMobileSelector } from '../../../../selectors/activeItemSelectors';
import { IMenuModel } from '../../../../models/Menu';
import { ILocaleModel } from '../../../../models/Locale';
import { modulesByKeySelector } from '../../../../selectors/modulesSelectors';
import { getSupportedLanguageFromPlaceholder } from '../../../../utils/locale';
import { Checked } from '../menu/menuStyled';
import { LocaleContainer, LocaleIcon, LocaleItem } from './localeStyled';

export interface ILocaleProps {
  locale: ILocaleModel;
  item: IItemModel;
  updateLocale: typeof updateLocale;
  onClose: () => void;
  setActiveMenu: typeof setActiveMenuMobile;
  setNextLocaleChangeModal: typeof setNextLocaleChangeModal;
  parentActiveMenu: IMenuModel;
}

export const MenuItemLocale: FunctionalComponent<ILocaleProps> = ({
  locale,
  item,
  setNextLocaleChangeModal,
}) => {
  const { selectedLocale } = locale;
  const { anchorText, menuImageUrl, placeholder } = item;
  const itemLocale = getSupportedLanguageFromPlaceholder(placeholder);
  const isSelected = selectedLocale === itemLocale;

  const handleClick = () =>
    !isSelected && setNextLocaleChangeModal({ selectedLocale: itemLocale } as ILocaleModel);

  return (
    <Fragment>
      <NavItem onClick={handleClick}>
        <LocaleContainer>
          <LocaleIcon src={menuImageUrl} />
          <LocaleItem isSelected={isSelected} dangerouslySetInnerHTML={{ __html: anchorText }} />
        </LocaleContainer>
        {isSelected && <Checked />}
      </NavItem>
    </Fragment>
  );
};

const mapStateToProps = (state: AppState) => ({
  locale: modulesByKeySelector(state, 'locale'),
  parentActiveMenu: parentActiveMenuMobileSelector(state),
});

const mapDispatchToProps = {
  setNextLocaleChangeModal,
  updateLocale,
};

export default connect(mapStateToProps, mapDispatchToProps)(MenuItemLocale);
