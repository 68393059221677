import { RefObject } from 'preact';
import { useEffect, useCallback } from 'preact/hooks';

const ESC_KEYCODE = '27';

interface IUseClickOutsideProps {
  onClose: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ref: RefObject<any>;
  handleOnEscape?: boolean;
}

const useClickOutside = (props: IUseClickOutsideProps): void => {
  const { ref, onClose, handleOnEscape = true } = props;

  const handleClickOutside = useCallback(
    (event: FocusEvent) => {
      if (ref.current && !ref.current.contains(event.target)) {
        onClose();
      }
    },
    [onClose, ref],
  );

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (handleOnEscape && event.key === ESC_KEYCODE) {
        event.preventDefault();
        onClose();
      }
    },
    [handleOnEscape, onClose],
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleClickOutside, handleKeyDown, ref]);
};

export default useClickOutside;
