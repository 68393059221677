import { Record } from 'immutable';
import { IModelStatic } from '../interfaces/IModel';

export interface IVHS {
  percentageViewed: string;
  timestampStart: Date;
  videoGuid: string;
}

export interface IRawVHS {
  videoGuid: string;
  percentageViewed: string;
  timestampStart: Date;
}

const defaultProps = {
  percentageViewed: '',
  timestampStart: new Date(),
  videoGuid: '',
};

export interface IVHSModel extends Record<IVHS>, IVHS {}

const VHS: IModelStatic<IVHSModel, IRawVHS> = class
  extends Record<IVHS>(defaultProps, 'VHS')
  implements IVHSModel {
  static createFromRaw(rawVHS: IRawVHS): IVHSModel {
    return new this(rawVHS);
  }
};

export default VHS;
