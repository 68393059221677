import { Record } from 'immutable';
import { SupportedLanguages, DEFAULT_LOCALE } from '../enums/locale';
import { IModel, IModelStatic } from '../interfaces/IModel';
import { IGeolocationModel } from './Geolocation';

export interface ILocale {
  selectedLocale: SupportedLanguages;
  geolocation?: IGeolocationModel;
}

export type IConfigLocale = ILocale;

export interface ILocaleModel extends Record<ILocale>, ILocale, IModel<ILocaleModel, ILocale> {
  toConfig(): IConfigLocale;
  validateGeolocation(rules: string[]): boolean;
  validateSupportedLanguage(rules: string[]): boolean;
}

const defaultProps = {
  selectedLocale: DEFAULT_LOCALE,
  geolocation: undefined,
};

const Locale: IModelStatic<ILocaleModel, ILocale> = class
  extends Record<ILocale>(defaultProps, 'Locale')
  implements ILocaleModel {
  static createFromRaw(rawLocale?: ILocale): ILocaleModel {
    return new this(rawLocale || {});
  }

  serialize(): ILocale {
    const { selectedLocale, geolocation } = this;

    return {
      selectedLocale,
      geolocation,
    };
  }

  mergeFromConfig(fromConfig: IConfigLocale): ILocaleModel {
    return this.merge(fromConfig);
  }

  toConfig(): IConfigLocale {
    const { selectedLocale, geolocation } = this;

    return {
      selectedLocale,
      geolocation,
    };
  }

  validateGeolocation(rules: string[]): boolean {
    return !!this.geolocation && rules.includes(this.geolocation?.country.toUpperCase());
  }

  validateSupportedLanguage(rules: string[]): boolean {
    return !!this.selectedLocale && rules.includes(this.selectedLocale.toUpperCase());
  }
};

export default Locale;
