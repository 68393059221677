import styled from 'styled-components';
import { Media } from '../../../styles/media';

const { tablet } = Media;

const Overlay1 = styled.h2`
  font-size: 28px;
  font-weight: 400;

  ${tablet`
    font-size: 36px;
  `};
`;

export default Overlay1;
