import { Record } from 'immutable';
import { IUserBase } from '../interfaces/IUserBase';
import { IModel, IModelStatic } from '../interfaces/IModel';

export interface ISsoAccount extends IUserBase {
  coachId?: string;
}

export interface ISsoAccountModel
  extends Record<ISsoAccount>,
    ISsoAccount,
    IModel<ISsoAccountModel, ISsoAccount> {}

interface ISsoAccountModelStatic extends IModelStatic<ISsoAccountModel, ISsoAccount> {
  createFromRawArray(accounts?: ISsoAccount[]): ISsoAccountModel[];
}

const defaultProps = {
  email: '',
  guid: '',
  firstName: '',
  lastName: '',
  avatarUrl: '',
  coachId: '',
};

const SsoAccount: ISsoAccountModelStatic = class
  extends Record<ISsoAccount>(defaultProps, 'SsoAccount')
  implements ISsoAccountModel {
  static createFromRawArray(accounts: ISsoAccount[]): ISsoAccountModel[] {
    return accounts.map((a) => SsoAccount.createFromRaw(a));
  }

  static createFromRaw({
    guid,
    firstName,
    lastName,
    avatarUrl,
    email,
    coachId,
  }: ISsoAccount): ISsoAccountModel {
    return new this({
      email,
      guid,
      firstName,
      lastName,
      avatarUrl,
      coachId,
    });
  }

  serialize(): ISsoAccount {
    return this.toObject();
  }

  mergeFromConfig(fromConfig: ISsoAccount): ISsoAccountModel {
    return this.merge(fromConfig);
  }
};

export default SsoAccount;
