import styled from 'styled-components';
import { ITypography } from '../../interfaces/typography';

export const Body1Template: ITypography = {
  fontSize: '14px',
  fontWeight: '700',
  lineHeight: '1.43em',
  letterSpacing: '0.025em',
};

const { fontSize, fontWeight, lineHeight, letterSpacing } = Body1Template;

const Body1 = styled.p`
  font-size: ${fontSize};
  font-weight: ${fontWeight};
  line-height: ${lineHeight};
  letter-spacing: ${letterSpacing};
`;

export default Body1;
