import { bindActionCreators, Store } from 'redux';
import IUnificationScript from '../../interfaces/IUnificationScript';
import * as modulesActions from '../../actions/modulesActions';
import * as eventActions from '../../actions/eventActions';
import { getSsoModule } from '../sso';
import { getLocaleModule } from '../locale';
import { getSearchModule } from '../search';
import { getVideoPlayerModule } from '../videoPlayer';
import { IModulesActions, IModulesThunkActions } from '../../actions/interfaces/IModulesActions';
import { IEventActionCreators, IEventActions } from '../../actions/interfaces/IEventActions';
import Event from '../../models/Event';
import { getCartModule } from '../cart';
import { modulesByKeySelector } from '../../selectors/modulesSelectors';
import { IMain, IMainModel } from '../../models/Main';

const getMainModule = (store: Store): IUnificationScript => {
  const { dispatch, getState } = store;
  const { initializeModules, updateMainModule, updateIsDebugEnabled } = bindActionCreators<
    IModulesThunkActions,
    IModulesActions
  >(modulesActions, dispatch);

  const { subscribeEventActionCreator, unsubscribeEventActionCreator } = bindActionCreators<
    IEventActionCreators,
    IEventActions
  >(eventActions, dispatch);

  return {
    async getConfig() {
      const state = getState();

      return Promise.resolve(
        (modulesByKeySelector(state, 'main') as IMainModel).toObject() as IMain,
      );
    },
    async init(config) {
      const {
        payload: { main },
      } = await initializeModules(config);

      return main.toObject();
    },
    async updateConfig(config) {
      const {
        payload: { main },
      } = await updateMainModule(config);

      return main.toObject();
    },
    async addEventListener(eventName, callBack) {
      await subscribeEventActionCreator(Event.createFromRaw({ eventName, callBack }));
    },
    async removeEventListener(eventName, callBack) {
      await unsubscribeEventActionCreator(Event.createFromRaw({ eventName, callBack }));
    },
    async enableDebug() {
      await updateIsDebugEnabled(true);
    },
    search: getSearchModule(store),
    sso: getSsoModule(store),
    locale: getLocaleModule(store),
    cart: getCartModule(store),
    videoPlayer: getVideoPlayerModule(store),
  };
};

export default getMainModule;
