import { Record } from 'immutable';
import get from 'lodash/get';
import { VIDEO_PROGRAM_TYPES, HIT_TYPES, BODi } from '../enums/algolia';
import { IModelStatic } from '../interfaces/IModel';
import { IRawWorkout } from '../interfaces/IWorkout';
import Program from './Program';
import AssociatedContent from './AssociatedContent';
import VideoModalSection from './VideoModalSection';
import Violator from './Violator';
import { SourceLanguage } from '../enums/locale';
import { IWorkout, IWorkoutModel } from './Workout';

const defaultProps = {
  associatedContent: undefined,
  brandCode: '',
  description: '',
  duration: '',
  equipments: undefined,
  groupName: '',
  id: '',
  imageUrl: '',
  isBODi: false,
  objectID: '',
  pdfCount: 0,
  position: 0,
  program: undefined,
  programTitle: '',
  queryID: '',
  slug: '',
  sourceLanguage: undefined,
  subNavSlug: '',
  tier: undefined,
  title: '',
  trainer: '',
  type: VIDEO_PROGRAM_TYPES.LESSON,
  violator: undefined,
  video: undefined,
  workoutLevel: '',
  workoutType: '',
  workoutTypes: undefined,
  __hitType: HIT_TYPES.LESSON,
};

const Lesson: IModelStatic<IWorkoutModel, IRawWorkout> = class
  extends Record<IWorkout>(defaultProps, 'Lesson')
  implements IWorkoutModel {
  static createFromRaw(rawWorkout?: IRawWorkout): IWorkoutModel {
    const {
      brandCode,
      description,
      equipments: rawEquipments,
      groupName,
      id,
      imageUrl,
      mpxVideo: video,
      objectID,
      program: rawProgram,
      programTitle,
      slug,
      subNavSlug,
      tier,
      title,
      trainer: rawTrainer,
      resources,
      resourceGroupTitle,
      sourceLanguage: rawSourceLanguage,
      videoDurationMinutes,
      violator: rawViolator,
      workoutTypes: rawWorkoutTypes,
      workoutType,
      __position: position = 0,
      __queryID: queryID,
    } = rawWorkout || {};

    const isBODi = tier === BODi;
    const optionalViolatorText = rawEquipments?.optionalViolatorText;
    const duration = videoDurationMinutes ? `${videoDurationMinutes}m` : '0';
    const trainer = get(rawTrainer, '[0].name', '');
    const sourceLanguage = SourceLanguage[rawSourceLanguage || ''];

    const associatedContent = AssociatedContent.createFromRaw({
      links: resources,
      title: resourceGroupTitle || '',
    });
    const equipments = VideoModalSection.createFromRaw({
      headlineText: rawEquipments?.headlineText,
      optionalViolatorText: optionalViolatorText ? `* ${optionalViolatorText}` : '',
      items: [
        ...(rawEquipments?.requiredEquipments || []),
        ...(rawEquipments?.recommendedEquipments?.map((equipment: string) => `${equipment}*`) ||
          []),
      ],
    });

    const workoutTypes = VideoModalSection.createFromRaw({
      headlineText: rawWorkoutTypes?.title,
      items: rawWorkoutTypes?.items || [],
    });

    const pdfCount = associatedContent?.links.length || 0;
    const program = rawProgram ? Program.createFromRaw(rawProgram) : undefined;
    const violator = rawViolator && Violator.createFromRaw(rawViolator?.meta_box);

    return new this({
      associatedContent,
      brandCode,
      description,
      duration,
      equipments,
      groupName,
      id,
      imageUrl,
      isBODi,
      objectID,
      pdfCount,
      position,
      program,
      programTitle,
      queryID,
      slug,
      subNavSlug,
      sourceLanguage,
      tier,
      title,
      trainer,
      video,
      violator,
      workoutType,
      workoutTypes,
    });
  }
};

export default Lesson;
