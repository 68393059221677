import { createSelector, ParametricSelector } from 'reselect';
import { AppState } from '../interfaces/appState';
import { IMain, IMainModel } from '../models/Main';
import { ModulesStatus } from '../reducers/modulesReducer';
import { ILocaleModel } from '../models/Locale';
import { ISearchModel } from '../models/Search';
import { ICartModel } from '../models/Cart';
import { ISsoModel } from '../interfaces/ISso';
import { IModulesState, IModulesStateRecord } from '../interfaces/IModules';
import { DeviceTypes } from '../utils/device';
import { LoggedStatus } from '../enums/sso';

export const modulesStateSelector = (state: AppState): IModulesStateRecord => state.modules;
const moduleByKey = <K extends keyof IModulesState>(_: AppState, key: K): K => key;
const navigationByKey = <K extends keyof IMain>(_: AppState, key: K): K => key;

type ModuleKeyType =
  | IMainModel
  | ISsoModel
  | ICartModel
  | ISearchModel
  | ILocaleModel
  | ModulesStatus;

export const modulesByKeySelector: ParametricSelector<
  AppState,
  keyof IModulesState,
  ModuleKeyType
> = createSelector(
  modulesStateSelector,
  moduleByKey,
  <K extends keyof IModulesState>(modules: IModulesStateRecord, key: K): ModuleKeyType =>
    modules.get(key),
);

export const mainModuleNavigationSelector: ParametricSelector<
  AppState,
  keyof IMain,
  IMainModel[keyof IMain]
> = createSelector(
  modulesStateSelector,
  navigationByKey,
  <K extends keyof IMain>(modules: IModulesStateRecord, key: K): IMainModel[keyof IMain] =>
    modules.main.get(key),
);

export const mainModuleSelector = (state: AppState): IMainModel =>
  modulesByKeySelector(state, 'main') as IMainModel;

export const isMobileSelector = createSelector(
  mainModuleSelector,
  ({ device }) => device === DeviceTypes.mobile,
);

export const isCustomRoutingEnabled = createSelector(
  mainModuleSelector,
  ({ isCustomNavigationEnabled }) => isCustomNavigationEnabled,
);

export const appIdSelector = createSelector(mainModuleSelector, ({ appId }) => appId);

export const ssoModuleSelector = (state: AppState): ISsoModel =>
  modulesByKeySelector(state, 'sso') as ISsoModel;

export const isAccessTokenLoadingSelector = createSelector(
  ssoModuleSelector,
  ({ userLoginStatus, accessToken }) => {
    return !userLoginStatus || (userLoginStatus === LoggedStatus.loggedIn && !accessToken);
  },
);
