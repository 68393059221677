import { Action, ActionCreatorsMapObject } from 'redux';
import { IDefaultAction } from '../../interfaces/common';
import { IEventModel } from '../../models/Event';
import { ThunkResult } from './../interfaces/IAction';

export const SUBSCRIBE_EVENT = 'UNIFICATION/SUBSCRIBE_EVENT';
export const UNSUBSCRIBE_EVENT = 'UNIFICATION/UNSUBSCRIBE_EVENT';

export const EVENT_DOES_NOT_EXIST = 'The event does not exists';
export const EVENT_REGISTERED = 'This event has already been registered';
export const EVENT_NOT_SUPPORTED = 'This event is not supported';
export interface ISubscribeEventAction extends Action<typeof SUBSCRIBE_EVENT> {
  payload: IEventModel;
}

export interface IUnsubscribeEventAction extends Action<typeof UNSUBSCRIBE_EVENT> {
  payload: IEventModel;
}

export interface ISubscribeEventActionCreator {
  (event: IEventModel): ThunkResult<ISubscribeEventAction>;
}

export interface IUnsubscribeEventActionCreator {
  (event: IEventModel): ThunkResult<IUnsubscribeEventAction>;
}

export interface IEventActions extends ActionCreatorsMapObject {
  subscribeEventActionCreator(event: IEventModel): Promise<ISubscribeEventAction>;
  unsubscribeEventActionCreator(event: IEventModel): Promise<ISubscribeEventAction>;
}

export interface IEventActionCreators extends ActionCreatorsMapObject {
  subscribeEventActionCreator: ISubscribeEventActionCreator;
  unsubscribeEventActionCreator: IUnsubscribeEventActionCreator;
}

export type EventActions = ISubscribeEventAction | IUnsubscribeEventAction | IDefaultAction;
