import { Record } from 'immutable';
import { IModelStatic } from '../interfaces/IModel';
import RichText, { IRichTextModel, IRichText } from './RichText';
import { IMenuItem, MenuItemTypes } from '../interfaces/IMenuItem';

export interface IContentBlock extends IMenuItem {
  title: string;
  text: IRichTextModel;
}

export interface IRawContentBlock {
  id: string;
  title: string;
  locale?: string;
  __typename?: string;
  richText: IRichText;
}

export interface IContentBlockModel extends Record<IContentBlock>, IContentBlock {}

const defaultProps = {
  id: '',
  title: '',
  locale: '',
  text: RichText.createFromRaw(),
  __typename: MenuItemTypes.contentBlock,
};

const ContentBlock: IModelStatic<IContentBlockModel, IRawContentBlock> = class
  extends Record<IContentBlock>(defaultProps, 'ContentBlock')
  implements IContentBlockModel {
  static createFromRaw(rawContentBlock: IRawContentBlock): IContentBlockModel {
    const { richText: rawText } = rawContentBlock || {};
    const text = RichText.createFromRaw(rawText);

    return new this({ ...(rawContentBlock || {}), text });
  }
};

export default ContentBlock;
