/** @jsx h */
import { FunctionalComponent, h } from 'preact';
import { parse } from 'query-string';
import { ISearchModel } from '../../models/Search';
import { SearchContainer, SearchButton, SearchIcon } from './searchStyled';
import { IMenuItemModel } from '../../models/Menu';
import { IItemModel } from '../../models/Item';
import { E2E, getTestID, useE2ETesting } from '../../providers/e2eTestingProvider';

const SEARCH_PATH_REGEX = /\bsearch\b/;

export const getDecodedQuery = (pathname: string, search: string): string => {
  if (!SEARCH_PATH_REGEX.test(pathname)) {
    return '';
  }
  const { query = '' } = parse(search);
  return decodeURIComponent(query as string);
};

export interface ISearchProps {
  isDesktop: boolean;
  search: ISearchModel;
  item: IMenuItemModel;
  handleOnSearch?: () => void;
}

export const Search: FunctionalComponent<ISearchProps> = ({ isDesktop, item, handleOnSearch }) => {
  const searchText = getDecodedQuery(location.pathname, location.search.slice(1));
  const { testIdPrefix } = useE2ETesting();
  const testIdValue = getTestID(testIdPrefix, E2E.component.button, 'search');

  const { menuImageUrl, anchorText } = item as IItemModel;
  return (
    <SearchContainer onClick={handleOnSearch} data-testid={testIdValue}>
      <SearchButton>
        <SearchIcon src={menuImageUrl} isMobile={!isDesktop} />
        {isDesktop ? searchText || anchorText : null}
      </SearchButton>
    </SearchContainer>
  );
};

export default Search;
