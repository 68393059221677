/* eslint-disable prettier/prettier */
/* 
  CF: The bundler package will change the json keys 
  while optimizing/transpiling code and we need to keep
  the key values as they are.
*/
const TEALIUM_LINKS = {
  'play-video-button-search': {
    'data-tealium-key': 'play-video-button',
    site_section: 'search',
    site_subsection_2: '${from}',
    page_name: '${tab_name}',
    page_type: 'search',
    event_type: 'action',
    event_name: 'img-btn:${state}-workout:${title}',
    event_data: '${event_data}',
    search_refiners: '${search_state}',
  },
  vw_search_page: {
    'data-tealium-key': 'vw_search_page',
    site_section: 'search',
    page_type: 'search',
    page_name: '${page_name}',
    search_refiners: '${search_refiners}',
  },
  vw_promo_modal: {
    'data-tealium-key': 'vw_promo_modal',
    site_section: 'bodi',
    page_name: 'Promo Modal',
    tealium_event: 'View Promo Modal',
    attribution_link_url: '${attributionLinkUrl}',
    attribution_link_query_string_parameters: '${attributionLinkParameters}',
    promo_prompt: '${promo_prompt}',
    promo_description: '${promo_description}',
  },
  link_dismiss_promo_modal: {
    'data-tealium-key': 'lnk_dismiss_promo_modal',
    event_name: 'Dismiss Promo Modal',
    tealium_event: 'Dismiss Promo Modal',
    site_section: 'bodi',
    page_name: 'Promo Modal',
    promo_description: '${promo_description}',
    promo_prompt: '${promo_prompt}',
  },
  link_click_promo_cta: {
    'data-tealium-key': 'lnk_click_promo_cta',
    event_name: 'Click Promo CTA',
    tealium_event: 'Click Promo CTA',
    site_section: 'bodi',
    page_name: 'Promo Modal',
    promo_description: '${promo_description}',
    promo_prompt: '${promo_prompt}',
    cta_text: '${cta_text}',
  },
  link_tracking: {
    'data-tealium-key': 'link_tracking',
    link_tracking: '${event}',
  },
  'download-pdf-asset-search': {
    'data-tealium-key': 'download-pdf-asset',
    site_section: 'search',
    page_name: '${tab_name}',
    page_type: 'search',
    event_type: 'nav',
    event_name: 'link-img:${type}_${title}',
    event_data: '${data}',
    search_refiners: '${search_state}',
  },
};

export default TEALIUM_LINKS;
