import styled from 'styled-components';
import { Body2, Body4 } from '../../shared/typography';

export const CoachText = styled(Body2)`
  color: ${({ theme }) => theme.darkGray};
  margin: 0;
`;

export const CoachEmail = styled(Body4).attrs({ as: 'a' })`
  color: ${({ theme }) => theme.oneAppBlue};
  text-decoration: none;
  text-transform: lowercase;
  margin: 0;
`;

export const Container = styled.div`
  background: ${({ theme }) => theme.pearlGray};
  height: 60px;
  padding: 0 24px;
  display: grid;
  grid-template-columns: max-content max-content max-content;
  align-items: center;
  grid-gap: 8px;
  position: relative;
  z-index: 300;
`;

export const CoachAvatar = styled.img`
  width: 30px;
  height: 30px;
  border-radius: 50%;
`;
