import styled from 'styled-components';
import { Media } from '../../../styles/media';

const { tablet } = Media;

const FTR = styled.p`
  font-size: 10px;
  opacity: 0.5;
  letter-spacing: 0.2px;
  color: ${({ theme }) => theme.darkGray};

  ${tablet`
    font-size: 9px;
  `};
`;

export default FTR;
