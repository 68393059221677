/** @jsx h */
import { Fragment, FunctionalComponent, h } from 'preact';
import { useState } from 'preact/hooks';
import { Icon } from '../../menuLocations/topNavIcons/topNavIconsStyled';
import { IItemModel } from '../../../../models/Item';
import MobileNavigation from '../../../../containers/mobileNavigation';
import { IMenuItemModel } from '../../../../models/Menu';

export interface IHamburgerIconProps {
  item: IMenuItemModel;
}

const HamburgerIcon: FunctionalComponent<IHamburgerIconProps> = ({ item }) => {
  const [isNavigationOpen, setIsNavigationOpen] = useState(false);

  const { menuImageUrl } = item as IItemModel;
  return (
    <Fragment>
      <Icon src={menuImageUrl} onClick={() => setIsNavigationOpen(true)} />
      {isNavigationOpen && (
        <MobileNavigation isOpen={isNavigationOpen} onClose={() => setIsNavigationOpen(false)} />
      )}
    </Fragment>
  );
};

export default HamburgerIcon;
