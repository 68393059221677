/** @jsx h */
import { FunctionalComponent, h } from 'preact';
import { useEffect, useRef } from 'preact/hooks';
import { Background, Container, Input, Reset, ResetIcon, Row } from './searchFieldStyled';

export interface ISearchFieldProps {
  isVisible: boolean;
  onChange: () => void;
  onHide: () => void;
  onKeyDown: () => void;
  placeholder: string;
  value: string;
}

const SearchField: FunctionalComponent<ISearchFieldProps> = ({
  isVisible,
  onChange,
  onHide,
  onKeyDown,
  placeholder,
  value,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isVisible) {
      inputRef.current?.focus();
    } else {
      inputRef.current?.blur();
    }
  }, [isVisible]);

  return (
    <Background isVisible={isVisible}>
      <Container>
        <Row>
          <Input
            autoCapitalize="off"
            autoComplete="off"
            autoCorrect="off"
            maxLength="512"
            onChange={onChange}
            onKeyDown={onKeyDown}
            placeholder={placeholder}
            ref={inputRef}
            required
            spellCheck="false"
            value={value}
          />
          <Reset onClick={onHide} type="button">
            <ResetIcon alt="reset-icon" />
          </Reset>
        </Row>
      </Container>
    </Background>
  );
};

export default SearchField;
