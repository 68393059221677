import { useState, useEffect, useRef } from 'preact/hooks';
import type { WebVideoPlayer, actionsMap } from 'web-video-player';

export function useWebVideoPlayerLazy(props: { load: boolean }) {
  const [loaded, setLoaded] = useState(false);
  const actionsMapRef = useRef<typeof actionsMap>(null);
  const WebVideoPlayerRef = useRef<typeof WebVideoPlayer | null>(null);

  useEffect(() => {
    if (!props.load || loaded) return;

    import('web-video-player').then((module) => {
      actionsMapRef.current = module.actionsMap;
      WebVideoPlayerRef.current = module.WebVideoPlayer;
      setLoaded(true);
    });
  }, [props.load, loaded]);

  return {
    loaded,
    actionsMap: actionsMapRef.current,
    Component: WebVideoPlayerRef.current,
  };
}
