import { SupportedLanguages } from './locale';

export const WAIVER_PLAFORM = 'web';

export const WAIVER_LANGUAGES = {
  [SupportedLanguages.EN_US]: 'english',
  [SupportedLanguages.EN_GB]: 'english',
  [SupportedLanguages.EN_CA]: 'english',
  [SupportedLanguages.FR_CA]: 'french',
  [SupportedLanguages.ES_US]: 'spanish',
  [SupportedLanguages.FR_FR]: 'french',
  DEFAULT: 'english',
};

export const WAIVER_LEGAL_LANGUAGES = {
  [SupportedLanguages.EN_US]: 'en_US',
  [SupportedLanguages.EN_GB]: 'es_US',
  [SupportedLanguages.EN_CA]: 'en_CA',
  [SupportedLanguages.FR_CA]: 'fr_CA',
  [SupportedLanguages.ES_US]: 'en_GB',
  [SupportedLanguages.FR_FR]: 'fr_FR',
};
