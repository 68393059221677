import { Record } from 'immutable';
import { IUserBase } from '../interfaces/IUserBase';
import { Roles } from '../enums/sso';
import { IModel, IModelStatic } from '../interfaces/IModel';
import { CountryList } from '../enums/locale';
export interface IUserInfo extends IUserBase {
  roleList: Array<Roles>;
  entitlementList: Array<string>;
  coachId?: number;
  primaryCountry: string;
}

export interface IUserInfoModel
  extends Record<IUserInfo>,
    IUserInfo,
    IModel<IUserInfoModel, IUserInfo> {}

const defaultProps: IUserInfo = {
  email: '',
  guid: '',
  firstName: '',
  lastName: '',
  avatarUrl: '',
  roleList: [],
  entitlementList: [],
  coachId: 0,
  primaryCountry: CountryList.US,
};

const UserInfo: IModelStatic<IUserInfoModel, IUserInfo> = class
  extends Record<IUserInfo>(defaultProps, 'UserInfo')
  implements IUserInfoModel {
  static createFromRaw({
    email,
    guid,
    firstName,
    lastName,
    avatarUrl,
    roleList,
    entitlementList,
    coachId,
    primaryCountry,
  }: IUserInfo): IUserInfoModel {
    return new this({
      email,
      guid,
      firstName,
      lastName,
      avatarUrl,
      roleList,
      entitlementList,
      coachId,
      primaryCountry,
    });
  }

  serialize(): IUserInfo {
    return this.toObject();
  }

  mergeFromConfig(fromConfig: IUserInfo): IUserInfoModel {
    return this.merge(fromConfig);
  }
};

export default UserInfo;
