/** @jsx h */
import { FunctionalComponent, h } from 'preact';
import { useMemo } from 'preact/hooks';
import { IMenuItemModel } from '../../models/Menu';
import mobileFactory from '../../factories/MobileFactory';

export interface ISideNavItemProps {
  item: IMenuItemModel;
  isBold?: boolean;
  onClose?: () => void;
}

const SideNavItem: FunctionalComponent<ISideNavItemProps> = ({ item, onClose, isBold = false }) => {
  const Component = useMemo(() => mobileFactory.mapNavItem(item), [item]);

  return <Component item={item} onClose={onClose} isBold={isBold} shouldWrapNavItem />;
};

export default SideNavItem;
