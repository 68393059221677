/** @jsx h */
import { FunctionalComponent, h } from 'preact';
import { useMemo } from 'preact/hooks';
import { IMenuItemModel } from '../../models/Menu';
import desktopFactory from '../../factories/DesktopFactory';

export interface IDropdownItemProps {
  item: IMenuItemModel;
  isSubItem?: boolean;
}

const DropdownItem: FunctionalComponent<IDropdownItemProps> = ({ item, isSubItem = false }) => {
  const Component = useMemo(() => desktopFactory.mapDropdownItem(item), [item]);

  return Component ? <Component item={item} isSubItem={isSubItem} /> : null;
};

export default DropdownItem;
