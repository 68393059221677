/** @jsx h */
import { h } from 'preact';
import { useEffect, useState } from 'preact/hooks';
import { useTranslation } from 'react-i18next';
import { AutoCompleteLocale } from '../autoComplete/locale';
import { NoResults as NoResultsContent } from '../autoComplete/suggestionContainerStyled';
import { NoResultsContainer } from './topCategoriesStyled';

const RENDER_DELAY_MS = 1500;

// Algolia is not reliable to notify if result fetching
// is done. The `searching` prop alternates between true and false
// multiple times, so this component used to be briefly displayed
// before actual results were loaded.

export const NoResults = () => {
  const { t } = useTranslation();
  const [isVisible, setVisible] = useState(false);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setVisible(true);
    }, RENDER_DELAY_MS);
    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  if (!isVisible) return null;

  return (
    <NoResultsContainer>
      <NoResultsContent>{t(AutoCompleteLocale.noResults)}</NoResultsContent>
    </NoResultsContainer>
  );
};
