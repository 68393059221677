import styled from 'styled-components';
import { Media } from '../../../styles/media';
import { ITypography } from '../../interfaces/typography';
import Body1, { Body1Template } from '../body1';

const { tablet } = Media;

export const Body1d2Template: ITypography = {
  ...Body1Template,
  fontSize: '16px',
};
const { fontSize, fontWeight, lineHeight, letterSpacing } = Body1d2Template;

type TextProps = {
  italic: boolean;
};

const Body1d2 = styled(Body1)<TextProps>`
  font-weight: ${fontWeight};
  font-style: ${({ italic }) => (italic ? 'italic' : 'normal')};
  line-height: ${lineHeight};
  letter-spacing: ${letterSpacing};

  ${tablet`
    font-size: ${fontSize};
  `};
`;

export default Body1d2;
