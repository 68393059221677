import Bluebird from 'bluebird';
import { CrossStorageClient } from 'cross-storage';
import { loadFromConfig } from '../utils/configLoader';

const iFrameUrl = loadFromConfig('IFRAME_URL');

const ERROR_CROSSDOMAIN_STORAGE = 'CrossStorage is unavailable...';

export const getLocalStorage = (): CrossStorageClient | undefined => {
  try {
    return new CrossStorageClient(iFrameUrl, {
      timeout: 5000,
      frameId: 'local-storage',
      promise: Bluebird,
    });
  } catch (e) {
    console.error(ERROR_CROSSDOMAIN_STORAGE);
  }
};

export const isStorageAvailable = async (
  storage: CrossStorageClient | undefined,
  connectionPromise: Promise<void> | undefined,
): Promise<boolean | undefined> => {
  try {
    if (storage) {
      await connectionPromise;
      return true;
    }
  } catch (e) {
    console.error(ERROR_CROSSDOMAIN_STORAGE);
    return false;
  }
};
