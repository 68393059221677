import { Record } from 'immutable';
import { IUserBase } from '../interfaces/IUserBase';
import { IModel, IModelStatic } from '../interfaces/IModel';

export interface ICoachInfoModel
  extends Record<IUserBase>,
    IUserBase,
    IModel<ICoachInfoModel, IUserBase> {}

const defaultProps = {
  email: '',
  guid: '',
  firstName: '',
  lastName: '',
  avatarUrl: '',
  coachType: '',
};

const CoachInfo: IModelStatic<ICoachInfoModel, IUserBase> = class
  extends Record<IUserBase>(defaultProps, 'CoachInfo')
  implements ICoachInfoModel {
  static createFromRaw({
    guid,
    firstName,
    lastName,
    avatarUrl,
    email,
    coachType,
  }: IUserBase): ICoachInfoModel {
    return new this({
      email,
      guid,
      firstName,
      lastName,
      avatarUrl,
      coachType,
    });
  }

  serialize(): IUserBase {
    return this.toObject();
  }

  mergeFromConfig(fromConfig: IUserBase): ICoachInfoModel {
    return this.merge(fromConfig);
  }
};

export default CoachInfo;
