/** @jsx h */
import { FunctionalComponent, h } from 'preact';
import { useCallback } from 'preact/hooks';
import { connect } from 'react-redux';
import { IItemModel } from '../../../../models/Item';
import PrimaryButton from '../primaryButton';
import { logInUser } from '../../../../actions/ssoActions';

export interface ILoginButtonProps {
  item: IItemModel;
  logInUser: typeof logInUser;
}

export const LoginButton: FunctionalComponent<ILoginButtonProps> = ({ item, logInUser }) => {
  const onLogin = useCallback(
    (event: Event) => {
      event.preventDefault();

      logInUser();
    },
    [logInUser],
  );

  return <PrimaryButton item={item} onClick={onLogin} />;
};

export default connect(null, { logInUser })(LoginButton);
