import styled from 'styled-components';
import { Media } from '../../../styles/media';
import { ITypography } from '../../interfaces/typography';
import Body6, { Body6Template } from '../body6';

const { tablet } = Media;

export const Body6dTemplate: ITypography = {
  ...Body6Template,
  fontSize: '14px',
};

const { fontSize, fontWeight, lineHeight, letterSpacing } = Body6dTemplate;

type TextProps = {
  italic: boolean;
};

const Body6d = styled(Body6)<TextProps>`
  font-weight: ${fontWeight};
  font-style: ${({ italic }) => (italic ? 'italic' : 'normal')};
  line-height: ${lineHeight};
  letter-spacing: ${letterSpacing};

  ${tablet`
    font-size: ${fontSize};
  `};
`;

export default Body6d;
