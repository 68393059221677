import HttpMethod from '../../enums/httpMethod';
import { SupportedLanguages } from '../../enums/locale';
import { ICartSummaryResponse } from '../../interfaces/ICartSummary';
import Cart, { ICartModel } from '../../models/Cart';
import { loadFromConfig } from '../../utils/configLoader';
import { request } from '../restClient';

const { URL } = loadFromConfig('CART_API');
const TBB_SHOP_LIST = loadFromConfig('TBB_SHOP_LIST');

export interface ICartSummary {
  get: (accessToken: string, locale: SupportedLanguages) => Promise<ICartModel>;
}

const CartSummary: ICartSummary = {
  get: async (accessToken, locale) => {
    const tbbStore = TBB_SHOP_LIST[locale];
    const { json } = await request<unknown, ICartSummaryResponse>({
      path: URL,
      queryParams: {
        pushSite: tbbStore,
        token: accessToken,
      },
      method: HttpMethod.GET,
      parseResponse: true,
    });

    return Cart.createFromSummary(json);
  },
};

export default CartSummary;
