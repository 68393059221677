import styled from 'styled-components';

export const LocaleContainer = styled.button`
  align-items: center;
  background-color: transparent;
  border: none;
  color: transparent;
  display: flex;
  height: 30px;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
`;

export const LocaleIcon = styled.img`
  width: 20px;
  height: 20px;
  cursor: pointer;
  max-width: unset;
`;

export const Wrapper = styled.div``;
