import styled from 'styled-components';
import { Media } from '../../../styles/media';

const { tablet } = Media;

const Text1 = styled.p`
  font-size: 14px;
  font-weight: 400;

  ${tablet`
    font-size: 15px;
  `};
`;

export const Text1NoBottomMargin = styled(Text1)`
  margin-bottom: 0;
`;

export default Text1;
