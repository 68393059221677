/** @jsx h */
import { FunctionalComponent, h } from 'preact';
import { useTranslation, Trans } from 'react-i18next';
import { useRef, useEffect } from 'preact/hooks';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { SupportedLanguages } from '../../../enums/locale';
import { LocaleIcons } from '../../../assets';
import { LocaleChangeModal } from '../locale';
import {
  Background,
  Close,
  Container,
  WhiteBox,
  BodyText,
  GrayBox,
  AcceptButton,
  Heading,
  BodyContainer,
  InlineImage,
} from '../localeChangeModalStyled';

export interface ILocaleCoachMessageModalProps {
  onAccept(): void;
  newLocale: SupportedLanguages;
}

const LocaleCoachMessageModal: FunctionalComponent<ILocaleCoachMessageModalProps> = ({
  onAccept,
  newLocale,
}) => {
  const ref = useRef<HTMLElement | null>(null);
  const newLocaleIcon = LocaleIcons[newLocale];
  const { t } = useTranslation();
  const newLocaleKey = LocaleChangeModal[newLocale];
  const localeOptions = { lng: newLocale };

  useEffect(() => {
    if (ref.current) {
      disableBodyScroll(ref.current);
    }
    return () => clearAllBodyScrollLocks();
  }, []);

  return (
    <Background>
      <Container ref={ref}>
        <WhiteBox>
          <Close onClick={onAccept} />
          <Heading>{t(LocaleChangeModal.countryAndLanguageUpdated, localeOptions)}</Heading>
          <BodyContainer>
            <BodyText>
              <Trans
                i18nKey={LocaleChangeModal.viewOnlyText}
                values={{ locale: t(newLocaleKey, localeOptions) }}
                components={{ image: <InlineImage src={newLocaleIcon} /> }}
                tOptions={localeOptions}
              />
            </BodyText>
          </BodyContainer>
        </WhiteBox>
        <GrayBox isRightAligned>
          <AcceptButton onClick={onAccept}>{t(LocaleChangeModal.ok, localeOptions)}</AcceptButton>
        </GrayBox>
      </Container>
    </Background>
  );
};

export default LocaleCoachMessageModal;
