import { ActionCreatorsMapObject } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { ILocaleModel } from '../../models/Locale';
import { AppState } from '../../interfaces/appState';
import { IAction } from './IAction';

export const UPDATE_SELECTED_LOCALE = 'MODULES/UPDATE_SELECTED_LOCALE';
export const SET_USER_LOCATION = 'MODULES/SET_USER_LOCATION';

export interface IUpdateLocaleAction extends IAction {
  payload: ILocaleModel;
}

export type UpdateLocaleAction = ThunkAction<
  IUpdateLocaleAction,
  AppState,
  null,
  IUpdateLocaleAction
>;

export type AsyncUpdateLocaleAction = ThunkAction<
  Promise<IUpdateLocaleAction>,
  AppState,
  null,
  IUpdateLocaleAction
>;

export interface IUpdateLocaleActionCreator {
  (supportedLanguage: string): UpdateLocaleAction;
}

export interface ISetLocaleActionCreator {
  (locale: ILocaleModel): AsyncUpdateLocaleAction;
}

export interface ILocaleActions extends ActionCreatorsMapObject {
  updateLocale(locale: string): Promise<IUpdateLocaleAction>;
  getUserLocation(locale: ILocaleModel): Promise<IUpdateLocaleAction>;
}

export interface ILocaleActionCreators extends ActionCreatorsMapObject {
  updateLocale: IUpdateLocaleActionCreator;
}
