/** @jsx h */
import DefaultAvatar from '../../../../assets/avatar.svg';
import { FunctionalComponent, h } from 'preact';
import { useMemo } from 'preact/hooks';
import { connect } from 'react-redux';
import { AppState } from '../../../../interfaces/appState';
import { ISsoModel } from '../../../../interfaces/ISso';
import { modulesByKeySelector } from '../../../../selectors/modulesSelectors';
import { AvatarIcon } from '../../../userAvatar/userAvatarStyled';
import { UserInfoProfileContainer, ItemLinkText, ItemText } from '../../desktopNavStyled';
import { loadFromConfig } from '../../../../utils/configLoader';
import { CoachAccountsLocale } from '../accountSwitch/locale';
import { useTranslation } from 'react-i18next';
import AppLink from '../../../appLink';
import Item, { IItemModel } from '../../../../models/Item';
import { selectedLocaleSelector } from '../../../../selectors/localeSelector';
import { Roles } from '../../../../enums/sso';
import { resolveRelativeUrl } from '../../../../utils/device';
import { E2E, getTestID, useE2ETesting } from '../../../../providers/e2eTestingProvider';

const TBB_MY_ACCOUNT_PATH = loadFromConfig('TBB_MY_ACCOUNT_PATH');
const TBB_DOMAIN = loadFromConfig('APP_DOMAIN_LIST').TBB;
export interface IUserProfileInfoProps {
  sso: ISsoModel;
  selectedLocale: string;
}

export const UserProfileInfo: FunctionalComponent<IUserProfileInfoProps> = ({
  sso,
  selectedLocale,
}) => {
  const {
    userInfo: { email, firstName, guid, lastName, avatarUrl, coachId, roleList = [] } = {},
  } = sso;
  const { testIdPrefix } = useE2ETesting();
  const { t } = useTranslation();
  const countryCode = selectedLocale.split('_')[1];
  const shouldShowCoachId = !roleList.includes(Roles.preferredCustomer);

  const item = useMemo<IItemModel>(
    () =>
      Item.createFromRaw().set(
        'parsedLink',
        `${TBB_DOMAIN}${TBB_MY_ACCOUNT_PATH.replace('{countryCode}', countryCode)}`,
      ),
    [countryCode],
  );

  return (
    <UserInfoProfileContainer>
      <AvatarIcon
        alt={guid || firstName}
        src={avatarUrl || `${resolveRelativeUrl(DefaultAvatar)}`}
        data-testid={getTestID(testIdPrefix, E2E.component.icon, 'userAvatar')}
      />
      <AppLink item={item}>
        <ItemLinkText isBold data-testid={getTestID(testIdPrefix, E2E.component.text, 'userName')}>
          {firstName} {lastName}
        </ItemLinkText>
      </AppLink>
      <ItemText isSubItem data-testid={getTestID(testIdPrefix, E2E.component.text, 'userEmail')}>
        {email}
      </ItemText>
      {shouldShowCoachId && coachId ? (
        <ItemLinkText
          isSubItem
          data-testid={getTestID(testIdPrefix, E2E.component.text, 'userCoachId')}
        >
          {t(CoachAccountsLocale.coachId, { coachId })}
        </ItemLinkText>
      ) : null}
    </UserInfoProfileContainer>
  );
};

const mapStateToProps = (state: AppState) => ({
  sso: modulesByKeySelector(state, 'sso'),
  selectedLocale: selectedLocaleSelector(state),
});

export default connect(mapStateToProps)(UserProfileInfo);
