import { Record } from 'immutable';
import get from 'lodash/get';
import { IHit } from '../interfaces/ISearch';
import { IModelStatic } from '../interfaces/IModel';
import { HIT_TYPES } from '../enums/algolia';
import { IRawProgramMaterial } from '../interfaces/IProgramMaterial';
import AssociatedLink, { IAssociatedLinkModel } from './AssociatedLink';
import Program, { IProgramModel } from './Program';
import { IRawResource } from '../interfaces/IWorkout';

export interface IProgramMaterial extends IHit {
  title: string;
  categorySlug: string;
  description: string;
  imageUrl: string;
  program?: IProgramModel;
  associatedContent: IAssociatedLinkModel;
}

export interface IProgramMaterialModel extends Record<IProgramMaterial>, IProgramMaterial {}

const defaultProps: IProgramMaterial = {
  title: '',
  categorySlug: '',
  description: '',
  imageUrl: '',
  objectID: '',
  position: 0,
  queryID: '',
  program: undefined,
  associatedContent: AssociatedLink.createFromRaw(),
  __hitType: HIT_TYPES.PROGRAM_MATERIAL,
};

const ProgramMaterial: IModelStatic<IProgramMaterialModel, IRawProgramMaterial> = class
  extends Record<IProgramMaterial>(defaultProps, 'ProgramMaterial')
  implements IProgramMaterialModel {
  static createFromRaw(rawProgramMaterial?: IRawProgramMaterial): IProgramMaterialModel {
    const {
      categorySlug,
      description,
      objectID,
      program,
      imageUrl,
      title,
      __position: position,
      __queryID: queryID,
    } = rawProgramMaterial || {};

    return new this({
      title,
      categorySlug: categorySlug || '',
      description: description || '',
      imageUrl: imageUrl || '',
      objectID: objectID || '',
      position: position || 0,
      queryID: queryID || '',
      program: program ? Program.createFromRaw(program) : undefined,
      associatedContent: this.createAssociatedContent(rawProgramMaterial),
    });
  }

  static createAssociatedContent(rawProgramMaterial?: IRawProgramMaterial): IAssociatedLinkModel {
    const resource: IRawResource = {
      attachment: {
        title: get(rawProgramMaterial, 'title', ''),
        ID: get(rawProgramMaterial, 'objectID', ''),
        url: get(rawProgramMaterial, 'url') || '',
      },
      lock: get(rawProgramMaterial, 'lock', false),
      url: get(rawProgramMaterial, 'url') || '',
      title: get(rawProgramMaterial, 'title', ''),
      link: '',
      type: '',
      slug: '',
    };

    return AssociatedLink.createFromRaw(resource);
  }
};

export default ProgramMaterial;
