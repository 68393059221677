/** @jsx h */
import { FunctionalComponent, h } from 'preact';
import { IconContainer } from './topNavIconsStyled';
import { IMenuLocationModel } from '../../../../models/MenuLocation';
import { E2E, getTestID, useE2ETesting } from '../../../../providers/e2eTestingProvider';
import TopNavItem from '../../../../components/topNavItem';

export interface ITopNavIconsProps {
  menuLocation: IMenuLocationModel;
  isDesktop: boolean;
  isTablet: boolean;
}

const TopNavIcons: FunctionalComponent<ITopNavIconsProps> = ({
  menuLocation: { menuItemList },
  isDesktop,
  isTablet,
}) => {
  const { testIdPrefix } = useE2ETesting();

  return (
    <IconContainer data-testid={getTestID(testIdPrefix, E2E.component.icon, 'bodi')}>
      {menuItemList.map((itemMenu) => (
        <TopNavItem key={itemMenu.id} isTablet={isTablet} isDesktop={isDesktop} item={itemMenu} />
      ))}
    </IconContainer>
  );
};

export default TopNavIcons;
