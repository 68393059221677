import { SupportedLanguages } from '../enums/locale';
const { EN_CA, EN_GB, FR_CA, FR_FR: FR_FR_LOCALE, EN_US, ES_US } = SupportedLanguages;

export const FR_FR = {
  aria_label_show_submenus:
    'Appuyez sur la touche Shift + touche Espace pour afficher les sous-menus.',
  search_nav: 'Recherche',
  search_categories_connector: 'dans',
  all: 'Tous {{nbHits}}',
  product: 'Acheter {{nbHits}}',
  program: 'Programme',
  programs: 'Programmes {{nbHits}}',
  recipes: 'Recettes {{nbHits}}',
  workouts: 'Séances d’entraînement {{nbHits}}',
  lessons: 'Leçons {{nbHits}}',
  other: 'Autre {{nbHits}}',
  programMaterials: 'Ressources du programme {{nbHits}}',
  search_results: "Résultats de recherche pour '{{- query}}'",
  search_noResults: 'Aucun résultat trouvé',
  search_recentSearches: 'Recherches récentes',
  search_clearAll: 'Effacer tout',
  search_see_all: 'Afficher tout',
  search_shopNow: 'Acheter maintenant',
  search_fitnesslevel_beginner: 'Débutant',
  search_fitnesslevel_intermediate: 'Intermédiaire',
  search_fitnesslevel_advanced: 'Avancé',
  search_categories: 'Catégories',
  search_backToCategories: '< Retour aux catégories',
  search_sortby_label: 'Trier par:',
  search_sort_title: 'A-Z',
  search_sort_featured: 'En vedette',
  search_sort_popularity: 'Popularité',
  search_sort_newest: 'Plus récent',
  search_gender: 'Genre',
  search_size: 'Taille',
  search_style: 'Style',
  search_subcategories: 'Sous-catégorie',
  search_filters: 'Filtres',
  search_shop_title: 'Acheter',
  search_chooseCategory: 'Choisissez une catégorie',
  search_applyFilters: 'Appliquer les filtres',
  apparel: 'Apparel',
  gear: 'Gear',
  flavor: 'Flavor',
  gearType: 'Type of Gear',
  nutrition: 'Nutrition',
  sale: 'Sale',
  shakeology: 'Shakeology',
  challengePacks: 'Challenge Packs',
  tsp: 'Total Solution Packs',
  trainer: 'Entraîneur/Expert',
  more_details: 'Plus de détails',
  search_clearRefinements_clearAll: 'Effacer tout',
  search_topResult: 'Meilleur résultat',
  search_viewProgram: 'Voir le programme',
  search_stats_result: '{{nbHits}} résultat',
  search_stats_results: '{{nbHits}} résultats',
  videoWaiver_waiverAgreementHeading: 'attention!',
  videoWaiver_waiverAgreementBody: `<p style="text-align:center; font-weight: bold;">&#9888;<span style="text-decoration: underline;">AVERTISSEMENT !</span>&#9888;</p>
  <p style="text-align:center; font-weight: bold; text-decoration: underline;">Nous tenons à votre sécurité</p>
  <p style="text-align:center; font-weight: bold; text-decoration: underline;">Veuillez consulter votre médecin, évaluer votre niveau de condition physique et respecter toutes les consignes de sécurité avant de commencer tout programme d'entraînement ou de nutrition et/ou d'utiliser tout équipement.</p>
  <p>Beachbody  propose  une  large  gamme  de  programmes  d'entraînement,  dont  certains  sont physiquement exigeants et de haute intensité. C'est pourquoi vous devez impérativement écouter votre corps, faire preuve de bon sens, vous hydrater correctement et faire des pauses afin d'éviter toute blessure. Si vous ressentez inconfort, douleurs, étourdissements, vertiges, essoufflement ou nausées, arrêtez immédiatement tout exercice et consultez votre médecin. Une mauvaise pratique ou un excès d'exercice physique peuvent entraîner des blessures graves ou la mort.</p>
  <p>Lors d'activité sportives en plein air, veillez à votre environnement et évitez ce qui pourrait vous blesser ou interrompre votre entraînement, particulièrement en cas de marche ou course à pied dans des lieux publics, tels que les rues ou sur des trottoirs.</p>
  <p>Si vous souffrez de problèmes médicaux particuliers ou spéciaux, si vous êtes une femme enceinte, que vous avez du diabète ou de l'asthme, ou des antécédents de problèmes au niveau des genoux, des chevilles, des épaules ou de la colonne vertébrale (dos ou cou), vous devez consulter votre médecin pour comprendre tous les risques et complications potentiels liés à l'utilisation de nos programmes d'entraînement ou de nutrition et/ou de nos équipements, et obtenir son autorisation avant de commencer. Négliger ce conseil pourrait entraîner des blessures graves pour vous et les autres (y compris, le cas échéant, votre enfant à naître). <span style="font-weight:bold;">En vous engageant dans un programme d'entraînement ou de nutrition et/ou en utilisant quelque équipement que ce soit, vous en assumez tous les risques et dangers, y compris les risques de blessure ou de mort.</span></p>
  <p style="text-align: center; font-weight: bold; text-decoration: underline; margin-bottom: 0; font-size: larger;">UTILISATION SÛRE DE L'ÉQUIPEMENT</p>
  <p style="text-align: center; font-weight: bold; text-decoration: underline; margin-top: 0;">LES ÉQUIPEMENTS DE FITNESS PRÉSENTENT DES RISQUES, QUI, EN CAS DE PROBLÈME, SONT SUSCEPTIBLES D'ENTRAÎNER DES BLESSURES GRAVES OU LA MORT</p>
  <p>Certains programmes peuvent impliquer l'utilisation d'équipements tels que le vélo de fitness Myx, des cordes à sauter, bandes élastiques, barres de traction, bancs, ballons de stabilité, disques coulissants, poids et autres équipements qui, s'ils ne sont pas utilisés correctement, sont susceptibles  de  provoquer  des  blessures  graves  ou  la  mort.  Avant  de  commencer,  lisez attentivement l'ensemble des manuels et instructions de sécurité, d'entretien et d'utilisation.</p>
  <p style="margin-bottom: 0;">Pour votre sécurité, vous devez :</p>
  <ul style="margin-top: 0;">
   <li>Utiliser les équipements montrés dans les entraînements <span style="text-decoration:underline;">uniquement</span> comme indiqué dans les démonstrations et de manière appropriée.</li>
   <li>Inspecter les équipements avant chaque utilisation et vous abstenir d'utiliser un équipement s'il semble endommagé, usé ou défectueux.</li>
   <li>Tenir les enfants, animaux et tout obstacle à distance de vos équipements et de votre zone d'entraînement à tout moment.</li>
   <li>Utiliser un ancrage sécurisé, approprié et stable pour tout équipement nécessitant d'être suspendu ou fixé.</li>
   <li>Utiliser tout équipement avec prudence de façon à ne jamais perdre prise et à le contrôler en permanence, par exemple en vous assurant que vos mains ne sont ni mouillées, ni en sueur.</li>
   <li>ous assurer que les bandes élastiques sont fermement sécurisées et assemblées afin d'éviter qu'elles ne glissent, s'échappent, dérapent ou ne vous blessent, vous ou votre entourage.</li>
   <li>Vous assurer que votre banc et votre barre de traction sont stables et assez solides pour supporter votre poids et ne pas bouger lors de l'utilisation. Si vous ne pensez pas être en mesure d'effectuer certains exercices en toute sécurité avec votre banc ou votre barre de traction, ou si vous ne disposez pas de l'équipement adéquat, vous devez opter pour les versions modifiées des exercices.</li>
  </ul>
  <p style="text-align: center; font-weight: bold; text-decoration: underline; font-size: larger;">UTILISATION SÛRE DU VÉLO DE FITNESS MYX</p>
  <p style="margin-bottom: 0;">AEn plus des avertissements ci-dessus, pour votre sécurité lors de l'utilisation du vélo de fitness Myx, de la tablette et des accessoires connexes (le « vélo »), vous devez :</p>
  <ul style="margin-top: 0;">
   <li>Lire et suivre attentivement le manuel du produit et les vidéos d'instructions avant de commencer, afin de vous assurer de préparer, utiliser et manipuler le vélo correctement et en toute sécurité.</li>
   <li>N'utiliser le vélo qu'après avoir fixé la selle et le guidon de façon sécurisée.</li>
   <li>Tenir les enfants, animaux, mains, vêtements amples, lacets, bijoux et autres éloignés des éléments en mouvement.</li>
   <li>Faire preuve de prudence lorsque vous montez sur le vélo ou en descendez.</li>
   <li>Ne pas stocker, utiliser ou manipuler le vélo en extérieur ou dans des lieux humides ou mouillés.</li>
   <li>Manipuler le vélo sur une surface stable et plane.</li>
   <li>Respecter les limitations de poids : le poids de l'utilisateur ne doit pas excéder 158 kg (350 livres).</li>
   <li>Respecter les restrictions de taille : l'utilisateur doit mesurer entre 1,50 m et 2,03 m (4'11" et 6'8").</li>
   <li>Respecter les restrictions d'âge stipulées dans le manuel du produit.</li>
   <li>Respecter l'espace minimal requis autour du vélo stipulé dans le manuel du produit.</li>
   <li>Suivre les entraînements prévus pour le vélo Myx <span style="text-decoration: underline;">uniquement</span> avec le vélo Myx (et en aucun cas avec un vélo de ville, VTT ou tout autre type de vélo).</li>
  </ul>
  <p>Veuillez consulter votre médecin avant de commencer des entraînements cardio, en particulier si vous êtes enceinte, âgé(e) de moins de 18 ans, que vous avez des problèmes cardiaques, pulmonaires ou toute autre problème médical, ou que vous doutez des conséquences d'une augmentation maximale de votre rythme cardiaque. Si vous avez moins de 18 ans, veuillez également demander au préalable la permission à vos parents ou tuteurs. Le rythme cardiaque, les calories, la cadence et les autres données et valeurs affichées sur le vélo lors de son utilisation pourraient s'avérer inexactes et ne doivent être utilisées qu'à titre de référence.</p>
  <p>Dans toutes les mesures permises par la loi, Beachbody, LLC et ses sociétés mères, affiliées et filiales ne seront tenues responsables envers aucune personne ou entité d'aucun décès, blessure, dommage ou perte causés ou présumés causés directement ou indirectement par tout programme ou séance d'entraînement, complément alimentaire, programme de nutrition, équipement (y compris, sans toutefois s'y limiter, le vélo de fitness Myx), conseil ou tout autre produit, service ou matériel.</p>`,
  videoWaiver_waiverAgreementCheckboxLabel:
    'En cliquant et cochant la case « J’accepte », je reconnais avoir lu, compris et accepté cet avertissement.',
  videoWaiver_waiverAgreementAcceptButtonLabel: 'accepter',
  videoWaiver_waiverAgreementDeclineButtonLabel: 'refuser',
  videoPlayer_closeButton: 'Fermer',
  workout_pdf: '{{duration}}  |  {{pdfCount}} PDF',
  workout_pdfs: '{{duration}}  |  {{pdfCount}} PDFs',
  workout_type: '{{duration}}  |  {{workoutType}}',
  workout_type_and_date: '{{duration}}  |  {{workoutType}}  |  {{filmDate}}',
  sign_in: 'Se Connecter En Tant Que',
  add_new: 'Ajouter Nouveau',
  remove: 'Supprimer',
  coach_id: 'ID de Partenaire: {{coachId}}',
  my_coach: 'Mon partenaire BODi',
  backToCategories: 'Retour aux catégories',
  title: 'Programmes',
  subtitle: 'S’entraîner, c’est mieux ensemble !',
  joinAGroup: 'Rejoignez un groupe.',
  filters: 'Filtres',
  placeholder: 'Rechercher des programmes',
  programType: 'Types de programme',
  duration: 'Durée du programme',
  apply: 'Appliquer les filtres',
  noResultsDescription:
    'Malheureusement, nous n’avons pas pu renvoyer d’objets correspondant à votre recherche.',
  noResultsTitle: 'Beachbody est une question de résultats.',
  beginner: 'Débutant',
  intermediate: 'Intermédiaire',
  advanced: 'Avancé',
  findProgram: 'Trouvez un programme complet ici.',
  workoutType: 'Types de séances d’entraînement',
  bodyPart: 'Parties du corps',
  noEquipment: 'Sans équipement',
  fitnessLevel: 'Niveau de fitness',
  workouts_noResultsDescription:
    "Essayez d'assouplir votre recherche et commencez à voir les résultats instantanément.",
  workouts_noResultsTitle: 'Vous nous avez trop étirés.',
  workouts_title: 'Séances d’entraînement',
  workouts_subtitle: 'Besoin de plus de conseils?',
  workouts_placeholder: 'Rechercher des séances',
  workouts_duration: 'Durée',
  recipes_title: 'Vidéos de recettes',
  recipes_subtitle:
    'Pour de délicieuses nouvelles recettes chaque mois, ainsi que des plans de menus, des listes de courses et l’accès à une communauté de soutien exclusive, inscrivez-vous à',
  recipes_link: ' Nutrition+.',
  recipes_linkURL:
    'https://www.teambeachbody.com/shop/fr/d/NutritionPlusDigitalStreaming?locale=fr_FR&destPage=d/NutritionPlusDigitalStreaming&ICID=TBB_HERO_SHOPNOW_NUTPLUS',
  recipes_definitions_title: 'Définitions des “Convient aux”',
  recipes_definitions_paleo_friendly_title: 'Respectueux du Régime Paléo',
  recipes_definitions_paleo_friendly_description:
    'Ces recettes ne contiennent que des viandes, des poissons, des œufs, des fruits, des légumes, des noix, des graines et des huiles et matières graisses saines. Elles excluent les graines, les légumineuses, les produits laitiers, les sucres raffinés, les aliments transformés et d’autres ingrédients que, par le passé, l’homme ne pouvait obtenir sans chasser ni cueillir.',
  recipes_definitions_gluten_free_title: 'Sans Gluten',
  recipes_definitions_gluten_free_description:
    'Ces recettes sont conçues pour être sans gluten et n’en contiennent pas. Si vous suivez un régime sans gluten, assurez-vous de toujours vérifier la composition des ingrédients. Certains aliments sont souvent préparés dans des usines traitant également du blé et d’autres céréales.',
  recipes_definitions_vegetarian_title: 'Végétarien',
  recipes_definitions_vegetarian_description:
    'Ces recettes sont ovo-lacto-végétariennes et ne contiennent ni volaille, ni viande, ni poisson. Veuillez lire attentivement la composition sur l’étiquette de chaque produit pour vous en assurer.',
  recipes_definitions_vegan_title: 'Végan',
  recipes_definitions_vegan_description:
    'Ces recettes ne contiennent aucun produit d’origine animal. Veuillez lire attentivement la composition sur l’étiquette de chaque produit pour vous en assurer.',
  recipes_definitions_high_fiber_title: 'Teneur élevée en Fibres',
  recipes_definitions_high_fiber_description:
    'Ces recettes contiennent au moins 20 % de la valeur quotidienne recommandée en fibres.',
  recipes_definitions_no_dairy_title: 'Sans Produit Laitier',
  recipes_definitions_no_dairy_description: 'Ces recettes ne contiennent aucun produit laitier.',
  recipes_definitions_timed_nutrition_title: 'Chrononutrition',
  recipes_definitions_timed_nutrition_description:
    'Les recettes affichant l’icône [CN] sont conçues pour s’adapter au profil de la Chrononutrition d’Ultimate Portion Fix (UPF). Les équivalences des récipients pour cette recette correspondent ou consistent en un sous-ensemble de récipients qui correspond à au moins un des repas de la Chrononutrition d’UPF. N’oubliez pas de vérifier votre plan spécifique pour vous assurer qu’il fonctionne avec vos nombres de récipients.',
  recipes_definitions_high_protein_title: 'Riche en Protéines',
  recipes_definitions_high_protein_description:
    'Ces recettes contiennent au moins 20 % de la valeur quotidienne recommandée en protéines.',
  recipes_definitions_quick_easy_title: 'Rapide et Facile',
  recipes_definitions_quick_easy_description:
    'Ces recettes sont conçues pour être réalisées en 15 minutes ou moins et ne demandent que peu ou pas de cuisson.',
  meal: 'Repas',
  dish: 'Plat',
  mainIngredient: 'Ingrédient principal',
  recipes_noResultsDescription:
    'Essayez de bousculer vos critères de recherche et trouvez-vous quelque chose à manger.',
  recipes_noResultsTitle: 'Envie de résultats?',
  worksWellFor: 'Convient aux',
  containers: 'Récipients',
  programsMaterials_title: 'Ressources du programme',
  other_title: 'Autre',
  faq: 'https://faq.beachbody.fr/app/answers/list/kw/{{query}}/search/1',
  faq_title: 'Recherchez ‘{{query}}’ dans nos FAQs',
  faq_cancel: 'Annuler',
  faq_cancelAccout: 'Annuler le compte',
  [EN_CA]: 'Canada (English)',
  [FR_CA]: 'Canada (Français)',
  [FR_FR_LOCALE]: 'France (Français)',
  [EN_US]: 'U.S.A (English)',
  [ES_US]: 'U.S.A (Español)',
  [EN_GB]: 'United Kingdom (English)',
  countryAndLanguage: 'Pays et langue',
  localeChangeNote: 'Veuillez noter que les articles seront supprimés de votre panier.',
  currentShopIn: 'Nous voyons que vous magasinez au(x)/en:',
  newShopIn: 'Voulez-vous magasiner au(x)/en',
  yes: 'Oui',
  no: 'Non',
  ok: 'Ok',
  countryAndLanguageUpdated: 'Pays et langue mis à jour',
  viewOnlyText:
    'Vous entrez dans Team Beachbody <image /> {{locale}} en mode affichage uniquement. Si vous souhaitez modifier les paramètres de votre profil et faire des achats dans ce pays, veuillez communiquer avec le Service des relations avec les coachs',
  others: 'Autre',
  signUp_redirectModal_text:
    'Inscrivez-vous à Beachbody On Demand®, nous devons vous envoyer dans notre boutique Team Beachbody. Procéder?',
  signUp_redirectModal_freeRegText:
    'Créer un compte Inscrivez-vous pour obtenir un compte gratuit. Aucune carte de crédit n’est requise',
  signUp_redirectModal_button_yes: 'Oui',
  signUp_redirectModal_button_no: 'Non',
  signUp_redirectModal_shopPath: '/shop/fr{{- extraPath}}/bod',
  signUp_unavailableModal_text: 'We are sorry this program is currently unavailable.',
  signUp_unavailableModal_button_close: 'Close',
  search_currentRefinements_rangeMins: '{{min}}-{{max}} Mins',
  search_rangeSlider_summary: '{{min}} - {{max}} min',
  search_moreResults: 'Plus',
  your_coach: 'Votre partenaire BODi, {{firstName}} {{lastName}}',
  search_exit: 'Recherche de Sortie',
  top_categories: 'Catégories Supérieures',
  withTrainer: 'avec {{trainer}}',
  rating_title: 'Notez votre expérience!',
  rating_placeholder: 'Dites-nous en plus sur votre expérience',
  rating_submit: 'Envoyer',
  rating_skip: 'Passer',
  rating_howWasIt: 'Comment c’était ?',
  product_card_modal_buy_now: 'acheter',
  currency: '{{price}} €',
  workout_violator_spanish: 'SP',
  workout_violator_french: 'FR',
  workouts_all_levels: 'Tous niveaux',
};
