import styled from 'styled-components';
import { Body6, BodyMed } from '../../../../shared/typography';

export const AvatarIcon = styled.img`
  width: 36px;
  height: 36px;
  border-radius: 50%;
`;

export const UserInfoContainer = styled.div`
  display: flex;
`;

export const ProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 16px;
`;

export const Profile = styled(Body6)`
  color: ${({ theme }) => theme.medGray};
  text-transform: uppercase;
  margin: 0;
`;

export const UserName = styled(BodyMed)`
  margin: 0;
`;
