import { Record } from 'immutable';
import { parseUrl, stringify } from 'query-string';
import root from 'window-or-global';
import isEmpty from 'lodash/isEmpty';
import { DefaultTheme } from 'styled-components';
import { IMenuItem, MenuItemTypes } from '../interfaces/IMenuItem';
import { IModelStatic } from '../interfaces/IModel';
import AppContext, { IAppContext, IAppContextModel } from './AppContext';
import { IRawGroup } from './Group';
import Placeholder, { IPlaceholderModel, IRawPlaceholder } from './Placeholder';
import { loadFromConfig } from '../utils/configLoader';
import { theme } from '../styles/theme';
import { CTA_FONT_WEIGHT_DEFAULT } from '../enums/styles';

const BOD_GROUP_URL = loadFromConfig('BOD_GROUP_URL');
const PERSISTENT_URL_PARAMS = loadFromConfig('PERSISTENT_URL_PARAMS');

const contentfulDomains = new Map(Object.entries(loadFromConfig('CONTENFUL_DOMAINS')));
const appDomains = new Map(Object.entries(loadFromConfig('APP_DOMAIN_LIST')));
export const GROUPS_ITEM_NAME = 'Header - Item - BODgroups';

export enum LinkTypes {
  internal = 'Internal',
  external = 'External',
}

export enum LinkLocations {
  sameWindow = 'Same Window',
  newWindow = 'New Window',
  newTab = 'New Tab',
}

export enum Domain {
  bod = 'BeachbodyOnDemand.com',
  beachBody = 'Beachbody.com',
  coachOffice = 'CoachOffice',
  teamBeachBody = 'TeamBeachbody.com',
  shareACart = 'ShareACart',
}

export enum ItemTypes {
  buttonPrimary = 'Button - Primary',
  buttonSecondary = 'Button - Secondary',
  featuredLink = 'Featured Link',
  placeholder = 'Placeholder',
  default = 'default',
  subItem = 'subItem',
}

export interface IItem extends IMenuItem {
  id: string;
  itemName: string;
  anchorText: string;
  url: string;
  domain?: string;
  path: string;
  linkLocation?: LinkLocations;
  itemType: ItemTypes;
  linkType: LinkTypes;
  placeholder?: IPlaceholderModel;
  appContextList: Array<IAppContextModel>;
  parsedLink: string;
  menuImageUrl: string;
  textColor?: string;
  fontWeight?: number;
}

export interface IRawItem {
  id: string;
  itemName: string;
  anchorText: string;
  url: string;
  domain?: string;
  path: string;
  itemType?: ItemTypes;
  appContextList: Array<IAppContext>;
  locale: string;
  menuImageUrl: string;
  placeholder?: IRawPlaceholder;
  linkType?: LinkTypes;
  linkLocation?: LinkLocations;
  textColor?: string;
  fontWeight?: number;
  __typename?: string;
}

export interface IItemModel extends Record<IItem>, IItem {
  getIsActive(): boolean;
  getActiveCategory(subNavCategory: Array<string>): string | undefined;
}

export type IItemComposed = IItem;
interface ITheme extends DefaultTheme {
  darkGray: string;
}

const defaultProps = {
  id: '',
  anchorText: '',
  itemName: '',
  url: '',
  path: '',
  domain: undefined,
  newWindow: false,
  itemType: ItemTypes.default,
  appContextList: [],
  locale: '',
  linkType: LinkTypes.internal,
  linkLocation: LinkLocations.sameWindow,
  parsedLink: '',
  menuImageUrl: '',
  placeholder: undefined,
  textColor: (theme as ITheme).darkGray,
  fontWeight: CTA_FONT_WEIGHT_DEFAULT,
  __typename: MenuItemTypes.item,
};

interface IITemModelStatic extends IModelStatic<IItemModel, IRawItem> {
  createFromRawGroup(group: IRawGroup): IItemModel;
}

const Item: IITemModelStatic = class
  extends Record<IItem>(defaultProps, 'Item')
  implements IItemModel {
  static createFromRaw(rawItem?: IRawItem): IItemModel {
    const {
      appContextList: rawAppContextList = [],
      placeholder: rawPlaceholder,
      itemType: rawItemType,
      linkType,
      domain,
      path,
      url,
      linkLocation: rawLinkLocation,
    } = rawItem || {};

    const appContextList = rawAppContextList.map((appContext) =>
      AppContext.createFromRaw(appContext),
    );

    const placeholder = rawPlaceholder ? Placeholder.createFromRaw(rawPlaceholder) : undefined;
    const itemType = rawItemType || ItemTypes.default;

    const domainAddress = appDomains.get(contentfulDomains.get(domain || '') || '');

    const parsedLink = linkType === LinkTypes.internal && domain ? `${domainAddress}${path}` : url;
    const linkLocation = rawLinkLocation || LinkLocations.sameWindow;

    let parsedLinkParams = '';
    if (root.window && root.location) {
      const { query: browserParams } = parseUrl(location.search || '');

      const { query: queryParams, url: parsedLinkUrl } = parseUrl(parsedLink || '');

      const hasParams = parsedLink?.includes('?') || !isEmpty(browserParams);

      const filteredParams = Object.entries(browserParams).reduce(
        (actual, [key, value]) =>
          PERSISTENT_URL_PARAMS.includes(key.toLowerCase()) ? { ...actual, [key]: value } : actual,
        {},
      );

      parsedLinkParams =
        parsedLinkUrl && hasParams
          ? `${parsedLinkUrl}?${stringify({ ...filteredParams, ...queryParams })}`
          : parsedLinkUrl;
    }

    return new this({
      ...(rawItem || {}),
      appContextList,
      placeholder,
      itemType,
      parsedLink: parsedLinkParams || parsedLink,
      domain: domainAddress,
      linkLocation,
    });
  }

  static createFromRawGroup({ name, id }: IRawGroup): IItemModel {
    return Item.createFromRaw({
      anchorText: name,
      itemName: `${GROUPS_ITEM_NAME} - ${name}`,
      linkType: LinkTypes.internal,
      domain: Domain.bod,
      path: BOD_GROUP_URL.replace('{id}', id),
      itemType: ItemTypes.subItem,
    } as IRawItem);
  }

  getActiveCategory(subNavCategory: Array<string>): string | undefined {
    const { domain } = this;
    const currentDomain = `${location.protocol}//${location.host}`;

    return subNavCategory.find(
      (category) => new RegExp(category).test(location.pathname) && domain === currentDomain,
    );
  }

  getIsActive(): boolean {
    const { parsedLink } = this;

    try {
      const parsedLinkURL = new URL(parsedLink);
      const baseLocation = `${location.protocol}//${location.host}${location.pathname}`;
      const cleanedParsedLink = `${parsedLinkURL.origin}${parsedLinkURL.pathname}`;

      return cleanedParsedLink === baseLocation;
    } catch (e) {
      return false;
    }
  }
};

export default Item;
