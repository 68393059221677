import styled from 'styled-components';
import { ITypography } from '../../interfaces/typography';

export const Body5Template: ITypography = {
  fontSize: '10px',
  fontWeight: '700',
  lineHeight: '1.6em',
  letterSpacing: '0.025em',
};

const { fontSize, fontWeight, lineHeight, letterSpacing } = Body5Template;

const Body5 = styled.p`
  font-size: ${fontSize};
  font-weight: ${fontWeight};
  line-height: ${lineHeight};
  letter-spacing: ${letterSpacing};
`;

export default Body5;
