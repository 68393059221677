import styled, { keyframes } from 'styled-components';
import { Body2, BodyMed, Headline3dm } from '../../shared/typography';

export const Overlay = styled.div`
  background-color: rgba(0, 0, 0, 0.8);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 1;
  padding: 30px;
  z-index: 5000;
  position: fixed;
  overflow-x: hidden;
  overflow-y: auto;
  align-items: center;
  justify-content: center;
`;

const expand = keyframes`
  0% {
    transform: translate3d(-340px, 0, 0);
    opacity: 0;
    }
  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
`;

export const MobileNav = styled.nav`
  background: ${({ theme }) => theme.pearlGray};
  position: absolute;
  top: 0;
  left: 0;
  width: 340px;
  height: 100%;
  transform: ${({ isOpen }) => (isOpen ? 'translate3d(0, 0, 0)' : 'translate3d(-340px, 0, 0)')};
  transition: transform 0.35s;
  z-index: 10;
  animation: ${expand} 0.5s ease-in-out;
`;

export const NavContainer = styled.ul`
  background: ${({ theme }) => theme.whiteBG};
  list-style: none;
  margin: 0;
  padding: 0;
`;

export const NavItem = styled(BodyMed).attrs({ as: 'li' })`
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.lighterGray};
  display: flex;
  justify-content: ${({ shouldNotJustify }) => (shouldNotJustify ? 'initial' : 'space-between')};
  background: ${({ isSubItem, theme }) => (isSubItem ? theme.pearlGray : theme.whiteBG)};
  padding: ${({ isSubItem }) => (isSubItem ? '9px 24px 10px 42px' : '9px 24px 10px')};
  font-size: ${({ isSubItem }) => (isSubItem ? '14px' : '16px')};
`;

export const SubNavItem = styled(Body2).attrs({ as: 'div' })`
  margin: 0;
`;

export const ActiveMenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 117px;
  background-color: ${({ theme }) => theme.whiteBG};
  border-bottom: 1px solid ${({ theme }) => theme.lighterGray};
`;

export const ActiveMenu = styled(Headline3dm)`
  font-weight: bold;
  margin: 0;
  padding: 24px;
`;

export const GoBack = styled.div`
  display: flex;
  padding: 20px 24px 0;
`;

export const MainMenu = styled(BodyMed)`
  margin: 0 16px 0;
`;

export const NavItemText = styled.span``;
