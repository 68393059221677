import { gql } from '@apollo/client';

export default gql`
  query GetTranslationsBySlug($slugs: [String]!) {
    getTranslationsBySlug(slugs: $slugs) {
      meta_box {
        translationGroups {
          title
          translations {
            key
            value
          }
        }
      }
    }
  }
`;
