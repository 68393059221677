import { IEvent } from '../models/Event';

export interface IEventModule<T> {
  dispatchEvent: (eventName: string, params?: T) => void;
  dispatchEventWithResponse: (eventName: string, params?: T) => Promise<never>;
}

export const getEventHandler = <T>(events: Array<IEvent>): IEventModule<T> => {
  return {
    dispatchEvent: (eventName: string, params?: T) => {
      events
        .filter(({ eventName: targetEventName }) => targetEventName === eventName)
        .map((event: IEvent) => event.callBack(params));
    },
    dispatchEventWithResponse: async (eventName: string, params?: T) => {
      const event = events.find(({ eventName: targetEventName }) => targetEventName === eventName);

      return (await event?.callBack(params)) as never;
    },
  };
};
