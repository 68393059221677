import { createSelector } from 'reselect';
import orderBy from 'lodash/orderBy';
import { IHit, IRecentSearch } from '../interfaces/ISearch';
import { AppState } from '../interfaces/appState';
import { ISearchModel } from '../models/Search';
import { mainModuleSelector, ssoModuleSelector, modulesByKeySelector } from './modulesSelectors';
import { HIT_TYPES, PRODUCT_FACETS, SEARCH_TABS } from '../enums/algolia';
import { selectedLocaleSelector } from './localeSelector';
import { SearchRoutes, TBBRoutes } from '../enums/routes';
import { IVideo } from '../interfaces/IVideo';
import { loadFromConfig } from '../utils/configLoader';
import { buildAlgoliaFilter } from '../utils/algolia';
import { AppIdEnum } from '../enums/main';
import { Roles } from '../enums/sso';

const HIDDEN_SHOPPING_CATEGORIES = loadFromConfig('HIDDEN_SHOPPING_CATEGORIES');

const { SEARCH } = SearchRoutes;
const RECENT_SEARCHES_LIMIT = 5;
const {
  ALL,
  PROGRAMS,
  PRODUCT,
  WORKOUTS,
  LESSONS,
  PROGRAM_MATERIALS,
  OTHER,
  RECIPES,
} = SEARCH_TABS;

const { SHOP } = TBBRoutes;
export const defaultIndexOrder = [RECIPES, LESSONS, PROGRAM_MATERIALS, OTHER];

const searchSelector = (state: AppState): ISearchModel =>
  modulesByKeySelector(state, 'search') as ISearchModel;

export const recentSearchesSelector = createSelector(
  searchSelector,
  ({ recentSearches }): Array<IRecentSearch> =>
    orderBy(recentSearches, ['popularity'], 'desc').slice(0, RECENT_SEARCHES_LIMIT),
);

export const topResultSelector = createSelector(
  searchSelector,
  ({ topResult }): IHit | undefined => topResult,
);

export const isTopProductResultSelector = createSelector(
  searchSelector,
  ({ topResult }): boolean => topResult?.__hitType === HIT_TYPES.PRODUCT,
);

export const indicesSelector = createSelector(
  searchSelector,
  (): Array<string> => {
    return [ALL, PRODUCT, PROGRAMS, WORKOUTS, ...defaultIndexOrder];
  },
);

export const isUnavailableSelector = createSelector(
  searchSelector,
  ({ isUnavailable }): boolean => !!isUnavailable,
);

export const isFreeRegSelector = createSelector(
  searchSelector,
  ({ isFreeReg }): boolean => !!isFreeReg,
);

export const videoDataSelector = createSelector(
  searchSelector,
  ({ videoData }): IVideo | undefined => videoData,
);

export const searchPathSelector = createSelector(
  mainModuleSelector,
  selectedLocaleSelector,
  ({ appId }, selectedLocale): string => {
    const countryCode = selectedLocale.split('_')[1];
    return appId === AppIdEnum.tbb ? `${SHOP}/${countryCode}${SEARCH}` : SEARCH;
  },
);

export const productFilterSelector = createSelector(
  ssoModuleSelector,
  ({ userInfo: { roleList = [] } = {} }) => {
    // Unregistered users should be considered as registered
    if (roleList.length === 0) roleList = [Roles.registeredUser];

    const rolesFilter = roleList
      .map((role) => `${PRODUCT_FACETS.CUSTOMER_ROLES}:${role}`)
      .join(' OR ');

    const hiddenCategoriesFilter = HIDDEN_SHOPPING_CATEGORIES.map(
      (category) => `NOT ${PRODUCT_FACETS.CATEGORY}:'${category}'`,
    ).join(' AND ');

    return buildAlgoliaFilter(rolesFilter, hiddenCategoriesFilter);
  },
);
