/** @jsx h */
import { FunctionalComponent, h } from 'preact';
import { SupportedLanguages } from '../../enums/locale';
import { IItemModel } from '../../models/Item';
import { getSupportedLanguageFromPlaceholder } from '../../utils/locale';
import { LocaleIcon, LocaleItem, LocaleText } from './dropdownLocaleStyled';
import {
  E2E,
  getDataTestIdSuffix,
  getTestID,
  useE2ETesting,
} from '../../providers/e2eTestingProvider';

export interface ILocaleProps {
  selectedLocale: SupportedLanguages;
  item: IItemModel;
  updateLocale(newLocale: SupportedLanguages): void;
}

const MenuItemLocale: FunctionalComponent<ILocaleProps> = ({
  selectedLocale,
  item,
  updateLocale,
}) => {
  const { testIdPrefix } = useE2ETesting();
  const testIdValue = getTestID(
    testIdPrefix,
    E2E.component.link,
    getDataTestIdSuffix(item.itemName),
  );
  const { anchorText, menuImageUrl, placeholder } = item;

  const itemLocale = getSupportedLanguageFromPlaceholder(placeholder);
  return (
    <LocaleItem
      data-testid={testIdValue}
      item={item}
      onClick={() => itemLocale !== selectedLocale && updateLocale(itemLocale)}
    >
      <LocaleIcon alt={anchorText} src={menuImageUrl} />
      <LocaleText
        isSelected={itemLocale == selectedLocale}
        dangerouslySetInnerHTML={{ __html: anchorText }}
      />
    </LocaleItem>
  );
};

export default MenuItemLocale;
