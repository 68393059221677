import styled from 'styled-components';
import Button1Fill from '../button1Fill';

const Button2Fill = styled(Button1Fill)`
  padding: 0 20px;
  height: 2.5em;
  font-size: 12px;
`;

export default Button2Fill;
