import {
  NAVIGATION_REDIRECT,
  NAVIGATION_REPLACE,
  INavigationRedirectActionCreator,
  INavigationReplaceActionCreator,
} from './interfaces/INavigationAction';
import { isCustomRoutingEnabled, appIdSelector } from '../selectors/modulesSelectors';
import { redirect, replace, shouldUseCustomRouting, createNavigationEvent } from '../utils/history';

export const navigationRedirectActionCreator: INavigationRedirectActionCreator = (
  url,
  isSameWindow = true,
) => (dispatch, getState) => {
  const isCustomNavigationEnabled = isCustomRoutingEnabled(getState());
  const appId = appIdSelector(getState());
  const isUsingCustomRoutingWithApp = shouldUseCustomRouting(
    isCustomNavigationEnabled as boolean,
    appId,
  );

  if (!isUsingCustomRoutingWithApp) {
    redirect(url, isSameWindow);
  }

  return dispatch({
    type: NAVIGATION_REDIRECT,
    event: isUsingCustomRoutingWithApp ? createNavigationEvent(url, isSameWindow) : {},
  });
};

export const navigationReplaceActionCreator: INavigationReplaceActionCreator = (
  url,
  state,
  isLocaleNavigation = false,
) => (dispatch, getState) => {
  const isCustomNavigationEnabled = isCustomRoutingEnabled(getState());
  const appId = appIdSelector(getState());
  const isUsingCustomRoutingWithApp = shouldUseCustomRouting(
    isCustomNavigationEnabled as boolean,
    appId,
  );

  if (!isUsingCustomRoutingWithApp) {
    replace(url, state);
  }

  return dispatch({
    type: NAVIGATION_REPLACE,
    event: isUsingCustomRoutingWithApp ? createNavigationEvent(url, true, isLocaleNavigation) : {},
  });
};
