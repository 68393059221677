/** @jsx h */
import { Fragment, FunctionalComponent, h } from 'preact';
import { connect } from 'react-redux';
import { NavItem } from '../../../../containers/mobileNavigation/mobileNavigationStyled';
import { AppState } from '../../../../interfaces/appState';
import { IItemModel } from '../../../../models/Item';
import { userGroupsSelector } from '../../../../selectors/groupsSelectors';
import DropdownItem from '../../../../components/dropdownItem';

export interface IGroupsProps {
  groups: Array<IItemModel>;
  shouldWrapNavItem: boolean;
}

export const Groups: FunctionalComponent<IGroupsProps> = ({
  groups,
  shouldWrapNavItem = false,
}) => {
  const navItemWrapper = (item: IItemModel) => {
    if (shouldWrapNavItem) {
      return (
        <NavItem isSubItem>
          <DropdownItem key={item.id} item={item} />
        </NavItem>
      );
    }

    return <DropdownItem key={item.id} item={item} />;
  };
  return <Fragment>{groups.map((item) => navItemWrapper(item))}</Fragment>;
};

const mapStateToProps = (state: AppState) => ({
  groups: userGroupsSelector(state),
});

export default connect(mapStateToProps)(Groups);
