import { Record } from 'immutable';
import intersectionBy from 'lodash/intersectionBy';
import { LoggedStatus } from '../enums/sso';
import CoachInfo from './CoachInfo';
import UserInfo from './UserInfo';
import SsoAccount from './SsoAccount';
import {
  IConfigSso,
  IRawEntitlements,
  IRawSso,
  ISso,
  ISsoModel,
  ISsoStatic,
} from '../interfaces/ISso';
import { loadFromConfig } from '../utils/configLoader';

const DIRECT_RESPONSE_MARKETING_REGIONS = loadFromConfig('DIRECT_RESPONSE_MARKETING_REGIONS');

const defaultProps = {
  isEnabled: false,
  isLoggingOut: false,
  userInfo: undefined,
  coachInfo: undefined,
  accountList: undefined,
  userLoginStatus: undefined,
  hasGroups: false,
  accessToken: undefined,
  idToken: undefined,
};

const Sso: ISsoStatic = class extends Record<ISso>(defaultProps, 'Sso') implements ISsoModel {
  static createFromRaw({
    userInfo,
    coachInfo,
    userLoginStatus,
    isEnabled,
    accessToken,
    accountList,
    idToken,
    isLoggingOut,
  }: IConfigSso = {}): ISsoModel {
    return new this({
      isEnabled,
      userInfo: (!!userInfo && UserInfo.createFromRaw(userInfo)) || undefined,
      coachInfo: (!!coachInfo && CoachInfo.createFromRaw(coachInfo)) || undefined,
      accountList: (!!accountList && SsoAccount.createFromRawArray(accountList)) || undefined,
      userLoginStatus,
      accessToken,
      idToken,
      isLoggingOut,
    });
  }

  static createUserEntitlementsFromRaw({
    programList,
    productList,
  }: IRawEntitlements): Array<string> {
    const productEntitlementList = productList.reduce<Array<string>>(
      (list, { GROUP_LIST: entitlementName, ACTIVE_CONTINUITY }) =>
        ACTIVE_CONTINUITY === 'Y' ? list.concat([entitlementName]) : list,
      [],
    );

    const programEntitlementList = programList.reduce<Array<string>>(
      (list, { ENTITLEMENT_NAME: entitlementName, ENABLED_FLAG }) =>
        ENABLED_FLAG === 'Y' ? list.concat([entitlementName]) : list,
      [],
    );

    return productEntitlementList.concat(programEntitlementList);
  }

  serialize(): IRawSso {
    const { userInfo, coachInfo, userLoginStatus, accountList, isLoggingOut } = this;

    return {
      userInfo: (userInfo && userInfo.serialize()) || undefined,
      coachInfo: (coachInfo && coachInfo.serialize()) || undefined,
      accountList: (accountList && accountList.map((a) => a.serialize())) || undefined,
      userLoginStatus,
      isLoggingOut,
    };
  }
  toConfig(): IConfigSso {
    const {
      userInfo,
      coachInfo,
      userLoginStatus,
      accessToken,
      isEnabled,
      accountList,
      idToken,
      isLoggingOut,
    } = this;

    return {
      userLoginStatus,
      accessToken,
      idToken,
      isEnabled,
      userInfo: userInfo?.toObject(),
      coachInfo: coachInfo?.toObject(),
      accountList: accountList?.map((a) => a.toObject()),
      isLoggingOut,
    };
  }

  mergeFromStorage({ userInfo, coachInfo, userLoginStatus, hasGroups }: ISsoModel): ISsoModel {
    const parsedUserInfo =
      (this.userInfo && userInfo && this.userInfo?.merge(userInfo)) ||
      userInfo ||
      this.userInfo ||
      undefined;

    const parsedCoachInfo =
      (this.coachInfo && coachInfo && this.coachInfo?.merge(coachInfo)) ||
      coachInfo ||
      this.coachInfo ||
      undefined;

    const parsedUserLoginStatus = userLoginStatus || this.userLoginStatus;

    return this.merge({
      hasGroups,
      userLoginStatus: parsedUserLoginStatus,
      userInfo: parsedUserLoginStatus === LoggedStatus.loggedIn ? parsedUserInfo : undefined,
      coachInfo: parsedCoachInfo,
      idToken: this.idToken,
      accessToken: this.accessToken,
    });
  }

  mergeFromConfig({
    userInfo,
    coachInfo,
    isEnabled,
    userLoginStatus,
    accessToken,
    hasGroups,
    accountList: accountList,
    idToken,
  }: IConfigSso): ISsoModel {
    const parsedUserInfo =
      (this.userInfo && userInfo && this.userInfo?.mergeFromConfig(userInfo)) ||
      (userInfo && UserInfo.createFromRaw(userInfo)) ||
      this.userInfo ||
      undefined;

    const parsedCoachInfo =
      (this.coachInfo && coachInfo && this.coachInfo?.mergeFromConfig(coachInfo)) ||
      (coachInfo && CoachInfo.createFromRaw(coachInfo)) ||
      this.coachInfo ||
      undefined;

    const parsedAccounts =
      (accountList && SsoAccount.createFromRawArray(accountList)) || this.accountList || undefined;

    const parsedUserLoginStatus = userLoginStatus || this.userLoginStatus;

    return this.merge({
      isEnabled,
      hasGroups,
      userLoginStatus: parsedUserLoginStatus,
      userInfo: parsedUserLoginStatus === LoggedStatus.loggedIn ? parsedUserInfo : undefined,
      coachInfo: parsedCoachInfo,
      accountList: parsedAccounts,
      accessToken: accessToken || this.accessToken,
      idToken: idToken || this.idToken,
    });
  }

  // business logic
  validateUserRole(rules: string[]): boolean {
    const { roleList = [] } = this.userInfo || {};

    return (
      !!this.isEnabled &&
      !!intersectionBy<string>(rules, roleList, (role) => role.toLowerCase()).length
    );
  }
  validateMissingUserRole(rules: string[]): boolean {
    const { roleList = [] } = this.userInfo || {};

    return (
      !!this.isEnabled &&
      !intersectionBy<string>(rules, roleList, (role) => role.toLowerCase()).length
    );
  }
  validateEntitlementList(rules: string[]): boolean {
    const { entitlementList = [] } = this.userInfo || {};

    return (
      !!this.isEnabled &&
      !!intersectionBy<string>(rules, entitlementList, (entitlement) => entitlement.toLowerCase())
        .length
    );
  }
  validateUserLoggedStatus(rules: string[]): boolean {
    const { userLoginStatus = LoggedStatus.loggedOut } = this;
    return rules.includes(userLoginStatus);
  }
  validateMissingEntitlement(rules: string[]): boolean {
    const { entitlementList = [] } = this.userInfo || {};

    return (
      !!this.isEnabled &&
      !intersectionBy<string>(rules, entitlementList, (entitlement) => entitlement.toLowerCase())
        .length
    );
  }
  validateSupportedInDirectResponseMarketingRegion(rules: string[], geolocation: string): boolean {
    const formattedDRMarketingRegions = DIRECT_RESPONSE_MARKETING_REGIONS.map((region) =>
      region.toUpperCase(),
    );
    const country = (this.userLoginStatus === LoggedStatus.loggedIn
      ? this.userInfo?.primaryCountry || ''
      : geolocation
    ).toUpperCase();

    const isEnabled = !!this.isEnabled;
    const isDRMarketingRegion = formattedDRMarketingRegions.includes(country);
    const isRegularRegion = rules.includes('false') && !isDRMarketingRegion;
    const isExclusiveForDRMarketingRegions = rules.includes('true') && isDRMarketingRegion;

    return isEnabled && (isRegularRegion || isExclusiveForDRMarketingRegions);
  }
};

export default Sso;
