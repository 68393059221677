import styled, { css, DefaultTheme } from 'styled-components';
import { resolveRelativeUrl } from '../../utils/device';
import { Body2, Body4, Headline4dm, Headline5 } from '../../shared/typography';
import { Headline3dmTemplate } from '../../shared/typography/headline3dm';
import { Media } from '../../styles/media';
import FAQ from '../../assets/faq.svg';

const { desktopUp, tabletUp } = Media;

export const ResultsContainer = styled.div`
  align-items: center;
  display: flex;
  margin: 0 0 5px;
`;

export const ResultCount = styled(Body2)`
  margin: 0 0 0 5px;
`;

export const SuggestionContainer = styled.div`
  background-color: ${({ theme }) => theme.pearlGray};
  border: 1px solid ${({ theme }) => theme.lighterGray};
  border-bottom-left-radius: 17px;
  border-bottom-right-radius: 17px;
  display: flex;
  flex-direction: column;
  height: 100%;
  left: 0;
  position: fixed;
  top: 50px;
  transform: translateX(0px);
  transition: opacity 0.8s ease, transform 0.8s ease;
  width: 100%;
  z-index: 300;

  ${tabletUp`
    flex-direction: row;
    height: 100vh;
    position: absolute;
  `};

  ${desktopUp`
    ${css<DefaultTheme>`
      height: 258px;
      left: 0;
      margin-left: auto;
      margin-right: auto;
      max-width: 1320px;
      position: absolute;
      right: 0;
      top: 64px;
    `}
  `};

  & ul {
    padding: 0;
  }
`;

export const Section = styled.div`
  display: 'block';
  margin: 0 25px;
  padding: 16px 0;
  width: calc(100% - 50px);

  ${tabletUp`
    ${css<{ isFirstSection: boolean }>`
      margin: 0;
      padding: 25px 40px 0;
      width: 50%;
      border-right: ${({ theme, isFirstSection }) =>
        !isFirstSection && `1px solid ${theme.lighterGray}`};
    `};
  `};

  ${desktopUp`
    ${css<{ isFirstSection: boolean }>`
      padding-left: ${({ isFirstSection }) => isFirstSection && '188px'};
      > ul {
        padding-left: 148px;
      }
    `};
  `};
`;

export const LeftSection = styled(Section)`
  border-bottom: ${({ isContainingResults, theme }) =>
    isContainingResults && `1px solid ${theme.lighterGray}`};
  display: block;
  padding-top: 16px;

  ${tabletUp`
    ${css<{ isRecentSearch: boolean }>`
      border-bottom: 0;
      border-right: ${({ theme, isRecentSearch }) =>
        !isRecentSearch && `1px solid ${theme.lighterGray}`};
      padding-top: 24px;
    `};
  `};

  ${desktopUp`
    ${css<{ isRecentSearch: boolean }>`
      padding-left: ${({ isRecentSearch }) => isRecentSearch && '188px'};

      > ul {
        padding-left: 148px;
      }
    `};
  `};
`;

export const NoResults = styled(Body2)`
  color: ${({ theme }) => theme.disabledGray};

  ${tabletUp`
    padding-left: 148px;
  `};
`;

export const Title = styled(Headline4dm)`
  font-size: ${Headline3dmTemplate.fontSize};
  margin: 0;
`;

export const HeaderContainer = styled.div`
  align-items: flex-end;
  display: flex;
  padding-bottom: 8px;
`;

export const Clear = styled(Body4)`
  color: ${({ theme }) => theme.oneAppBlue};
  cursor: pointer;
  margin-bottom: 0;
  margin-left: 13px;
`;

export const CategoryTitle = styled(Headline5)`
  font-weight: 900;
  margin: 0;
  padding-bottom: 16px;

  ${tabletUp`
    padding-bottom: 8px;
  `};
`;

export const FAQTitle = styled(Headline5)`
  color: ${({ theme }) => theme.oneAppBlue};
`;

export const FAQImage = styled.img.attrs({ src: `${resolveRelativeUrl(FAQ)}` })`
  margin-top: 50px;

  ${tabletUp`
    margin-top: 0;
  `};
`;

export const FAQSection = styled.div`
  align-items: center;
  display: flex;
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;

export const SpinnerContainer = styled.div`
  background-color: ${({ theme }) => theme.pearlGray};
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 100%;
  border-bottom-left-radius: 17px;
  border-bottom-right-radius: 17px;
`;
