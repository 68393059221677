import styled from 'styled-components';
import { Media } from '../../../styles/media';

const { tabletUp } = Media;

type TextProps = {
  fixedSize: boolean;
};

export const Headline4dmTemplate = {
  fontSize: '16px',
  fontWeight: '900',
  lineHeight: '1.375em',
  letterSpacing: '0.019em',
};

const { fontSize, fontWeight, lineHeight, letterSpacing } = Headline4dmTemplate;

const Headline4dm = styled.h4<TextProps>`
  font-size: ${fontSize};
  font-weight: ${fontWeight};
  line-height: ${lineHeight};
  letter-spacing: ${letterSpacing};
  ${({ fixedSize }) =>
    !fixedSize &&
    tabletUp`
    font-size: 20px;
    line-height: 1.3em;
    letter-spacing: 0.01em;
  `};
`;

export default Headline4dm;
