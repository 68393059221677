import root from 'window-or-global';
import { Breakpoints } from '../enums/breakpoints';

export enum DeviceTypes {
  mobile = 'mobile',
  desktop = 'desktop',
  tablet = 'tablet',
}

export const matchMedia = (breakpoint: Breakpoints): boolean => {
  const matchMedia = root.window?.matchMedia;
  if (matchMedia) {
    const { matches } = matchMedia(`screen and (min-width: ${breakpoint}px)`);
    return matches;
  }

  return true;
};

export const getDeviceType = (): DeviceTypes => {
  const isDesktop = matchMedia(Breakpoints.xlarge);
  const isTablet = matchMedia(Breakpoints.large);

  const deviceType =
    (isDesktop && DeviceTypes.desktop) || (isTablet && DeviceTypes.tablet) || DeviceTypes.mobile;
  return deviceType;
};

export const getRelativePath = (): string =>
  root.document?.currentScript?.getAttribute('src')?.split('/').slice(0, -1).join('/') || '';

export const resolveRelativeUrl = (originalUrl: string, _relativePath?: string) => {
  const relativePath = _relativePath || getRelativePath();

  if (!originalUrl || !relativePath) return originalUrl;

  try {
    const originalUrlParsed = new URL(originalUrl);
    const relativeUrlParsed = new URL(relativePath);
    return `${relativeUrlParsed.origin}${originalUrlParsed.pathname}${originalUrlParsed.search}${originalUrlParsed.hash}`;
  } catch (e) {
    console.warn(e, { originalUrl, relativePath });
    return originalUrl;
  }
};
