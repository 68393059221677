import {
  contryCodeByLanguageCode,
  DEFAULT_LOCALE,
  localeByContryCode,
  localeByLanguageCode,
  SupportedLanguages,
} from '../enums/locale';
import { IItemModel } from '../models/Item';
import { IPlaceholderModel } from '../models/Placeholder';
import { IGeolocationModel } from '../models/Geolocation';

export function isLocaleValid(locale: string): boolean {
  return locale.toUpperCase() in SupportedLanguages;
}

export function getLocaleFromDashedLanguage(language: string): SupportedLanguages {
  const undashedLanguage = language.replace('-', '_');

  if (isLocaleValid(undashedLanguage)) {
    return getSupportedLanguage(undashedLanguage);
  }

  return (
    localeByContryCode[undashedLanguage.slice(-2).toUpperCase()] ||
    localeByLanguageCode[undashedLanguage.slice(0, 2).toUpperCase()]
  );
}

export function getLocaleFromBrowser(): SupportedLanguages | undefined {
  const { language } = navigator;

  if (language.includes('-')) {
    return getLocaleFromDashedLanguage(language);
  }

  // Language code only;
  const countryCode =
    contryCodeByLanguageCode[language.slice(0, 2).toLowerCase()] || language.slice(0, 2);
  return (
    localeByContryCode[countryCode.toUpperCase()] || localeByLanguageCode[countryCode.toUpperCase()]
  );
}

export function getLocaleByGeolocationOrDefault(
  geolocation: IGeolocationModel,
): SupportedLanguages {
  const { country } = geolocation;
  return localeByContryCode[country] || DEFAULT_LOCALE;
}

export function getSupportedLanguage(language: string): SupportedLanguages {
  return SupportedLanguages[language.toUpperCase() as keyof typeof SupportedLanguages];
}

export function getSupportedLanguageFromPlaceholder(
  placeholder: IPlaceholderModel | undefined,
): SupportedLanguages {
  const languageCode = placeholder?.type.toString().split('.')[2].replace('-', '_') || '';
  return getSupportedLanguage(languageCode);
}

export function getSelectedLocaleItemFromList(
  menuItemList: Array<IItemModel>,
  selectedLocale: SupportedLanguages,
): IItemModel | undefined {
  return menuItemList.find(({ placeholder }) => {
    const itemLocale = getSupportedLanguageFromPlaceholder(placeholder);
    return itemLocale === selectedLocale;
  });
}
