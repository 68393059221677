import styled from 'styled-components';
import { Media } from '../../../styles/media';

const { tablet } = Media;

export const Headline5Template = {
  fontSize: '16px',
  fontWeight: 400,
};

const { fontSize, fontWeight } = Headline5Template;

const Headline5 = styled.h5`
  font-size: ${fontSize};
  font-weight: ${fontWeight};
  ${tablet`
    font-size: 14px;
  `};
`;

export const H5StyledNoBottomMargin = styled(Headline5)`
  margin-bottom: 0;
`;

export default Headline5;
