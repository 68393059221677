import { bindActionCreators } from 'redux';
import { useDispatch } from 'react-redux';
import { useMemo } from 'react';
import { INavigationActionCreators } from '../actions/interfaces/INavigationAction';
import {
  navigationRedirectActionCreator,
  navigationReplaceActionCreator,
} from '../actions/navigationActions';

export const useNavigation: () => INavigationActionCreators = () => {
  const dispatch = useDispatch();
  const navigationService = useMemo(
    () =>
      bindActionCreators(
        {
          redirect: navigationRedirectActionCreator,
          replace: navigationReplaceActionCreator,
        },
        dispatch,
      ),
    [dispatch],
  );
  return navigationService;
};

export default useNavigation;
