import styled from 'styled-components';
import { Media } from '../../../styles/media';

const { tablet } = Media;

type TextProps = {
  fixedSize: boolean;
};

const Headline1dm = styled.h1<TextProps>`
  font-size: 28px;
  font-weight: 900;
  line-height: 1.214em;
  letter-spacing: 0.011em;
  ${({ fixedSize }) =>
    !fixedSize &&
    tablet`
    font-size: 44px;
    line-height: 1.182em;
    letter-spacing: 0em;
  `};
`;

export default Headline1dm;
