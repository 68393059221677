import { Record } from 'immutable';
import get from 'lodash/get';
import { MAX_OPACITY_NUM } from '../enums/common';
import { IModelStatic } from '../interfaces/IModel';
import { IRawProgramViolators } from '../interfaces/IProgram';
import { IRawViolator } from '../interfaces/ISearch';

export interface IViolator {
  to: string;
  title: string;
  color: string;
  fillColor: string;
  fillOpacity: string | number;
  strokeColor: string;
  url?: string;
  isTBBViolator?: boolean;
}

export interface IViolatorModel extends Record<IViolator>, IViolator {}
export interface IViolatorModelStatic extends IModelStatic<IViolatorModel, IRawViolator> {
  createBannerFromRawProgram(
    rawProgram: IRawProgramViolators | undefined,
  ): IViolatorModel | undefined;
  createViolatorListFromRawProgram(
    rawProgram: IRawProgramViolators | undefined,
  ): Array<IViolatorModel>;
}

const defaultProps = {
  isTBBViolator: false,
  url: undefined,
  title: '',
  to: '',
  color: '',
  fillColor: '',
  fillOpacity: '',
  strokeColor: '',
};

const Violator: IViolatorModelStatic = class
  extends Record<IViolator>(defaultProps, 'Violator')
  implements IViolatorModel {
  static createFromRaw(rawViolator?: IRawViolator): IViolatorModel {
    const opacityPercent = get(rawViolator, 'violatorFillColorOpacity', 100) as number;
    const hexFillOpacity = Math.round((MAX_OPACITY_NUM * opacityPercent) / 100).toString(16);
    const title = get(rawViolator, 'violatorText', '');

    return new this({
      color: get(rawViolator, 'violatorTextColor', ''),
      fillColor: get(rawViolator, 'violatorFillColor', ''),
      fillOpacity: hexFillOpacity,
      isTBBViolator: get(rawViolator, 'isTBBViolator', false),
      strokeColor: get(rawViolator, 'violatorStrokeColor', ''),
      title,
      url: get(rawViolator, 'url', ''),
    });
  }

  static createBannerFromRawProgram({ boxArtFlag }: IRawProgramViolators | undefined = {}):
    | IViolatorModel
    | undefined {
    return boxArtFlag ? this.createFromRaw(boxArtFlag) : undefined;
  }

  static createViolatorListFromRawProgram({
    boxArtText = [],
  }: IRawProgramViolators | undefined = {}): Array<IViolatorModel> {
    return boxArtText.map((rawViolator) => this.createFromRaw(rawViolator));
  }
};

export default Violator;
