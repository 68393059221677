import { IWorkoutModel } from '../models/Workout';
import { useSelector } from 'react-redux';
import {
  isProgramMaterialAvailableSelector,
  isVideoContentAvailableSelector,
  isWorkoutUnavailableSelector,
  purchaseUrlByAppSelector,
} from '../selectors/lockContentSelectors';
import { AppState } from '../interfaces/appState';

export const NOT_AVAIL = 'entitled-not-avail-for-unlock';
export interface IUseLockContentProps {
  workout: IWorkoutModel;
}

const useLockContent = ({
  workout,
}: IUseLockContentProps): {
  isVideoAvailable: boolean;
  isProgramMaterialAvailable: boolean;
  purchaseUrl: string | undefined;
  isUnavailable: boolean;
} => {
  const isVideoAvailable = useSelector<AppState, boolean>((state) =>
    isVideoContentAvailableSelector(state, workout),
  );

  const isProgramMaterialAvailable = useSelector<AppState, boolean>((state) =>
    isProgramMaterialAvailableSelector(state, workout),
  );

  const purchaseUrl = useSelector<AppState, string | undefined>((state) =>
    purchaseUrlByAppSelector(state, workout),
  );

  const isUnavailable = useSelector<AppState, boolean>((state) =>
    isWorkoutUnavailableSelector(state, workout),
  );

  return {
    isVideoAvailable,
    isProgramMaterialAvailable,
    isUnavailable,
    purchaseUrl,
  };
};

export default useLockContent;
