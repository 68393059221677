import LegalClient from '../gateways/legal/legalClient';
import Waiver, { IWaiverModel } from '../models/Waiver';
import { SupportedLanguages } from '../enums/locale';

const ERROR = 'Something went wrong while consuming the legal service';

export const getWaiverContent = async (locale: SupportedLanguages): Promise<IWaiverModel> => {
  try {
    return await LegalClient(locale).getWaiverContent();
  } catch (error) {
    console.error(`${ERROR}: GET ${(error as Error)?.message}`);
    return Waiver.createFromRaw({ waiverContent: undefined, requirewaiver: true });
  }
};
