import styled from 'styled-components';

export const SubMenuContainer = styled.ul`
  display: flex;
  flex-direction: column;
  padding: 0;
  padding-left: 16px;
  margin: 0;
  list-style: none;
`;

export const MenuContainer = styled.li`
  padding: 0;
  margin: 0;
  list-style: none;
`;
