/** @jsx h */
import { FunctionalComponent, h } from 'preact';
import { IMenuLocation } from '../../../../models/MenuLocation';
import { MenuOptions } from './topNavAccountStyled';
import TopNavItem from '../../../topNavItem';

export interface ITopNavAccountProps {
  handleOnSearch?: () => void;
  isTablet: boolean;
  isDesktop: boolean;
  menuLocation: IMenuLocation;
}

const TopNavAccount: FunctionalComponent<ITopNavAccountProps> = ({
  handleOnSearch,
  isTablet,
  isDesktop,
  menuLocation: { menuItemList },
}) => (
  <MenuOptions>
    {menuItemList.map((menu) => (
      <TopNavItem
        key={menu.id}
        handleOnSearch={handleOnSearch}
        item={menu}
        isTablet={isTablet}
        isDesktop={isDesktop}
      />
    ))}
  </MenuOptions>
);

export default TopNavAccount;
