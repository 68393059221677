import { Record } from 'immutable';
import { IModelStatic } from '../interfaces/IModel';

export interface IGeolocation {
  country: string;
  ip: string;
}

export interface IRawGeolocation {
  country: string;
  ip: string;
}

export interface IGeolocationModel extends Record<IGeolocation>, IGeolocation {}

const defaultProps = {
  country: '',
  ip: '',
};

const Geolocation: IModelStatic<IGeolocationModel, IRawGeolocation> = class
  extends Record<IGeolocation>(defaultProps, 'GeoLocation')
  implements IGeolocationModel {
  static createFromRaw(rawGeolocation?: IGeolocation): IGeolocationModel {
    return new this({ ...(rawGeolocation || {}) });
  }
};

export default Geolocation;
