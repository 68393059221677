import { h } from 'preact';
import bodLogo from '../../assets/beachbody-ball.svg';
import { resolveRelativeUrl } from '../../utils/device';
import {
  SpinnerContainer,
  LoadingIcon,
  SpinnerOne,
  SpinnerTwo,
  SpinnerThree,
  BodLogo,
} from './videoPlayerStyled';

export interface VideoPlayerSpinnerProps {
  isOpen: boolean;
}

export function VideoPlayerSpinner({ isOpen }: VideoPlayerSpinnerProps): h.JSX.Element | null {
  return isOpen ? (
    <SpinnerContainer>
      <LoadingIcon>
        <SpinnerOne />
        <SpinnerTwo />
        <SpinnerThree />
      </LoadingIcon>
      <BodLogo src={`${resolveRelativeUrl(bodLogo)}`} />
    </SpinnerContainer>
  ) : null;
}
