import { Action, ActionCreatorsMapObject } from 'redux';
import { DeviceTypes } from '../../utils/device';
import { IAction, ThunkResult } from './IAction';
import { IUpdateSsoConfigAction, IUpdateSsoPartialAction } from './ISsoAction';
import {
  IGetTopResultAction,
  IUpdateSearchConfigAction,
  ISetIsUnavailableAction,
  ISetVideoData,
} from './ISearchActions';
import { IUnificationScriptInitialConfig } from '../../interfaces/IUnificationScript';
import { ThunkAction } from 'redux-thunk';
import { AppState } from '../../interfaces/appState';
import { IUpdateLocaleAction } from './ILocaleAction';
import { IUpdateCartAction } from './ICartAction';
import { IModulesStateRecord } from '../../interfaces/IModules';
import { IUserInfo } from '../../models/UserInfo';
import { IUserBase } from '../../interfaces/IUserBase';

const MODULES_PREFIX = 'MODULES';
export const INITIALIZE_MODULES = `${MODULES_PREFIX}/INITIALIZE_MODULES`;
export const INTERNAL_INITIALIZE_MODULES = `${MODULES_PREFIX}/INTERNAL_INITIALIZE_MODULES`;
export const UPDATE_MAIN_MODULE = `${MODULES_PREFIX}/UPDATE_MAIN_MODULE`;
export const UPDATE_ON_FOCUS = `${MODULES_PREFIX}/UPDATE_ON_FOCUS`;
export const UPDATE_DEVICE = `${MODULES_PREFIX}/UPDATE_DEVICE`;
export const UPDATE_ROUTE = `${MODULES_PREFIX}/UPDATE_ROUTE`;
export const UPDATE_IS_DEBUG_ENABLED = `${MODULES_PREFIX}/UPDATE_IS_DEBUG_ENABLED`;
export const UPDATE_VERSION_NAME = `${MODULES_PREFIX}/UPDATE_VERSION_NAME`;
export interface IInternalInitializationAction extends IAction {
  payload: IModulesStateRecord;
}

export interface IInitializeModulesAction extends IAction {
  payload: IModulesStateRecord;
}

export interface IUpdateMainModuleAction extends IAction {
  payload: IModulesStateRecord;
}

export interface IUpdateDeviceAction extends Action<typeof UPDATE_DEVICE> {
  payload: DeviceTypes;
}

export interface IUpdateRouteAction extends Action<typeof UPDATE_ROUTE> {
  payload: string;
}

export interface IUpdateVersionNameAction extends Action<typeof UPDATE_VERSION_NAME> {
  payload: string;
}

export interface IUpdateVersionNameActionCreator {
  (): ThunkResult<IUpdateVersionNameAction>;
}

export interface IInternalInitializationActionCreator {
  (): ThunkAction<Promise<IInternalInitializationAction>, AppState, null, IInitializeModulesAction>;
}
export interface IUpdateIsDebugEnabledAction extends Action<typeof UPDATE_IS_DEBUG_ENABLED> {
  payload: boolean;
}

export interface IInitializeModulesActionCreator {
  (initialConfig: IUnificationScriptInitialConfig): ThunkAction<
    Promise<IInitializeModulesAction>,
    AppState,
    null,
    IInitializeModulesAction
  >;
}

export interface IUpdateMainModuleActionCreator {
  (updatedConfig: IUnificationScriptInitialConfig): ThunkAction<
    IInitializeModulesAction,
    AppState,
    null,
    IInitializeModulesAction
  >;
}

export interface IUpdateOnFocusActionCreator {
  (): ThunkAction<Promise<IInitializeModulesAction>, AppState, null, IInitializeModulesAction>;
}

export interface IModulesActions extends ActionCreatorsMapObject {
  initializeModules(
    updatedConfig: IUnificationScriptInitialConfig,
  ): Promise<IUpdateMainModuleAction>;
  updateMainModule(updatedConfig: IUnificationScriptInitialConfig): IUpdateMainModuleAction;
  updateOnFocus(): Promise<IUpdateMainModuleAction>;
}

export interface IModulesThunkActions extends ActionCreatorsMapObject {
  initializeModules: IInitializeModulesActionCreator;
  updateMainModule: IUpdateMainModuleActionCreator;
  updateOnFocus: IUpdateOnFocusActionCreator;
  internalInitialization: IInternalInitializationActionCreator;
}

export type ModulesActions =
  | IInitializeModulesAction
  | IUpdateMainModuleAction
  | IUpdateDeviceAction
  | IUpdateSsoConfigAction
  | IUpdateSearchConfigAction
  | IGetTopResultAction
  | IUpdateLocaleAction
  | IUpdateCartAction
  | ISetIsUnavailableAction
  | IInternalInitializationAction
  | ISetVideoData
  | IUpdateSsoPartialAction<IUserInfo>
  | IUpdateSsoPartialAction<IUserBase>;
