import { useCallback, useEffect } from 'preact/hooks';

declare global {
  interface Document {
    msHidden: never;
    webkitHidden: never;
  }
}

export enum VisibilityChangeEvents {
  msEvent = 'msvisibilitychange',
  webkitEvent = 'webkitvisibilitychange',
}

const isHiddenSupported =
  typeof (document.msHidden || document.webkitHidden || document.hidden) != 'undefined';

const getIsPageHidden = () => document.hidden || document.msHidden || document.webkitHidden;

export interface IOnFocus {
  (): void;
}

const useOnTabFocus = (onFocus: IOnFocus): void => {
  const onVisibilityChange = useCallback(() => {
    if (!getIsPageHidden()) onFocus();
  }, [onFocus]);

  useEffect(() => {
    if (isHiddenSupported) {
      document.addEventListener(VisibilityChangeEvents.msEvent, onVisibilityChange, false);
      document.addEventListener(VisibilityChangeEvents.webkitEvent, onVisibilityChange, false);

      return () => {
        document.removeEventListener(VisibilityChangeEvents.msEvent, onVisibilityChange, false);
        document.removeEventListener(VisibilityChangeEvents.webkitEvent, onVisibilityChange, false);
      };
    }
  }, [onVisibilityChange]);
};

export default useOnTabFocus;
