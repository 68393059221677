import { render, h } from 'preact';
// This line allow us to use async
import 'regenerator-runtime/runtime';
import './styles/index.css';
import type { store } from 'web-video-player/dist/store/index';

import IUnificationScript from './interfaces/IUnificationScript';
import App from './containers/app';
import { getModules } from './modules';
import { moduleHotAccept } from './utils/hotModules';
import { addStylesheetURL } from './utils/common';
import { FONT_URL } from './enums/common';
declare global {
  interface Window {
    UnificationScript: IUnificationScript;
    VideoPlayerStore: typeof store;
  }
}

const ROOT_NODE_ID = 'unification-root-node';
const FONT_NODE_ID = 'unification-root-font-node';

const getRootNode = (): HTMLElement => {
  const existingNode = document.getElementById(ROOT_NODE_ID);
  if (!!existingNode) {
    return existingNode;
  }

  const rootNode = document.createElement('div');
  rootNode.setAttribute('id', ROOT_NODE_ID);

  return rootNode;
};

addStylesheetURL(FONT_NODE_ID, FONT_URL);

window.UnificationScript = getModules();

render(<App />, getRootNode());

moduleHotAccept(module);
