import { FunctionalComponent, h, Fragment, FunctionComponent } from 'preact';
import { useEffect, useMemo } from 'preact/hooks';
import { lazy } from 'preact/compat';
import { connect, useDispatch } from 'react-redux';
import {
  mainModuleNavigationSelector,
  modulesByKeySelector,
} from '../../selectors/modulesSelectors';
import { AppState } from '../../interfaces/appState';
import { IBaseMain } from '../../models/Main';
import Portal from '../../components/portal';
import ErrorBoundary from '../../components/errorBoundary';
import { initializeI18n } from '../../locales/i18n';
import { selectedLocaleSelector } from '../../selectors/localeSelector';
import { SupportedLanguages } from '../../enums/locale';
import useMatchMedia from '../../hooks/useMatchMedia';
import { BUNDLE_NAMES } from '../../enums/common';
import { DEFAULT_PLAYER_CONTAINER_ID } from '../../enums/video';
import { Breakpoints } from '../../enums/breakpoints';
import { updateDevice, updateOnFocus, updateRoute } from '../../actions/modulesActions';
import { updateLocale } from '../../actions/localeActions';
import { DeviceTypes } from '../../utils/device';
import { ISearchModel } from '../../models/Search';
import TopNav from '../topNav';
import Player from '../../containers/videoPlayer/player';
import useOnTabFocus from '../../hooks/useOnTabFocus';
import { GlobalStyle } from '../../shared/global/global';
import { getMenusActionCreator } from '../../actions/headerActions';
import { ModulesStatus } from '../../reducers/modulesReducer';
import DynamicImport from '../../components/dynamicImport';
import { getTranslations } from '../../actions/translationActions';

const Footer = lazy<FunctionComponent>(async () => await import('../footer'));
const Search = lazy<FunctionComponent>(async () => await import('../search/SearchPage'));

export interface IUnificationProps {
  footer: IBaseMain;
  search: ISearchModel;
  selectedLocale: SupportedLanguages;
  topNav: IBaseMain;
  modulesStatus: ModulesStatus;
  updateDevice: typeof updateDevice;
  updateLocale: typeof updateLocale;
  updateRoute: typeof updateRoute;
  updateOnFocus: typeof updateOnFocus;
  getMenus: typeof getMenusActionCreator;
}

export const Unification: FunctionalComponent<IUnificationProps> = ({
  footer,
  search,
  selectedLocale,
  topNav,
  modulesStatus,
  updateDevice,
  updateLocale,
  updateRoute,
  updateOnFocus,
  getMenus,
}) => {
  useEffect(() => {
    initializeI18n(selectedLocale);
  }, [selectedLocale]);

  const dispatch = useDispatch();
  const isDesktop = useMatchMedia(Breakpoints.xlarge, true, 10);
  const isTablet = useMatchMedia(Breakpoints.large, false, 10);

  useOnTabFocus(updateOnFocus);

  useEffect(() => {
    const deviceType =
      (isDesktop && DeviceTypes.desktop) || (isTablet && DeviceTypes.tablet) || DeviceTypes.mobile;
    updateDevice(deviceType);
  }, [isDesktop, isTablet, updateDevice]);

  useEffect(() => {
    if (modulesStatus === ModulesStatus.initialized) {
      getMenus(selectedLocale);
      updateLocale(selectedLocale);
    }
  }, [getMenus, modulesStatus, selectedLocale, updateLocale]);

  const handleUpdateRoute = () => {
    updateRoute(window.location.href);
  };

  useEffect(() => {
    window.addEventListener('UnificationUpdateRoute', handleUpdateRoute);
    return () => {
      window.removeEventListener('UnificationUpdateRoute', handleUpdateRoute);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const videoPlayerElementId = useMemo(() => {
    if (!document.getElementById(DEFAULT_PLAYER_CONTAINER_ID)) {
      const element = document.createElement('div');
      element.id = DEFAULT_PLAYER_CONTAINER_ID;
      document.body.append(element);
    }

    return DEFAULT_PLAYER_CONTAINER_ID;
  }, []);

  useEffect(() => {
    if (modulesStatus === ModulesStatus.initialized) dispatch(getTranslations());
  }, [dispatch, modulesStatus, selectedLocale]);

  return (
    <ErrorBoundary>
      <Fragment>
        {topNav.isEnabled && (
          <Portal containerId={topNav.elementId}>
            <GlobalStyle>
              <TopNav />
            </GlobalStyle>
          </Portal>
        )}

        {search.isEnabled && (
          <Portal containerId={search.elementId}>
            <DynamicImport bundleName={BUNDLE_NAMES.SEARCH}>
              <GlobalStyle>
                <Search />
              </GlobalStyle>
            </DynamicImport>
          </Portal>
        )}

        {footer.isEnabled && (
          <Portal containerId={footer.elementId}>
            <DynamicImport bundleName={BUNDLE_NAMES.FOOTER}>
              <GlobalStyle>
                <Footer />
              </GlobalStyle>
            </DynamicImport>
          </Portal>
        )}

        {videoPlayerElementId && (
          <Portal containerId={videoPlayerElementId}>
            <GlobalStyle>
              <Player />
            </GlobalStyle>
          </Portal>
        )}
      </Fragment>
    </ErrorBoundary>
  );
};

const mapStateToProps = (state: AppState) => ({
  topNav: mainModuleNavigationSelector(state, 'topNav'),
  search: modulesByKeySelector(state, 'search'),
  subNav: mainModuleNavigationSelector(state, 'subNav'),
  footer: mainModuleNavigationSelector(state, 'footer'),
  modulesStatus: modulesByKeySelector(state, 'status'),
  selectedLocale: selectedLocaleSelector(state),
});

export default connect(mapStateToProps, {
  updateDevice,
  updateRoute,
  updateOnFocus,
  updateLocale,
  getMenus: getMenusActionCreator,
})(Unification);
