import styled from 'styled-components';
import { Body1, Body2 } from '../../shared/typography';

export const CategoryText = styled(Body1)`
  margin: 0 0 0 5px;
`;

export const Category = styled(Body2)`
  color: ${({ theme }) => theme.disabledGray};
  margin: 0;
`;

export const CategoryContainer = styled.div`
  cursor: pointer;
  display: flex;
`;

export const NoResultsContainer = styled.div`
  display: flex;

  p {
    padding: 0;
  }
`;
