import { Record } from 'immutable';
import { IModelStatic } from '../interfaces/IModel';
import AppContext, { IAppContext, IAppContextModel } from './AppContext';
import Item, { IItemModel, IRawItem } from './Item';
import Menu, { IRawMenu, IMenuItemModel } from './Menu';
import Placeholder, { IRawPlaceholder } from './Placeholder';
import { IRawFeatureProduct } from './FeaturedProduct';
import Banner, { IRawBanner } from './Banner';
import { MenuItemTypes } from '../interfaces/IMenuItem';
import ContentBlock, { IRawContentBlock } from './ContentBlock';
import { IModulesStateRecord } from '../interfaces/IModules';

export interface IMenuLocation {
  slug: string;
  title: string;
  appContextList: Array<IAppContextModel>;
  menuItemList: Array<IMenuItemModel>;
  parentItem?: IItemModel;
  subNavCategory: Array<string>;
}

type RawMenuItemType =
  | IRawItem
  | IRawMenu
  | IRawPlaceholder
  | IRawFeatureProduct
  | IRawBanner
  | IRawContentBlock;

export interface IRawMenuLocation {
  slug: string;
  title: string;
  appContextList: Array<IAppContext>;
  menuItemList: Array<RawMenuItemType> | null;
  subNavCategory?: Array<string>;
  parentItem?: IRawItem;
}

export interface IMenuLocationModel extends Record<IMenuLocation>, IMenuLocation {
  applyAppContext(modules: IModulesStateRecord): IMenuLocationModel;
  getActiveCategory(): string | undefined;
  isEmpty(): boolean;
}

export interface IMenuLocationStaticModel
  extends IModelStatic<IMenuLocationModel, IRawMenuLocation> {
  applyAppContextToLocationList(
    menuLocationList: Array<IMenuLocationModel>,
    modules: IModulesStateRecord,
  ): Array<IMenuLocationModel>;
}

const defaultProps = {
  slug: '',
  title: '',
  menuItemList: [],
  appContextList: [],
  subNavCategory: [],
  parentItem: undefined,
};

const MenuLocation: IMenuLocationStaticModel = class
  extends Record<IMenuLocation>(defaultProps, 'MenuLocation')
  implements IMenuLocationModel {
  static createFromRaw(rawMenuLocation?: IRawMenuLocation): IMenuLocationModel {
    const {
      menuItemList: rawMenuItemList = [],
      appContextList: rawAppContextList = [],
      parentItem: rawParentItem,
    } = rawMenuLocation || {};

    const menuItemList = rawMenuItemList
      ? rawMenuItemList.map((menuItem) => {
          return this.getMenuItemByType(menuItem);
        })
      : undefined;

    const appContextList = rawAppContextList.map((appContext) =>
      AppContext.createFromRaw(appContext),
    );

    const parentItem = rawParentItem ? Item.createFromRaw(rawParentItem) : undefined;

    return new this({
      ...(rawMenuLocation || {}),
      appContextList,
      menuItemList,
      parentItem,
    });
  }

  isEmpty(): boolean {
    return !this.parentItem?.anchorText && !this.menuItemList.length;
  }

  applyAppContext(modules: IModulesStateRecord): IMenuLocationModel {
    const { menuItemList, parentItem } = this;
    const shouldParentItemBeDisplayed =
      this.parentItem && AppContext.applyRuleTypeList(this.parentItem.appContextList, modules);

    return this.merge({
      menuItemList: AppContext.applyToMenuItemList(menuItemList, modules),
      parentItem: shouldParentItemBeDisplayed ? parentItem : undefined,
    });
  }

  getActiveCategory(): string | undefined {
    const { subNavCategory } = this;

    return subNavCategory.find((category) => new RegExp(category).test(location.pathname));
  }

  static getMenuItemByType(rawItem: RawMenuItemType): IMenuItemModel {
    switch (rawItem.__typename) {
      case MenuItemTypes.item: {
        const rawItemInstance = rawItem as IRawItem;
        return Item.createFromRaw({
          ...rawItemInstance,
          itemType: rawItemInstance.itemType,
        });
      }

      case MenuItemTypes.placeholder: {
        return Placeholder.createFromRaw(rawItem as IRawPlaceholder);
      }

      case MenuItemTypes.banner: {
        return Banner.createFromRaw(rawItem as IRawBanner);
      }

      case MenuItemTypes.contentBlock: {
        return ContentBlock.createFromRaw(rawItem as IRawContentBlock);
      }
    }

    return Menu.createFromRaw(rawItem as IRawMenu);
  }

  static applyAppContextToLocationList(
    menuLocationList: Array<IMenuLocationModel>,
    modules: IModulesStateRecord,
  ): Array<IMenuLocationModel> {
    return menuLocationList
      .reduce<Array<IMenuLocationModel>>((list, menuLocation) => {
        const shouldBeDisplayed = AppContext.applyRuleTypeList(
          menuLocation.appContextList,
          modules,
        );
        return shouldBeDisplayed ? list.concat([menuLocation.applyAppContext(modules)]) : list;
      }, [])
      .sort(
        (menuLocation1, menuLocation2) =>
          menuLocation2.appContextList.length - menuLocation1.appContextList.length,
      );
  }
};

export default MenuLocation;
