/** @jsx h */
import { FunctionalComponent, h } from 'preact';
import { useRef } from 'preact/hooks';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link } from '../navLink/navLinkStyled';
import { IMenuModel } from '../../../../models/Menu';
import { IMenuItemModel } from '../../../../models/Menu';
import { DropdownMenu } from '../../../dropdownMenu';
import { Dropdown } from '../../../dropdown';
import { AppState } from '../../../../interfaces/appState';
import { isTopNavItemActiveSelector } from '../../../../selectors/activeItemSelectors';
import { TopNavComponents } from '../../../../enums/tealium';
import { topNavMenuLocale } from './locale';
import { useDropdown } from '../../../../providers/dropdownProvider';
import {
  E2ETestingProvider,
  getDataTestIdSuffix,
  useE2ETesting,
} from '../../../../providers/e2eTestingProvider';

export interface IMenuProps {
  item: IMenuItemModel;
  isActive: boolean;
}

const Menu: FunctionalComponent<IMenuProps> = ({ item, isActive }) => {
  const menu = item as IMenuModel;
  const { parentItem, menuSections } = menu;
  const { columnList } = menuSections;
  const linkRef = useRef<HTMLElement>();
  const { t } = useTranslation();
  const { focusedMenuRef, onBlurCallback } = useDropdown();
  const { testIdPrefix } = useE2ETesting();

  return (
    <E2ETestingProvider
      testIdPrefix={testIdPrefix}
      testIdSuffix={getDataTestIdSuffix(parentItem?.itemName)}
    >
      <Dropdown
        openComponent={(isOpen, handleMouseEnter, handleMouseLeave, wrapperRef) => {
          const handleEventListener = (event: KeyboardEvent) => {
            if (event.shiftKey && event.key === ' ') {
              handleMouseEnter();
            }
          };

          const handleOnBlur = (event: FocusEvent) => {
            if (!wrapperRef.current?.contains(event.relatedTarget as Node)) {
              handleMouseLeave();
              linkRef.current?.removeEventListener('keydown', handleEventListener);
            }
          };

          const handleOnFocus = () => {
            if (!focusedMenuRef?.current) {
              linkRef.current?.addEventListener('keydown', handleEventListener);
            }

            if (focusedMenuRef && onBlurCallback) {
              focusedMenuRef.current = wrapperRef.current;
              onBlurCallback.current = handleOnBlur;
            }
          };

          return parentItem ? (
            <Link
              ref={linkRef}
              item={parentItem}
              tealiumComponent={TopNavComponents.NAVBAR}
              isOpen={isOpen}
              isActive={isActive}
              onFocus={handleOnFocus}
              onBlur={handleOnBlur}
              role="button"
              aria-haspopup="true"
              aria-expanded={isOpen}
              aria-label={`${parentItem?.anchorText}. ${t(topNavMenuLocale.ariaLabel)}`}
              dangerouslySetInnerHTML={{ __html: parentItem?.anchorText }}
            />
          ) : null;
        }}
        top="26px"
      >
        {columnList?.length > 0 ? <DropdownMenu menu={menu} /> : null}
      </Dropdown>
    </E2ETestingProvider>
  );
};

const mapStateToProps = (state: AppState, { item }: IMenuProps) => {
  const { parentItem: { id = '' } = {} } = item as IMenuModel;
  return {
    isActive: isTopNavItemActiveSelector(state, id),
  };
};

export default connect(mapStateToProps)(Menu);
