import { useCallback, useState } from 'preact/hooks';

export interface IUseStepFormResult {
  currentStep: number;
  goTo(step: number): void;
  goToPrev(): void;
  goToNext(): void;
  goToFirstStep(): void;
}

const useStepForm = (startingStep = 1): IUseStepFormResult => {
  const [currentStep, setCurrentStep] = useState<number>(startingStep);

  return {
    currentStep,
    goTo: useCallback((step) => setCurrentStep(step), []),
    goToPrev: useCallback(() => setCurrentStep(currentStep - 1), [currentStep]),
    goToNext: useCallback(() => setCurrentStep(currentStep + 1), [currentStep]),
    goToFirstStep: useCallback(() => setCurrentStep(1), []),
  };
};

export default useStepForm;
