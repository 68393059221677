import LocalStorageClient, { ILocalStorageClientUser } from '../localStorageClient';
import Cart, { ICartModel, IRawCart } from '../../models/Cart';

const CART_KEY = 'cart';

const { get, set, del } = LocalStorageClient<IRawCart>();

const CartClient: ILocalStorageClientUser<ICartModel> = {
  get: () => {
    const rawCartItem = get(CART_KEY);

    return Cart.createFromRaw(
      rawCartItem?.data ? { ...rawCartItem?.data, hasLoaded: false } : undefined,
    );
  },
  set: (cart) => set(CART_KEY, { data: cart.serialize() }),
  del: () => del(CART_KEY),
};

export default CartClient;
