import GroupClient from '../gateways/groupsAPI/groupClient';
import { IItemModel } from '../models/Item';
import { ISsoModel } from '../interfaces/ISso';
import { groupsSelector } from '../selectors/groupsSelectors';
import {
  ISetGroupActionCreator,
  ISetGroupsAction,
  SET_USER_GROUPS_MENU,
} from './interfaces/IGroupAction';
import { NO_ACTION } from './interfaces/IAction';

export const setUserGroups = (menus: Array<IItemModel>): ISetGroupsAction => ({
  type: SET_USER_GROUPS_MENU,
  payload: menus,
});

export const getUserGroupsActionCreator: ISetGroupActionCreator = (sso: ISsoModel) => async (
  dispatch,
  getState,
) => {
  const { accessToken } = sso;
  const { hasLoaded } = groupsSelector(getState());

  if (accessToken && !hasLoaded) {
    const groups = await GroupClient(accessToken).get();
    return dispatch(setUserGroups(groups));
  } else {
    return dispatch({
      type: NO_ACTION,
    });
  }
};
