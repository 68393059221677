import { createSelector } from 'reselect';
import { ITranslationModel } from 'src/models/Translation';
import { AppState } from '../interfaces/appState';

export const PROMO_TITLE = 'postClassPromo';
export const FIRST_PLAY_TITLE = 'bodiFirstPlay';

const translationSelector = (state: AppState) => state.translation;

export const promoTranslationSelector = createSelector(
  translationSelector,
  ({ translationGroups }: ITranslationModel) =>
    translationGroups?.find((translationItem) => translationItem.title === PROMO_TITLE),
);

export const firstPlayTranslationSelector = createSelector(
  translationSelector,
  ({ translationGroups }: ITranslationModel) =>
    translationGroups?.find((translationItem) => translationItem.title === FIRST_PLAY_TITLE),
);
