import { ITealiumEvent, ITealiumTemplate, IUtagData } from '../../interfaces/ITealium';
import { SupportedLanguages } from '../../enums/locale';
import { AppIdEnum } from '../../enums/main';
import TEALIUM_LINKS from './tealiumLinks';
import { TEALIUM_PAGE_NAMES } from './tealiumPages';
import { TealiumEventTypes } from '../../enums/tealium';
import isEmpty from 'lodash/isEmpty';

export const ANCHOR = 'A';
export const DOM = 'dom';
export const PLATFORM = 'web';
export const VERSION = 'Thunderbird-1.0';
export const NA = 'n/a';

export const STRING = 'string';
export const UNDEFINED = 'undefined';
export const LOCALE_DELIMITER = '_';

/*
  Input: 'link-txt------here main-nav- bar -Fitness-60 - 90- -mins   cosa'
  Output: 'link-txt-here-main-nav-bar-Fitness-60-90-mins-cosa'
*/
export const toSnake = (tealiumString: string): string =>
  tealiumString.replace(
    /(\s-\s+)|(-\s+)|(\s-+)|(\s+)|(-+)/g,
    (match, p1, p2, p3, p4, p5, offset, string) => {
      if (p2 && string[offset + 2] === '-') {
        return '';
      } else if (p1 || p2 || p3 || p4 || p5) {
        return '-';
      }

      return '';
    },
  );

export function sanitizeTealiumObject(
  tealiumObject: IUtagData,
  shouldPreserveWhitespace = false,
): IUtagData {
  const newTealiumObject = {} as IUtagData;
  for (const property in tealiumObject) {
    if (typeof tealiumObject[property] === STRING && !shouldPreserveWhitespace) {
      newTealiumObject[property] = toSnake(tealiumObject[property] as string) as never;
    } else {
      newTealiumObject[property] = tealiumObject[property];
    }
  }
  return newTealiumObject;
}

export function parseStringTemplate(
  template: never | string,
  map: IUtagData,
  fallback?: string,
): string {
  let tealiumValue = null;
  const get = (path: string, obj: IUtagData, fb = `$\{${path}}`) =>
    path.split('|').reduce((res, key: string) => {
      const resType = typeof obj[key];
      if (resType !== STRING && resType !== UNDEFINED) {
        tealiumValue = obj[key];
      }
      return (obj[key] || fb) as string;
    }, '');

  const templateResult = template.replace(/\$\{.+?}/g, (match: string) => {
    const path = match.substr(2, match.length - 3).trim();

    return get(path, map, fallback);
  });

  return tealiumValue !== null ? tealiumValue : templateResult;
}

export function parseTemplate(jsonTemplate: IUtagData, data?: IUtagData): IUtagData {
  if (!data) {
    return jsonTemplate;
  }

  const replacement = {} as IUtagData;

  Object.keys(jsonTemplate).forEach((key: string) => {
    if (typeof data[key] !== UNDEFINED) {
      Object.keys(data[key] as never).forEach((node: string) => {
        const parsedValue = (data[key] as never)[node];

        replacement[node] = parsedValue;
      });

      const parsedString = parseStringTemplate(jsonTemplate[key] as string, replacement);

      jsonTemplate[key] = parsedString as never;
    }
  });

  return jsonTemplate;
}

export const getGlobals = (
  app: AppIdEnum,
  locale: SupportedLanguages | undefined,
  ip: string,
): IUtagData => ({
  site_name: app.toLowerCase(),
  site_global_group: DOM,
  site_platform: PLATFORM,
  site_version_name: VERSION,
  locale: locale || SupportedLanguages.EN_US,
  user_lang_preference: locale || NA,
  purchase_region: locale ? locale.split(LOCALE_DELIMITER)[1].toUpperCase() : NA,
  user_ip: ip,
});

export const findTemplate = (key: string): ITealiumTemplate =>
  ((TEALIUM_LINKS as ITealiumTemplate)[key] as ITealiumTemplate) || {};

export const getTealiumPageName = (pathname: string): string => {
  return pathname ? TEALIUM_PAGE_NAMES[pathname] : '';
};

export const parseTemplateAndSanitizeData = (
  jsonTemplate: IUtagData,
  data: IUtagData,
  eventType: TealiumEventTypes,
  eventPassed: ITealiumEvent = {} as ITealiumEvent,
  shouldPreserveWhitespace?: boolean,
): IUtagData => {
  const tealiumEventData = parseTemplate(jsonTemplate, data);

  const tealiumData = sanitizeTealiumObject(
    {
      ...window.utag_data,
      ...tealiumEventData,
    },
    shouldPreserveWhitespace,
  );

  if (!isEmpty(eventPassed) && eventType === TealiumEventTypes.EVENT) {
    Object.keys(tealiumEventData).forEach((key) => {
      if (tealiumEventData[key]) {
        const value = tealiumEventData[key] as string;
        const copyPropsSubstring = value.substring(value.indexOf('%') + 1, value.lastIndexOf('%'));

        if (copyPropsSubstring) {
          if (eventPassed.tagName === ANCHOR) {
            tealiumData[key] = eventPassed[copyPropsSubstring];
          } else {
            let findTheAnchor = eventPassed.parentElement;

            while (findTheAnchor?.tagName !== ANCHOR && findTheAnchor) {
              findTheAnchor = findTheAnchor.parentElement;
            }

            if (findTheAnchor) tealiumData[key] = findTheAnchor[copyPropsSubstring];
          }
        } else {
          tealiumData[key] = tealiumEventData[key];
        }
      }
    });
  }

  return tealiumData;
};
