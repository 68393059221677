import styled from 'styled-components';

export const Columns = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Column = styled.ul`
  width: ${({ columnWidth }) => (columnWidth ? columnWidth : 'max-content')};
  padding: ${({ columnPadding }) => (columnPadding ? columnPadding : '0px 24px')};
  margin: 0;
  list-style: none;
  > li:last-child {
    margin: 0;
  }
  > ul:last-child ul > li:last-child {
    margin: 0;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 158px;
  ${({ containerWidth }) => (containerWidth ? `width: ${containerWidth}` : '')}; ;
`;

export const Footer = styled.ul`
  padding: 16px 24px 0;
  margin: 0;
  width: max-content;
  box-sizing: border-box;
  list-style: none;

  li:last-child {
    margin-bottom: 0;
  }
`;

export const FooterContainer = styled.div`
  width: 100%;
  border-top: solid 1px ${({ theme }) => theme.lighterGray};
  margin: 16px 0 0;
`;
