import RestClient from '../restClient';
import { SupportedLanguages } from '../../enums/locale';
import IKeyValue from '../../interfaces/IKeyValue';
import { loadFromConfig } from '../../utils/configLoader';
import Waiver, { IWaiverModel } from '../../models/Waiver';
import { WAIVER_LEGAL_LANGUAGES } from '../../enums/waiver';
import { CONTENT_TYPES } from '../../utils/constants';

const COUNTRY_CANADA_CODE = 'CA';
const COUNTRY_FRENCH_CODE = 'fr';
const LOCALE_SEPARATOR = '_';
export const BOD_CONTENT_HTML = 'bod-wd-v3-1.html';

const { LEGAL_API } = loadFromConfig('VIDEO_PLAYER');

export interface ILegalClient {
  getWaiverContent: () => Promise<IWaiverModel>;
}

const LegalClient = (locale: SupportedLanguages): ILegalClient => {
  const [language, country] = locale.split(LOCALE_SEPARATOR);
  const countryCode = country === COUNTRY_FRENCH_CODE ? COUNTRY_CANADA_CODE : country.toUpperCase();
  const BASE_HEADERS: IKeyValue<string> = {
    'Accept-Language': `${language}-${countryCode}`,
    'Point-Of-Purchase': countryCode,
    'Content-Type': CONTENT_TYPES.JSON,
  };

  const { get } = RestClient<string>(`${LEGAL_API}/${BOD_CONTENT_HTML}`, {
    ...BASE_HEADERS,
  });

  return {
    getWaiverContent: async () => {
      const queryParams = {
        locale: WAIVER_LEGAL_LANGUAGES[locale],
      };
      const { json } = await get({ queryParams }, false);

      return Waiver.createFromRaw({ requirewaiver: true, waiverContent: json });
    },
  };
};

export default LegalClient;
