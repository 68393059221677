import { SupportedLanguages } from '../../enums/locale';

const MENU_DATA_BY_LOCALE = {
  [SupportedLanguages.EN_CA]: import('./en_ca.json'),
  [SupportedLanguages.FR_CA]: import('./fr_ca.json'),
  [SupportedLanguages.EN_US]: import('./en_us.json'),
  [SupportedLanguages.ES_US]: import('./es_us.json'),
  [SupportedLanguages.EN_GB]: import('./en_gb.json'),
  [SupportedLanguages.FR_FR]: import('./fr_fr.json'),
};

export default MENU_DATA_BY_LOCALE;
