import { store } from '../configureStore';
import { mainModuleNavigationSelector } from '../selectors/modulesSelectors';

const color = '#2490FF';

export const getIsDebugEnabled = (): boolean => {
  const { getState } = store;
  const state = getState();
  return mainModuleNavigationSelector(state, 'isDebugEnabled') as boolean;
};

export const log = (...params: Parameters<typeof console.log>): void => {
  if (getIsDebugEnabled()) {
    console.log(`%c [UNIFICATION]`, `color: ${color}`, ...params);
  }
};

export const warn = (...params: Parameters<typeof console.log>): void => {
  if (getIsDebugEnabled()) {
    console.warn(`%c [UNIFICATION]`, `color: ${color}`, ...params);
  }
};

export const error = (...params: Parameters<typeof console.log>): void => {
  if (getIsDebugEnabled()) {
    console.error(`%c [UNIFICATION]`, `color: ${color}`, ...params);
  }
};
