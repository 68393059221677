import styled from 'styled-components';
import { Media } from '../../../../styles/media';

const { tabletUp, desktopUp } = Media;

export const IconContainer = styled.div`
  display: grid;
  grid-column: 1;
  grid-template-columns: auto auto;
  grid-gap: 16px;
  align-items: center;
  justify-self: start;

  ${desktopUp`
    display: block;
    margin-right: 16px;
  `};
`;

export const Icon = styled.img.attrs({
  width: 18,
  height: 18,
})`
  ${tabletUp`
    height: auto;
    width: 100%;
  `};
  ${desktopUp`
    max-width: fit-content;
  `};
`;
