import { ISearchState } from '../../interfaces/ISearchState';
import { IUtagData } from '../../interfaces/ITealium';

export const PROGRAM_MATERIALS_EVENT_TEMPLATE = ({
  objectID,
  pageName,
  position,
  queryID,
  searchState,
  title,
  type,
  url,
}: IUtagData): IUtagData => ({
  page_name: {
    tab_name: pageName,
  },
  event_name: {
    title,
    type,
  },
  event_data: {
    data: JSON.stringify({
      filename_url: url,
      item_position: position,
      objectID,
    }),
    url,
  },
  search_refiners: {
    search_state: JSON.stringify({ ...(searchState as ISearchState), queryID }),
  },
});

export default PROGRAM_MATERIALS_EVENT_TEMPLATE;
