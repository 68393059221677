import { Middleware } from 'redux';
import { AppState } from '../interfaces/appState';
import CartClient from '../gateways/modulesStorage/CartClient';
import SsoClient from '../gateways/modulesStorage/SsoClient';
import SearchClient from '../gateways/modulesStorage/SearchClient';
import LocaleClient from '../gateways/modulesStorage/LocaleClient';
import { SAVE_TO_STORE_ACTIONS as SSO_ACTIONS } from '../actions/ssoActions';
import { SAVE_TO_STORE_ACTIONS as SEARCH_ACTIONS } from '../actions/searchActions';
import { SAVE_TO_STORE_ACTIONS as CART_ACTIONS } from '../actions/cartActions';
import { SAVE_TO_STORE_ACTIONS as LOCALE_ACTIONS } from '../actions/localeActions';

export const storeMiddleware: Middleware<AppState> = (store) => (next) => (action) => {
  const result = next(action);
  const {
    modules: { cart, sso, search, locale },
  } = store.getState();
  if (SSO_ACTIONS.includes(action.type)) {
    SsoClient.set(sso);
  }
  if (CART_ACTIONS.includes(action.type)) {
    CartClient.set(cart);
  }
  if (LOCALE_ACTIONS.includes(action.type)) {
    LocaleClient.set(locale);
  }
  if (SEARCH_ACTIONS.includes(action.type)) {
    SearchClient.set(search);
  }

  return result;
};
