import { Record } from 'immutable';
import { Reducer } from 'redux';
import { UPDATE_SELECTED_LOCALE, SET_USER_LOCATION } from '../actions/interfaces/ILocaleAction';
import {
  INITIALIZE_MODULES,
  INTERNAL_INITIALIZE_MODULES,
  ModulesActions,
  UPDATE_DEVICE,
  UPDATE_IS_DEBUG_ENABLED,
  UPDATE_MAIN_MODULE,
  UPDATE_ROUTE,
  UPDATE_VERSION_NAME,
} from '../actions/interfaces/IModulesActions';
import {
  UPDATE_LOGIN_STATUS,
  UPDATE_USER_INFO,
  UPDATE_COACH_INFO,
  UPDATE_SSO_CONFIG,
  UPDATE_ACCESS_TOKEN,
  UPDATE_HAS_GROUPS,
  GET_USER_ENTITLEMENTS,
  UPDATE_SSO_CONFIG_WITH_SIDE_EFFECTS,
} from '../actions/interfaces/ISsoAction';
import Cart, { ICartModel } from '../models/Cart';
import Locale, { ILocaleModel } from '../models/Locale';
import Main from '../models/Main';
import Search, { ISearchModel } from '../models/Search';
import { ISsoModel } from '../interfaces/ISso';
import Sso from '../models/Sso';
import { DeviceTypes } from '../utils/device';
import {
  IS_FREE_REG,
  IS_UNAVAILABLE,
  SET_VIDEO_DATA,
  UPDATE_SEARCH_CONFIG,
  UPDATE_TOP_RESULT,
} from '../actions/interfaces/ISearchActions';
import { IHit } from 'src/interfaces/ISearch';
import {
  UPDATE_CART_CONFIG,
  UPDATE_ITEMS_COUNT,
  WIPE_CART,
  GET_CART_SUMMARY,
} from '../actions/interfaces/ICartAction';
import { IVideo } from 'src/interfaces/IVideo';
import { IModulesState, IModulesStateRecord } from '../interfaces/IModules';
import { CLEAR_RECENT_SEARCHES, STORE_SEARCH } from '../actions/interfaces/ISearchActions';
import { IRecentSearch } from '../interfaces/ISearch';
import { IUserInfoModel } from '../models/UserInfo';
import { ICoachInfoModel } from '../models/CoachInfo';
import { LOGIN_USER, LOGOUT_USER, UPDATE_ACCOUNTS } from '../actions/interfaces/ISsoAction';

export enum ModulesStatus {
  initialized = 'initialized',
  waiting = 'waiting',
}

export const ModulesState = Record<IModulesState>({
  status: ModulesStatus.waiting,
  main: Main.createFromRaw(),
  sso: Sso.createFromRaw(),
  cart: Cart.createFromRaw(),
  locale: Locale.createFromRaw(),
  search: Search.createFromRaw(),
});

const initialState = ModulesState();

export const modulesReducer: Reducer<IModulesStateRecord, ModulesActions> = (
  state = initialState,
  action: ModulesActions,
): IModulesStateRecord => {
  const { type, payload } = action;

  switch (type) {
    case INTERNAL_INITIALIZE_MODULES: {
      return payload as IModulesStateRecord;
    }
    case UPDATE_MAIN_MODULE:
    case INITIALIZE_MODULES: {
      return (payload as IModulesStateRecord).set('status', ModulesStatus.initialized);
    }

    case UPDATE_DEVICE: {
      const deviceType = payload as DeviceTypes;
      const { main } = state;

      return state.merge({
        main: main.set('device', deviceType),
      });
    }

    case UPDATE_ROUTE: {
      const route = payload as string;
      const { main } = state;

      return state.merge({
        main: main.set('route', route),
      });
    }

    case UPDATE_VERSION_NAME: {
      const versionName = payload as string;
      const { main } = state;

      return state.merge({
        main: main.set('versionName', versionName),
      });
    }

    case UPDATE_IS_DEBUG_ENABLED: {
      const isDebugEnabled = payload as boolean;
      const { main } = state;

      return state.merge({
        main: main.set('isDebugEnabled', isDebugEnabled),
      });
    }

    case UPDATE_ACCESS_TOKEN: {
      const ssoConfig = payload as ISsoModel;
      const { sso } = state;

      const updatedState = sso
        .set('accessToken', ssoConfig.get('accessToken'))
        .set('idToken', ssoConfig.get('idToken'));

      return state.set('sso', updatedState);
    }

    case UPDATE_USER_INFO: {
      const { sso } = state;
      const updatedState = sso.set(
        'userInfo',
        sso.userInfo ? sso.userInfo.merge(payload as IUserInfoModel) : (payload as IUserInfoModel),
      );

      return state.set('sso', updatedState);
    }

    case UPDATE_ACCOUNTS: {
      const ssoConfig = payload as ISsoModel;
      const { sso } = state;
      const updatedState = sso.set('accountList', ssoConfig.get('accountList'));
      return state.set('sso', updatedState);
    }

    case GET_USER_ENTITLEMENTS: {
      const ssoConfig = payload as ISsoModel;
      const { sso } = state;
      const { userInfo } = sso;

      const updatedState = sso.set(
        'userInfo',
        userInfo?.set('entitlementList', ssoConfig.userInfo?.get('entitlementList') || []),
      );

      return state.set('sso', updatedState);
    }

    case UPDATE_LOGIN_STATUS: {
      const ssoConfig = payload as ISsoModel;
      const { sso } = state;

      const updatedState = sso.set('userLoginStatus', ssoConfig.get('userLoginStatus'));

      return state.set('sso', updatedState);
    }

    case UPDATE_COACH_INFO: {
      const { sso } = state;

      const updatedState = sso.set('coachInfo', payload as ICoachInfoModel);

      return state.set('sso', updatedState);
    }

    case UPDATE_HAS_GROUPS: {
      const { sso } = state;

      const updatedState = sso.set('hasGroups', payload as boolean);

      return state.set('sso', updatedState);
    }

    case UPDATE_SSO_CONFIG:
    case UPDATE_SSO_CONFIG_WITH_SIDE_EFFECTS:
    case LOGIN_USER:
    case LOGOUT_USER: {
      const ssoConfig = payload as ISsoModel;

      return state.merge({
        sso: ssoConfig,
      });
    }

    case UPDATE_SEARCH_CONFIG:
    case STORE_SEARCH: {
      const searchConfig = payload as ISearchModel;

      return state.merge({
        search: searchConfig,
      });
    }

    case CLEAR_RECENT_SEARCHES: {
      const { search } = state;
      const updatedState = search.set('recentSearches', [] as Array<IRecentSearch>);
      return state.set('search', updatedState);
    }

    case UPDATE_TOP_RESULT: {
      const topResult = payload as IHit;

      const { search } = state;
      return state.merge({
        search: search.set('topResult', topResult),
      });
    }

    case UPDATE_SELECTED_LOCALE: {
      const locale = payload as ILocaleModel;
      const { selectedLocale } = locale;
      return state.merge({
        locale: locale.set('selectedLocale', selectedLocale),
      });
    }

    case SET_USER_LOCATION: {
      const locale = payload as ILocaleModel;
      return state.merge({
        locale,
      });
    }

    case UPDATE_CART_CONFIG:
    case GET_CART_SUMMARY: {
      const cartConfig = payload as ICartModel;

      return state.merge({
        cart: cartConfig,
      });
    }

    case WIPE_CART: {
      const { cart } = state;
      const updatedState = cart.set('itemsCount', 0).set('hasLoaded', false);
      return state.set('cart', updatedState);
    }

    case UPDATE_ITEMS_COUNT: {
      const cartConfig = payload as ICartModel;
      const { cart } = state;

      const updatedState = cart.set('itemsCount', cartConfig.itemsCount);
      return state.set('cart', updatedState);
    }

    case IS_UNAVAILABLE: {
      const isUnavailable = payload as boolean;

      const { search } = state;

      return state.merge({
        search: search.set('isUnavailable', isUnavailable),
      });
    }

    case IS_FREE_REG: {
      const isFreeReg = payload as boolean;

      const { search } = state;

      return state.merge({
        search: search.set('isFreeReg', isFreeReg),
      });
    }

    case SET_VIDEO_DATA: {
      const videoData = payload as IVideo;

      const { search } = state;

      return state.merge({
        search: search.set('videoData', videoData),
      });
    }

    default:
      return state;
  }
};
