import TealiumClient from '../gateways/tealium/tealiumClient';
import { ITealiumEvent, IUtagData } from '../interfaces/ITealium';

const ERROR = 'Something went wrong while consuming the tealium event service';

export const sendTealiumLinkEvent = async (
  jsonTemplate: IUtagData,
  data: IUtagData,
  eventPassed: ITealiumEvent = {} as ITealiumEvent,
  shouldPreserveWhitespace = false,
): Promise<IUtagData> => {
  try {
    return await TealiumClient.event(jsonTemplate, data, eventPassed, shouldPreserveWhitespace);
  } catch (error) {
    console.error(`${ERROR}: LINK EVENT ${(error as Error)?.message}`);
    return {} as IUtagData;
  }
};

export const sendTealiumViewEvent = async (
  jsonTemplate: IUtagData,
  data: IUtagData,
  shouldPreserveWhitespace = false,
): Promise<IUtagData> => {
  try {
    return await TealiumClient.view(jsonTemplate, data, shouldPreserveWhitespace);
  } catch (error) {
    console.error(`${ERROR}: VIEW EVENT ${(error as Error)?.message}`);
    return {} as IUtagData;
  }
};
