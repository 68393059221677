import { Action } from 'redux';
import { ThunkResult } from './IAction';
import { IWaiverModel } from '../../models/Waiver';

export const SET_USER_WAIVER_PROFILE = 'WAIVER/SET_USER_WAIVER_PROFILE';
export const SET_NO_WAIVER_REQUIRED = 'WAIVER/SET_NO_WAIVER_REQUIRED';
export const SET_IS_WAIVER_ANONYMOUS_USERS_REQUIRED =
  'WAIVER/SET_IS_WAIVER_ANONYMOUS_USERS_REQUIRED';

export interface ISetUserWaiverAction extends Action<typeof SET_USER_WAIVER_PROFILE> {
  payload: IWaiverModel;
}

export interface ISetNoWaiverRequiredAction extends Action<typeof SET_NO_WAIVER_REQUIRED> {
  payload: string;
}

export interface ISetIsWaiverAnonymousUsersRequiredAction
  extends Action<typeof SET_IS_WAIVER_ANONYMOUS_USERS_REQUIRED> {
  payload: boolean;
}

export interface IGetUserWaiverContentActionCreator {
  (): ThunkResult<ISetUserWaiverAction>;
}

export interface ISetUserWaiverAcceptedActionCreator {
  (videoGuid: string, brandCode?: string): ThunkResult<ISetNoWaiverRequiredAction>;
}

export type WaiverActions =
  | ISetUserWaiverAction
  | ISetNoWaiverRequiredAction
  | ISetIsWaiverAnonymousUsersRequiredAction;
