import { SupportedLanguages } from '../../enums/locale';
import IKeyValue from '../../interfaces/IKeyValue';

const { EN_CA, FR_CA, FR_FR, EN_US, ES_US, EN_GB } = SupportedLanguages;

export const LocaleChangeModal: IKeyValue<string> = {
  [EN_CA]: EN_CA,
  [FR_CA]: FR_CA,
  [FR_FR]: FR_FR,
  [EN_US]: EN_US,
  [ES_US]: ES_US,
  [EN_GB]: EN_GB,
  countryAndLanguage: 'countryAndLanguage',
  note: 'localeChangeNote',
  currentShopIn: 'currentShopIn',
  newShopIn: 'newShopIn',
  yes: 'yes',
  no: 'no',
  ok: 'ok',
  countryAndLanguageUpdated: 'countryAndLanguageUpdated',
  viewOnlyText: 'viewOnlyText',
};
