import RestClient from '../restClient';
import { SupportedLanguages } from '../../enums/locale';
import IKeyValue from '../../interfaces/IKeyValue';
import { loadFromConfig } from '../../utils/configLoader';
import { WAIVER_PLAFORM, WAIVER_LANGUAGES } from '../../enums/waiver';
import Waiver, { IWaiverModel } from '../../models/Waiver';
import { CONTENT_TYPES, BODWEB } from '../../utils/constants';

const { URL, API_KEY } = loadFromConfig('VIDEO_PLAYER_WAIVER');

const BASE_HEADERS: IKeyValue<string> = {
  'x-api-key': API_KEY,
  'Content-Type': CONTENT_TYPES.JSON,
  bodweb: BODWEB,
};

export interface IWaiverResponse {
  requirewaiver: boolean;
}

export interface IAcceptWaiverRequest {
  profileId: string;
  videoId: string;
  language: string;
  waiverSlug: string;
  status: string;
  brandCode?: string;
}

export interface IWaiverClient {
  getWaiverStatus: (
    userId: string,
    videoGuid: string,
    locale: SupportedLanguages,
    accessToken: string,
    brandCode?: string,
  ) => Promise<IWaiverModel>;
  postWaiverStatus: (
    userId: string,
    videoGuid: string,
    locale: SupportedLanguages,
    accessToken: string,
    brandCode?: string,
  ) => Promise<boolean>;
}

const { get, post } = RestClient<IWaiverResponse | IAcceptWaiverRequest>(URL, BASE_HEADERS);

const WaiverClient: IWaiverClient = {
  getWaiverStatus: async (
    profileId: string,
    videoId: string,
    locale: SupportedLanguages,
    accessToken: string,
    brandCode?: string,
  ) => {
    const queryParams = {
      profileId,
      videoId,
      language: WAIVER_LANGUAGES[locale],
      platform: WAIVER_PLAFORM,
      ...(brandCode ? { brandCode } : {}),
    };

    const headers = {
      authorization: `Bearer ${accessToken}`,
    };

    const { json } = await get<IWaiverResponse>({ queryParams, headers });

    return Waiver.createFromRaw(json);
  },
  postWaiverStatus: async (
    profileId: string,
    videoId: string,
    locale: SupportedLanguages,
    accessToken: string,
    brandCode?: string,
  ) => {
    const body = {
      profileId,
      brandCode,
      videoId,
      language: WAIVER_LANGUAGES[locale],
      waiverSlug: 'deprecated',
      status: 'active',
    };
    const headers = {
      authorization: `Bearer ${accessToken}`,
    };

    await post<IAcceptWaiverRequest>({ body, headers });
    return true;
  },
};

export default WaiverClient;
