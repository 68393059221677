import { TealiumEventTypes } from '../../enums/tealium';
import { ITealiumEvent, IUtagData } from '../../interfaces/ITealium';
import { parseTemplateAndSanitizeData } from './tealium';

export interface ITealiumClient {
  event: (
    jsonTemplate: IUtagData,
    data: IUtagData,
    eventPassed?: ITealiumEvent,
    shouldPreserveWhitespace?: boolean,
  ) => Promise<IUtagData>;
  view: (
    jsonTemplate: IUtagData,
    data: IUtagData,
    shouldPreserveWhitespace?: boolean,
  ) => Promise<IUtagData>;
}

const TealiumClient: ITealiumClient = {
  event: async (
    jsonTemplate,
    data,
    eventPassed: ITealiumEvent = {} as ITealiumEvent,
    shouldPreserveWhitespace = false,
  ) => {
    return new Promise((resolve) => {
      const tealiumData = parseTemplateAndSanitizeData(
        jsonTemplate,
        data,
        TealiumEventTypes.EVENT,
        eventPassed,
        shouldPreserveWhitespace,
      );

      window.utag.link(tealiumData);
      setTimeout(() => {
        resolve(tealiumData);
      });
    });
  },
  view: async (jsonTemplate, data, shouldPreserveWhitespace = false) => {
    return new Promise((resolve) => {
      const tealiumData = parseTemplateAndSanitizeData(
        jsonTemplate,
        data,
        TealiumEventTypes.VIEW,
        undefined,
        shouldPreserveWhitespace,
      );

      window.utag.view(tealiumData);
      setTimeout(() => {
        resolve(tealiumData);
      });
    });
  },
};

export default TealiumClient;
