import styled from 'styled-components';
import { Media } from '../../../styles/media';

const { tablet } = Media;

const Text5 = styled.p`
  font-size: 9px;
  font-weight: 400;

  ${tablet`
    font-size: 11px;
    font-weight: 700;
  `};
`;

export const Text5NoBottomMargin = styled(Text5)`
  margin-bottom: 0;
`;

export default Text5;
