/** @jsx h */
import { createContext, VNode, h } from 'preact';
import { useContext } from 'preact/hooks';
import { connect } from 'react-redux';
import { SupportedEvents } from '../enums/event';
import { AppState } from '../interfaces/appState';
import { getEventHandler } from '../middlewares/event';
import { IEventModel } from '../models/Event';
import { eventsSelector } from '../selectors/eventSelector';

export interface IEventHandlerProviderProps {
  children: VNode;
  events: Array<IEventModel>;
}

export interface IEventHandlerContext {
  dispatchEvent<T = unknown>(eventName: SupportedEvents, params?: T): void;
  dispatchEventWithResponse: <T = never>(eventName: SupportedEvents, params?: T) => Promise<never>;
}

const Context = createContext({} as IEventHandlerContext);
export const useEventHandler = (): IEventHandlerContext => useContext(Context);

export const EventHandlerProvider = ({ children, events }: IEventHandlerProviderProps): VNode => {
  const { dispatchEvent, dispatchEventWithResponse } = getEventHandler(events);

  return (
    <Context.Provider value={{ dispatchEvent, dispatchEventWithResponse }}>
      {children}
    </Context.Provider>
  );
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const mapStateToProps = (state: AppState) => ({
  events: eventsSelector(state),
});

export default connect(mapStateToProps)(EventHandlerProvider);
