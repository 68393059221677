import styled, { css } from 'styled-components';
import tickIcon from '../../../assets/tick.svg';
import { resolveRelativeUrl } from '../../../utils/device';
import { Body1, Body2 } from '../../typography';

export const Container = styled(Body1).attrs({ as: 'div' })`
  display: flex;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  padding-bottom: ${({ noPadding }) => (noPadding ? 0 : 16)}px;
  align-items: center;
`;

export const Box = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 2px;
  flex-shrink: 0;
  border: solid 1px ${({ theme, disabled }) => (disabled ? theme.lighterGray : theme.containerGray)};
  margin-right: 8px;
  background-color: ${({ theme, disabled }) => (disabled ? theme.lighterGray : theme.whiteBG)};

  ${({ checked }) =>
    checked &&
    css`
      background-image: url(${resolveRelativeUrl(tickIcon)});
      background-size: 11px 8px;
      background-position: center;
      background-repeat: no-repeat;
    `};

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      filter: grayscale(100%);
      pointer-events: none;
    `};
`;

export const ErrorMessage = styled.div`
  color: ${({ theme }) => theme.errorRed};
  width: 100%;
  padding-top: 8px;
`;

export const Label = styled(Body2).attrs({ as: 'div' })`
  flex: 1;
  font-style: ${({ italic }) => (italic ? 'italic' : 'normal')};
`;

export const ChildWrapper = styled(Body2).attrs({ as: 'div' })`
  display: flex;
  margin-bottom: 0;
  width: ${({ fullWidthChild }) => (fullWidthChild ? '80%' : 'unset')};
`;
