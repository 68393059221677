import { FunctionalComponent, h } from 'preact';
import { connect } from 'react-redux';
import { menuLocationSelector } from '../../selectors/topNavSelectors';
import { AppState } from '../../interfaces/appState';
import { IMenuLocationModel } from '../../models/MenuLocation';
import { GLOBAL_BANNER_LOCATION } from '../../enums/topNav';
import PromoBanner from '../../components/promoBanner';
import { IBannerModel } from '../../models/Banner';

const globalBannerSelector = menuLocationSelector(GLOBAL_BANNER_LOCATION);

export interface IGlobalBannerProps {
  menuLocation?: IMenuLocationModel;
}

const GlobalBanner: FunctionalComponent<IGlobalBannerProps> = ({ menuLocation }) => {
  const { menuItemList: [promoBanner] = [] } = menuLocation || {};

  return promoBanner ? <PromoBanner promoBanner={promoBanner as IBannerModel} /> : null;
};

const mapStateToProps = (state: AppState) => ({
  menuLocation: globalBannerSelector(state),
});

export default connect(mapStateToProps)(GlobalBanner);
