interface IHot {
  accept(): void;
}

export interface IModule {
  hot?: IHot;
}

export const moduleHotAccept = ({ hot }: IModule): void => {
  hot?.accept();
};
