import styled from 'styled-components';
import ButtonStroke from '../buttonStroke';
import { Media } from '../../../styles/media';

const { tablet } = Media;

type ButtonProps = {
  secondary: boolean;
};

const ButtonFloating = styled(ButtonStroke)<ButtonProps>`
  padding: 0;
  border: none;
  box-shadow: none;
  color: ${(secondary) => secondary && 'white'};
  background-color: transparent;

  &:hover,
  a&:hover,
  &:focus,
  &:active {
    box-shadow: none;
    background-color: transparent;
  }

  &:disabled {
    &,
    &:hover {
      background-color: transparent;
    }
  }

  ${tablet`
    padding: 0;
  `};
`;

export default ButtonFloating;
