/** @jsx h */
import noop from 'lodash/noop';
import { FunctionalComponent, h } from 'preact';
import { IItemModel } from '../../../../models/Item';
import AppLink from '../../../appLink';
import { Secondary } from '../../desktopNavStyled';

export interface ISecondaryButtonProps {
  item: IItemModel;
  onClick?(event: Event): void;
  margin?: string;
}

const SecondaryButton: FunctionalComponent<ISecondaryButtonProps> = ({ item, onClick, margin }) => {
  const { anchorText } = item;

  return (
    <AppLink item={item} onClick={onClick}>
      <Secondary margin={margin}>{anchorText}</Secondary>
    </AppLink>
  );
};

SecondaryButton.defaultProps = {
  onClick: noop,
};

export default SecondaryButton;
