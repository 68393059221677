/** @jsx h */
import { FunctionalComponent, h } from 'preact';
import { useMemo } from 'preact/hooks';
import mobileFactory from '../../factories/MobileFactory';
import { IMenuLocationModel } from '../../models/MenuLocation';

export interface INavigationFactoryProps {
  menuLocation: IMenuLocationModel;
  onClose(): void;
}

const SideNavLocation: FunctionalComponent<INavigationFactoryProps> = ({
  menuLocation,
  onClose,
}) => {
  const Component = useMemo(() => mobileFactory.mapMenuLocation(menuLocation), [menuLocation]);

  return <Component menuLocation={menuLocation} onClose={onClose} />;
};

export default SideNavLocation;
