/** @jsx h */
import { FunctionalComponent, h } from 'preact';
import { ItemLink, Container } from '../navLink/navLinkStyled';
import { IMenuModel } from '../../../../models/Menu';
import { IMenuItemModel } from '../../../../models/Menu';
import { DropdownMenu } from '../../../dropdownMenu';
import { Dropdown } from '../../../dropdown';
import { HUNDRED_PERCENT } from '../../../../utils/constants';

const COLUMN_PADDING = '5px 28px';

export interface ILoginDropdownProps {
  item: IMenuItemModel;
}

const LoginDropdown: FunctionalComponent<ILoginDropdownProps> = ({ item }) => {
  const menu = item as IMenuModel;
  const { parentItem: { anchorText } = {} } = menu;

  return (
    <Container>
      <Dropdown
        openComponent={(isOpen) => (
          <ItemLink isOpen={isOpen} key={anchorText}>
            {anchorText}
          </ItemLink>
        )}
        right="0px"
        top="30px"
      >
        <DropdownMenu menu={menu} columnPadding={COLUMN_PADDING} columnWidth={HUNDRED_PERCENT} />
      </Dropdown>
    </Container>
  );
};

export default LoginDropdown;
