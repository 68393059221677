/** @jsx h */
import { FunctionalComponent, h } from 'preact';
import { useCallback } from 'preact/hooks';
import noop from 'lodash/noop';
import { NavItem } from '../../../../containers/mobileNavigation/mobileNavigationStyled';
import { IItemModel } from '../../../../models/Item';
import { IMenuItemModel } from '../../../../models/Menu';
import { useEventHandler } from '../../../../providers/eventHandlerProvider';
import { ItemIcon } from '../../../desktopNav/desktopNavStyled';
import { SupportedEvents } from '../../../../enums/event';
import { ISideNavItemProps } from '../../../sideNavItem';

export interface ISignUpProps extends ISideNavItemProps {
  item: IMenuItemModel;
}

const SignUp: FunctionalComponent<ISignUpProps> = ({ item, onClose = noop }) => {
  const { anchorText, menuImageUrl } = item as IItemModel;
  const { dispatchEvent } = useEventHandler();

  const handleSignUp = useCallback(
    (event: Event) => {
      event.preventDefault();

      onClose();
      dispatchEvent(SupportedEvents.onSignUp);
    },
    [dispatchEvent, onClose],
  );

  return (
    <NavItem shouldNotJustify isBold onClick={handleSignUp}>
      {menuImageUrl && <ItemIcon alt={anchorText} src={menuImageUrl} hasContent={!!anchorText} />}
      {anchorText}
    </NavItem>
  );
};

export default SignUp;
