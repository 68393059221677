/** @jsx h */
import { FunctionalComponent, h } from 'preact';
import { connect } from 'react-redux';
import { Link, Container } from './navLinkStyled';
import { IMenuItemModel } from '../../../../models/Menu';
import { IItemModel } from '../../../../models/Item';
import { isTopNavItemActiveSelector } from '../../../../selectors/activeItemSelectors';
import {
  E2E,
  getDataTestIdSuffix,
  getTestID,
  useE2ETesting,
} from '../../../../providers/e2eTestingProvider';
import { AppState } from '../../../../interfaces/appState';
import { ItemIcon } from '../../desktopNavStyled';

export interface INavLinkProps {
  item: IMenuItemModel;
  onClick?(event: Event): void;
  isActive: boolean;
}

const NavLink: FunctionalComponent<INavLinkProps> = ({ item, isActive, onClick }) => {
  const { testIdPrefix } = useE2ETesting();
  const { menuImageUrl, anchorText, itemName } = item as IItemModel;

  const elementTypeID = !!menuImageUrl ? E2E.component.icon : E2E.component.link;
  const testIdValue = getTestID(testIdPrefix, elementTypeID, getDataTestIdSuffix(itemName));

  return (
    <Container>
      <Link data-testid={testIdValue} item={item} isActive={isActive} onClick={onClick}>
        {menuImageUrl && (
          <ItemIcon alt={itemName || anchorText} src={menuImageUrl} hasContent={!!anchorText} />
        )}
        {anchorText}
      </Link>
    </Container>
  );
};

const mapStateToProps = (state: AppState, { item }: INavLinkProps) => {
  const { id } = item as IItemModel;
  return {
    isActive: isTopNavItemActiveSelector(state, id),
  };
};

export default connect(mapStateToProps)(NavLink);
