import styled from 'styled-components';
import { ITypography } from '../../interfaces/typography';
import { Media } from '../../../styles/media';

const { tablet } = Media;

export const Editorial2dTemplate: ITypography = {
  fontSize: '30px',
  fontWeight: '300',
};

export const Editorial2Template: ITypography = {
  fontSize: '22px',
  fontWeight: '300',
};

const { fontSize, fontWeight } = Editorial2Template;

type TextProps = {
  bold: boolean;
  fixedSize: boolean;
};

const Editorial2 = styled.h2<TextProps>`
  font-size: ${fontSize};
  font-weight: ${({ bold }) => (bold ? 'bold' : `${fontWeight}`)};
  ${({ fixedSize }) =>
    !fixedSize &&
    tablet`
    font-size: 30px;
  `};
`;

export default Editorial2;
