import { FunctionalComponent, h } from 'preact';
import { bindActionCreators } from 'redux';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { store } from '../../configureStore';
import { theme } from '../../styles/theme';
import Unification from '../unification';
import EventHandlerProvider from '../../providers/eventHandlerProvider';
import TealiumProvider from '../../providers/tealiumProvider';
import * as modulesActions from '../../actions/modulesActions';
import { IModulesActions, IModulesThunkActions } from '../../actions/interfaces/IModulesActions';

const { dispatch } = store;
const { internalInitialization } = bindActionCreators<IModulesThunkActions, IModulesActions>(
  modulesActions,
  dispatch,
);

internalInitialization();

const App: FunctionalComponent = () => (
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <EventHandlerProvider>
        <TealiumProvider>
          <Unification />
        </TealiumProvider>
      </EventHandlerProvider>
    </ThemeProvider>
  </Provider>
);

export default App;
