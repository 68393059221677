import styled from 'styled-components';
import { Media } from '../../styles/media';
import Body1 from '../../shared/typography/body1';

const { tabletUp } = Media;

export const PromoContainer = styled.div`
  background: ${({ backgroundColor, theme }) => backgroundColor || theme.nutritionalsYellow};
  color: ${({ theme }) => theme.darkGray};
  padding: 10px 24px;
  position: relative;

  ${tabletUp`
    z-index: 300;
  `};
`;

export const PromoText = styled.div`
  align-items: center;
  color: ${({ textColor, theme }) => textColor || theme.darkGray};
  display: flex;
  height: 18px;
  margin: 0 auto;
  justify-content: center;
`;

export const PromoEllipsis = styled(Body1)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0;
  font-weight: ${({ fontWeight }) => fontWeight};
  color: ${({ textColor, theme }) => textColor || theme.darkGray};
`;

export const PromoLink = styled(Body1).attrs({ as: 'a' })`
  color: ${({ ctaTextColor, theme }) => ctaTextColor || theme.darkGray};
  cursor: pointer;
  font-weight: ${({ ctaFontWeight }) => ctaFontWeight || 400};
  text-decoration: underline;
  white-space: nowrap;
  padding-left: 5px;
`;
