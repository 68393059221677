import IKeyValue from '../interfaces/IKeyValue';

export const ALL_PAGE_NAME = 'search-all';
export const AUTO_SUGGESTION_HITS_PER_PAGE = 5;
export const AUTO_SUGGESTION_PROPERTY = 'query';
export const DEBOUNCE_TIME_URL_UPDATE = 700;
export const ENTER_KEYCODE = 13;
export const ESCAPE_KEYCODE = 27;
export const FIRST_PAGE = 1;
export const LABEL_SEPARATOR = '||';
export const LIMIT_OF_DISPLAYED_ITEMS = 100;
export const QUERY_STRING_SEPARATOR = 'comma';
export const SORT_FACET_VALUES_BY = 'alpha';
export const TOP_CATEGORIES_LIMIT = 3;
export const WHITE_SPACES_REGEX = /\s/g;
export const LAST_ROUTE_STATE_KEY = 'lastRouteState';
export const TEN_MINUTE_TRAINER = 'TM';

export enum ALGOLIA {
  CONFIG_ID = 'ALGOLIA',
}

export enum SUPPORTED_LANGUAGES {
  EN = '',
  FR_FR = 'fr_fr',
  FR_CA = 'fr_ca',
  ES_US = 'es_us',
}

export enum SHOP_SUPPORTED_LANGUAGES {
  EN = '',
  EN_CA = 'en_ca',
  FR_FR = 'fr_fr',
  FR_CA = 'fr_ca',
  ES_US = 'es_us',
  EN_GB = 'en_gb',
}

export const LOCALE_INDICES_SUFFIXES = {
  [SUPPORTED_LANGUAGES.EN]: '',
  [SUPPORTED_LANGUAGES.FR_FR]: '-fr_FR',
  [SUPPORTED_LANGUAGES.FR_CA]: '-fr_CA',
  [SUPPORTED_LANGUAGES.ES_US]: '-es_US',
  [SHOP_SUPPORTED_LANGUAGES.EN_CA]: '-en_CA',
  [SHOP_SUPPORTED_LANGUAGES.EN_GB]: '-en_GB',
};

export enum ALGOLIA_SEARCH_INDICES {
  LESSONS = 'LESSONS_APPSYNC',
  OTHER = 'OTHER_VIDEOS_APPSYNC',
  PRODUCT = 'unification_product_catalog',
  PROGRAMS = 'PROGRAMS_APPSYNC',
  PROGRAM_MATERIALS = 'PROGRAM_MATERIALS_APPSYNC',
  RECIPES = 'RECIPES_APPSYNC',
  WORKOUTS = 'WORKOUTS_APPSYNC',
  SUGGESTIONS = 'AUTO_SUGGESTIONS',
}

export enum INDICES_LABEL {
  ALL = 'all',
  LESSONS = 'lessons',
  OTHER = 'other',
  PRODUCT = 'product',
  PROGRAMS = 'programs',
  PROGRAM_MATERIALS = 'programMaterials',
  RECIPES = 'recipes',
  WORKOUTS = 'workouts',
}

export enum SEARCH_TABS {
  ALL = 'all',
  LESSONS = 'lessons',
  OTHER = 'other',
  PRODUCT = 'product',
  PROGRAMS = 'programs',
  PROGRAM_MATERIALS = 'program-materials',
  RECIPES = 'recipes',
  WORKOUTS = 'workouts',
}

export enum PRODUCT_FIELDS {
  TITLE = 'title',
  IMAGES = 'productImages',
  URL = 'landingPageUrl',
}

export enum PROGRAM_FIELDS {
  DESCRIPTION = 'description',
  IMAGES = 'images',
  SLUG = 'slug',
  TITLE = 'title',
}

export enum PRODUCT_FACETS {
  CATEGORY = 'filters.ByParentCategory',
  CUSTOMER_TYPE = 'customerTypesForView',
  CUSTOMER_ROLES = 'customerRoles',
  GEAR = 'filters.ByTypeofGear',
  GENDER = 'filters.ByGender',
  FLAVOR = 'filters.ByFlavor',
  LEVEL = 'filters.ByProgramLevel',
  PROGRAM = 'filters.ByProgram',
  PROGRAM_TYPE = 'filters.ByProgramTypes',
  SIZE = 'filters.BySize',
  STYLE = 'filters.ByStyle',
  SUBCATEGORY_SHAKEOLOGY = 'subParentCategory.Shakeology',
  TITLE = 'title',
  TRAINER = 'filters.ByTrainer',
  TYPE = 'filters.ByOfType',
}

export enum PROGRAM_FACETS {
  DURATION = 'programDuration.range',
  TRAINER = 'trainer.name',
  TYPE = 'workoutType.title',
}

export enum RECIPE_FACETS {
  CONTAINER = 'videoDetailsModalRecipeSection.containerRowOne.facet',
  DISH = 'Dish.title',
  MAIN_INGREDIENT = 'MainIngredient.title',
  MEAL = 'Meal.facet',
  PROGRAM = 'program.facet',
  WORKS_WELL_FOR = 'videoDetailsModalContentGroupOne.items.facet',
}

export enum VIDEO_PROGRAM_TYPES {
  RECIPE = 'recipe',
  LESSON = 'lesson',
  WORKOUT = 'workout',
  PROMO = 'promo',
  FREE_WORKOUT = 'free-workout',
}

export const VIDEO_TYPE_TO_PATH: IKeyValue<string> = {
  [VIDEO_PROGRAM_TYPES.FREE_WORKOUT]: '/workouts',
  [VIDEO_PROGRAM_TYPES.WORKOUT]: '/workouts',
  DEFAULT: '/start-here',
};

export enum HIT_TYPES {
  PROGRAM = 'program',
  PRODUCT = 'product',
  WORKOUT = 'workout',
  LESSON = 'lesson',
  RECIPE = 'recipe',
  PROGRAM_MATERIAL = 'programMaterial',
}

export const PROGRAMS_REFINEMENT_FACETS = [
  { id: PROGRAM_FACETS.TYPE, hasEmbeddedAttributes: false },
  { id: PROGRAM_FACETS.TRAINER, hasEmbeddedAttributes: false },
  { id: PROGRAM_FACETS.DURATION, hasEmbeddedAttributes: false },
];

export const PRODUCT_REFINEMENT_FACETS = [
  { id: PRODUCT_FACETS.CATEGORY, hasEmbeddedAttributes: false },
  { id: PRODUCT_FACETS.PROGRAM, hasEmbeddedAttributes: false },
  { id: PRODUCT_FACETS.PROGRAM_TYPE, hasEmbeddedAttributes: false },
  { id: PRODUCT_FACETS.GENDER, hasEmbeddedAttributes: false },
  { id: PRODUCT_FACETS.SIZE, hasEmbeddedAttributes: false },
  { id: PRODUCT_FACETS.STYLE, hasEmbeddedAttributes: false },
  { id: PRODUCT_FACETS.TRAINER, hasEmbeddedAttributes: false },
  { id: PRODUCT_FACETS.TYPE, hasEmbeddedAttributes: false },
  { id: PRODUCT_FACETS.FLAVOR, hasEmbeddedAttributes: false },
  { id: PRODUCT_FACETS.GEAR, hasEmbeddedAttributes: false },
  { id: PRODUCT_FACETS.SUBCATEGORY_SHAKEOLOGY, hasEmbeddedAttributes: false },
];

export enum PROGRAMS_FACETS {
  DURATION = 'programDuration.range',
  TRAINER = 'trainer.name',
  TYPE = 'workoutType.title',
}

export enum SORT_INDICES {
  SORT_BY_DATE = 'sortByDate',
  SORT_BY_POPULARITY = 'sortByPopularity',
  SORT_BY_TITLE = 'sortByTitle',
}

export enum WORKOUTS_FACETS {
  BODY_PART = 'bodyParts',
  DURATION = 'videoDurationMinutes',
  EQUIPMENT = 'requiredEquips',
  LEVEL = 'workoutLevels',
  PROGRAM = 'program.title',
  TIER = 'tier',
  TRAINER = 'trainer.name',
  TYPE = 'workoutTypes.items',
}

export const WORKOUTS_REFINEMENT_FACETS = [
  { id: WORKOUTS_FACETS.TYPE, hasEmbeddedAttributes: false },
  { id: WORKOUTS_FACETS.BODY_PART, hasEmbeddedAttributes: false },
  { id: WORKOUTS_FACETS.TRAINER, hasEmbeddedAttributes: false },
  { id: WORKOUTS_FACETS.PROGRAM, hasEmbeddedAttributes: false },
];

export const WORKOUTS_RANGE_DURATION = {
  MAX: 100,
  MIN: 0,
};

export const FITNESS_OPTIONS = {
  BEGINNER: 'Beginner',
  INTERMEDIATE: 'Intermediate',
  ADVANCED: 'Advanced',
  ALL_LEVELS: 'All Levels',
};

export const WORKOUTS_CONTEXT = 'workouts';

export const LESSONS_FACETS = {
  PROGRAM: 'program.title',
};

export const LESSONS_REFINEMENT_FACETS = [
  { id: LESSONS_FACETS.PROGRAM, hasEmbeddedAttributes: false },
];

export enum CATEGORY_OPTIONS {
  APPAREL = 'Apparel',
  CHALLENGE_PACKS = 'Challenge Packs',
  GEAR = 'Gear',
  NUTRITION = 'Nutrition',
  SALE = 'Sale',
  SHAKEOLOGY = 'Shakeology',
  TSP = 'Total Solution Packs',
}

export const RECIPES_FACETS = {
  CONTAINER: 'videoDetailsModalRecipeSection.containerRowOne.facet',
  DISH: 'Dish.title',
  MAIN_INGREDIENT: 'MainIngredient.title',
  MEAL: 'Meal.facet',
  PROGRAM: 'program.facet',
  WORKS_WELL_FOR: 'videoDetailsModalContentGroupOne.items.facet',
};

export const RECIPES_REFINEMENT_FACETS = [
  { id: RECIPES_FACETS.MEAL, hasEmbeddedAttributes: false },
  { id: RECIPES_FACETS.WORKS_WELL_FOR, hasEmbeddedAttributes: true },
  { id: RECIPES_FACETS.DISH, hasEmbeddedAttributes: false },
  { id: RECIPES_FACETS.MAIN_INGREDIENT, hasEmbeddedAttributes: false },
  { id: RECIPES_FACETS.PROGRAM, hasEmbeddedAttributes: false },
  { id: RECIPES_FACETS.CONTAINER, hasEmbeddedAttributes: true },
];

export const PROGRAM_MATERIALS_FACETS = {
  PROGRAM: 'program.title',
};

export const PROGRAM_MATERIALS_REFINEMENT_FACETS = [
  { id: PROGRAM_MATERIALS_FACETS.PROGRAM, hasEmbeddedAttributes: false },
];

export const BODi = 'BODi';
export const NoEquipment = 'No Equipment Needed';
