import { IAction, ThunkResult } from './IAction';
import { IEntitlementModel } from '../../models/Entitlement';
import { IAccessPolicyModel } from '../../models/AccessPolicy';

const ENTITLEMENT_PREFIX = 'ENTITLEMENT';
export const SET_RAMS_ENTITLEMENTS = `${ENTITLEMENT_PREFIX}/SET_RAMS_ENTITLEMENTS`;
export const SET_GROUPS_ENTITLEMENTS = `${ENTITLEMENT_PREFIX}/SET_GROUPS_ENTITLEMENTS`;
export const SET_ACCESS_POLICIES = `${ENTITLEMENT_PREFIX}/SET_ACCESS_POLICIES`;

export interface ISetRamsEntitlementsAction extends IAction {
  payload: Array<string>;
}

export interface ISetGroupsEntitlementsAction extends IAction {
  payload: Array<IEntitlementModel>;
}

export interface ISetAccessPoliciesAction extends IAction {
  payload: Array<IAccessPolicyModel>;
}

export interface IGetEntitlements {
  (): ThunkResult<
    ISetRamsEntitlementsAction | ISetGroupsEntitlementsAction | ISetAccessPoliciesAction
  >;
}

export type EntitlementActions =
  | ISetRamsEntitlementsAction
  | ISetGroupsEntitlementsAction
  | ISetAccessPoliciesAction;
