/** @jsx h */
import { FunctionalComponent, h } from 'preact';
import { PropRef, useRef, useCallback } from 'preact/hooks';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { IItemModel } from '../../../../models/Item';
import { IMenuItemModel } from '../../../../models/Menu';
import { ItemIcon } from '../../../desktopNav/desktopNavStyled';
import AppLink from '../../../../components/appLink';
import { SubNavItem, SubNavItemText } from './subNavLinkStyled';
import { isSubNavItemActiveSelector } from '../../../../selectors/activeItemSelectors';
import { AppState } from '../../../../interfaces/appState';
import { TopNavComponents } from '../../../../enums/tealium';
import { subNavLinkLocale } from './locale';
import { useDropdown } from '../../../../providers/dropdownProvider';
import {
  E2E,
  getDataTestIdSuffix,
  getTestID,
  useE2ETesting,
} from '../../../../providers/e2eTestingProvider';

export interface ISubNavLinkProps {
  item: IMenuItemModel;
  onClick?(event: Event): void;
  isActive?: boolean;
  isDropdown?: boolean;
  isOpen?: boolean;
  handleMouseEnter?: () => void;
  handleMouseLeave?: () => void;
  wrapperRef?: PropRef<HTMLElement>;
}

const SubNavLink: FunctionalComponent<ISubNavLinkProps> = ({
  item,
  onClick,
  isActive,
  isDropdown = false,
  isOpen,
  handleMouseEnter,
  handleMouseLeave,
  wrapperRef,
}) => {
  const itemModel = item as IItemModel;
  const { anchorText = '', menuImageUrl } = itemModel;
  const { t } = useTranslation();
  const linkRef = useRef<HTMLElement>();
  const { focusedMenuRef, onBlurCallback } = useDropdown();
  const { testIdPrefix } = useE2ETesting();
  const testIdValue = getTestID(
    testIdPrefix,
    E2E.component.link,
    getDataTestIdSuffix(itemModel?.itemName),
  );

  const handleEventListener = useCallback(
    (event: KeyboardEvent) => {
      if (event.shiftKey && event.key === ' ') {
        handleMouseEnter && handleMouseEnter();
      }
    },
    [handleMouseEnter],
  );

  const handleOnBlur = useCallback(
    (event: FocusEvent) => {
      if (wrapperRef && !wrapperRef.current?.contains(event.relatedTarget as Node)) {
        handleMouseLeave && handleMouseLeave();
        linkRef.current?.removeEventListener('keydown', handleEventListener);
      }
    },
    [handleEventListener, handleMouseLeave, wrapperRef],
  );

  const handleOnFocus = useCallback(() => {
    if (!focusedMenuRef?.current) {
      linkRef.current?.addEventListener('keydown', handleEventListener);
    }

    if (focusedMenuRef && wrapperRef && onBlurCallback) {
      focusedMenuRef.current = wrapperRef.current;
      onBlurCallback.current = handleOnBlur;
    }
  }, [focusedMenuRef, handleEventListener, handleOnBlur, onBlurCallback, wrapperRef]);

  return (
    <SubNavItem isActive={isActive} isLink={!isDropdown} data-testid={testIdValue}>
      <AppLink
        ref={linkRef}
        tealiumComponent={TopNavComponents.SUBNAV}
        item={itemModel}
        onClick={onClick}
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
        role="button"
        aria-haspopup="true"
        aria-expanded={isOpen}
        aria-label={`${anchorText}. ${t(subNavLinkLocale.ariaLabel)}`}
      >
        {menuImageUrl && <ItemIcon alt={anchorText} src={menuImageUrl} hasContent={!!anchorText} />}
        <SubNavItemText dangerouslySetInnerHTML={{ __html: anchorText }} />
      </AppLink>
    </SubNavItem>
  );
};

const mapStateToProps = (state: AppState, { item, isActive }: ISubNavLinkProps) => {
  const { id } = item as IItemModel;
  return {
    isActive: isSubNavItemActiveSelector(state, id) || isActive,
  };
};

export default connect(mapStateToProps)(SubNavLink);
