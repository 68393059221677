/** @jsx h */
import { Fragment, FunctionalComponent, h } from 'preact';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Clear,
  FAQImage,
  FAQSection,
  FAQTitle,
  HeaderContainer,
  LeftSection,
  SpinnerContainer,
  SuggestionContainer,
  Title,
} from './suggestionContainerStyled';
import { Suggestion } from './autoCompleteStyled';
import { IHit, IHits, IRecentSearch, ISuggestion } from '../../interfaces/ISearch';
import { AppState } from '../../interfaces/appState';
import { modulesByKeySelector } from '../../selectors/modulesSelectors';
import { ILocaleModel } from '../../models/Locale';
import { productFilterSelector, recentSearchesSelector } from '../../selectors/searchSelector';
import { AutoCompleteLocale } from './locale';
import { IMainModel } from '../../models/Main';
import { DeviceTypes } from '../../utils/device';
import TopCategories from '../topCategories';
import { Backdrop } from '../../containers/topNav/topNavStyled';
import TopResultHit from '../topResultHit';
import Spinner from '../../shared/components/spinner';

export interface ISuggestionsContainerProps {
  containerProps: ISuggestion;
  clearRecentSearches: () => void;
  hits: Array<IHits>;
  isFAQTerm: boolean;
  isVisible: boolean;
  locale: ILocaleModel;
  main: IMainModel;
  onFAQSelected: () => void;
  onSuggestionSelected: (selected: IRecentSearch) => void;
  productFilter: string;
  query: string;
  recentSearches: Array<IRecentSearch>;
  topResult: IHit;
  onHide: () => void;
  isLoading: boolean;
}

export const SuggestionsContainer: FunctionalComponent<ISuggestionsContainerProps> = ({
  children,
  containerProps,
  clearRecentSearches,
  hits,
  isFAQTerm,
  isVisible,
  locale,
  main,
  onFAQSelected,
  onSuggestionSelected,
  productFilter,
  recentSearches,
  query,
  onHide,
  isLoading,
}) => {
  const { device } = main;
  const { t } = useTranslation();
  const isContainingResults = !!hits.length;

  return (
    <div {...containerProps}>
      <Backdrop isVisible={isVisible} onClick={onHide} />
      <SuggestionContainer>
        {isFAQTerm && isVisible ? (
          <FAQSection onClick={onFAQSelected}>
            <FAQImage />
            <FAQTitle>{t(AutoCompleteLocale.faqTitle, { query })}</FAQTitle>
          </FAQSection>
        ) : (
          <Fragment>
            {isLoading && (
              <SpinnerContainer>
                <Spinner />
              </SpinnerContainer>
            )}
            {query && isVisible && (
              <Fragment>
                {isContainingResults ? (
                  <LeftSection isContainingResults={isContainingResults}>{children}</LeftSection>
                ) : null}
                {device === DeviceTypes.mobile && <TopResultHit />}
                <TopCategories
                  isContainingResults={isContainingResults}
                  onSuggestionSelected={onSuggestionSelected}
                  productFilter={productFilter}
                  query={query}
                  selectedLocale={locale.selectedLocale}
                />
                {device !== DeviceTypes.mobile && <TopResultHit />}
              </Fragment>
            )}
            {/* eslint-disable-next-line max-len */}
            {!query && isVisible && (recentSearches.length > 0 || device === DeviceTypes.mobile) && (
              <Fragment>
                <LeftSection isRecentSearch>
                  {!!recentSearches.length && (
                    <HeaderContainer>
                      <Title>{t(AutoCompleteLocale.recentSearches)}</Title>
                      <Clear onClick={clearRecentSearches}>{t(AutoCompleteLocale.clearAll)}</Clear>
                    </HeaderContainer>
                  )}
                  {recentSearches.map((recentSearch) => (
                    <div key={recentSearch.value}>
                      <Suggestion
                        onClick={() =>
                          onSuggestionSelected({
                            recentSearchValue: recentSearch.value,
                            tab: recentSearch.tab,
                          } as IRecentSearch)
                        }
                      >
                        {recentSearch.value}
                      </Suggestion>
                    </div>
                  ))}
                </LeftSection>
              </Fragment>
            )}
          </Fragment>
        )}
      </SuggestionContainer>
    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  locale: modulesByKeySelector(state, 'locale') as ILocaleModel,
  main: modulesByKeySelector(state, 'main') as IMainModel,
  productFilter: productFilterSelector(state),
  recentSearches: recentSearchesSelector(state),
});

export default connect(mapStateToProps)(SuggestionsContainer);
