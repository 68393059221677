import { Record } from 'immutable';
import filter from 'lodash/filter';
import reduce from 'lodash/reduce';
import { IModelStatic } from '../interfaces/IModel';
import { IModulesStateRecord } from '../interfaces/IModules';
import Item, { IItemModel } from './Item';
import Menu, { IMenuItemModel, IMenuModel } from './Menu';

export enum AppContextTypes {
  app = 'app',
  loggedStatus = 'loggedStatus',
  entitlement = 'entitlement',
  userRole = 'userRole',
  hasGroups = 'hasGroups',
  device = 'device',
  nonSupported = 'nonSupported',
  navCategory = 'navCategory',
  notGroups = 'notGroups',
  missingEntitlement = 'missingEntitlement',
  missingRole = 'missingRole',
  geolocation = 'geolocation',
  supportedLanguage = 'supportedLanguage',
  beachbodyReferrer = 'beachbodyReferrer',
  supportedInDirectResponseMarketingRegion = 'supportedInDirectResponseMarketingRegion',
}
const supportedRuleList = Object.values(AppContextTypes);

export interface IAppContext {
  type: AppContextTypes;
  rules: Array<string>;
}

export interface IAppContextModel extends Record<IAppContext>, IAppContext {
  applyRuleType(modules: IModulesStateRecord): boolean;
}

export interface IAppContextStatic extends IModelStatic<IAppContextModel, IAppContext> {
  applyRuleTypeList(appContextList: Array<IAppContextModel>, modules: IModulesStateRecord): boolean;
  applyToMenuItemList(
    menuItemList: Array<IMenuItemModel>,
    modules: IModulesStateRecord,
  ): Array<IMenuItemModel>;
}

const defaultProps = {
  type: AppContextTypes.nonSupported,
  rules: [],
};

const AppContext: IAppContextStatic = class
  extends Record<IAppContext>(defaultProps, 'AppContext')
  implements IAppContextModel {
  static createFromRaw(rawAppContext?: IAppContext): IAppContextModel {
    return new this(rawAppContext || {});
  }

  applyRuleType({ sso, main, locale }: IModulesStateRecord): boolean {
    const { type, rules } = this;
    switch (type) {
      // SSO
      case AppContextTypes.userRole: {
        return sso.validateUserRole(rules);
      }
      case AppContextTypes.missingRole: {
        return sso.validateMissingUserRole(rules);
      }
      case AppContextTypes.entitlement: {
        return sso.validateEntitlementList(rules);
      }
      case AppContextTypes.missingEntitlement: {
        return sso.validateMissingEntitlement(rules);
      }
      case AppContextTypes.loggedStatus: {
        return sso.validateUserLoggedStatus(rules);
      }
      case AppContextTypes.hasGroups: {
        return !!sso.hasGroups;
      }
      case AppContextTypes.supportedInDirectResponseMarketingRegion: {
        return sso.validateSupportedInDirectResponseMarketingRegion(
          rules,
          locale.geolocation?.country || '',
        );
      }

      // MAIN
      case AppContextTypes.app: {
        return main.validateAppId(rules);
      }

      case AppContextTypes.beachbodyReferrer: {
        return main.validateReferrer();
      }

      // DEVICE
      case AppContextTypes.device: {
        return main.validateDevice(rules);
      }

      // LOCALE
      case AppContextTypes.geolocation: {
        return locale.validateGeolocation(rules);
      }

      case AppContextTypes.supportedLanguage: {
        return locale.validateSupportedLanguage(rules);
      }

      default: {
        return true;
      }
    }
  }

  static applyRuleTypeList(
    appContextList: Array<IAppContextModel>,
    modules: IModulesStateRecord,
  ): boolean {
    return filter(appContextList, ({ type }: IAppContextModel) =>
      supportedRuleList.includes(type),
    ).reduce((shouldBeDisplayed: boolean, appContext) => {
      if (!shouldBeDisplayed) return false;

      return appContext.applyRuleType(modules);
    }, true);
  }

  static applyToMenuItemList(
    menuItemList: Array<IMenuItemModel>,
    modules: IModulesStateRecord,
  ): Array<IMenuItemModel> {
    return reduce(
      menuItemList,
      (acc: Array<IMenuItemModel>, currentItem): Array<IMenuItemModel> => {
        const recordName = Record.getDescriptiveName(currentItem);
        if (recordName === Item.displayName) {
          return AppContext.applyRuleTypeList((currentItem as IItemModel).appContextList, modules)
            ? acc.concat([currentItem])
            : acc;
        }

        if (recordName === Menu.displayName) {
          const menu = currentItem as IMenuModel;
          const shouldApplyAppContext = AppContext.applyRuleTypeList(menu.appContextList, modules);
          const canApplyAppContext = !!menu.applyAppContext;

          if (!shouldApplyAppContext) return acc;

          if (canApplyAppContext) return acc.concat([menu.applyAppContext(modules)]);

          return acc.concat([currentItem]);
        }

        return acc.concat([currentItem]);
      },
      [],
    );
  }
};

export default AppContext;
