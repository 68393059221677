/** @jsx h */
import { createContext, VNode, h, FunctionalComponent } from 'preact';
import { useCallback, useContext, useEffect } from 'preact/hooks';
import { connect } from 'react-redux';
import { ITealiumEvent, IUtag, IUtagData } from '../interfaces/ITealium';
import { AppState } from '../interfaces/appState';
import { modulesByKeySelector } from '../selectors/modulesSelectors';
import { ILocaleModel } from '../models/Locale';
import { IMainModel } from '../models/Main';
import { getGlobals, sanitizeTealiumObject } from '../gateways/tealium/tealium';
import { sendTealiumLinkEvent, sendTealiumViewEvent } from '../services/tealiumService';

declare global {
  interface Window {
    utag: IUtag;
    utag_data: IUtagData;
  }
}

export interface ITealiumProvider {
  analyticsData?: IUtagData;
  children: VNode;
  locale: ILocaleModel;
  main: IMainModel;
}

export interface ITealiumContext {
  event: (
    jsonTemplate: IUtagData,
    data: IUtagData,
    eventPassed?: ITealiumEvent,
    shouldPreserveWhitespace?: boolean,
  ) => void;
  view: (jsonTemplate: IUtagData, data: IUtagData, shouldPreserveWhitespace?: boolean) => void;
  sanitizeTealiumObject: (tealiumObject: IUtagData) => IUtagData;
  initializeTealium: () => void;
}

const Context = createContext({} as ITealiumContext);
export const useTealium = (): ITealiumContext => useContext(Context);

export const TealiumProvider: FunctionalComponent<ITealiumProvider> = ({
  analyticsData,
  children,
  main,
  locale,
}) => {
  const { selectedLocale } = locale;
  const { appId, userIP = '' } = main;

  const initializeTealium = useCallback((): void => {
    if (window.utag_data && !window.utag_data.site_name) {
      window.utag_data = {
        ...window.utag_data,
        ...getGlobals(appId, selectedLocale, userIP),
        ...analyticsData,
      };
    }
  }, [analyticsData, appId, selectedLocale, userIP]);

  const event = async (
    jsonTemplate: IUtagData,
    data: IUtagData,
    eventPassed: ITealiumEvent = {} as ITealiumEvent,
    shouldPreserveWhitespace = false,
  ) => {
    sendTealiumLinkEvent(jsonTemplate, data, eventPassed, shouldPreserveWhitespace);
  };

  const view = async (
    jsonTemplate: IUtagData,
    data: IUtagData,
    shouldPreserveWhitespace = false,
  ) => {
    sendTealiumViewEvent(jsonTemplate, data, shouldPreserveWhitespace);
  };

  useEffect(() => {
    initializeTealium();
  }, [analyticsData, initializeTealium]);

  return (
    <Context.Provider value={{ event, initializeTealium, sanitizeTealiumObject, view }}>
      {children}
    </Context.Provider>
  );
};

export interface IMapStateToProps {
  locale: ILocaleModel;
  main: IMainModel;
}

export const mapStateToProps = (state: AppState): IMapStateToProps => ({
  locale: modulesByKeySelector(state, 'locale') as ILocaleModel,
  main: modulesByKeySelector(state, 'main') as IMainModel,
});

export default connect(mapStateToProps)(TealiumProvider);
