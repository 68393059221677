import styled, { css } from 'styled-components';
import { Button2Fill, Button2Stroke } from '../../shared/buttons';
import AppLink from '../appLink';

interface IItemIconProps {
  hasContent: boolean;
}

export const ItemIcon = styled.img.attrs<IItemIconProps>({
  width: 13,
  height: 13,
})`
  width: 100%;
  height: auto;
  ${({ hasContent }) =>
    hasContent &&
    css`
      margin-right: 12px;
      display: flex;
      width: 13px;
      height: 13px;
    `};
`;

export const ItemContainer = styled.li`
  margin-bottom: 8px;
  line-height: 1.5;

  ${({ isFeatured }) =>
    isFeatured &&
    css`
      margin-top: 16px;
    `};
`;

export const ItemLink = styled(AppLink)`
  cursor: pointer;
  display: flex;
  margin-bottom: 0;

  &:hover {
    color: ${({ theme }) => theme.oneAppBlue};
  }
`;

export const ItemText = styled.span`
  color: ${({ theme }) => theme.darkGray};
  font-weight: ${({ isBold }) => (isBold ? 'bold' : 'normal')};
  margin: 0;
  letter-spacing: 0.2px;
  display: flex;
  margin-bottom: 0;
  ${({ isFeatured }) =>
    isFeatured &&
    css`
      margin-top: 16px;
    `};
`;

export const ItemLinkText = styled(ItemText)`
  cursor: pointer;
  color: inherit;
`;

export const CoachLoginLink = styled(ItemLinkText)`
  color: ${({ theme }) => theme.oneAppBlue};
`;

export const Primary = styled(Button2Fill)`
  margin: 0 0 16px;
  width: 100%;
`;

export const TopNavLogin = styled(Button2Fill)`
  width: 100%;
`;

export const Secondary = styled(Button2Stroke)`
  width: 100%;
  margin: ${({ margin }) => margin};
`;

export const UserInfoProfileContainer = styled.li`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const AnchorText = styled.span``;
