import styled from 'styled-components';
import { BodyMed } from '../../../../shared/typography';

export const LocaleContainer = styled.div`
  align-items: center;
  border-radius: 50%;
  display: flex;
  justify-content: left;
`;

export const LocaleIcon = styled.img`
  width: 20px;
  height: 20px;
`;

export const SelectedLocale = styled(BodyMed)`
  color: ${({ theme }) => theme.darkGray};
  margin: 0 8px;
`;

export const LocaleItem = styled.p`
  padding-right: 15px;
  font-weight: ${({ isSelected }) => (isSelected ? 'bold' : 'normal')};
  cursor: pointer;
  display: flex;
  margin: 0 8px;
`;
