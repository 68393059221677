import styled, { css } from 'styled-components';
import ArrowIcon from '../../../../assets/arrow.svg';
import CheckIcon from '../../../../assets/check.svg';
import { resolveRelativeUrl } from '../../../../utils/device';

const rotateElement = (degrees: number) => `transform: rotate(${degrees}deg)`;

export const Arrow = styled.img.attrs({ src: `${resolveRelativeUrl(ArrowIcon)}` })`
  width: max-content;
  ${({ isOpen }) =>
    isOpen &&
    css`
      ${rotateElement(180)};
    `};
`;

export const RightArrow = styled(Arrow)`
  ${rotateElement(-90)};
`;

export const LeftArrow = styled(Arrow)`
  ${rotateElement(90)};
`;

export const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CollapseContainer = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    font-size: 14px;
    background-color: ${({ theme }) => theme.pearlGray};
    padding-left: 40px;
    &:first-child {
      border-top: none;
    }
  }
`;

export const Checked = styled.img.attrs({ src: `${resolveRelativeUrl(CheckIcon)}` })``;
