/** @jsx h */
import { Fragment, FunctionalComponent, h } from 'preact';
import { useCallback } from 'preact/hooks';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { removeAccount } from '../../../../actions/ssoActions';
import { SupportedEvents } from '../../../../enums/event';
import { ISsoAccount } from '../../../../models/SsoAccount';
import { useEventHandler } from '../../../../providers/eventHandlerProvider';
import { AccountItem } from './accountItem';
import { Accounts, AddNewLink, Container, Title } from './accountSwitchStyled';
import { Roles } from '../../../../enums/sso';
import { CoachAccountsLocale } from './locale';

export interface IDropdownAccountsProps {
  accounts?: ISsoAccount[];
  removeAccount: typeof removeAccount;
  roleList: Array<Roles>;
}

export const DropdownAccounts: FunctionalComponent<IDropdownAccountsProps> = ({
  accounts = [],
  removeAccount,
  roleList,
}) => {
  const { t } = useTranslation();
  const { dispatchEvent } = useEventHandler();
  const shouldShowCoachId = !roleList.includes(Roles.preferredCustomer);

  const handleAddAccountAdd = useCallback(
    (event: Event) => {
      event.preventDefault();
      dispatchEvent(SupportedEvents.onAccountAdd);
    },
    [dispatchEvent],
  );

  return (
    <Container>
      {accounts && accounts.length > 0 && (
        <Fragment>
          <Title>{t(CoachAccountsLocale.signIn)}</Title>
          <Accounts>
            {accounts.map((account) => (
              <AccountItem
                key={account.coachId}
                account={account}
                removeAccount={removeAccount}
                shouldShowCoachId={shouldShowCoachId}
              />
            ))}
          </Accounts>
        </Fragment>
      )}
      <AddNewLink onClick={handleAddAccountAdd}>+ {t(CoachAccountsLocale.addNew)}</AddNewLink>
    </Container>
  );
};

export default connect(null, { removeAccount })(DropdownAccounts);
