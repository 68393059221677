import { Action } from 'redux';
import { ThunkResult } from './IAction';
import { IVHSModel } from '../../models/Vhs';

export const SET_VIDEO_HISTORY = 'VHS/SET_VIDEO_HISTORY';
export const SET_SHOULD_LOAD_HISTORY = 'VHS/SET_SHOULD_LOAD_HISTORY';

export interface ISetVideoHistoryAction extends Action<typeof SET_VIDEO_HISTORY> {
  payload: Array<IVHSModel>;
}

export interface ISetShouldLoadHistoryAction extends Action<typeof SET_SHOULD_LOAD_HISTORY> {
  payload: boolean;
}

export interface IGetVideoHistoryActionCreator {
  (): ThunkResult<ISetVideoHistoryAction | ISetShouldLoadHistoryAction>;
}

export type VHSActions = ISetVideoHistoryAction | ISetShouldLoadHistoryAction;
