import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Document } from '@contentful/rich-text-types';
import { Record } from 'immutable';
import { ComponentChild } from 'preact';
import { IModelStatic } from '../interfaces/IModel';

export enum RichTextNodeTypes {
  text = 'text',
}

export interface IRichTextMark {
  type: string;
}

export interface IRichTextContent {
  nodeType: string;
  value: string;
  marks: Array<IRichTextMark>;
  content: Array<IRichTextContent>;
}

export interface IRichText {
  nodeType: string;
  content: Array<IRichTextContent>;
}

export interface IRichTextModel extends Record<IRichText>, IRichText {
  getContentValue(): ComponentChild;
}

const defaultProps = {
  nodeType: '',
  content: [],
};

const RichText: IModelStatic<IRichTextModel, IRichText> = class
  extends Record<IRichText>(defaultProps, 'RichText')
  implements IRichTextModel {
  static createFromRaw(rawRichText: IRichText): IRichTextModel {
    return new this(rawRichText);
  }

  getContentValue(): ComponentChild {
    return documentToReactComponents((this as unknown) as Document);
  }
};

export default RichText;
