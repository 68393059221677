import styled from 'styled-components';
import { ITypography } from '../../interfaces/typography';

export const Body2aTemplate: ITypography = {
  fontSize: '16px',
  fontWeight: 'normal',
  lineHeight: '1.38',
  letterSpacing: '0.2px',
};

const { fontSize, fontWeight, lineHeight, letterSpacing } = Body2aTemplate;

type TextProps = {
  bold: boolean;
  defaultLetterSpacing: boolean;
};

const Body2a = styled.p<TextProps>`
  font-size: ${fontSize};
  font-weight: ${({ bold }) => (bold ? 'bold' : `${fontWeight}`)};
  line-height: ${lineHeight};
  letter-spacing: ${({ defaultLetterSpacing }) =>
    defaultLetterSpacing ? '0' : `${letterSpacing}`};
`;

export default Body2a;
