import { parse, stringify } from 'query-string';
import { PERPETUATED_URL_PARAM_WHITELIST } from '../enums/routes';
import { REGEX_SPECIAL_CHARACTERS, NFD } from './constants';
import { BODi } from '../enums/algolia';
import { loadFromConfig } from './configLoader';

const BODI_MULTIPLE_TRAINER_TBB_PARAMS = loadFromConfig('BODI_MULTIPLE_TRAINER_TBB_PARAMS');

export const getUrlWithPersistedParams = (url: string, extraParameters = {}): string => {
  const { search = '' } = location;
  const { query, referringRepId, ...rest } = parse(search);
  const shouldPersistParams = !!PERPETUATED_URL_PARAM_WHITELIST.find((path) => url.includes(path));

  return shouldPersistParams || referringRepId
    ? `${url}?${stringify(
        { query, referringRepId, ...rest, ...extraParameters },
        { skipEmptyString: true },
      )}`
    : `${url}?${stringify(extraParameters, { skipEmptyString: true })}`;
};

export const getBODiQueryParams = (
  formattedLocale: string,
  trainer: string,
): { referralProgramId: string; locale: string; trainerName: string } => ({
  referralProgramId: BODi,
  locale: formattedLocale,
  trainerName:
    BODI_MULTIPLE_TRAINER_TBB_PARAMS[trainer] ||
    trainer.normalize(NFD).replace(REGEX_SPECIAL_CHARACTERS, ''),
});
