import { h, createContext, VNode, FunctionalComponent, RefObject } from 'preact';
import { useRef } from 'preact/hooks';
import { useContext } from 'preact/hooks';

export interface IDropdownContext {
  focusedMenuRef: RefObject<HTMLElement | undefined> | null;
  onBlurCallback: RefObject<((event: FocusEvent) => void) | undefined> | null;
}

const Context = createContext({} as IDropdownContext);

export interface IDropdownProvider {
  children: VNode;
}

export const useDropdown = (): IDropdownContext => useContext(Context);

const DropdownProvider: FunctionalComponent<IDropdownProvider> = ({ children }) => {
  const onBlurCallback = useRef<(event: FocusEvent) => void>();
  const focusedMenuRef = useRef<HTMLElement>();

  return <Context.Provider value={{ onBlurCallback, focusedMenuRef }}>{children}</Context.Provider>;
};

export default DropdownProvider;
