import styled from 'styled-components';
import { Body2 } from '../../shared/typography';
import AppLink from '../appLink';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 160px;
`;

export const LocaleIcon = styled.img`
  margin: 0 12px;
  width: 24px;
  height: 24px;
`;

export const LocaleItem = styled(AppLink)`
  padding-right: 15px;
  color: ${({ theme }) => theme.medGray};
  cursor: pointer;
  display: flex;
  margin: 0 10px;
  margin-top: 16px;
  &:hover {
    color: ${({ theme }) => theme.oneAppBlue};
  }
`;

export const LocaleText = styled(Body2)`
  font-weight: ${({ isSelected }) => (isSelected ? 'bold' : 'normal')};
  color: ${({ isSelected, theme }) => (isSelected ? theme.darkGray : theme.medGray)};
  margin: 2px 4px;
  height: 20px;
  width: max-content;
  &:hover {
    color: ${({ theme }) => theme.oneAppBlue};
  }
`;

export const LocaleContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: -16px;
  width: max-content;
`;
