import { bindActionCreators, Store } from 'redux';
import { ILocaleActionCreators } from '../../actions/interfaces/ILocaleAction';
import { SupportedLanguages } from '../../enums/locale';
import { ILocaleActions } from '../../actions/interfaces/ILocaleAction';
import localeActions from '../../actions/localeActions';
import { IConfigLocale, ILocaleModel } from '../../models/Locale';
import { modulesByKeySelector } from '../../selectors/modulesSelectors';
import { log } from '../../utils/logging';

export interface ILocaleModule {
  getLocaleConfig(): Promise<IConfigLocale>;
  updateLocale(locale: SupportedLanguages): Promise<IConfigLocale>;
}

export const getLocaleModule = (store: Store): ILocaleModule => {
  const { dispatch, getState } = store;
  const { updateLocale } = bindActionCreators<ILocaleActionCreators, ILocaleActions>(
    localeActions,
    dispatch,
  );

  return {
    getLocaleConfig() {
      const locale = modulesByKeySelector(getState(), 'locale') as ILocaleModel;
      log('locale', 'getLocaleConfig', locale.toConfig());

      return Promise.resolve(locale.toConfig());
    },
    async updateLocale(selectedLocale) {
      log('locale', 'updateLocale', selectedLocale);
      const { payload: locale } = await updateLocale(selectedLocale);

      return locale.toConfig();
    },
  };
};
