import { DocumentNode } from 'graphql';
import IAppSyncClient from '../../../interfaces/IAppSyncClient';
import { getApolloClient } from './apolloClient';
import { OperationVariables } from '@apollo/client';

const AppSyncClient = <T>(locale: string, withCache: boolean): IAppSyncClient<T> => {
  const { query } = getApolloClient(locale, withCache);
  return {
    runQuery<F, X = T>(queryName: DocumentNode, variables: F & OperationVariables) {
      return (query<T>({ query: queryName, variables }) as unknown) as Promise<X>;
    },
  };
};

export default AppSyncClient;
