import styled from 'styled-components';

export const AvatarButton = styled.button`
  background-color: transparent;
  border: none;
  color: transparent;
  padding: 0;
  margin: 0;
`;

export const AvatarIcon = styled.img`
  border-radius: 50%;
  box-sizing: border-box;
  cursor: pointer;
  height: 30px;
  margin: 0;
  width: 30px;
  max-width: unset;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  min-width: 206px;
  width: ${({ showAccounts }) => (showAccounts ? '412px' : 'fit-content')};
`;
