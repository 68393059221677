/** @jsx h */
import { FunctionalComponent, h } from 'preact';
import { connect } from 'react-redux';
import SubNavigation from '../../components/subNav';
import { AppState } from '../../interfaces/appState';
import { IMenuLocationModel } from '../../models/MenuLocation';
import { subNavSelector } from '../../selectors/topNavSelectors';

export interface ISubNavProps {
  subNav?: IMenuLocationModel;
}

const SubNav: FunctionalComponent<ISubNavProps> = ({ subNav }) => {
  return subNav && !subNav?.isEmpty() ? <SubNavigation menuLocation={subNav} /> : null;
};

const mapStateToProps = (state: AppState) => ({
  subNav: subNavSelector(state),
});

export default connect(mapStateToProps)(SubNav);
