import styled from 'styled-components';
import { Media } from '../../../../styles/media';

const { tabletUp } = Media;

export const MenuOptions = styled.div`
  align-items: center;
  display: grid;
  grid-column: 3;
  grid-auto-flow: column;
  grid-column-gap: 16px;
  justify-self: end;
  ${tabletUp`
    margin-left: 50px;
  `};
`;
