import styled, { css, DefaultTheme } from 'styled-components';
import ButtonFill from '../buttonFill';
import { setAllLinkStates } from '../../../styles/media';

const Button1FillLinkStates = css<DefaultTheme>`
  color: ${({ theme }) => theme.oneAppBlue};
  text-decoration: none;
`;

export default styled(ButtonFill)`
  color: ${({ theme }) => theme.oneAppBlue};
  border: solid 1px ${({ theme }) => theme.oneAppBlue};
  background-color: ${({ theme }) => theme.whiteBG};

  a& {
    ${setAllLinkStates`${Button1FillLinkStates}`};
  }

  &:hover,
  a:hover& {
    color: ${({ theme }) => theme.containerBlue};
    border-color: ${({ theme }) => theme.containerBlue};
    background-color: ${({ theme }) => theme.whiteBG};
  }

  &:focus {
    color: ${({ theme }) => theme.darkBlue};
    border-color: ${({ theme }) => theme.darkBlue};
    background-color: ${({ theme }) => theme.whiteBG};
  }

  &:disabled {
    border-color: ${({ theme }) => theme.lighterGray};
    color: ${({ theme }) => theme.skyGray};

    &:hover {
      color: ${({ theme }) => theme.skyGray};
    }
  }
`;
