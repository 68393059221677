/** @jsx h */
import { Fragment, FunctionalComponent, h } from 'preact';
import { Configure } from 'react-instantsearch-dom';
import InstantSearchWrapper from '../instantSearchWrapper';
import { ALGOLIA_SEARCH_INDICES, AUTO_SUGGESTION_HITS_PER_PAGE } from '../../enums/algolia';
import { getShopIndexLocaleSuffix } from '../../utils/algolia';
import AutoComplete from '../autoComplete';
import { SupportedLanguages } from '../../enums/locale';

export interface IAutoSuggestionProps {
  initialValue: string;
  isVisible: boolean;
  onHide: () => void;
  locale: SupportedLanguages;
  placeholder: string;
}

const AutoSuggestion: FunctionalComponent<IAutoSuggestionProps> = ({
  initialValue,
  isVisible,
  locale,
  onHide,
  placeholder,
}) => {
  const localeSuffix = getShopIndexLocaleSuffix(locale);

  if (!isVisible) return null;

  return (
    <InstantSearchWrapper
      indexName={`${ALGOLIA_SEARCH_INDICES.SUGGESTIONS}${localeSuffix}`}
      withCustomClient
    >
      <Fragment>
        <Configure hitsPerPage={AUTO_SUGGESTION_HITS_PER_PAGE} />
        <AutoComplete
          initialValue={initialValue}
          isVisible={isVisible}
          onHide={onHide}
          placeholder={placeholder}
        />
      </Fragment>
    </InstantSearchWrapper>
  );
};

export default AutoSuggestion;
