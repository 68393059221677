/** @jsx h */
import { FunctionalComponent, h } from 'preact';
import { useMemo } from 'preact/hooks';
import { IMenuItemModel } from '../../models/Menu';
import { subNavDesktopFactory } from '../../factories/DesktopFactory';

export interface ISubNavItemProps {
  item: IMenuItemModel;
}

const SubNavItem: FunctionalComponent<ISubNavItemProps> = ({ item }) => {
  const Component = useMemo(() => subNavDesktopFactory.mapNavItem(item), [item]);

  return Component ? <Component item={item} /> : null;
};

export default SubNavItem;
