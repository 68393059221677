import Body1 from './body1';
import Body1d from './body1d';
import Body1d2 from './body1d2';
import Body2 from './body2';
import Body2a, { Body2aTemplate } from './body2a';
import Body3 from './body3';
import Body4 from './body4';
import Body4d from './body4d';
import Body4d2 from './body4d2';
import Body5 from './body5';
import Body6, { Body6Template } from './body6';
import Body6d from './body6d';
import CardFooter from './cardFooter';
import CardTitle from './cardTitle';
import Digits1 from './digits1';
import Digits2 from './digits2';
import Digits3 from './digits3';
import Editorial1 from './editorial1';
import Editorial2 from './editorial2';
import Editorial3 from './editorial3';
import Editorial4 from './editorial4';
import FTR from './ftr';
import Headline1dm from './headline1dm';
import Headline2dm from './headline2dm';
import Headline3 from './headline3';
import Headline3dm from './headline3dm';
import Headline4dm from './headline4dm';
import Headline5 from './headline5';
import HeadlineCard from './headlineCard';
import Intro1 from './intro1';
import Intro2 from './intro2';
import NAV1 from './nav1';
import NAV2 from './nav2';
import NAV3 from './nav3';
import Overlay1 from './overlay1';
import Section1 from './section1';
import Text1 from './text1';
import Text2 from './text2';
import Text3 from './text3';
import Text4 from './text4';
import Text5 from './text5';
import Text6 from './text6';
import Text7Template from './text7';
import Text8 from './text8';
import Text9 from './text9';
import BodyMed from './bodyMed';

export {
  BodyMed,
  Body1,
  Body1d,
  Body1d2,
  Body2,
  Body2a,
  Body2aTemplate,
  Body3,
  Body4,
  Body4d,
  Body4d2,
  Body5,
  Body6,
  Body6Template,
  Body6d,
  CardFooter,
  CardTitle,
  Digits1,
  Digits2,
  Digits3,
  Editorial1,
  Editorial2,
  Editorial3,
  Editorial4,
  FTR,
  Headline1dm,
  Headline2dm,
  Headline3,
  Headline3dm,
  Headline4dm,
  Headline5,
  HeadlineCard,
  Intro1,
  Intro2,
  NAV1,
  NAV2,
  NAV3,
  Overlay1,
  Section1,
  Text1,
  Text2,
  Text3,
  Text4,
  Text5,
  Text6,
  Text7Template,
  Text8,
  Text9,
};
