import queryString from 'query-string';
import HttpMethod from '../enums/httpMethod';
import IKeyValue from '../interfaces/IKeyValue';
import {
  IRestClient,
  ParseOption,
  RequestParams,
  ResponseAPI,
  RestClientParams,
} from '../interfaces/IRestClient';
import { CONTENT_TYPE } from '../utils/constants';

const injectPathParameters = (path: string, pathParameters: IKeyValue<string> = {}) =>
  Object.keys(pathParameters).reduce(
    (acc, parameter: string) =>
      acc.replace(new RegExp(`{{${parameter}}}`, 'gm'), pathParameters[parameter]),
    path,
  );

const getHeaders = (customHeaders: IKeyValue<string>): Headers => {
  return new Headers({
    ...customHeaders,
  });
};

const parseOptions = <T>({
  path,
  queryParams = {},
  pathParameters,
  method = HttpMethod.GET,
  headers = {},
  body,
}: RestClientParams<T>) => {
  const options: ParseOption = {
    endpoint: `${injectPathParameters(path, pathParameters)}?${queryString.stringify(queryParams, {
      skipNull: true,
    })}`,
    options: {
      method,
      headers: getHeaders(headers),
    },
  };
  if (typeof body === 'object' && JSON.stringify(body)) {
    options.options.body = JSON.stringify(body);
  }
  return options;
};

const parseJson = async <T>(response: Response, parseResponse: boolean): Promise<T> => {
  const { headers } = response;
  const text = await response.text();
  return parseResponse && headers.get(CONTENT_TYPE) && !!text ? JSON.parse(text) : text;
};

const handleResponse = async <T>(
  response: Response,
  { parseResponse = true },
): Promise<ResponseAPI<T>> => {
  const { status, headers } = response;
  const json = await parseJson<T>(response, parseResponse);

  if (status < 200 || status >= 400) {
    return Promise.reject({ status, json, headers });
  }

  return Promise.resolve({
    status,
    json,
    headers,
  });
};

export const request = async <T, X>(
  requestParams: RestClientParams<T>,
): Promise<ResponseAPI<X>> => {
  const { parseResponse } = requestParams;
  const { endpoint, options } = parseOptions(requestParams);
  const response = await fetch(endpoint, { ...options });
  return handleResponse<X>(response, { parseResponse });
};

const RestClient = <T>(
  path: string,
  baseHeaders: IKeyValue<string> = {},
  pathParameters: IKeyValue<string> = {},
): IRestClient<T> => {
  const basePath = injectPathParameters(path, pathParameters);
  const singleItemPath = `${basePath}/{{id}}`;

  const restRequest = <R>(
    requestParams: RequestParams<T> = {},
    method: HttpMethod = HttpMethod.GET,
    parseResponse = true,
  ): Promise<ResponseAPI<R>> => {
    const { id, headers = {}, pathParameters = {} } = requestParams;
    const path = id ? singleItemPath : basePath;
    if (id && pathParameters) {
      pathParameters.id = id;
    }

    return request<T, R>({
      ...requestParams,
      path,
      parseResponse,
      method,
      headers: { ...baseHeaders, ...headers },
      pathParameters,
    });
  };

  return {
    get(requestParams = {}, parseResponse = true) {
      return restRequest(requestParams, HttpMethod.GET, parseResponse);
    },
    getOne(requestParams) {
      return restRequest(requestParams);
    },
    post(requestParams) {
      return restRequest(requestParams, HttpMethod.POST);
    },
    put(requestParams) {
      return restRequest(requestParams, HttpMethod.PUT);
    },
    remove(requestParams) {
      return restRequest(requestParams, HttpMethod.DELETE);
    },
  };
};

export default RestClient;
