/** @jsx h */
import { Component, FunctionalComponent, ComponentType, h, ComponentChildren, VNode } from 'preact';
import { SupportedEvents } from '../../enums/event';
import { useEventHandler } from '../../providers/eventHandlerProvider';

export interface IWrappedComponentProps {
  children?: VNode;
}

export interface IErrorBoundaryProps extends IWrappedComponentProps {
  onHandleError: (error: Error) => void;
}
export interface IErrorBoundaryState {
  hasError: boolean;
}

export class ErrorBoundary extends Component<IErrorBoundaryProps, IErrorBoundaryState> {
  state: IErrorBoundaryState = {
    hasError: false,
  };

  shouldComponentUpdate(
    nextProps: Readonly<IErrorBoundaryProps>,
    nextState: Readonly<IErrorBoundaryState>,
  ): boolean {
    return !nextState.hasError;
  }

  componentDidCatch(error: Error): void {
    console.error('Caught error test test: ', error);
    this.setState({ hasError: true });
    this.props.onHandleError(error);
  }

  render(props: IErrorBoundaryProps): ComponentChildren {
    return props.children;
  }
}

const withErrorDispatch = (
  WrappedComponent: ComponentType<IErrorBoundaryProps>,
): FunctionalComponent<IWrappedComponentProps> => {
  const ErrorComponent: FunctionalComponent<IWrappedComponentProps> = (props) => {
    const { dispatchEvent } = useEventHandler();

    return (
      <WrappedComponent
        {...props}
        onHandleError={(errorInfo) => dispatchEvent(SupportedEvents.onError, errorInfo.message)}
      />
    );
  };

  return ErrorComponent;
};

export default withErrorDispatch(ErrorBoundary);
