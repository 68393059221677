/** @jsx h */
import { FunctionalComponent, h } from 'preact';
import { connect } from 'react-redux';
import { useCallback, useEffect, useMemo, useState } from 'preact/hooks';
import { useTranslation } from 'react-i18next';
import get from 'lodash/get';
import debounce from 'lodash/debounce';
import { stringify } from 'query-string';
import AutoSuggest, {
  ChangeEvent,
  InputProps,
  RenderInputComponentProps,
  RenderSuggestionsContainerParams,
  SuggestionSelectedEventData,
  SuggestionsFetchRequestedParams,
} from 'react-autosuggest';
import { Highlight, connectAutoComplete } from 'react-instantsearch-dom';
import useNavigation from '../../hooks/useNavigation';
import { AutoSuggestContainer, Suggestion } from './autoCompleteStyled';
import SuggestionsContainer from './suggestionContainer';
import {
  AUTO_SUGGESTION_PROPERTY,
  ENTER_KEYCODE,
  ESCAPE_KEYCODE,
  HIT_TYPES,
  SEARCH_TABS,
  SUPPORTED_LANGUAGES,
  WHITE_SPACES_REGEX,
} from '../../enums/algolia';
import SearchField from '../searchField';
import { IHit, IHits, IRecentSearch, ISuggestion } from '../../interfaces/ISearch';
import { IProductModel } from '../../models/Product';
import { clearRecentSearches, getTopResult, storeSearch } from '../../actions/searchActions';
import { IGetTopResultAction } from '../../actions/interfaces/ISearchActions';
import {
  isTopProductResultSelector,
  searchPathSelector,
  topResultSelector,
} from '../../selectors/searchSelector';
import { AppState } from '../../interfaces/appState';
import { FAQ_TERMS } from '../../enums/faqTerms';
import { SHOP_TERMS } from '../../enums/shopTerms';
import { TARGET } from '../../enums/common';
import { AutoCompleteLocale } from './locale';
import SearchHistoryClient from '../../gateways/modulesStorage/SearchHistoryClient';
import SearchHistory from '../../models/SearchHistory';
import { ILocaleModel } from '../../models/Locale';
import { modulesByKeySelector } from '../../selectors/modulesSelectors';
import { loadFromConfig } from '../../utils/configLoader';

const DEBOUNCE_INTERVAL = loadFromConfig('SEARCH_BAR_DEBOUNCE_INTERVAL_MS') ?? 400;

export interface RenderInputComponentExtProps extends RenderInputComponentProps {
  isVisible: boolean;
  onChange: () => void;
  onHide: () => void;
  onKeyDown: () => void;
  placeholder: string;
  value: string;
}

export interface AutoCompleteProps {
  hits: Array<IHits>;
  initialValue: string;
  isTopProductResult: boolean;
  isVisible: boolean;
  onHide: () => void;
  placeholder: string;
  refine: (value: string) => void;
  searchPath: string;
  topResult: IHit;
  clearRecentSearches: typeof clearRecentSearches;
  getTopResult: (suggestion: string) => Promise<IGetTopResultAction>;
  storeSearch: typeof storeSearch;
  locale: ILocaleModel;
  currentRefinement: string;
}

export const AutoComplete: FunctionalComponent<AutoCompleteProps> = ({
  hits,
  initialValue,
  isTopProductResult,
  isVisible,
  onHide,
  placeholder,
  refine,
  searchPath,
  topResult,
  clearRecentSearches,
  getTopResult,
  storeSearch,
  locale: { selectedLocale },
  currentRefinement,
}) => {
  const { t } = useTranslation();
  const { redirect } = useNavigation();
  const [value, setValue] = useState<string>(initialValue);
  const [isLoading, setLoading] = useState(false);
  const suggestions = get(hits, '[0].hits', []);

  const isFAQTerm = useMemo<boolean>(
    () => FAQ_TERMS.map((term) => t(term).toLowerCase()).includes(value.toLowerCase()),
    [value, t],
  );

  const isShopTerm = useCallback(
    (query: string) => SHOP_TERMS.map((term) => term.toLowerCase()).includes(query.toLowerCase()),
    [],
  );

  const onFAQSelected = useCallback(() => {
    window.open(t(AutoCompleteLocale.faq, { query: encodeURIComponent(value) }), TARGET.BLANK);
  }, [t, value]);

  const shouldRedirectToProductTab = useCallback(
    (query: string, tab: SEARCH_TABS) =>
      (isTopProductResult &&
        tab === SEARCH_TABS.ALL &&
        (topResult as IProductModel).title.toLowerCase().trim() === query.toLowerCase().trim()) ||
      isShopTerm(query),
    [isShopTerm, isTopProductResult, topResult],
  );

  const closeAndSelectSuggestion = useCallback(
    (query: string, tab: SEARCH_TABS = SEARCH_TABS.ALL) => {
      if (isFAQTerm) {
        return onFAQSelected();
      } else if (query && query.replace(WHITE_SPACES_REGEX, '') && query.length > 1) {
        onHide();
        storeSearch(query, tab);
        const queryString = stringify({ query }, { encode: true });
        const tabIndex = shouldRedirectToProductTab(query, tab) ? SEARCH_TABS.PRODUCT : tab;

        if (!location.href.includes(searchPath)) {
          SearchHistoryClient.set(
            SearchHistory.createFromRaw({
              createdAt: Date.now(),
              origin: location.href,
            }),
          );
        }
        const searchUrl = `${searchPath}/${tabIndex}?${queryString}&locale=${selectedLocale}`;
        redirect(searchUrl);
      }
    },
    [
      isFAQTerm,
      onFAQSelected,
      onHide,
      searchPath,
      shouldRedirectToProductTab,
      storeSearch,
      selectedLocale,
      redirect,
    ],
  );
  const onSuggestionSelected = useCallback(
    (suggestion: string, tab: SEARCH_TABS) => {
      getTopResult(suggestion).then(({ payload }) => {
        closeAndSelectSuggestion(
          suggestion,
          payload?.objectID && payload?.__hitType === HIT_TYPES.PRODUCT && tab === SEARCH_TABS.ALL
            ? SEARCH_TABS.PRODUCT
            : tab,
        );
      });
    },
    [closeAndSelectSuggestion, getTopResult],
  );

  const onKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.keyCode === ENTER_KEYCODE) {
        closeAndSelectSuggestion(value);
      }
      if (event.keyCode === ESCAPE_KEYCODE) {
        onHide();
      }
    },
    [closeAndSelectSuggestion, onHide, value],
  );

  const getSuggestionValue = useCallback((suggestion: ISuggestion) => {
    const { query } = suggestion;
    return query;
  }, []);

  const onChange = useCallback((event: InputEvent, { newValue }: ChangeEvent) => {
    setValue(newValue);
    setLoading(!!newValue);
  }, []);

  useEffect(() => {
    setLoading(false);
  }, [currentRefinement]);

  const onSuggestionsFetchRequested = useMemo(
    () =>
      debounce(({ value }: SuggestionsFetchRequestedParams) => {
        if (value) getTopResult(value);
        refine(value);
      }, DEBOUNCE_INTERVAL),
    [getTopResult, refine],
  );

  const inputProps = useMemo<InputProps<ISuggestion>>(
    () => ({
      isVisible,
      onChange,
      onHide,
      onKeyDown,
      placeholder,
      value,
    }),
    [isVisible, onChange, onHide, onKeyDown, placeholder, value],
  );

  const renderSuggestion = useCallback(
    (suggestionHit: ISuggestion) => (
      <Suggestion>
        <Highlight attribute={AUTO_SUGGESTION_PROPERTY} hit={suggestionHit} tagName="strong" />
      </Suggestion>
    ),
    [],
  );

  const renderSuggestionsContainer = useCallback(
    (props: RenderSuggestionsContainerParams) => (
      <SuggestionsContainer
        {...props}
        clearRecentSearches={clearRecentSearches}
        hits={suggestions}
        isFAQTerm={isFAQTerm}
        isVisible={isVisible}
        localeCode={SUPPORTED_LANGUAGES.EN}
        onFAQSelected={onFAQSelected}
        onSuggestionSelected={(selected: IRecentSearch) => {
          const { recentSearchValue, tab } = selected;
          onSuggestionSelected(recentSearchValue || value, tab as SEARCH_TABS);
        }}
        onHide={onHide}
        isLoading={isLoading}
      />
    ),
    [
      clearRecentSearches,
      suggestions,
      isFAQTerm,
      isVisible,
      onFAQSelected,
      onHide,
      onSuggestionSelected,
      value,
      isLoading,
    ],
  );

  useEffect(() => {
    if (initialValue) {
      refine(initialValue);
    }
  }, [initialValue, refine]);

  return (
    <AutoSuggestContainer isVisible={isVisible}>
      <AutoSuggest
        alwaysRenderSuggestions
        focusInputOnSuggestionClick={false}
        getSuggestionValue={getSuggestionValue}
        inputProps={inputProps}
        onSuggestionSelected={(
          event: InputEvent,
          { suggestionValue }: SuggestionSelectedEventData<ISuggestion>,
        ) => {
          onSuggestionSelected(suggestionValue, SEARCH_TABS.ALL);
        }}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        renderInputComponent={(props: RenderInputComponentProps) => {
          const {
            isVisible,
            onChange,
            onHide,
            onKeyDown,
            placeholder,
            value,
          } = props as RenderInputComponentExtProps;
          return (
            <SearchField
              isVisible={isVisible}
              onChange={onChange}
              onHide={onHide}
              onKeyDown={onKeyDown}
              placeholder={placeholder}
              value={value}
            />
          );
        }}
        renderSuggestion={renderSuggestion}
        renderSuggestionsContainer={renderSuggestionsContainer}
        suggestions={suggestions}
      />
    </AutoSuggestContainer>
  );
};

const mapStateToProps = (state: AppState) => ({
  isTopProductResult: isTopProductResultSelector(state),
  searchPath: searchPathSelector(state),
  topResult: topResultSelector(state),
  locale: modulesByKeySelector(state, 'locale'),
});

const mapDispatchToProps = {
  clearRecentSearches,
  getTopResult,
  storeSearch,
};

export default connect(mapStateToProps, mapDispatchToProps)(connectAutoComplete(AutoComplete));
