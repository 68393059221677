import { createSelector } from 'reselect';
import { SupportedLanguages } from '../enums/locale';
import { ILocaleModel } from '../models/Locale';
import { IGeolocationModel } from '../models/Geolocation';
import { AppState } from '../interfaces/appState';
import { modulesByKeySelector } from './modulesSelectors';
import { loadFromConfig } from '../utils/configLoader';

const DIRECT_RESPONSE_MARKETING_REGIONS = loadFromConfig(
  'DIRECT_RESPONSE_MARKETING_REGIONS',
).map((region) => region.toUpperCase());

const localeSelector = (state: AppState): ILocaleModel =>
  modulesByKeySelector(state, 'locale') as ILocaleModel;

export const selectedLocaleSelector = createSelector(
  localeSelector,
  (locale: ILocaleModel): SupportedLanguages => locale?.selectedLocale,
);

export const geolocationLocaleSelector = createSelector(
  localeSelector,
  (locale: ILocaleModel): IGeolocationModel | null => locale?.geolocation || null,
);

export const isDirectResponseMarketingRegionSelector = createSelector(
  geolocationLocaleSelector,
  (geolocation: IGeolocationModel | null): boolean => {
    const country = geolocation?.country.toUpperCase() || '';

    return DIRECT_RESPONSE_MARKETING_REGIONS.includes(country);
  },
);
