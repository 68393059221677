import GeolocationClient from '../gateways/geolocation/geolocationClient';
import GeolocationModel, { IGeolocationModel } from '../models/Geolocation';
const ERROR = 'Something went wrong while consuming the geo location service';

export const getLocation = async (): Promise<IGeolocationModel> => {
  try {
    return await GeolocationClient.getLocation();
  } catch (error) {
    console.error(`${ERROR}: ${(error as Error)?.message}`);
    return GeolocationModel.createFromRaw();
  }
};
