import styled from 'styled-components';

interface IDropdownContainerProps {
  bottom: string;
  left: string;
  right: string;
  top: string;
}

export const DropdownWrapper = styled.li.attrs<{ isParentElement: boolean }>(
  ({ isParentElement }) => ({
    as: isParentElement ? 'div' : 'li',
  }),
)`
  position: relative;
  display: flex;
  padding-bottom: 10px;
  margin-bottom: -10px;
  margin-top: 0;
  padding-left: 0;
`;

export const DropdownContainer = styled.div<IDropdownContainerProps>`
  background-color: ${({ theme }) => theme.whiteBG};
  min-width: 100px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.22);
  padding: 24px 0 24px 0;
  position: absolute;
  bottom: ${({ bottom }) => bottom};
  left: ${({ left }) => left};
  right: ${({ right }) => right};
  transform: ${({ translate }) => `translateX(-${translate}px)`};
  top: ${({ top }) => top};
  width: ${({ width }) => width};
  z-index: 1000;
`;
