import { Record } from 'immutable';
import { IModelStatic } from '../interfaces/IModel';
import { IRawResource } from '../interfaces/IWorkout';
import AssociatedLink, { IAssociatedLinkModel } from './AssociatedLink';

export interface IAssociatedContent {
  title: string;
  links: Array<IAssociatedLinkModel>;
}

export interface IAssociatedContentModel extends Record<IAssociatedContent>, IAssociatedContent {}

export interface IRawAssociatedContent {
  title: string;
  links?: Array<IRawResource>;
}

const defaultProps = {
  title: '',
  links: [],
};

const AssociatedContent: IModelStatic<IAssociatedContentModel, IRawAssociatedContent> = class
  extends Record<IAssociatedContent>(defaultProps, 'AssociatedContent')
  implements IAssociatedContentModel {
  static createFromRaw(rawAssociatedContent?: IRawAssociatedContent): IAssociatedContentModel {
    const { links: rawLinks = [] } = rawAssociatedContent || {};
    const links = rawLinks.map((link) => AssociatedLink.createFromRaw(link));

    return new this({ ...(rawAssociatedContent || {}), links });
  }
};

export default AssociatedContent;
