/** @jsx h */
import { FunctionalComponent, h } from 'preact';
import { useCallback, useEffect, useMemo, useState } from 'preact/hooks';
import { connect } from 'react-redux';
import { countryCodeByLocale, SupportedLanguages } from '../../enums/locale';
import { AppIdEnum } from '../../enums/main';
import { LoggedStatus, Roles } from '../../enums/sso';
import useStepForm from '../../hooks/useStepForm';
import { AppState } from '../../interfaces/appState';
import { ISsoModel } from '../../interfaces/ISso';
import { ICartModel } from '../../models/Cart';
import { ILocaleModel } from '../../models/Locale';
import { IMainModel } from '../../models/Main';
import { modulesByKeySelector } from '../../selectors/modulesSelectors';
import LocaleCoachMessageModal from './coachMessage';
import LocaleChangeConfirmationModal from './confirmation';

export enum Steps {
  CONFIRMATION = 1,
  COACH_MESSAGE = 2,
}

export interface ILocaleChangeModalProps {
  onCancel(): void;
  onAccept(): void;
  newLocale: SupportedLanguages;
  sso: ISsoModel;
  locale: ILocaleModel;
  cart: ICartModel;
  main: IMainModel;
}

export const LocaleChangeModal: FunctionalComponent<ILocaleChangeModalProps> = ({
  onCancel,
  onAccept,
  newLocale,
  sso,
  main,
  cart: { itemsCount },
  locale: { selectedLocale },
}) => {
  const { currentStep, goToNext, goTo } = useStepForm(Steps.CONFIRMATION);
  const [shouldDisplay, setShouldDisplay] = useState<boolean>(false);
  const { userLoginStatus, userInfo } = sso;
  const isLoggedIn = userLoginStatus === LoggedStatus.loggedIn;

  const shouldShowCoachMessage = useMemo<boolean>(() => {
    const { primaryCountry } = userInfo || {};
    const selectedCountry = countryCodeByLocale[newLocale || ''];
    const isCoach = sso.validateUserRole([Roles.coach, Roles.preferredCustomer]);

    return isCoach && primaryCountry !== selectedCountry;
  }, [newLocale, sso, userInfo]);

  const isAppSupported = useMemo<boolean>(
    () => main.validateAppId([AppIdEnum.bod.toLowerCase(), AppIdEnum.tbb.toLowerCase()]),
    [main],
  );

  const isSameCountry = useMemo<boolean>(
    () => newLocale?.slice(-2).toUpperCase() === selectedLocale.slice(-2).toUpperCase(),
    [newLocale, selectedLocale],
  );

  const handleOnAccept = useCallback(() => {
    setShouldDisplay(false);
    onAccept();
  }, [onAccept]);

  const handleOnConfirmationAccept = useCallback(() => {
    if (shouldShowCoachMessage) return goToNext();

    return handleOnAccept();
  }, [goToNext, handleOnAccept, shouldShowCoachMessage]);

  useEffect(() => {
    if (isSameCountry) {
      return handleOnAccept();
    }

    if (!!itemsCount && isAppSupported) {
      setShouldDisplay(true);
      return goTo(Steps.CONFIRMATION);
    }

    if (shouldShowCoachMessage && isAppSupported) {
      setShouldDisplay(true);
      return goTo(Steps.COACH_MESSAGE);
    }

    return handleOnAccept();
  }, [
    goTo,
    handleOnAccept,
    isAppSupported,
    isLoggedIn,
    isSameCountry,
    itemsCount,
    shouldShowCoachMessage,
  ]);

  if (!shouldDisplay) return null;

  switch (currentStep) {
    default:
    case Steps.CONFIRMATION: {
      return (
        <LocaleChangeConfirmationModal
          selectedLocale={selectedLocale}
          newLocale={newLocale}
          onCancel={onCancel}
          onAccept={handleOnConfirmationAccept}
          isLoggedIn={isLoggedIn}
        />
      );
    }

    case Steps.COACH_MESSAGE: {
      return <LocaleCoachMessageModal newLocale={newLocale} onAccept={handleOnAccept} />;
    }
  }
};

const mapStateToProps = (state: AppState) => ({
  sso: modulesByKeySelector(state, 'sso'),
  locale: modulesByKeySelector(state, 'locale'),
  cart: modulesByKeySelector(state, 'cart'),
  main: modulesByKeySelector(state, 'main'),
});

export default connect(mapStateToProps)(LocaleChangeModal);
