/** @jsx h */
import { Fragment, FunctionalComponent, h } from 'preact';
import { connect } from 'react-redux';
import TopNavItem from '../../../../components/topNavItem';
import { AppState } from '../../../../interfaces/appState';
import { IMenuItemModel } from '../../../../models/Menu';
import { IMenuLocationModel } from '../../../../models/MenuLocation';
import {
  activeSubNavItemSelector,
  activeTopNavItemSelector,
} from '../../../../selectors/activeItemSelectors';
import { MenusContainer } from './topNavMenuStyled';
import ActiveMenuItem from '../../../activeMenuItem';
import DropdownProvider from '../../../../providers/dropdownProvider';
import { E2E, getTestID, useE2ETesting } from '../../../../providers/e2eTestingProvider';

export interface ITopNavMenuProps {
  menuLocation: IMenuLocationModel;
  isDesktop: boolean;
  handleOnSearch?(): void;
  activeTopNavMenu?: IMenuItemModel;
  activeSubNavMenu?: IMenuItemModel;
}

const TopNavMenu: FunctionalComponent<ITopNavMenuProps> = ({
  menuLocation: { menuItemList },
  isDesktop,
  activeTopNavMenu,
  activeSubNavMenu,
}) => {
  const { testIdPrefix } = useE2ETesting();
  const testIdValue = getTestID(testIdPrefix, E2E.component.tabs, 'list');
  const activeMenuItem = activeTopNavMenu || activeSubNavMenu;

  return (
    <DropdownProvider>
      <Fragment>
        {(isDesktop && (
          <MenusContainer data-testid={testIdValue}>
            {menuItemList.map((itemMenu) => (
              <TopNavItem key={itemMenu.id} item={itemMenu} />
            ))}
          </MenusContainer>
        )) ||
          (activeMenuItem && <ActiveMenuItem activeMenuItem={activeMenuItem} />) ||
          null}
      </Fragment>
    </DropdownProvider>
  );
};

const mapStateToProps = (state: AppState) => ({
  activeTopNavMenu: activeTopNavItemSelector(state),
  activeSubNavMenu: activeSubNavItemSelector(state),
});

export default connect(mapStateToProps)(TopNavMenu);
