/** @jsx h */
import { Fragment, FunctionalComponent, h } from 'preact';
import { useCallback, useMemo, useState } from 'preact/hooks';
import { useTranslation } from 'react-i18next';
import { Configure, Index } from 'react-instantsearch-core';
import orderBy from 'lodash/orderBy';
import {
  ALGOLIA_SEARCH_INDICES,
  INDICES_LABEL,
  SEARCH_TABS,
  TOP_CATEGORIES_LIMIT,
} from '../../enums/algolia';
import { SupportedLanguages } from '../../enums/locale';
import { IRecentSearch } from '../../interfaces/ISearch';
import { getIndexLocaleSuffix, getShopIndexLocaleSuffix } from '../../utils/algolia';
import { AutoCompleteLocale } from '../autoComplete/locale';
import {
  CategoryTitle,
  ResultCount,
  ResultsContainer,
  Section,
} from '../autoComplete/suggestionContainerStyled';
import { Category, CategoryContainer, CategoryText } from './topCategoriesStyled';
import Results from './results';
import { NoResults } from './NoResults';

export interface ITopCategoriesProps {
  isContainingResults: boolean;
  onSuggestionSelected: (result: IRecentSearch) => void;
  productFilter: string;
  query: string;
  selectedLocale: SupportedLanguages;
}

export interface ICategory {
  label: string;
  nbHits: number;
  tab: string;
}

export const TopCategories: FunctionalComponent<ITopCategoriesProps> = ({
  isContainingResults,
  onSuggestionSelected,
  productFilter,
  query,
  selectedLocale,
}) => {
  const [nbHitsPrograms, setNbHitsPrograms] = useState(0);
  const [nbHitsProducts, setNbHitsProducts] = useState(0);
  const [nbHitsWorkouts, setNbHitsWorkouts] = useState(0);
  const [nbHitsLessons, setNbHitsLessons] = useState(0);
  const [nbHitsRecipes, setNbHitsRecipes] = useState(0);
  const [nbHitsProgramMaterials, setNbHitsProgramMaterials] = useState(0);
  const [nbHitsOther, setNbHitsOther] = useState(0);

  const { t } = useTranslation();
  const localeSuffix = getIndexLocaleSuffix(selectedLocale);
  const shopLocaleSuffix = getShopIndexLocaleSuffix(selectedLocale);

  const getCategoryResult = useCallback(
    (category: string, tab: string, nbHits: number) => (
      <ResultsContainer>
        <CategoryContainer
          onClick={() => {
            onSuggestionSelected({ tab } as IRecentSearch);
          }}
        >
          <Category>
            {query} {t(AutoCompleteLocale.connector)}
          </Category>
          <CategoryText>{t(category)}</CategoryText>
        </CategoryContainer>
        <ResultCount>({nbHits})</ResultCount>
      </ResultsContainer>
    ),
    [onSuggestionSelected, query, t],
  );

  const topResults = useMemo(() => {
    const categories = [
      { label: INDICES_LABEL.PROGRAMS, nbHits: nbHitsPrograms, tab: SEARCH_TABS.PROGRAMS },
      { label: INDICES_LABEL.PRODUCT, nbHits: nbHitsProducts, tab: SEARCH_TABS.PRODUCT },
      { label: INDICES_LABEL.WORKOUTS, nbHits: nbHitsWorkouts, tab: SEARCH_TABS.WORKOUTS },
      { label: INDICES_LABEL.LESSONS, nbHits: nbHitsLessons, tab: SEARCH_TABS.LESSONS },
      { label: INDICES_LABEL.RECIPES, nbHits: nbHitsRecipes, tab: SEARCH_TABS.RECIPES },
      {
        label: INDICES_LABEL.PROGRAM_MATERIALS,
        nbHits: nbHitsProgramMaterials,
        tab: SEARCH_TABS.PROGRAM_MATERIALS,
      },
      { label: INDICES_LABEL.OTHER, nbHits: nbHitsOther, tab: SEARCH_TABS.OTHER },
    ] as Array<ICategory>;

    const topCategories = orderBy(categories, ['nbHits'], 'desc').slice(0, TOP_CATEGORIES_LIMIT);
    return topCategories.filter(({ nbHits }) => nbHits > 0);
  }, [
    nbHitsLessons,
    nbHitsOther,
    nbHitsProducts,
    nbHitsProgramMaterials,
    nbHitsPrograms,
    nbHitsRecipes,
    nbHitsWorkouts,
  ]);

  const hasMatches = topResults.length;

  return (
    <Section isFirstSection={!isContainingResults}>
      <Index indexName={`${ALGOLIA_SEARCH_INDICES.PROGRAMS}${localeSuffix}`}>
        <Results setNbHits={setNbHitsPrograms} />
      </Index>
      <Index indexName={`${ALGOLIA_SEARCH_INDICES.PRODUCT}${shopLocaleSuffix}`}>
        <Configure filters={productFilter} />
        <Results setNbHits={setNbHitsProducts} />
      </Index>
      <Index indexName={`${ALGOLIA_SEARCH_INDICES.WORKOUTS}${localeSuffix}`}>
        <Results setNbHits={setNbHitsWorkouts} />
      </Index>
      <Index indexName={`${ALGOLIA_SEARCH_INDICES.LESSONS}${localeSuffix}`}>
        <Results setNbHits={setNbHitsLessons} />
      </Index>
      <Index indexName={`${ALGOLIA_SEARCH_INDICES.RECIPES}${localeSuffix}`}>
        <Results setNbHits={setNbHitsRecipes} />
      </Index>
      <Index indexName={`${ALGOLIA_SEARCH_INDICES.PROGRAM_MATERIALS}${localeSuffix}`}>
        <Results setNbHits={setNbHitsProgramMaterials} />
      </Index>
      <Index indexName={`${ALGOLIA_SEARCH_INDICES.OTHER}${localeSuffix}`}>
        <Results setNbHits={setNbHitsOther} />
      </Index>
      {hasMatches ? (
        <Fragment>
          <CategoryTitle>{t(AutoCompleteLocale.topCategories)}</CategoryTitle>
          {topResults.map(({ label, tab, nbHits }) => getCategoryResult(label, tab, nbHits))}
        </Fragment>
      ) : (
        <NoResults />
      )}
    </Section>
  );
};

export default TopCategories;
