import styled from 'styled-components';
import Body5, { Body5Template } from '../body5';
import { ITypography } from '../../interfaces/typography';

export const CardFooterTemplate: ITypography = {
  ...Body5Template,
  fontWeight: 'normal',
};

const { fontWeight } = CardFooterTemplate;

type TextProps = {
  italic: boolean;
};

const CardFooter = styled(Body5)<TextProps>`
  font-weight: ${fontWeight};
  font-style: ${({ italic }) => (italic ? 'italic' : 'normal')};
`;

export default CardFooter;
