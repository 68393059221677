import IKeyValue from '../../interfaces/IKeyValue';
import { IRawGroup } from '../../models/Group';
import Item, { IItemModel } from '../../models/Item';
import { loadFromConfig } from '../../utils/configLoader';
import RestClient from '../restClient';
import { BEARER, CONTENT_TYPES } from '../../utils/constants';

const { URL, API_KEY } = loadFromConfig('GROUPS_API');
const BASE_HEADERS: IKeyValue<string> = {
  'x-api-key': API_KEY,
  'Content-Type': CONTENT_TYPES.JSON,
};

export interface IGroupClient {
  get: () => Promise<IItemModel[]>;
}

export interface IGroupResp {
  count: number;
  items: IRawGroup[];
}

const GroupClient = (accessToken: string): IGroupClient => {
  const authorization = `${BEARER} ${accessToken}`;
  const { get } = RestClient<IRawGroup>(URL, { ...BASE_HEADERS, authorization, bodweb: 'true' });
  const activeGroupsPageSize = 3;
  const defaultFilter: IKeyValue<string> = {
    sortBy: 'startDate',
    sortOrder: 'desc',
    currentGroups: 'true',
    pageSize: activeGroupsPageSize.toString(),
  };

  const getGroups = async (
    params: IKeyValue<string>,
    groupList: Array<IRawGroup> = [],
  ): Promise<IRawGroup[]> => {
    const { isCoachAdmin } = params;
    const { json: { items: groupItemList = [] } = {} } =
      (await get<IGroupResp>({ queryParams: params })) || {};
    const totalFound = groupItemList.length;
    const groups = await (isCoachAdmin === 'true' && totalFound < activeGroupsPageSize
      ? getGroups({ ...defaultFilter, pageSize: '3', state: 'participant' }, groupItemList)
      : groupList.concat(groupItemList));

    const ids = groups.map(({ id }) => id);
    return groups.filter(({ id }, index) => ids.indexOf(id) === index).slice(0, 3);
  };

  return {
    get: async () => {
      const activeGroups = await getGroups({ ...defaultFilter, isCoachAdmin: 'true' });
      return activeGroups.map((group) => Item.createFromRawGroup(group));
    },
  };
};

export default GroupClient;
