export enum LoggedStatus {
  loggedIn = 'loggedIn',
  loggedOut = 'loggedOut',
}

export enum Roles {
  coach = 'COACH',
  club = 'CLUB',
  preferredCustomer = 'PC',
  registeredUser = 'REGISTEREDUSER',
  ukclub = 'UKCLUB',
  CORP = 'corpCoach',
}

export const AkamaiRegex = /login/gm;
