import styled from 'styled-components';
import { Media } from '../../styles/media';

const { tabletUp, desktopUp } = Media;

export const CloseButton = styled.button`
  background: url(${({ image }) => image || ''}) no-repeat 50%;
  border: 0;
  cursor: pointer;
  height: 20px;
  margin: 32px 0 0 29px;
  padding: 10px;
  position: absolute;
  width: 12%;
  z-index: 6000;

  ${tabletUp`
    height: 30px;
    width: 9%;
  `};

  ${desktopUp`
    height: 30px;
    width: 5%;
  `};

  .loginWindow > & {
    right: 0px;
  }
`;

export const VideoPortalContainer = styled.div`
  position: fixed;
  z-index: 60000;
  top: 0;
  left: 0;
  background-color: ${({ theme }) => theme.black};
  width: 100%;
  height: 100%;
`;

export const SpinnerContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const BodLogo = styled.img`
  border-radius: 50%;
  position: absolute;
  width: 90px;
  height: 90px;
`;

export const LoadingIcon = styled.div`
  width: 100px;
  height: 100px;
`;

export const SpinnerBase = styled.div`
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 100px;
  height: 100px;
  border: 2px solid transparent;
  border-radius: 50%;
  animation: spin 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-top: ${({ theme }) => `2px solid ${theme.oneAppBlue}`};
`;

export const SpinnerOne = styled(SpinnerBase)`
  animation-delay: -0.3s;
`;

export const SpinnerTwo = styled(SpinnerBase)`
  animation-delay: -0.2s;
`;

export const SpinnerThree = styled(SpinnerBase)`
  animation-delay: -0.1s;
`;
