import styled, { css } from 'styled-components';
import { Body2 } from '../../shared/typography';

export const Suggestion = styled(Body2)`
  cursor: pointer;
  margin: 5px 0;
  text-transform: capitalize;
`;

export const AutoSuggestContainer = styled.div`
  transition: position 0.5s ease, transform 0.5s ease;
  width: 100%;

  & ul {
    margin: 0;
  }

  & li {
    cursor: pointer;
    list-style: none;
    margin: 0;
    padding: 0;
  }

  ${({ isVisible }) =>
    !isVisible &&
    css`
      display: none;
    `};
`;
