import { ActionCreatorsMapObject } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { ICart, ICartModel } from '../../models/Cart';
import { AppState } from '../../interfaces/appState';
import { IAction } from './IAction';
import { IModulesStateRecord } from '../../interfaces/IModules';

const CART_PREFIX = 'CART';
export const UPDATE_CART_CONFIG = `${CART_PREFIX}/UPDATE_CART_CONFIG`;
export const GET_CART_SUMMARY = `${CART_PREFIX}/GET_CART_SUMMARY`;
export const UPDATE_ITEMS_COUNT = `${CART_PREFIX}/UPDATE_ITEMS_COUNT`;
export const WIPE_CART = `${CART_PREFIX}/WIPE_CART`;

export interface IUpdateCartAction extends IAction {
  payload: ICartModel;
}

export type UpdateCartAction = ThunkAction<IUpdateCartAction, AppState, null, IUpdateCartAction>;
export type WipeCartAction = ThunkAction<IAction, AppState, null, IAction>;

export type AsyncUpdateCartAction = ThunkAction<
  Promise<IUpdateCartAction>,
  AppState,
  null,
  IUpdateCartAction
>;

export interface IUpdateCartActionCreator {
  (cartConfig: ICart): UpdateCartAction;
}

export interface IUpdateItemsCountActionCreator {
  (itemsCount: number): UpdateCartAction;
}

export interface IGetCartSummaryActionCreator {
  (modules: IModulesStateRecord): AsyncUpdateCartAction;
}

export interface IWipeCartActionCreator {
  (): WipeCartAction;
}

export interface ICartActions extends ActionCreatorsMapObject {
  updateConfig(cartConfig: ICart): Promise<IUpdateCartAction>;
  updateItemsCount(itemsCount: number): Promise<IUpdateCartAction>;
  getCartSummary(modules: IModulesStateRecord): Promise<IUpdateCartAction>;
  wipeCart(): Promise<IUpdateCartAction>;
}

export interface ICartActionCreators extends ActionCreatorsMapObject {
  updateConfig: IUpdateCartActionCreator;
  updateItemsCount: IUpdateItemsCountActionCreator;
  getCartSummary: IGetCartSummaryActionCreator;
  wipeCart: IWipeCartActionCreator;
}
