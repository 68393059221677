import { Record } from 'immutable';
import Item from '../models/Item';
import Menu, { IMenuItemModel } from '../models/Menu';

import SubNavLink from '../components/desktopNav/items/subNavLink';
import SubNavMenu from '../components/desktopNav/items/subNavMenu';

export type SubNavItemComponent = typeof SubNavLink | typeof SubNavMenu;

interface IItemTypeFactoryMapper {
  [itemTypeName: string]: SubNavItemComponent;
}

export interface ISubNavFactory {
  mapNavItem(item: IMenuItemModel): SubNavItemComponent;
}

const SubNavFactory = (): ISubNavFactory => {
  const subNavItemMapper: IItemTypeFactoryMapper = {
    [Menu.displayName]: SubNavMenu,
    [Item.displayName]: SubNavLink,
  };

  return {
    mapNavItem(item) {
      const recordType = Record.getDescriptiveName(item);
      return subNavItemMapper[recordType];
    },
  };
};

export default SubNavFactory;
