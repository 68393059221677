import { parse, parseUrl } from 'query-string';
import { Roles } from '../enums/sso';
import { BODRoutes } from '../enums/routes';
import { DEVICE_TYPES, TARGET } from '../enums/common';
import { loadFromConfig } from './configLoader';
import { ANDROID_REGEX, IOS_REGEX, PDF_EXTENSION, REGEX_DOMAIN_ONLY } from './constants';

const ALL_ACCESS = loadFromConfig('ALL_ACCESS');

const { club, registeredUser } = Roles;
const { OFFERS_BOD_FULL_PATH, REGISTER, UPGRADE } = BODRoutes;

const DOMAIN_WHITELIST = loadFromConfig('DOMAIN_WHITELIST');

export const isPDF = (url: string): boolean => !!(url && url.toLowerCase().endsWith(PDF_EXTENSION));

export const getCurrentDomain = (): string | undefined => {
  const regexDomain = window?.location?.hostname?.match(REGEX_DOMAIN_ONLY);
  return regexDomain && regexDomain[1] ? regexDomain[1] : undefined;
};

export const isDomainAllowed = (): boolean => {
  const domain = getCurrentDomain();
  if (domain && !DOMAIN_WHITELIST.find((e) => e.endsWith(domain))) {
    return false;
  }
  return true;
};

export const getUpgradePath = (
  role: Roles[] = [],
  isPremium: boolean,
  programBrandCode: string,
): string => {
  const { pathname, search } = location;
  const { query } = parseUrl(search.slice(1));

  const referralProgramId = `&referralProgramId=${programBrandCode}`;
  const redirect = `redirect=${pathname}${search}${referralProgramId}`;
  const offers = `${OFFERS_BOD_FULL_PATH}?${redirect}&${search.substring(1)}`;
  const upgrade = `${UPGRADE}?${search}&${redirect}`;
  const register = `${REGISTER}?${search}&${redirect}`;
  const premiumUpgrade = ALL_ACCESS;

  const isRegisteredUser = role.join().indexOf(registeredUser) !== -1;
  const isPayingSubscriber = role.join().indexOf(club) !== -1;

  if (!isRegisteredUser && !query.term) {
    return offers;
  }
  if (!isRegisteredUser && !!query.term) {
    return register;
  }
  if (isRegisteredUser && !isPayingSubscriber && !query.term) {
    return offers;
  }
  if (isRegisteredUser && !isPayingSubscriber && !!query.term) {
    return upgrade;
  }
  if (isPayingSubscriber && isPremium) {
    return premiumUpgrade;
  }

  return upgrade;
};

export const getIsOffsite = (str: string, target: string): boolean =>
  target === TARGET.BLANK || !!parse(str).hostname;

export const addStylesheetURL = (id: string, url: string): Promise<void> => {
  if (!document.getElementById(id)) {
    return new Promise((resolve) => {
      setTimeout(() => {
        const link = document.createElement('link');
        link.rel = 'stylesheet';
        link.setAttribute('id', id);
        link.href = url;

        const [headNode] = document.getElementsByTagName('head');
        headNode.appendChild(link);
        resolve();
      });
    });
  }

  return Promise.resolve();
};

export const getCurrentDevice = () => {
  if (navigator) {
    if (IOS_REGEX.test(navigator.userAgent)) {
      return DEVICE_TYPES.IOS;
    }

    if (ANDROID_REGEX.test(navigator.userAgent)) {
      return DEVICE_TYPES.ANDROID;
    }
  }

  return null;
};
