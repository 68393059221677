import { css, CSSObject, DefaultTheme, ThemedStyledProps, Interpolation } from 'styled-components';
import { em } from 'polished';

const customMediaQuery = <P>(
  minWidth: number,
  maxWidth: number,
  style: TemplateStringsArray | CSSObject,
  ...interpolations: Interpolation<ThemedStyledProps<DefaultTheme & P, DefaultTheme>>[]
): Interpolation<ThemedStyledProps<DefaultTheme & P, DefaultTheme>> => {
  return css`
    @media (min-width: ${minWidth}px) and (max-width: ${maxWidth}px) {
      ${css<DefaultTheme & P>(style, ...interpolations)}
    }
  `;
};

const minMediaQuery = <P>(
  minWidth: number,
  style: TemplateStringsArray | CSSObject,
  ...interpolations: Interpolation<ThemedStyledProps<DefaultTheme & P, DefaultTheme>>[]
): Interpolation<ThemedStyledProps<DefaultTheme & P, DefaultTheme>> => {
  return css`
    @media (min-width: ${minWidth}px) {
      ${css<DefaultTheme & P>(style, ...interpolations)}
    }
  `;
};

const maxMediaQuery = <P>(
  minWidth: number,
  style: TemplateStringsArray | CSSObject,
  ...interpolations: Interpolation<ThemedStyledProps<DefaultTheme & P, DefaultTheme>>[]
): Interpolation<ThemedStyledProps<DefaultTheme & P, DefaultTheme>> => {
  return css`
    @media (max-width: ${minWidth}px) {
      ${css<DefaultTheme & P>(style, ...interpolations)}
    }
  `;
};

const customScreenMediaQuery = <P>(
  minWidth: number,
  maxWidth: number,
  style: TemplateStringsArray | CSSObject,
  ...interpolations: Interpolation<ThemedStyledProps<DefaultTheme & P, DefaultTheme>>[]
): Interpolation<ThemedStyledProps<DefaultTheme & P, DefaultTheme>> => {
  return css`
    @media screen and (min-width: ${em(minWidth)}) and (max-width: ${em(maxWidth)}) {
      ${css<DefaultTheme & P>(style, ...interpolations)}
    }
  `;
};

const minScreenMediaQuery = <P>(width: string) => {
  return (
    style: TemplateStringsArray | CSSObject,
    ...interpolations: Interpolation<ThemedStyledProps<DefaultTheme & P, DefaultTheme>>[]
  ): Interpolation<ThemedStyledProps<DefaultTheme & P, DefaultTheme>> =>
    css`
      @media screen and (min-width: ${width}) {
        ${css<DefaultTheme & P>(style, ...interpolations)}
      }
    `;
};

export const WEB_BREAKPOINTS = {
  DESKTOP_MAX: 1320,
  DESKTOP_MIN: 1060,
  TABLET_MAX: 1059,
  TABLET_MIN: 768,
  MOBILE: 767,
};

interface IMedia {
  custom: typeof customMediaQuery;
  desktopUp: <P>(
    styles: TemplateStringsArray | CSSObject,
    ...interpolations: Interpolation<ThemedStyledProps<DefaultTheme & P, DefaultTheme>>[]
  ) => Interpolation<ThemedStyledProps<P & DefaultTheme, DefaultTheme>>;
  desktop: <P>(
    styles: TemplateStringsArray | CSSObject,
    ...interpolations: Interpolation<ThemedStyledProps<DefaultTheme & P, DefaultTheme>>[]
  ) => Interpolation<ThemedStyledProps<P & DefaultTheme, DefaultTheme>>;
  desktopDown: <P>(
    styles: TemplateStringsArray | CSSObject,
    ...interpolations: Interpolation<ThemedStyledProps<DefaultTheme & P, DefaultTheme>>[]
  ) => Interpolation<ThemedStyledProps<P & DefaultTheme, DefaultTheme>>;
  tabletUp: <P>(
    styles: TemplateStringsArray | CSSObject,
    ...interpolations: Interpolation<ThemedStyledProps<DefaultTheme & P, DefaultTheme>>[]
  ) => Interpolation<ThemedStyledProps<P & DefaultTheme, DefaultTheme>>;
  tablet: <P>(
    styles: TemplateStringsArray | CSSObject,
    ...interpolations: Interpolation<ThemedStyledProps<DefaultTheme & P, DefaultTheme>>[]
  ) => Interpolation<ThemedStyledProps<P & DefaultTheme, DefaultTheme>>;
  tabletDown: <P>(
    styles: TemplateStringsArray | CSSObject,
    ...interpolations: Interpolation<ThemedStyledProps<DefaultTheme & P, DefaultTheme>>[]
  ) => Interpolation<ThemedStyledProps<P & DefaultTheme, DefaultTheme>>;
  mobile: <P>(
    styles: TemplateStringsArray | CSSObject,
    ...interpolations: Interpolation<ThemedStyledProps<DefaultTheme & P, DefaultTheme>>[]
  ) => Interpolation<ThemedStyledProps<P & DefaultTheme, DefaultTheme>>;
  minScreenMediaQuery: typeof minScreenMediaQuery;
  landscape: <P>(
    styles: TemplateStringsArray | CSSObject,
    ...interpolations: Interpolation<ThemedStyledProps<DefaultTheme & P, DefaultTheme>>[]
  ) => Interpolation<ThemedStyledProps<P & DefaultTheme, DefaultTheme>>;
  desktopDownOnly: <P>(
    styles: TemplateStringsArray | CSSObject,
    ...interpolations: Interpolation<ThemedStyledProps<DefaultTheme & P, DefaultTheme>>[]
  ) => Interpolation<ThemedStyledProps<P & DefaultTheme, DefaultTheme>>;
}

export const setAllLinkStates = <P>(
  templateStrings: TemplateStringsArray | CSSObject,
  ...args: Interpolation<ThemedStyledProps<DefaultTheme & P, DefaultTheme>>[]
): Interpolation<ThemedStyledProps<P & DefaultTheme, DefaultTheme>> => css`
  &:hover,
  &:link,
  &:active,
  &:focus,
  &:visited {
    ${css(templateStrings, ...args)}
  }
`;

export const textTruncate = <P>(
  lineCount: number,
): Interpolation<ThemedStyledProps<P & DefaultTheme, DefaultTheme>> => css`
  display: -webkit-box;
  line-clamp: ${lineCount};
  -webkit-line-clamp: ${lineCount};
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

export const columnGap = <P>({
  horizontal = '0px',
  vertical = '0px',
}: {
  horizontal?: string;
  vertical?: string;
}): Interpolation<ThemedStyledProps<P & DefaultTheme, DefaultTheme>> => css`
  margin: calc(${vertical} / 2) calc(${horizontal} / -2);
  & > * {
    margin: calc(${vertical} / 2) calc(${horizontal} / 2);
  }
`;

export const Media: IMedia = {
  custom: customMediaQuery,
  desktopUp: <P>(
    styles: TemplateStringsArray | CSSObject,
    ...interpolations: Interpolation<ThemedStyledProps<DefaultTheme & P, DefaultTheme>>[]
  ) => minMediaQuery(WEB_BREAKPOINTS.DESKTOP_MIN, styles, ...interpolations),
  desktop: <P>(
    styles: TemplateStringsArray | CSSObject,
    ...interpolations: Interpolation<ThemedStyledProps<DefaultTheme & P, DefaultTheme>>[]
  ) =>
    customMediaQuery(
      WEB_BREAKPOINTS.DESKTOP_MIN,
      WEB_BREAKPOINTS.DESKTOP_MAX,
      styles,
      ...interpolations,
    ),
  desktopDown: <P>(
    styles: TemplateStringsArray | CSSObject,
    ...interpolations: Interpolation<ThemedStyledProps<DefaultTheme & P, DefaultTheme>>[]
  ) => maxMediaQuery(WEB_BREAKPOINTS.DESKTOP_MIN, styles, ...interpolations),
  tabletUp: <P>(
    styles: TemplateStringsArray | CSSObject,
    ...interpolations: Interpolation<ThemedStyledProps<DefaultTheme & P, DefaultTheme>>[]
  ) => minMediaQuery(WEB_BREAKPOINTS.TABLET_MIN, styles, ...interpolations),
  tablet: <P>(
    styles: TemplateStringsArray | CSSObject,
    ...interpolations: Interpolation<ThemedStyledProps<DefaultTheme & P, DefaultTheme>>[]
  ) =>
    customMediaQuery(
      WEB_BREAKPOINTS.TABLET_MIN,
      WEB_BREAKPOINTS.TABLET_MAX,
      styles,
      ...interpolations,
    ),
  tabletDown: <P>(
    styles: TemplateStringsArray | CSSObject,
    ...interpolations: Interpolation<ThemedStyledProps<DefaultTheme & P, DefaultTheme>>[]
  ) => maxMediaQuery(WEB_BREAKPOINTS.TABLET_MIN, styles, ...interpolations),
  mobile: <P>(
    styles: TemplateStringsArray | CSSObject,
    ...interpolations: Interpolation<ThemedStyledProps<DefaultTheme & P, DefaultTheme>>[]
  ) => maxMediaQuery(WEB_BREAKPOINTS.MOBILE, styles, ...interpolations),
  minScreenMediaQuery: minScreenMediaQuery,
  landscape: <P>(
    styles: TemplateStringsArray | CSSObject,
    ...interpolations: Interpolation<ThemedStyledProps<DefaultTheme & P, DefaultTheme>>[]
  ) =>
    customScreenMediaQuery(
      WEB_BREAKPOINTS.TABLET_MIN,
      WEB_BREAKPOINTS.TABLET_MAX,
      styles,
      ...interpolations,
    ),
  desktopDownOnly: <P>(
    styles: TemplateStringsArray | CSSObject,
    ...interpolations: Interpolation<ThemedStyledProps<DefaultTheme & P, DefaultTheme>>[]
  ) =>
    customScreenMediaQuery(
      WEB_BREAKPOINTS.TABLET_MIN + 1,
      WEB_BREAKPOINTS.DESKTOP_MIN,
      styles,
      ...interpolations,
    ),
};
