import { createSelector, OutputParametricSelector } from 'reselect';
import { TopNavTypes } from '../enums/topNav';
import { IMenuItemModel, IMenuModel } from '../models/Menu';
import { AppState } from '../interfaces/appState';
import { MenuItemTypes } from '../interfaces/IMenuItem';
import { IItemModel } from '../models/Item';
import {
  headerSelector,
  mobileMenuLocationListSelector,
  subNavSelector,
  topNavMenuLocationListSelector,
} from './topNavSelectors';
import { searchPathSelector } from './searchSelector';

export const activeMenuMobileSelector = createSelector(headerSelector, (topNav) =>
  topNav.get('activeMenuMobile'),
);

export const findMenu = (menu: IMenuModel, activeMenu: IMenuModel): IMenuModel | null => {
  const selectedMenu =
    menu.menuItemList &&
    (menu.menuItemList.find(
      (nestedMenuItem) =>
        (nestedMenuItem as IMenuModel).parentItem?.id === activeMenu.parentItem?.id,
    ) as IMenuModel);

  if (!selectedMenu) {
    return (
      menu.menuItemList &&
      (menu.menuItemList.reduce((acc, nestedMenuItem) => {
        const menu = findMenu(nestedMenuItem as IMenuModel, activeMenu);
        return menu?.parentItem?.id ? menu : acc;
      }, {}) as IMenuModel)
    );
  }

  return menu;
};

const getIsMatchMoreSpecific = (
  activeMenu: IMenuItemModel | undefined,
  matchingString: string,
): boolean => {
  if (!activeMenu) return true;
  if (activeMenu.__typename === MenuItemTypes.menu) {
    const { parentItem, subNavCategory } = activeMenu as IMenuModel;

    const activeMenuMatchingString = (parentItem &&
      parentItem.getActiveCategory(subNavCategory)) as string;

    return matchingString.length > activeMenuMatchingString.length;
  }

  return activeMenu.__typename === MenuItemTypes.item;
};

export const activeTopNavItemSelector = createSelector(
  topNavMenuLocationListSelector,
  (menuLocationList) => {
    const itemList =
      menuLocationList.find(({ slug }) => slug === TopNavTypes.MAIN)?.menuItemList || [];

    return itemList.reduce<IMenuItemModel | undefined>((activeMenu, item) => {
      switch (item.__typename) {
        case MenuItemTypes.menu: {
          const { parentItem, subNavCategory } = item as IMenuModel;

          const matchingString = parentItem && parentItem.getActiveCategory(subNavCategory);
          const itemMatchesActiveNavCategory =
            !!matchingString && getIsMatchMoreSpecific(activeMenu, matchingString);
          const parentItemMatchesPath =
            parentItem?.path?.slice(0, parentItem?.path?.indexOf('?')) == location.pathname;

          return itemMatchesActiveNavCategory || parentItemMatchesPath ? item : activeMenu;
        }

        case MenuItemTypes.item: {
          const isActive = (item as IItemModel).getIsActive();

          return isActive && activeMenu?.__typename !== MenuItemTypes.menu ? item : activeMenu;
        }

        default: {
          return activeMenu;
        }
      }
    }, undefined);
  },
);

export const activeSubNavItemSelector = createSelector(subNavSelector, (menuLocation) => {
  const { menuItemList = [] } = menuLocation || {};
  return menuItemList.find((item) => {
    switch (item.__typename) {
      case MenuItemTypes.menu: {
        return (item as IMenuModel).getIsActive();
      }

      case MenuItemTypes.item: {
        return (item as IItemModel).getIsActive();
      }

      default: {
        return false;
      }
    }
  });
});

export enum ItemSource {
  topNav = 'topNav',
  subNav = 'subNav',
}

export const menuItemActiveSelector = (_: AppState, itemId: string): string => itemId;
export const isItemActiveSelector = (
  itemSource: ItemSource = ItemSource.topNav,
): OutputParametricSelector<
  AppState,
  string,
  boolean,
  (activeMenu: IMenuItemModel | undefined, itemId: string, searchPath: string) => boolean
> =>
  createSelector(
    itemSource === ItemSource.topNav ? activeTopNavItemSelector : activeSubNavItemSelector,
    menuItemActiveSelector,
    searchPathSelector,
    (activeMenu, itemId, searchPath) => {
      if (location.href.includes(searchPath) && itemSource === ItemSource.topNav) {
        return false;
      }

      if (activeMenu?.__typename === MenuItemTypes.menu) {
        const { id, parentItem: { id: parentItemId } = {} } = activeMenu as IMenuModel;

        return itemId === id || itemId == parentItemId;
      }

      if (activeMenu) {
        const { id } = activeMenu as IItemModel;

        return itemId === id;
      }

      return false;
    },
  );

export const isSubNavItemActiveSelector = isItemActiveSelector(ItemSource.subNav);
export const isTopNavItemActiveSelector = isItemActiveSelector(ItemSource.topNav);

export const parentActiveMenuMobileSelector = createSelector(
  activeMenuMobileSelector,
  mobileMenuLocationListSelector,
  (activeMenu, menuLocationList): IMenuModel => {
    const menuItems = menuLocationList.reduce(
      (acc: Array<IMenuModel>, menuLocation) => [
        ...acc,
        ...(menuLocation.menuItemList as IMenuModel[]),
      ],
      [],
    );
    return menuItems.reduce((acc, menuItem) => {
      const parentMenu = findMenu(menuItem as IMenuModel, activeMenu as IMenuModel) as IMenuModel;

      return parentMenu?.parentItem?.id ? parentMenu : acc;
    }, {}) as IMenuModel;
  },
);
