/** @jsx h */
import { FunctionalComponent, h } from 'preact';
import { useMemo } from 'preact/hooks';
import { IMenuItemModel } from '../../models/Menu';
import desktopFactory from '../../factories/DesktopFactory';

export interface ITopNavItemProps {
  item: IMenuItemModel;
  isActive?: boolean;
  isTablet?: boolean;
  isDesktop?: boolean;
  handleOnSearch?(): void;
}

const TopNavItem: FunctionalComponent<ITopNavItemProps> = ({
  item,
  isActive,
  isTablet,
  isDesktop,
  handleOnSearch,
}) => {
  const Component = useMemo(() => desktopFactory.mapNavItem(item), [item]);

  return Component ? (
    <Component
      item={item}
      isActive={!!isActive}
      isTablet={!!isTablet}
      isDesktop={!!isDesktop}
      handleOnSearch={handleOnSearch}
    />
  ) : null;
};

export default TopNavItem;
