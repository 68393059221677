/** @jsx h */
import { FunctionalComponent, h } from 'preact';
import { useCallback } from 'preact/hooks';
import { useEventHandler } from '../../../../providers/eventHandlerProvider';
import { E2E, getTestID, useE2ETesting } from '../../../../providers/e2eTestingProvider';
import { IItemModel } from '../../../../models/Item';
import { SupportedEvents } from '../../../../enums/event';
import { TopNavLogin } from '../../desktopNavStyled';
import AppLink from '../../../appLink';

export interface ITopNavSignUpProps {
  item: IItemModel;
}

const TopNavSignUpButton: FunctionalComponent<ITopNavSignUpProps> = ({ item }) => {
  const { anchorText } = item;
  const { testIdPrefix } = useE2ETesting();
  const { dispatchEvent } = useEventHandler();

  const onSignUp = useCallback(
    (event: Event) => {
      event.preventDefault();

      dispatchEvent(SupportedEvents.onSignUp);
    },
    [dispatchEvent],
  );

  const testIdValue = getTestID(testIdPrefix, E2E.component.button, 'signup');

  return (
    <AppLink item={item} onClick={onSignUp}>
      <TopNavLogin data-testid={testIdValue}>{anchorText}</TopNavLogin>
    </AppLink>
  );
};

export default TopNavSignUpButton;
