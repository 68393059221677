import styled, { css } from 'styled-components';
import { Media } from '../../../styles/media';

const { tablet } = Media;

export const Text2Template = {
  fontSize: '12px',
  fontWeight: '400',
};
const { fontSize, fontWeight } = Text2Template;

const Text2 = styled.p`
  font-size: ${fontSize};
  font-weight: ${fontWeight};
`;

export const Text2MinHeight = styled(Text2)`
  ${tablet`
    ${css<{ accommodateTallText: boolean }>`
      min-height: ${({ accommodateTallText }) => (accommodateTallText ? '95px' : '60px')};
    `}
  `};
`;

export const Text2SmallFont = styled(Text2MinHeight)`
  @media screen and (max-width: 567px) {
    /* this as mobile will catch the iPhone5 in landscape mode */
    min-height: 70px;
    font-size: 11px;
  }
`;

export default Text2;
