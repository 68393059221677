import { useState, useEffect } from 'preact/hooks';

interface ScrollbarDimensions {
  width: number;
  height: number;
}

// Adapted from https://tobbelindstrom.com/blog/measure-scrollbar-width-and-height/
function getScrollbarDimensions(): ScrollbarDimensions {
  const { body } = document;
  const scrollDiv = document.createElement('div');

  // Append element with defined styling
  scrollDiv.setAttribute(
    'style',
    'width: 1337px; height: 1337px; position: absolute; left: -9999px; overflow: scroll;',
  );
  body.appendChild(scrollDiv);

  // Collect width & height of scrollbar
  const calculateValue = (type: 'Width' | 'Height') =>
    scrollDiv[`offset${type}` as 'offsetWidth' | 'offsetHeight'] -
    scrollDiv[`client${type}` as 'clientWidth' | 'clientHeight'];
  const scrollbarWidth = calculateValue('Width');
  const scrollbarHeight = calculateValue('Height');

  // Remove element
  body.removeChild(scrollDiv);

  return {
    width: scrollbarWidth,
    height: scrollbarHeight,
  };
}

export default function useScrollbarDimensions(): ScrollbarDimensions {
  const [scrollbarDimensions, setScrollbarDimensions] = useState<ScrollbarDimensions>(
    getScrollbarDimensions(),
  );

  useEffect(() => {
    function handleResize(): void {
      setScrollbarDimensions(getScrollbarDimensions());
    }

    window.addEventListener('resize', handleResize);

    return (): void => window.removeEventListener('resize', handleResize);
  }, []);

  return scrollbarDimensions;
}
