import styled from 'styled-components';
import { Button1Fill, Button1Tertiary } from '../../shared/buttons';
import closeIcon from '../../assets/close.svg';
import { Headline3dm, Body2, Body4 } from '../../shared/typography';
import { Media } from '../../styles/media';
import { resolveRelativeUrl } from '../../utils/device';

const { tabletUp } = Media;

type ModalProps = {
  isOpen: boolean;
};

export const Background = styled.div<ModalProps>`
  display: flex;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 1;
  padding: 30px;
  z-index: 9999;
  position: fixed;
  animation: keyframes-show-0-2-5 0.5s ease;
  overflow-x: hidden;
  overflow-y: auto;
  align-items: center;
  justify-content: center;
`;

export const Container = styled.div`
  background-color: ${({ theme }) => theme.whiteBG};
  border-radius: 2px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);

  width: 312px;

  ${tabletUp`
    width: 570px;
  `};
`;

export const Close = styled.img.attrs({ src: `${resolveRelativeUrl(closeIcon)}` })`
  position: absolute;
  top: 20px;
  right: 20px;
  height: 14px;
  width: 14px;
  border: none;
`;

export const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 8px 0px;

  ${tabletUp`
    flex-direction: row;
    align-items: center;
  `};
`;

export const WhiteBox = styled.div`
  padding: 38px 24px 8px;
  position: relative;

  ${tabletUp`
    ${BodyContainer}:last-of-type {
      margin-bottom: 32px;
    }
  `};
`;

export const BodyText = styled(Body2)`
  color: ${({ theme }) => theme.medGray};
  margin: 0;
`;

export const LanguageText = styled(BodyText)`
  color: ${({ theme }) => theme.oneAppBlue};
  margin-left: 5px;
`;

export const Heading = styled(Headline3dm)`
  margin: 0;
`;

export const GrayBox = styled.div`
  background-color: ${({ theme }) => theme.pearlGray};
  padding: 24px;
  display: flex;
  justify-content: ${({ isRightAligned }) => (isRightAligned ? 'flex-end' : 'space-between')};
`;

export const AcceptButton = styled(Button1Fill)`
  margin: 0;
  width: 116px;
`;

export const CancelButton = styled(Button1Tertiary)`
  margin: 0;
  color: ${({ theme }) => theme.oneAppBlue};
`;

export const IconContainer = styled.div`
  flex-grow: 1;
  margin: 8px auto;
  display: flex;
  align-items: center;

  ${tabletUp`
    flex-grow: inherit;
    margin: 0;
    margin-left: 5px;
  `};
`;

export const NoteText = styled(Body4)`
  color: ${({ theme }) => theme.medGray};
  margin: 0;
`;

export const InlineImage = styled.img`
  width: 24px;
  height: auto;
  margin: 0 5px;
`;
