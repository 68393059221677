import { MutableRef, useCallback, useRef, useState } from 'preact/hooks';
import useClickOutside from '../../../hooks/useClickOutside';

export const CLOSE_DELAY = 100;

export const OPEN_DELAY = 50;

export interface IUseDropdownResult {
  isOpen: boolean;
  handleOnMouseEnter(): void;
  handleOnMouseLeave(): void;
  handleClose(): void;
}

export const useDropdown = (
  ref: MutableRef<HTMLElement | undefined>,
  defaultIsOpen = false,
): IUseDropdownResult => {
  const [isOpen, setIsOpen] = useState(defaultIsOpen);
  const timer = useRef<NodeJS.Timeout | null>(null);

  const handleOnMouseEnter = useCallback(() => {
    if (timer.current) {
      clearTimeout(timer.current);
    }
    timer.current = setTimeout(() => setIsOpen(true), OPEN_DELAY);
  }, [timer, setIsOpen]);

  const handleClose = useCallback(() => {
    if (timer.current) {
      clearTimeout(timer.current);
    }
    setIsOpen(false);
  }, [timer, setIsOpen]);

  const handleOnMouseLeave = useCallback(() => {
    if (timer.current) {
      clearTimeout(timer.current);
    }
    timer.current = setTimeout(handleClose, CLOSE_DELAY);
  }, [handleClose, timer]);

  useClickOutside({ ref, onClose: handleClose });

  return { isOpen, handleOnMouseEnter, handleOnMouseLeave, handleClose };
};

export default useDropdown;
