import styled, { css, DefaultTheme } from 'styled-components';
import { Media, setAllLinkStates } from '../../../styles/media';

const { tablet } = Media;

type ButtonProps = {
  responsive: boolean;
  lowercase: boolean;
  small: boolean;
};

const ButtonFillLinkStates = css<DefaultTheme>`
  color: ${({ theme }) => theme.whiteBG};
  text-decoration: none;
`;

const ButtonFill = styled.button<ButtonProps>`
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  border: none;
  border-radius: 2em;
  padding: 0.5em 2em 0.6em;
  width: ${(responsive) => responsive && '100%'};
  vertical-align: middle;
  text-align: center;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme.whiteBG};
  background-color: ${({ theme }) => theme.oneAppBlue};
  box-shadow: ${({ theme }) => theme.boxShadowTwo};
  font-size: ${(small) => (small ? '11px' : '16px')};
  font-weight: bold;
  text-transform: ${(lowercase) => (lowercase ? 'none' : 'uppercase')};
  text-decoration: none;
  line-height: 1;
  outline: none;
  cursor: pointer;
  transition: box-shadow 0.25s, background-color 0.25s ease-out, color 0.25s ease-out;

  a& {
    ${setAllLinkStates`${ButtonFillLinkStates}`};
  }

  &::-moz-focus-inner {
    border: none;
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: inherit;
    opacity: 0;
  }

  &::before {
    background-color: ${({ theme }) => theme.whiteBG};
    transition: opacity 0.2s;
  }

  &::after {
    background: radial-gradient(circle at center, currentColor 1%, transparent 1%) center/10000%
      10000% no-repeat;
    transition: opacity 1s, background-size 0.5s;
  }

  &:hover {
    background-color: ${({ theme }) => theme.containerBlue};
    text-decoration: none;
  }

  &:hover,
  &:focus {
    box-shadow: ${({ theme }) => theme.boxShadowThree};
  }

  &:focus {
    background-color: ${({ theme }) => theme.darkBlue};
  }

  &:active {
    box-shadow: ${({ theme }) => theme.boxShadowOne};
  }

  &:active::after {
    opacity: 0.32;
    background-size: 100% 100%;
    transition: background-size 0s;
  }

  &:disabled {
    color: ${({ theme }) => theme.lighterGray};
    background-color: ${({ theme }) => theme.whiteBG};
    box-shadow: none;
    cursor: initial;
  }

  &:disabled::before,
  &:disabled::after {
    opacity: 0;
  }

  ${tablet`
    ${css<{ small: boolean }>`
      font-size: ${({ small }) => (small ? '14px' : '28px')};
    `}
  `};
`;

export default ButtonFill;
