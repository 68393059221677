import root from 'window-or-global';
import defaultConfig from '../../config.default.json';
import config from '../../config.json';
// interfaces
import IConfig from '../interfaces/IConfig';
import { getRelativePath } from './device';
declare global {
  interface Window {
    UNIFICATION_ENVIRONMENT_CONFIG: IConfig;
  }
}

const loadConfig = (): IConfig => {
  const parsedConfig: IConfig = {
    ...defaultConfig,
    ...config,
    RELATIVE_PATH: getRelativePath(),
  };

  if (root.window) {
    root.window.UNIFICATION_ENVIRONMENT_CONFIG = parsedConfig;
  }

  return parsedConfig;
};

loadConfig();

export const loadFromConfig = <K extends keyof IConfig>(key: K): IConfig[K] => {
  const configObject = root.window?.UNIFICATION_ENVIRONMENT_CONFIG || {
    ...defaultConfig,
    ...config,
    RELATIVE_PATH: '',
  };

  return configObject[key];
};
