export const getSuffixBySeparator = (value: string, separator: string) => {
  if (!value) {
    return '';
  }

  const names = value.split(separator);
  const suffix = names.length > 1 ? names[names.length - 1] : names[0];
  const trimmedSuffix = suffix.trimStart();

  return trimmedSuffix.toLowerCase();
};

export const getOnlyCharactersArray = (value: string) => {
  if (!value) {
    return [];
  }

  return value?.match(/[a-z]+/gi) as string[];
};

export const capitalize = (value?: string) => {
  if (!value) {
    return '';
  }

  return value.charAt(0).toUpperCase() + value.slice(1);
};

export const capitalizeArray = (value: string[] | null, capitalizeFirst = false) => {
  if (!value || !value.length) {
    return [];
  }

  return value.map((item, index) => (!index && capitalizeFirst ? item : capitalize(item)));
};
