import GeolocationModel, { IGeolocationModel, IRawGeolocation } from '../../models/Geolocation';
import { loadFromConfig } from '../../utils/configLoader';
import RestClient from '../restClient';

const { URL } = loadFromConfig('GEOLOCATION');

export interface IGeolocationClient {
  getLocation: () => Promise<IGeolocationModel>;
}

const { get } = RestClient<IRawGeolocation>(`${URL}`);

const GeolocationClient: IGeolocationClient = {
  getLocation: async () => {
    const { json } = await get();
    return GeolocationModel.createFromRaw(json);
  },
};

export default GeolocationClient;
