import { ICartModel, ICart } from '../models/Cart';
import { modulesByKeySelector } from '../selectors/modulesSelectors';
import {
  IUpdateItemsCountActionCreator,
  IUpdateCartActionCreator,
  UPDATE_CART_CONFIG,
  IGetCartSummaryActionCreator,
  IWipeCartActionCreator,
} from './interfaces/ICartAction';
import { AppIdEnum } from '../enums/main';
import { LoggedStatus } from '../enums/sso';
import { recoverCartForLocaleOrDefault } from '../services/cartService';
import { UPDATE_ITEMS_COUNT, WIPE_CART, GET_CART_SUMMARY } from './interfaces/ICartAction';

// Actions requiring CartClient.set
export const SAVE_TO_STORE_ACTIONS = [
  UPDATE_CART_CONFIG,
  UPDATE_ITEMS_COUNT,
  GET_CART_SUMMARY,
  WIPE_CART,
];

const mergeCartModule = (cart: ICartModel, newData: ICart): ICartModel =>
  cart.mergeFromConfig(newData);

export const updateConfig: IUpdateCartActionCreator = (cart) => (dispatch, getState) => {
  const cartModel = modulesByKeySelector(getState(), 'cart') as ICartModel;
  const updatedCart = mergeCartModule(cartModel, {
    isEnabled: cartModel.isEnabled,
    ...cart,
  });

  return dispatch({
    type: UPDATE_CART_CONFIG,
    payload: updatedCart,
  });
};

export const updateItemsCount: IUpdateItemsCountActionCreator = (itemsCount) => (
  dispatch,
  getState,
) => {
  const cartModel = modulesByKeySelector(getState(), 'cart') as ICartModel;
  const updatedCart = mergeCartModule(cartModel, {
    isEnabled: cartModel.isEnabled,
    itemsCount,
  });
  return dispatch({
    type: UPDATE_ITEMS_COUNT,
    payload: updatedCart,
  });
};

export const getCartSummary: IGetCartSummaryActionCreator = ({
  cart: { hasLoaded, isEnabled },
  cart,
  main: { appId },
  sso: { userLoginStatus, accessToken },
  locale: { selectedLocale },
}) => async (dispatch) => {
  const shouldLoadCart =
    appId === AppIdEnum.bod &&
    isEnabled &&
    userLoginStatus === LoggedStatus.loggedIn &&
    !!accessToken;

  const { itemsCount } = shouldLoadCart
    ? await recoverCartForLocaleOrDefault(accessToken as string, selectedLocale)
    : cart;

  const updatedCart = mergeCartModule(cart.set('hasLoaded', shouldLoadCart || hasLoaded), {
    isEnabled,
    itemsCount,
  });

  return dispatch({
    type: GET_CART_SUMMARY,
    payload: updatedCart,
  });
};

export const wipeCart: IWipeCartActionCreator = () => (dispatch) => {
  return dispatch({
    type: WIPE_CART,
  });
};

export default {
  updateConfig,
  updateItemsCount,
  getCartSummary,
  wipeCart,
};
