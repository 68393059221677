import SideNavFactory, { SideNavLocationComponent, SideNavItemComponent } from './SideNavFactory';
import { IFactory } from '../interfaces/factory';

export type IMobileFactory = IFactory<SideNavLocationComponent, SideNavItemComponent>;
const sideNavFactory = SideNavFactory();

const MobileFactory: IMobileFactory = {
  ...sideNavFactory,
};

export default MobileFactory;
