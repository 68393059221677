import { Middleware } from 'redux';
import { getIsDebugEnabled } from '../utils/logging';
import { AppState } from '../interfaces/appState';

const color = '#2490FF';

export const loggingMiddleware: Middleware<AppState> = (store) => (next) => (action) => {
  if (getIsDebugEnabled()) {
    console.group(`%c [UNIFICATION]`, `color: ${color}`, action.type);
    console.info('action', action);
    console.log('new state', store.getState());
    console.groupEnd();
  }
  const result = next(action);
  return result;
};
