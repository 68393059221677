import styled, { DefaultTheme, css } from 'styled-components';
import { setAllLinkStates } from '../../../styles/media';

type ButtonProps = {
  lowercase: boolean;
};

const Button1FillLinkStates = css<DefaultTheme>`
  color: ${({ theme }) => theme.whiteBG};
  text-decoration: none;
`;

const Button1Fill = styled.button<ButtonProps>`
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  border: none;
  border-radius: 2em;
  padding: 0 20px;
  width: fit-content;
  height: 40px;
  vertical-align: middle;
  text-align: center;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme.whiteBG};
  background-color: ${({ theme }) => theme.oneAppBlue};
  box-shadow: ${({ theme }) => theme.boxShadowTwo};
  font-size: 14px;
  font-weight: bold;
  text-transform: ${({ lowercase }) => (lowercase ? 'none' : 'uppercase')};
  text-decoration: none;
  line-height: 1;
  outline: none;
  cursor: pointer;
  background-position: center;
  transition: background 0.8s;

  ${setAllLinkStates`${Button1FillLinkStates}`};

  &::-moz-focus-inner {
    border: none;
  }

  &:focus {
    background-color: ${({ theme, noLayoutChangeOnFocus }) =>
      noLayoutChangeOnFocus ? theme.oneAppBlue : 'inherit'};
    color: ${({ theme, noLayoutChangeOnFocus }) =>
      noLayoutChangeOnFocus ? theme.whiteBG : 'inherit'};
  }

  &:hover {
    color: ${({ theme }) => theme.whiteBG};
    background: ${({ theme }) => theme.blueHover}
      radial-gradient(circle, transparent 1%, ${({ theme }) => theme.blueHover} 1%) center/15000%;
  }

  &:active {
    box-shadow: ${({ theme }) => theme.boxShadowOne};
    background-color: ${({ theme }) => theme.bluePressed};
    background-size: 100%;
    transition: background 0s;
  }

  &:disabled {
    color: ${({ theme }) => theme.whiteBG};
    background-color: ${({ theme }) => theme.disabledGray};
    box-shadow: none;
    cursor: initial;
  }
`;

export default Button1Fill;
