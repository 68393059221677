import { createSelector } from 'reselect';
import { AppState } from '../interfaces/appState';
import { IItem } from '../models/Item';
import { IGroupsStateRecord } from '../reducers/groupsReducer';

export const groupsSelector = (state: AppState): IGroupsStateRecord => state.groups;

export const userGroupsSelector = createSelector(
  groupsSelector,
  (groups: IGroupsStateRecord): Array<IItem> => groups.get('groupsItemList'),
);
