export const FONT_URL =
  'https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap';

export enum TARGET {
  BLANK = '_blank',
  SELF = '_self',
}

export enum PROTOCOL {
  HTTP = 'http',
  HTTPS = 'https',
}

export enum FILE_STATE {
  DOWNLOAD = 'download',
  LOCKED = 'locked',
}

export enum COUNTRY_CODES {
  US = 'us',
  GB = 'gb',
  CA = 'ca',
  FR = 'fr',
}

export const MAX_OPACITY_NUM = 255;

export enum BUNDLE_NAMES {
  FOOTER = 'Footer',
  HEADER = 'Header',
  SEARCH = 'Search',
}

export enum DEVICE_TYPES {
  IOS = 'ios',
  ANDROID = 'android',
}
