/** @jsx h */
import { FunctionalComponent, h } from 'preact';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { IProductModel } from '../../models/Product';
import TopResult from '../topResult';
import { modulesByKeySelector } from '../../selectors/modulesSelectors';
import { topResultSelector } from '../../selectors/searchSelector';
import { IHit } from '../../interfaces/ISearch';
import { AppState } from '../../interfaces/appState';
import { IProgramModel } from '../../models/Program';
import { IMainModel } from '../../models/Main';
import { HIT_TYPES } from '../../enums/algolia';
import { DeviceTypes } from '../../utils/device';
import { TopResultHitLocale } from './locale';

export interface ITopResultHit {
  main: IMainModel;
  topResult: IHit;
}

const TopResultHit: FunctionalComponent<ITopResultHit> = ({ main, topResult }) => {
  const { device } = main;
  const { t } = useTranslation();
  const isMobile = device === DeviceTypes.mobile;

  if (!topResult?.objectID) return null;

  const program = topResult as IProgramModel;
  const product = topResult as IProductModel;

  const topResultProps =
    topResult.__hitType === HIT_TYPES.PROGRAM
      ? {
          description: program.description,
          imageUrl: isMobile
            ? program.images?.mobile?.source_url
            : program.images?.desktop?.source_url,
          isMobile,
          linkText: t(TopResultHitLocale.viewProgram),
          redirectUrl: program.redirectUrl,
        }
      : {
          imageUrl: product.imageUrl,
          title: product.title,
          isMobile,
          linkText: t(TopResultHitLocale.shopNow),
          redirectUrl: product.redirectUrl,
        };

  return <TopResult {...topResultProps} />;
};

const mapStateToProps = (state: AppState) => ({
  main: modulesByKeySelector(state, 'main') as IMainModel,
  topResult: topResultSelector(state),
});

export default connect(mapStateToProps)(TopResultHit);
