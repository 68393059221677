import { createSelector, OutputSelector } from 'reselect';
import { AppState } from '../interfaces/appState';
import MenuLocation, { IMenuLocationModel } from '../models/MenuLocation';
import { ITopNavStateRecord } from '../reducers/topNavReducer';
import { modulesStateSelector } from './modulesSelectors';
import {
  MENU_LOCATION_DESKTOP_ORDER,
  MENU_LOCATION_MOBILE_ORDER,
  SUB_NAV,
  MOBILE_CUSTOM_DEFAULT,
} from '../enums/topNav';
import { FooterNavTypes } from '../enums/footer';
import { ILocaleModel } from '../models/Locale';

export const headerSelector = (state: AppState): ITopNavStateRecord => state.topNav;

export const getDefaultItemFromList = <T>(itemList: T[]): T => {
  return itemList[0];
};

export const getMenuLocationListBySlug = (
  menuLocationList: IMenuLocationModel[],
  targetSlug: string,
): IMenuLocationModel[] => menuLocationList.filter(({ slug }) => slug === targetSlug);

export const filteredMenuLocationListSelector = createSelector(
  headerSelector,
  modulesStateSelector,
  (topNav, modules) =>
    MenuLocation.applyAppContextToLocationList(topNav.get('menuLocationList'), modules),
);

export const menuLocationListSelector = (
  slugList: Array<string>,
): OutputSelector<
  AppState,
  IMenuLocationModel[],
  (res: IMenuLocationModel[]) => IMenuLocationModel[]
> =>
  createSelector(filteredMenuLocationListSelector, (menuLocationList) => {
    const aggregatedMenuList = slugList.reduce<IMenuLocationModel[][]>((acc, currentSlug) => {
      const matchLocationList = getMenuLocationListBySlug(menuLocationList, currentSlug);

      return matchLocationList.length ? [...acc, matchLocationList] : acc;
    }, []);

    return aggregatedMenuList.map(getDefaultItemFromList);
  });

export const topNavMenuLocationListSelector = menuLocationListSelector(MENU_LOCATION_DESKTOP_ORDER);

export const mobileMenuLocationListSelector = menuLocationListSelector(MENU_LOCATION_MOBILE_ORDER);

export const menuLocationSelector = (
  slug: string,
): OutputSelector<
  AppState,
  IMenuLocationModel | undefined,
  (IMenuLocationModel: IMenuLocationModel[]) => IMenuLocationModel | undefined
> =>
  createSelector(filteredMenuLocationListSelector, (menuLocationList) => {
    const aggregatedMenuList = getMenuLocationListBySlug(menuLocationList, slug);

    return getDefaultItemFromList(aggregatedMenuList);
  });

export const getMenuLocationListByActiveCategory = (
  menuLocationList: IMenuLocationModel[],
): IMenuLocationModel | undefined =>
  menuLocationList.find((menuList) => !!menuList.getActiveCategory());

export const customMenuLocationSelector = (
  slug: string,
): OutputSelector<
  AppState,
  IMenuLocationModel | undefined,
  (IMenuLocationModel: IMenuLocationModel[]) => IMenuLocationModel | undefined
> =>
  createSelector(filteredMenuLocationListSelector, (menuLocationList) => {
    const aggregatedMenuList = getMenuLocationListBySlug(menuLocationList, slug);
    const categoryMatch = getMenuLocationListByActiveCategory(aggregatedMenuList);

    return categoryMatch && getDefaultItemFromList([categoryMatch]);
  });

export const customDefaultMenuLocationSelector = createSelector(
  customMenuLocationSelector(MOBILE_CUSTOM_DEFAULT),
  (customMenuLocation) => {
    if (!customMenuLocation) return null;

    const { menuItemList } = customMenuLocation;
    const [defaultMenu] = menuItemList;

    return defaultMenu;
  },
);

export const shouldShowSsoAccountsSelector = createSelector(
  headerSelector,
  (topNav: ITopNavStateRecord): boolean => topNav.get('openSsoAccounts'),
);

export const nextLocaleChangeModalSelector = createSelector(
  headerSelector,
  (topNav: ITopNavStateRecord): ILocaleModel | null => topNav.get('nextLocale'),
);

const getMenuListByMatch = (
  activeMenuLocationList: IMenuLocationModel[],
  menuLocation: IMenuLocationModel,
): IMenuLocationModel[] => {
  const [activeMenuLocation] = activeMenuLocationList;
  const currentMenuLocationMatchingString = menuLocation.getActiveCategory() || '';
  if (!activeMenuLocation && !!currentMenuLocationMatchingString) return [menuLocation];
  const activeMenuLocationMatchingString = activeMenuLocation?.getActiveCategory() || '';

  if (
    !!currentMenuLocationMatchingString &&
    currentMenuLocationMatchingString.length > activeMenuLocationMatchingString?.length
  )
    return [menuLocation];

  if (
    !!currentMenuLocationMatchingString &&
    currentMenuLocationMatchingString === activeMenuLocationMatchingString
  )
    return activeMenuLocationList.concat([menuLocation]);

  return activeMenuLocationList;
};

export const subNavSelector = createSelector(
  filteredMenuLocationListSelector,
  (menuLocationList) => {
    const aggregatedMenuList = menuLocationList.reduce<IMenuLocationModel[]>(
      (activeSubNavList, menuLocation) =>
        menuLocation.slug === SUB_NAV
          ? getMenuListByMatch(activeSubNavList, menuLocation)
          : activeSubNavList,
      [],
    );

    return getDefaultItemFromList(aggregatedMenuList);
  },
);

const shouldShowFooterItem = (footerItem: IMenuLocationModel | undefined) =>
  footerItem?.subNavCategory?.length === 0 || footerItem?.getActiveCategory()
    ? footerItem
    : undefined;

const findClosestMatchFooter = (menuLocationList: IMenuLocationModel[]) => {
  const [{ footer }] = menuLocationList
    .map((footer) => ({
      pathMatch: footer.getActiveCategory() || '',
      footer,
    }))
    .sort((match1, match2) => match2.pathMatch.length - match1.pathMatch.length);
  return footer;
};

export const footerLocationSelector = (
  slug: string,
): OutputSelector<
  AppState,
  IMenuLocationModel | undefined,
  (IMenuLocationModel: IMenuLocationModel[]) => IMenuLocationModel | undefined
> =>
  createSelector(filteredMenuLocationListSelector, (menuLocationList) => {
    const aggregatedMenuList = getMenuLocationListBySlug(menuLocationList, slug).filter(
      shouldShowFooterItem,
    );
    if (aggregatedMenuList.length > 1) {
      return getDefaultItemFromList([findClosestMatchFooter(aggregatedMenuList)]);
    }
    return getDefaultItemFromList(aggregatedMenuList);
  });

export const footerMenuLocationListSelector = createSelector(
  footerLocationSelector(FooterNavTypes.faq),
  footerLocationSelector(FooterNavTypes.topLinks),
  footerLocationSelector(FooterNavTypes.bottomLinks),
  footerLocationSelector(FooterNavTypes.socialLinks),
  footerLocationSelector(FooterNavTypes.appLinks),
  footerLocationSelector(FooterNavTypes.contentBlock),
  (
    faqLocation,
    topLinksLocation,
    bottomLinksLocation,
    socialLinksLocation,
    appLinksLocation,
    contentBlockLocation,
  ) => ({
    faqLocation: shouldShowFooterItem(faqLocation),
    topLinksLocation: shouldShowFooterItem(topLinksLocation),
    socialLinksLocation: shouldShowFooterItem(socialLinksLocation),
    bottomLinksLocation: shouldShowFooterItem(bottomLinksLocation),
    appLinksLocation: shouldShowFooterItem(appLinksLocation),
    contentBlockLocation: shouldShowFooterItem(contentBlockLocation),
  }),
);
