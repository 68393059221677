import { ICacheData } from '../interfaces/ICacheData';
import {
  loadFromLocalStorage,
  removeFromLocalStorage,
  saveToLocalStorage,
} from '../utils/localStorage';

export interface ILocalStorageClient<T> {
  get(key: string): ICacheData<T> | null;
  set(key: string, value: ICacheData<T>): void;
  del(key: string): void;
}

export interface ILocalStorageClientUser<T> {
  get(): T;
  set(value: T): void;
  del?(): void;
}

const LocalStorageClient = <T>(): ILocalStorageClient<T> => ({
  get(key) {
    return loadFromLocalStorage<T>(key);
  },
  set(key, value) {
    saveToLocalStorage<T>(key, value);
  },
  del(key) {
    return removeFromLocalStorage(key);
  },
});

export default LocalStorageClient;
