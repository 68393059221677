export interface IMenuItem {
  id: string;
  locale: string;
  __typename: string;
}

export enum MenuItemTypes {
  item = 'UnificationItem',
  menu = 'UnificationMenu',
  placeholder = 'UnificationPlaceholder',
  featuredProduct = 'UnificationFeaturedProduct',
  banner = 'UnificationBanner',
  contentBlock = 'UnificationContentBlock',
}
