import { Record } from 'immutable';
import { IHit, IRecentSearch } from '../interfaces/ISearch';
import { IModel, IModelStatic } from '../interfaces/IModel';
import { IVideo } from '../interfaces/IVideo';
import { IBaseMain } from './Main';

export interface ISearch extends IBaseMain {
  recentSearches: Array<IRecentSearch>;
  topResult?: IHit;
  isUnavailable?: boolean;
  isFreeReg?: boolean;
  videoData?: IVideo;
}

export interface IConfigSearch extends IBaseMain {
  recentSearches: Array<IRecentSearch>;
}

export interface IRawSearch extends IBaseMain {
  recentSearches: Array<IRecentSearch>;
}

export interface ISearchModel
  extends Record<ISearch>,
    ISearch,
    IModel<ISearchModel, ISearch, IRawSearch> {
  toConfig(): ISearch;
}

const defaultProps = {
  isEnabled: false,
  isUnavailable: false,
  isFreeReg: false,
  elementId: '',
  recentSearches: [],
  topResult: {} as IHit,
  videoData: undefined,
};

const Search: IModelStatic<ISearchModel, ISearch> = class
  extends Record<ISearch>(defaultProps, 'Search')
  implements ISearchModel {
  static createFromRaw(rawSearch?: ISearch): ISearchModel {
    return new this(rawSearch || {});
  }

  serialize(): IRawSearch {
    const { elementId, isEnabled, recentSearches = [] } = this;

    return {
      elementId,
      isEnabled,
      recentSearches,
    };
  }

  mergeFromConfig(fromConfig: ISearch): ISearchModel {
    return this.merge(fromConfig);
  }

  toConfig(): IConfigSearch {
    const { elementId, isEnabled, recentSearches } = this;

    return {
      elementId,
      isEnabled,
      recentSearches,
    };
  }
};

export default Search;
