import styled from 'styled-components';
import { Media } from '../../../styles/media';

const { tablet } = Media;

const NAV1 = styled.a`
  font-size: 15px;
  font-weight: 700;
  color: ${({ theme }) => theme.darkGray};

  ${tablet`
    font-size: 14px;
  `};
`;

export default NAV1;
