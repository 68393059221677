import { ActionCreatorsMapObject } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { LoggedStatus } from '../../enums/sso';
import { AppState } from '../../interfaces/appState';
import { ISsoModel, IConfigSso } from '../../interfaces/ISso';
import { IUserBase } from '../../interfaces/IUserBase';
import { ISsoAccount } from '../../models/SsoAccount';
import { IUserInfo } from '../../models/UserInfo';
import { ICoachInfoModel } from '../../models/CoachInfo';
import { IAction } from './IAction';

const SSO_PREFIX = 'SSO';
export const UPDATE_LOGIN_STATUS = `${SSO_PREFIX}/UPDATE_LOGIN_STATUS`;
export const UPDATE_USER_INFO = `${SSO_PREFIX}/UPDATE_USER_INFO`;
export const UPDATE_COACH_INFO = `${SSO_PREFIX}/UPDATE_COACH_INFO`;
export const UPDATE_SSO_CONFIG = `${SSO_PREFIX}/UPDATE_SSO_CONFIG`;
export const LOGIN_USER = `${SSO_PREFIX}/LOGIN_USER`;
export const LOGOUT_USER = `${SSO_PREFIX}/LOGOUT_USER`;
export const UPDATE_ACCOUNTS = `${SSO_PREFIX}/UPDATE_ACCOUNTS`;
export const UPDATE_ACCESS_TOKEN = `${SSO_PREFIX}/UPDATE_ACCESS_TOKEN`;
export const UPDATE_HAS_GROUPS = `${SSO_PREFIX}/UPDATE_HAS_GROUPS`;
export const GET_USER_ENTITLEMENTS = `${SSO_PREFIX}/GET_USER_ENTITLEMENTS`;
export const UPDATE_SSO_CONFIG_WITH_SIDE_EFFECTS = `${SSO_PREFIX}/UPDATE_SSO_CONFIG_WITH_SIDE_EFFECTS`;

export interface IUpdateSsoConfigAction extends IAction {
  payload: ISsoModel;
}

export interface IUpdateSsoPartialAction<T> extends IAction {
  payload: T;
}

export type UpdateSsoConfigAction = ThunkAction<
  IUpdateSsoConfigAction,
  AppState,
  null,
  IUpdateSsoConfigAction
>;

export type UpdateSsoPartialAction<T> = ThunkAction<
  IUpdateSsoPartialAction<T>,
  AppState,
  null,
  IUpdateSsoPartialAction<T>
>;

export type AsyncUpdateSsoConfigAction = ThunkAction<
  Promise<IUpdateSsoConfigAction>,
  AppState,
  null,
  IUpdateSsoConfigAction
>;

export interface IUpdateSsoConfigActionCreator {
  (updatedConfig: IConfigSso): UpdateSsoConfigAction;
}

export interface IUpdateAccessTokenActionCreator {
  (accessToken: string, idToken?: string): UpdateSsoConfigAction;
}

export interface IGetUserEntitlementList {
  (sso: ISsoModel, forceUpdate?: boolean): AsyncUpdateSsoConfigAction;
}

export interface ILogoutUserActionCreator {
  (shouldRunPreEventAction?: boolean): UpdateSsoConfigAction;
}

export interface ILogInUserActionCreator {
  (shouldTriggerEvent?: boolean): UpdateSsoConfigAction;
}

export interface IUpdateUserInfoActionCreator {
  (userInfo: IUserInfo): UpdateSsoPartialAction<IUserInfo>;
}

export interface IUpdateCoachInfoActionCreator {
  (coachInfo: ICoachInfoModel): UpdateSsoPartialAction<ICoachInfoModel>;
}

export interface IUpdateAccountsActionCreator {
  (accounts: ISsoAccount[]): UpdateSsoConfigAction;
}

export interface IUpdateAccountActionCreator {
  (accounts: ISsoAccount): UpdateSsoConfigAction;
}
export interface IUpdateUserLoginStatusActionCreator {
  (userLoginStatus: LoggedStatus): UpdateSsoConfigAction;
}

export interface IUpdateHasGroupsActionCreator {
  (hasGroups: boolean): UpdateSsoPartialAction<boolean>;
}

export interface ISsoActions extends ActionCreatorsMapObject {
  updateConfig(updatedConfig: IConfigSso): Promise<IUpdateSsoConfigAction>;
  updateConfigWithSideEffects(updatedConfig: IConfigSso): Promise<IUpdateSsoConfigAction>;
  updateUserInfo(userInfo: IUserInfo): Promise<IUpdateSsoPartialAction<IUserInfo>>;
  updateCoachInfo(userInfo: IUserBase): Promise<IUpdateSsoPartialAction<IUserBase>>;
  updateAccessToken(accessToken: string, idToken?: string): Promise<IUpdateSsoConfigAction>;
  updateUserLoginStatus(loginStatus: LoggedStatus): Promise<IUpdateSsoConfigAction>;
  updateHasGroups(hasGroups: boolean): Promise<IUpdateSsoPartialAction<boolean>>;
  updateAccountList(accountList: ISsoAccount[]): Promise<IUpdateSsoConfigAction>;
  removeAccount(account: ISsoAccount): Promise<IUpdateSsoConfigAction>;
  logoutUser(): Promise<IUpdateSsoConfigAction>;
  logInUser(shouldTriggerEvent?: boolean): Promise<IUpdateSsoConfigAction>;
}

export interface ISsoActionCreators extends ActionCreatorsMapObject {
  updateConfig: IUpdateSsoConfigActionCreator;
  updateConfigWithSideEffects: IUpdateSsoConfigActionCreator;
  updateUserInfo: IUpdateUserInfoActionCreator;
  updateCoachInfo: IUpdateCoachInfoActionCreator;
  updateAccessToken: IUpdateAccessTokenActionCreator;
  updateUserLoginStatus: IUpdateUserLoginStatusActionCreator;
  updateHasGroups: IUpdateHasGroupsActionCreator;
  updateAccountList: IUpdateAccountsActionCreator;
  removeAccount: IUpdateAccountActionCreator;
  getUserEntitlements: IGetUserEntitlementList;
  logoutUser: ILogoutUserActionCreator;
  logInUser: ILogInUserActionCreator;
}
