import { Record } from 'immutable';
import reduce from 'lodash/reduce';
import { IModelStatic } from '../interfaces/IModel';
import AppContext from './AppContext';
import { IMenuItemModel } from './Menu';
import { MenuItemTypes } from '../interfaces/IMenuItem';
import { PlaceholderEnum, IPlaceholderModel } from './Placeholder';
import { IFeaturedProductModel } from './FeaturedProduct';
import { IModulesStateRecord } from '../interfaces/IModules';

type IMenuItemList = Array<IMenuItemModel>;

export interface IMenuSections {
  featureProductList: Array<IFeaturedProductModel>;
  columnList: Array<IMenuItemList>;
  footerColumnList: Array<IMenuItemList>;
}

export interface IMenuSectionsModel extends Record<IMenuSections>, IMenuSections {
  applyAppContext(modules: IModulesStateRecord): IMenuSectionsModel;
}

const defaultProps = {
  featureProductList: [],
  columnList: [],
  footerColumnList: [],
};

const MenuSections: IModelStatic<IMenuSectionsModel, IMenuItemList> = class
  extends Record<IMenuSections>(defaultProps, 'MenuSection')
  implements IMenuSectionsModel {
  static createFromRaw(menuItemList: IMenuItemList = []): IMenuSectionsModel {
    return new this({
      ...this.getSectionLists(menuItemList),
      featureProductList: this.getFeatureProductListFromItemList(menuItemList),
    });
  }

  private static getIsModelMatch(menuItem: IMenuItemModel, menuItemType: MenuItemTypes): boolean {
    const itemType = menuItem.__typename;

    return itemType === menuItemType;
  }

  private static getFeatureProductListFromItemList(
    menuItemList: IMenuItemList,
  ): Array<IFeaturedProductModel> {
    return reduce(
      menuItemList,
      (featureProductList: Array<IFeaturedProductModel>, menuItem) =>
        this.getIsModelMatch(menuItem, MenuItemTypes.featuredProduct)
          ? featureProductList.concat([menuItem as IFeaturedProductModel])
          : featureProductList,
      [],
    );
  }

  private static getSectionLists(menuItemList: IMenuItemList): IMenuSections {
    const columnList: Array<IMenuItemList> = [];
    const footerColumnList: Array<IMenuItemList> = [];
    let lastPlaceholder: IPlaceholderModel | null = null;

    const pushItemsFromLastPlaceholder = (
      itemList: IMenuItemList,
      menuItem: IMenuItemModel,
    ): IMenuItemList => {
      const isPlaceholder = this.getIsModelMatch(menuItem, MenuItemTypes.placeholder);
      const isFeaturedProduct = this.getIsModelMatch(menuItem, MenuItemTypes.featuredProduct);
      const resultItemList = itemList.concat(
        !isPlaceholder && !isFeaturedProduct ? [menuItem] : [],
      );

      if (lastPlaceholder && lastPlaceholder.type === PlaceholderEnum.divider) {
        footerColumnList.push(resultItemList);
        return [];
      }

      columnList.push(resultItemList);
      return [];
    };

    menuItemList.reduce((accMenuItemList: IMenuItemList, menuItem, index) => {
      const isItemPlaceholder = this.getIsModelMatch(menuItem, MenuItemTypes.placeholder);
      if (index === menuItemList.length - 1 && !isItemPlaceholder) {
        return pushItemsFromLastPlaceholder(accMenuItemList, menuItem);
      }

      if (!isItemPlaceholder && !this.getIsModelMatch(menuItem, MenuItemTypes.featuredProduct)) {
        return accMenuItemList.concat([menuItem]);
      }

      const resultList = pushItemsFromLastPlaceholder(accMenuItemList, menuItem);
      const placeholder = menuItem as IPlaceholderModel;
      lastPlaceholder = placeholder;

      return resultList;
    }, []);

    return { columnList, footerColumnList, featureProductList: [] };
  }

  applyAppContext(modules: IModulesStateRecord): IMenuSectionsModel {
    const { columnList, featureProductList, footerColumnList } = this;

    return this.merge({
      footerColumnList: reduce(
        footerColumnList,
        (acc: Array<IMenuItemList>, column): Array<IMenuItemList> => {
          const filteredColumn = AppContext.applyToMenuItemList(column, modules);

          return filteredColumn.length ? acc.concat([filteredColumn]) : acc;
        },
        [],
      ),
      featureProductList: AppContext.applyToMenuItemList(
        featureProductList,
        modules,
      ) as Array<IFeaturedProductModel>,
      columnList: reduce(
        columnList,
        (acc: Array<IMenuItemList>, column): Array<IMenuItemList> => {
          const filteredColumn = AppContext.applyToMenuItemList(column, modules);

          return filteredColumn.length ? acc.concat([filteredColumn]) : acc;
        },
        [],
      ),
    });
  }
};

export default MenuSections;
