import { Record } from 'immutable';
import { IHit } from '../interfaces/ISearch';
import { HIT_TYPES } from '../enums/algolia';
import { IModelStatic } from '../interfaces/IModel';
import {
  IRawProgram,
  IRawProgramDuration,
  IRawProgramDurationRange,
  IRawProgramImages,
  IRawProgramIntensity,
  IRawProgramState,
  IRawProgramPurchaseLinks,
} from '../interfaces/IProgram';
import Violator, { IViolatorModel } from './Violator';
import { loadFromConfig } from '../utils/configLoader';
import { BODRoutes } from '../enums/routes';

const { BOD } = loadFromConfig('APP_DOMAIN_LIST');
const { PROGRAMS } = BODRoutes;

export interface IProgram extends IHit {
  id: number;
  slug: string;
  lang: string;
  description: string;
  heroDescription: string;
  longDescription: string;
  title: string;
  videoCount: number;
  brandCode: string;
  category: string;
  liveDate: string;
  liveDateTimestamp: number;
  banner?: IViolatorModel;
  violatorList?: Array<IViolatorModel>;
  imageUrl: string;
  frequency?: IRawProgramDurationRange;
  programIntensity?: IRawProgramIntensity;
  programDuration?: IRawProgramDuration;
  images?: IRawProgramImages;
  redirectUrl: string;
  programPurchaseLinks?: IRawProgramPurchaseLinks;
  programState?: IRawProgramState;
}

export interface IProgramModel extends Record<IProgram>, IProgram {}

const defaultProps = {
  id: 0,
  slug: '',
  objectID: '',
  position: 0,
  lang: '',
  description: '',
  heroDescription: '',
  longDescription: '',
  title: '',
  __hitType: HIT_TYPES.PROGRAM,
  videoCount: 0,
  brandCode: '',
  category: '',
  liveDate: '',
  liveDateTimestamp: 0,
  queryID: '',
  banner: undefined,
  violatorList: [],
  programDuration: undefined,
  frequency: undefined,
  imageUrl: '',
  programIntensity: undefined,
  images: undefined,
  redirectUrl: '',
  programPurchaseLinks: undefined,
  programState: undefined,
};

const Program: IModelStatic<IProgramModel, IRawProgram> = class
  extends Record<IProgram>(defaultProps, 'Program')
  implements IProgramModel {
  static createFromRaw(rawProgram?: IRawProgram): IProgramModel {
    const {
      id,
      slug,
      objectID,
      lang,
      description,
      heroDescription,
      longDescription,
      title,
      videoCount,
      brandCode,
      category,
      liveDate,
      liveDateTimestamp,
      __position,
      __queryID,
      violators,
      programIntensity,
      workoutDurationMaximum,
      workoutDurationMinimum,
      programDuration,
      images,
      programPurchaseLinks,
      programState,
    } = rawProgram || {};
    const banner = Violator.createBannerFromRawProgram(violators);
    const violatorList = Violator.createViolatorListFromRawProgram(violators);
    const imageUrl = images?.desktop?.source_url || images?.mobile?.source_url;

    const { id: durationMax } = workoutDurationMaximum || {};
    const { id: durationMin = 0, unit = '' } = workoutDurationMinimum || {};

    const frequency: IRawProgramDurationRange | undefined =
      workoutDurationMaximum && workoutDurationMaximum
        ? {
            unit,
            title:
              durationMax !== durationMin
                ? `${durationMin}-${durationMax}`
                : durationMin.toString(),
          }
        : undefined;

    const redirectUrl = `${BOD}${PROGRAMS}/${slug}`;

    return new this({
      id,
      slug,
      objectID,
      lang,
      description,
      heroDescription,
      longDescription,
      title,
      videoCount,
      brandCode,
      category,
      liveDate,
      liveDateTimestamp,
      position: __position,
      queryID: __queryID,
      banner,
      violatorList,
      programDuration,
      imageUrl,
      programIntensity,
      frequency,
      images,
      redirectUrl,
      programPurchaseLinks,
      programState,
      __hitType: HIT_TYPES.PROGRAM,
    });
  }
};

export default Program;
