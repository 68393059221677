import { Record } from 'immutable';
import Groups from '../components/desktopNav/items/groups';
import MobileAccountSwitch from '../components/mobileNav/items/AccountSwitch';
import Coach from '../components/mobileNav/items/coach';
import Logo from '../components/mobileNav/items/logo';
import Locale from '../components/mobileNav/items/locale';
import MenuItemLocale from '../components/mobileNav/items/locale/menuItemLocale';
import LogIn from '../components/mobileNav/items/login';
import Logout from '../components/mobileNav/items/logout';
import Menu from '../components/mobileNav/items/menu';
import NavLink from '../components/mobileNav/items/navLink';
import SignUp from '../components/mobileNav/items/signUp';
import UserInfo from '../components/mobileNav/items/userInfo';
import FooterNavigation from '../components/mobileNav/menuLocations/footerNavigation';
import MenuNavigation from '../components/mobileNav/menuLocations/menuNavigation';
import TopNavigation from '../components/mobileNav/menuLocations/topNavigation';
import { MobileNavigationTypes } from '../enums/topNav';
import ItemModel, { IItemModel } from '../models/Item';
import MenuModel, { IMenuItemModel, IMenuModel } from '../models/Menu';
import { IMenuLocationModel } from '../models/MenuLocation';
import { PlaceholderEnum } from '../models/Placeholder';

export type SideNavLocationComponent =
  | typeof TopNavigation
  | typeof MenuNavigation
  | typeof FooterNavigation;

export type SideNavItemComponent =
  | typeof NavLink
  | typeof Menu
  | typeof UserInfo
  | typeof Coach
  | typeof Logo
  | typeof NavLink
  | typeof Groups
  | typeof Logout
  | typeof LogIn
  | typeof SignUp;

interface ISideNavigationMapper {
  [recordName: string]: SideNavLocationComponent;
}

interface INavigationItemFactoryMapper {
  [recordName: string]: SideNavItemComponent;
}

interface ISideNavFactory {
  mapMenuLocation(menuLocation: IMenuLocationModel): SideNavLocationComponent;
  mapNavItem(item: IMenuItemModel): SideNavItemComponent;
}

const SideNavFactory = (): ISideNavFactory => {
  const sideNavMapper: ISideNavigationMapper = {
    [MobileNavigationTypes.MAIN]: MenuNavigation,
    [MobileNavigationTypes.TOP]: TopNavigation,
    [MobileNavigationTypes.BOTTOM]: FooterNavigation,
  };

  const sideNavItemMapper: INavigationItemFactoryMapper = {
    [ItemModel.displayName]: NavLink,
    [MenuModel.displayName]: Menu,
    [PlaceholderEnum.loginButton]: LogIn,
    [PlaceholderEnum.signUpButton]: SignUp,
    [PlaceholderEnum.user]: UserInfo,
    [PlaceholderEnum.coach]: Coach,
    [PlaceholderEnum.logo]: Logo,
    [PlaceholderEnum.myAccount]: NavLink,
    [PlaceholderEnum.groups]: Groups,
    [PlaceholderEnum.logOut]: Logout,
    [PlaceholderEnum.mobileAccountSwitch]: MobileAccountSwitch,
    [PlaceholderEnum.locale]: Locale,
    [PlaceholderEnum.enUs]: MenuItemLocale,
    [PlaceholderEnum.esUs]: MenuItemLocale,
    [PlaceholderEnum.frFR]: MenuItemLocale,
    [PlaceholderEnum.enCA]: MenuItemLocale,
    [PlaceholderEnum.frCA]: MenuItemLocale,
    [PlaceholderEnum.enGB]: MenuItemLocale,
  };

  return {
    mapMenuLocation({ slug }) {
      return sideNavMapper[slug];
    },
    mapNavItem(item) {
      const itemModel = item as IItemModel;
      const menuItemModel = item as IMenuModel;
      const itemType = (itemModel.placeholder && itemModel.placeholder?.type) || '';
      const menuType =
        (menuItemModel.parentItem?.placeholder && menuItemModel.parentItem?.placeholder.type) || '';
      const recordType = Record.getDescriptiveName(item);

      return (
        sideNavItemMapper[itemType] || sideNavItemMapper[menuType] || sideNavItemMapper[recordType]
      );
    },
  };
};

export default SideNavFactory;
