import { ActionCreatorsMapObject } from 'redux';
import { IHit } from '../../interfaces/ISearch';
import { IConfigSearch, ISearchModel } from '../../models/Search';
import { IAction, SyncThunkResult, ThunkResult } from '../interfaces/IAction';
import { IVideo } from '../../interfaces/IVideo';
import { IWorkoutModel } from '../../models/Workout';
import { ITealiumEvent } from '../../interfaces/ITealium';

const SEARCH_PREFIX = 'SEARCH';
export const UPDATE_SEARCH_CONFIG = `${SEARCH_PREFIX}/UPDATE_SEARCH_CONFIG`;
export const STORE_SEARCH = `${SEARCH_PREFIX}/STORE_SEARCH`;
export const CLEAR_RECENT_SEARCHES = `${SEARCH_PREFIX}/CLEAR_RECENT_SEARCHES`;
export const UPDATE_TOP_RESULT = `${SEARCH_PREFIX}/UPDATE_TOP_RESULT`;
export const IS_UNAVAILABLE = `${SEARCH_PREFIX}/IS_UNAVAILABLE`;
export const IS_FREE_REG = `${SEARCH_PREFIX}/IS_FREE_REG`;
export const SET_VIDEO_DATA = `${SEARCH_PREFIX}/SET_VIDEO_DATA`;

export interface IUpdateSearchConfigAction extends IAction {
  payload: ISearchModel;
}

export interface IGetTopResultAction extends IAction {
  payload: IHit;
}

export interface ISetIsUnavailableAction extends IAction {
  payload: boolean;
}

export interface ISetVideoData extends IAction {
  payload: IVideo | null;
}

export type UpdateConfigAction = SyncThunkResult<IUpdateSearchConfigAction>;
export type GetTopResultAction = ThunkResult<IGetTopResultAction>;
export type ClearRecentSearchAction = SyncThunkResult<IAction>;
export type PlayOrRedirectVideoAction = SyncThunkResult<IAction>;

export interface IPlayOrRedirectVideoActionCreator {
  (
    purchaseUrl: string,
    isUnavailable: boolean,
    workout: IWorkoutModel,
    tealiumData: ITealiumEvent,
  ): PlayOrRedirectVideoAction;
}

export interface IUpdateSearchConfigActionCreator {
  (updatedConfig: IConfigSearch): UpdateConfigAction;
}

export interface IUpdateConfigActionCreator {
  (search: IConfigSearch): UpdateConfigAction;
}

export interface IStoreSearchActionCreator {
  (value: string, tab: string): UpdateConfigAction;
}

export interface IClearRecentSearchesActionCreator {
  (): ClearRecentSearchAction;
}

export interface IGetTopResultActionCreator {
  (query: string): GetTopResultAction;
}

export interface ISearchActions extends ActionCreatorsMapObject {
  updateConfig(updatedConfig: IConfigSearch): Promise<IUpdateSearchConfigAction>;
}

export interface ISearchActionCreators extends ActionCreatorsMapObject {
  updateConfig: IUpdateSearchConfigActionCreator;
}
