import { Record } from 'immutable';
import { IMenuItem, MenuItemTypes } from '../interfaces/IMenuItem';
import { IModelStatic } from '../interfaces/IModel';
import AppContext, { IAppContext, IAppContextModel } from './AppContext';
import Item, { IItemModel, IRawItem, ItemTypes } from './Item';
import MenuSections, { IMenuSectionsModel } from './MenuSections';
import Placeholder, { IRawPlaceholder, IPlaceholderModel } from './Placeholder';
import FeaturedProduct, { IRawFeatureProduct, IFeaturedProductModel } from './FeaturedProduct';
import { IBannerModel } from './Banner';
import ContentBlock, { IContentBlockModel, IRawContentBlock } from './ContentBlock';
import { IModulesStateRecord } from '../interfaces/IModules';

export enum SubMenuBehaviorTypes {
  accordion = 'Accordion',
  slideOut = 'SlideOut',
}

export enum MenuPlaceholderTypes {
  cart = 'menuPlaceholder.cart',
  search = 'menuPlaceholder.search',
  user = 'menuPlaceholder.profileIconUser',
  locale = 'menuPlaceholder.locale',
}

type IComposedMenuItemModel<M> =
  | IItemModel
  | M
  | IPlaceholderModel
  | IFeaturedProductModel
  | IBannerModel
  | IContentBlockModel;

type IRawMenuItem<R> = IRawItem | R | IRawPlaceholder | IRawFeatureProduct | IRawContentBlock;

export interface IMenu<M> extends IMenuItem {
  id: string;
  title: string;
  appContextList: Array<IAppContextModel>;
  menuItemList: Array<IComposedMenuItemModel<M>>;
  menuSections: IMenuSectionsModel;
  parentItem?: IItemModel;
  isSubMenu: boolean;
  subMenuBehavior?: string;
  subNavCategory: Array<string>;
}

export interface IRawMenu {
  id: string;
  title: string;
  menuItemList?: Array<IRawMenuItem<IRawMenu>>;
  appContextList?: Array<IAppContext>;
  parentItem?: IRawItem;
  locale?: string;
  isSubMenu?: boolean;
  subMenuBehavior?: SubMenuBehaviorTypes;
  subNavCategory?: Array<string>;
  __typename?: string;
}

export interface IMenuModel extends Record<IMenu<IMenuModel>>, IMenu<IMenuModel> {
  applyAppContext(modules: IModulesStateRecord): IMenuModel;
  getIsActive(): boolean;
}

export type IMenuItemModel = IComposedMenuItemModel<IMenuModel>;
type IMenuModelComposed = IMenu<IMenuModel>;

const defaultProps = {
  id: '',
  title: '',
  locale: '',
  url: '',
  parentItem: undefined,
  menuSections: MenuSections.createFromRaw(),
  menuItemList: [],
  appContextList: [],
  isSubMenu: false,
  subMenuBehavior: SubMenuBehaviorTypes.slideOut,
  menuImageUrl: undefined,
  itemType: '',
  subNavCategory: [],
  __typename: MenuItemTypes.menu,
};

const Menu: IModelStatic<IMenuModel, IRawMenu> = class
  extends Record<IMenuModelComposed>(defaultProps, 'Menu')
  implements IMenuModel {
  static createFromRaw(rawMenu: IRawMenu = { id: '', title: '', locale: '' }): IMenuModel {
    const {
      menuItemList: rawMenuItemList = [],
      appContextList: rawAppContextList = [],
      parentItem: rawParentItem,
      isSubMenu,
    } = rawMenu;

    const menuItemList = rawMenuItemList.map((menuItem) =>
      this.getMenuItemByType(rawMenu, menuItem),
    );
    const appContextList = rawAppContextList.map((appContext) =>
      AppContext.createFromRaw(appContext),
    );
    const parentItem = rawParentItem ? Item.createFromRaw(rawParentItem) : undefined;
    const menuSections = !isSubMenu ? MenuSections.createFromRaw(menuItemList) : undefined;

    return new this({
      ...rawMenu,
      menuSections,
      appContextList,
      parentItem,
      menuItemList,
    });
  }

  private static getMenuItemByType(
    { isSubMenu }: IRawMenu,
    rawItem: IRawMenuItem<IRawMenu>,
  ): IMenuItemModel {
    switch (rawItem.__typename) {
      case MenuItemTypes.item: {
        const rawItemInstance = rawItem as IRawItem;
        return Item.createFromRaw({
          ...rawItemInstance,
          itemType: isSubMenu ? ItemTypes.subItem : rawItemInstance.itemType,
        });
      }

      case MenuItemTypes.placeholder: {
        return Placeholder.createFromRaw(rawItem as IRawPlaceholder);
      }

      case MenuItemTypes.featuredProduct: {
        return FeaturedProduct.createFromRaw(rawItem as IRawFeatureProduct);
      }

      case MenuItemTypes.contentBlock: {
        return ContentBlock.createFromRaw(rawItem as IRawContentBlock);
      }
    }

    return this.createFromRaw({ ...rawItem, isSubMenu: true } as IRawMenu);
  }

  applyAppContext(modules: IModulesStateRecord): IMenuModel {
    const { menuSections, menuItemList } = this;

    return this.merge({
      menuItemList: AppContext.applyToMenuItemList(menuItemList, modules),
      menuSections: menuSections.applyAppContext(modules),
    });
  }

  getIsActive(): boolean {
    const { parentItem, menuItemList } = this;

    return (
      parentItem?.getIsActive() ||
      !!menuItemList.find((item) => {
        switch (item?.__typename) {
          case MenuItemTypes.menu: {
            return (item as IMenuModel).getIsActive();
          }

          case MenuItemTypes.item: {
            return (item as IItemModel).getIsActive();
          }

          default: {
            return false;
          }
        }
      })
    );
  }
};

export default Menu;
