/** @jsx h */
import { FunctionalComponent, h } from 'preact';
import { connect } from 'react-redux';
import {
  AvatarIcon,
  Profile,
  ProfileContainer,
  UserInfoContainer,
  UserName,
} from './userInfoStyled';
import DefaultAvatar from '../../../../assets/avatar.svg';
import { modulesByKeySelector } from '../../../../selectors/modulesSelectors';
import { AppState } from '../../../../interfaces/appState';
import { ISsoModel } from '../../../../interfaces/ISso';
import { setActiveMenuMobile } from '../../../../actions/headerActions';
import { IMenuModel } from '../../../../models/Menu';
import { RightArrow } from '../menu/menuStyled';
import { NavItem } from '../../../../containers/mobileNavigation/mobileNavigationStyled';
import { IMenuItemModel } from '../../../../models/Menu';
import { resolveRelativeUrl } from '../../../../utils/device';

export interface IUserInfoProps {
  sso: ISsoModel;
  item: IMenuItemModel;
  setActiveMenu: typeof setActiveMenuMobile;
}

export const UserInfo: FunctionalComponent<IUserInfoProps> = ({ sso, item, setActiveMenu }) => {
  const { userInfo } = sso;
  const { avatarUrl } = userInfo || {};

  return !!userInfo?.guid ? (
    <NavItem
      onClick={() => {
        setActiveMenu(item as IMenuModel);
      }}
    >
      <UserInfoContainer>
        <AvatarIcon src={avatarUrl || `${resolveRelativeUrl(DefaultAvatar)}`} />
        <ProfileContainer>
          <Profile dangerouslySetInnerHTML={{ __html: 'My Profile' }} />
          <UserName className="notranslate" isBold>
            {userInfo?.firstName} {userInfo?.lastName}
          </UserName>
        </ProfileContainer>
      </UserInfoContainer>
      <RightArrow />
    </NavItem>
  ) : null;
};

const mapStateToProps = (state: AppState) => ({
  sso: modulesByKeySelector(state, 'sso'),
});

export default connect(mapStateToProps, { setActiveMenu: setActiveMenuMobile })(UserInfo);
