import { IConfigLocale, ILocaleModel } from '../models/Locale';
import { modulesByKeySelector, modulesStateSelector } from '../selectors/modulesSelectors';
import {
  IUpdateLocaleActionCreator,
  UPDATE_SELECTED_LOCALE,
  ISetLocaleActionCreator,
  SET_USER_LOCATION,
} from './interfaces/ILocaleAction';
import { SupportedEvents } from '../enums/event';
import { getLocaleFromDashedLanguage } from '../utils/locale';
import { getLocation } from '../services/geoLocationService';
import { getCartSummary } from './cartActions';
import { updateLocaleQueryParam } from '../services/localeService';
import { navigationReplaceActionCreator } from './navigationActions';

// Actions requiring LocaleClient.set
export const SAVE_TO_STORE_ACTIONS = [SET_USER_LOCATION, UPDATE_SELECTED_LOCALE];

const mergeLocaleModule = (locale: ILocaleModel, newData: IConfigLocale): ILocaleModel =>
  locale.mergeFromConfig(newData);

export const updateLocale: IUpdateLocaleActionCreator = (locale) => (dispatch, getState) => {
  const localeModel = modulesByKeySelector(getState(), 'locale') as ILocaleModel;
  const modules = modulesStateSelector(getState());

  const undashedLocale = getLocaleFromDashedLanguage(locale);

  const updatedLocale = mergeLocaleModule(localeModel, {
    selectedLocale: undashedLocale,
  } as IConfigLocale);
  const updatedLocaleUrl = updateLocaleQueryParam(updatedLocale.selectedLocale);

  if (updatedLocaleUrl) {
    dispatch(navigationReplaceActionCreator(updatedLocaleUrl, undefined, true));
  }

  dispatch(
    getCartSummary(
      modules.set('locale', updatedLocale).set('cart', modules.get('cart').set('hasLoaded', false)),
    ),
  );

  return dispatch({
    type: UPDATE_SELECTED_LOCALE,
    event: {
      postEventAction: SupportedEvents.onLocaleChange,
    },
    payload: updatedLocale,
  });
};

export const getUserLocation: ISetLocaleActionCreator = (locale: ILocaleModel) => async (
  dispatch,
) => {
  const geolocation = await getLocation();

  return dispatch({
    type: SET_USER_LOCATION,
    payload: mergeLocaleModule(locale, {
      geolocation,
    } as IConfigLocale),
  });
};

export default {
  updateLocale,
  getUserLocation,
};
