import { createSelector } from 'reselect';
import { AppState } from '../interfaces/appState';
import { IWaiverStateRecord } from '../reducers/waiverReducer';
import { IWaiverModel } from '../models/Waiver';

const waiverSelector = (state: AppState): IWaiverStateRecord => state.waiver;

export const userWaiverProfileSelector = createSelector(
  waiverSelector,
  ({ userWaiverProfile }): IWaiverModel => userWaiverProfile,
);

export const hasLoadedContentSelector = createSelector(
  waiverSelector,
  ({ hasLoaded }): boolean => hasLoaded,
);

export const userWaiverContentSelector = createSelector(
  userWaiverProfileSelector,
  ({ waiverContent }): string | undefined => waiverContent,
);

export const noWaiverRequiredListSelector = createSelector(
  userWaiverProfileSelector,
  ({ noWaiverRequiredList }): Array<string> => noWaiverRequiredList || [],
);

export const isWaiverAnonymousUsersRequiredSelector = createSelector(
  userWaiverProfileSelector,
  ({ isWaiverAnonymousUsersRequired }) => isWaiverAnonymousUsersRequired,
);
