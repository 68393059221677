import styled from 'styled-components';
import { Media } from '../../../styles/media';

const { tablet } = Media;

type TextProps = {
  fixedSize: boolean;
};

export const Headline2DTemplate = {
  fontSize: '36px',
  lineHeight: '1.222em',
  letterSpacing: '0.006em',
};

export const Headline2MTemplate = {
  fontSize: '24px',
  fontWeight: '900',
  lineHeight: '1.25em',
  letterSpacing: '0.012em',
};

const Headline2dm = styled.h2<TextProps>`
  font-size: 24px;
  font-weight: 900;
  line-height: 1.25em;
  letter-spacing: 0.012em;
  ${({ fixedSize }) =>
    !fixedSize &&
    tablet`
    font-size: 36px;
    line-height: 1.222em;
    letter-spacing: 0.006em;
  `};
`;

export default Headline2dm;
