import { loadFromConfig } from './configLoader';
const { CMS } = loadFromConfig('APP_DOMAIN_LIST');

const CMSMatcher = new RegExp(`^https?://${CMS || 'bod-cms-assets'}`);

interface IGetOptimizedImageProps {
  imageUrl?: string;
  width?: string | number;
  height?: string | number;
  format?: string;
}

export default function getOptimizedImage({
  imageUrl,
  width,
  height,
  format = 'webp',
}: IGetOptimizedImageProps): string {
  if (!imageUrl) {
    return '';
  }

  if (!CMSMatcher.test(imageUrl)) {
    return imageUrl;
  }

  const parsedUrl = new URL(imageUrl);
  parsedUrl.searchParams.set('format', format);

  if (width) {
    parsedUrl.searchParams.set('width', width.toString());
  }

  if (height) {
    parsedUrl.searchParams.set('height', height.toString());
  }

  return parsedUrl.href;
}
