import styled from 'styled-components';

const Digits1 = styled.p`
  font-size: 20px;
  font-weight: 300;
  line-height: 1.3em;
  letter-spacing: 0.01em;
`;

export default Digits1;
