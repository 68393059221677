import styled from 'styled-components';
import { Media } from '../../../styles/media';
import Text1 from '../text1';

const { tablet } = Media;

export const Text9 = styled(Text1)`
  font-size: 5vw;
  line-height: 1.6;

  ${tablet`
    font-size: 24px;
  `};
`;

export default Text9;
