import { SupportedEvents } from '../enums/event';
import urlParse from 'url-parse';
import includes from 'lodash/includes';
import { ALLOWED_CUSTOM_NAVIGATION } from './constants';

export const push = (url: string, state = {}): void => {
  try {
    window.history.pushState(state, '', url);
  } catch (error) {
    window.location.assign(url);
  }
};

export const replace = (url: string, state = {}): void => {
  try {
    window.history.replaceState(state, '', url);
  } catch (error) {
    window.location.assign(url);
  }
};

export const redirect = (url: string, sameWindow = true): void => {
  sameWindow ? window.location.assign(url) : window.open(url);
};

export interface INavigationEvent {
  postEventAction: SupportedEvents;
  payload: {
    isSameWindow?: boolean;
    isLocaleNavigation?: boolean;
    location: ReturnType<typeof urlParse>;
  };
}

export const createNavigationEvent = (
  url: string,
  isSameWindow?: boolean,
  isLocaleNavigation?: boolean,
): INavigationEvent => ({
  postEventAction: SupportedEvents.onCustomNavigation,
  payload: {
    isSameWindow,
    isLocaleNavigation,
    location: urlParse(url, false),
  },
});

export const isAppAllowedCustomNavigation = (appId: string): boolean =>
  includes(ALLOWED_CUSTOM_NAVIGATION, appId);

export const shouldUseCustomRouting = (useCustomRouting: boolean, appId: string): boolean =>
  useCustomRouting && isAppAllowedCustomNavigation(appId);
