import { Record } from 'immutable';
import { IModelStatic } from '../interfaces/IModel';

export interface IWaiver {
  isWaiverRequired: boolean;
  isWaiverAnonymousUsersRequired?: boolean;
  noWaiverRequiredList?: Array<string>;
  waiverContent?: string;
}

export interface IRawWaiver {
  requirewaiver: boolean;
  noWaiverRequiredList?: Array<string>;
  waiverContent?: string;
}

const defaultProps = {
  isWaiverRequired: false,
  isWaiverAnonymousUsersRequired: true,
  waiverContent: undefined,
  noWaiverRequiredList: [],
};

export interface IWaiverModel extends Record<IWaiver>, IWaiver {}

const Waiver: IModelStatic<IWaiverModel, IRawWaiver> = class
  extends Record<IWaiver>(defaultProps, 'Waiver')
  implements IWaiverModel {
  static createFromRaw(rawWaiver: IRawWaiver): IWaiverModel {
    const { requirewaiver, waiverContent } = rawWaiver || {};
    return new this({ isWaiverRequired: requirewaiver, waiverContent });
  }
};

export default Waiver;
