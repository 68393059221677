/** @jsx h */
import { FunctionalComponent, h } from 'preact';
import { IItemModel } from '../../../../models/Item';
import { Close, IconImage } from './logoStyled';
import { NavItem } from '../../../../containers/mobileNavigation/mobileNavigationStyled';
import CloseIcon from '../../../../assets/x-icon.svg';
import { IMenuItemModel } from '../../../../models/Menu';
import BeachBodyIcon from '../../../../assets/beachbody.svg';
import AppLink from '../../../appLink';
import { resolveRelativeUrl } from '../../../../utils/device';

export interface ILogoProps {
  item: IMenuItemModel;
  onClose?: () => void;
}

const Logo: FunctionalComponent<ILogoProps> = ({ item, onClose }) => {
  const { menuImageUrl } = item as IItemModel;
  return (
    <NavItem>
      <AppLink item={item}>
        <IconImage src={menuImageUrl || `${resolveRelativeUrl(BeachBodyIcon)}`} />
      </AppLink>
      <Close src={`${resolveRelativeUrl(CloseIcon)}`} onClick={onClose} />
    </NavItem>
  );
};

export default Logo;
