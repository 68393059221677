import styled from 'styled-components';
import { Headline3 } from '../../shared/typography';

export const ActiveMenuText = styled(Headline3)`
  font-weight: bold;
  margin: 0;
  justify-self: center;
`;

export const ActiveMenuImage = styled.img`
  margin: 0;
  justify-self: center;
`;

export const Container = styled.div`
  text-align: center;
`;
