import styled from 'styled-components';
import { Media } from '../../../styles/media';

const { tabletUp } = Media;

const Headline3 = styled.h3`
  max-width: ${({ theme }) => theme.contentWellWidth};
  font-size: 18px;
  font-weight: 900;

  ${tabletUp`
    font-size: 15px;
  `};
`;

export default Headline3;
