import { createSelector } from 'reselect';
import find from 'lodash/find';
import { IEntitlementModel } from '../models/Entitlement';
import { ENTITLEMENT_GROUPS } from '../enums/entitlement';
import { AppState } from '../interfaces/appState';

const { ALL_ACCESS, FREE_USER } = ENTITLEMENT_GROUPS;

const entitlementSelector = (state: AppState) => state.entitlements;

export const ramsEntitlementsSelector = createSelector(
  entitlementSelector,
  ({ ramEntitlements }) => ramEntitlements,
);

export const groupsEntitlementsSelector = createSelector(
  entitlementSelector,
  ({ groupEntitlements }) => groupEntitlements,
);

export const accessPoliciesSelector = createSelector(
  entitlementSelector,
  ({ accessPolicies }) => accessPolicies,
);

export const freeContentSelector = createSelector(
  groupsEntitlementsSelector,
  (groupEntitlements: Array<IEntitlementModel>) => find(groupEntitlements, { slug: FREE_USER }),
);

export const clubProgramsSelector = createSelector(
  groupsEntitlementsSelector,
  (groupEntitlements: Array<IEntitlementModel>) =>
    find(groupEntitlements, { slug: ALL_ACCESS })?.programs,
);
