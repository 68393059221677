import { Record } from 'immutable';
import { IModelStatic } from '../interfaces/IModel';

export interface IVideoModalSection {
  items: Array<string>;
  headlineText?: string;
  optionalViolatorText?: string;
  subcategories?: Array<string>;
}

export interface IVideoModalSectionModel extends Record<IVideoModalSection>, IVideoModalSection {}

const defaultProps = {
  items: [],
  headlineText: '',
  optionalViolatorText: '',
  subcategories: undefined,
};

const VideoModalSection: IModelStatic<IVideoModalSectionModel, IVideoModalSection> = class
  extends Record<IVideoModalSection>(defaultProps, 'VideoModalSection')
  implements IVideoModalSectionModel {
  static createFromRaw(rawVideoModalSection?: IVideoModalSection): IVideoModalSectionModel {
    return new this(rawVideoModalSection);
  }
};

export default VideoModalSection;
