import { SupportedLanguages } from '../enums/locale';

const LOCALE_PARAM = 'locale';

export const updateLocaleQueryParam = (locale: SupportedLanguages): string | undefined => {
  const searchParams = new URLSearchParams(window.location.search);
  if (searchParams.has(LOCALE_PARAM)) {
    searchParams.set(LOCALE_PARAM, locale);
    return `${window.location.pathname}?${searchParams}`;
  }
};
