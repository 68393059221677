import styled from 'styled-components';
import { ITypography } from '../../interfaces/typography';
import Body5, { Body5Template } from '../body5';

export const Body6Template: ITypography = {
  ...Body5Template,
  fontWeight: '400',
};

const { fontWeight, lineHeight, letterSpacing } = Body6Template;

type TextProps = {
  italic: boolean;
};

const Body6 = styled(Body5)<TextProps>`
  font-weight: ${fontWeight};
  font-style: ${({ italic }) => (italic ? 'italic' : 'normal')};
  line-height: ${lineHeight};
  letter-spacing: ${letterSpacing};
`;

export default Body6;
