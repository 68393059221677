import {
  ISubscribeEventAction,
  IUnsubscribeEventAction,
  SUBSCRIBE_EVENT,
  UNSUBSCRIBE_EVENT,
  EVENT_DOES_NOT_EXIST,
  EVENT_NOT_SUPPORTED,
  EVENT_REGISTERED,
  ISubscribeEventActionCreator,
  IUnsubscribeEventActionCreator,
} from './interfaces/IEventActions';
import { IEventModel } from '../models/Event';
import { SupportedEvents } from '../enums/event';

export const subscribeEvent = (event: IEventModel): ISubscribeEventAction => ({
  type: SUBSCRIBE_EVENT,
  payload: event,
});

export const unsubscribeEvent = (event: IEventModel): IUnsubscribeEventAction => ({
  type: UNSUBSCRIBE_EVENT,
  payload: event,
});

const validateExistingEvent = (
  events: Array<IEventModel>,
  { eventName, callBack }: IEventModel,
): boolean =>
  !!events.find(
    ({ eventName: targetEventName, callBack: targetCallBack }) =>
      targetEventName === eventName && targetCallBack === callBack,
  );

const isNotSupportedEvent = (eventName: string): boolean =>
  !Object.values(SupportedEvents).find((supportedEvent: string) => supportedEvent === eventName);

export const subscribeEventActionCreator: ISubscribeEventActionCreator = (event) => async (
  dispatch,
  getState,
) => {
  const { events } = getState();

  if (isNotSupportedEvent(event.eventName)) {
    throw new Error(EVENT_NOT_SUPPORTED);
  }

  if (validateExistingEvent(events.get('events'), event)) {
    throw new Error(EVENT_REGISTERED);
  }

  return dispatch(subscribeEvent(event));
};

export const unsubscribeEventActionCreator: IUnsubscribeEventActionCreator = (event) => async (
  dispatch,
  getState,
) => {
  const { events } = getState();

  if (!validateExistingEvent(events.get('events'), event)) {
    throw new Error(EVENT_DOES_NOT_EXIST);
  }

  if (isNotSupportedEvent(event.eventName)) {
    throw new Error(EVENT_NOT_SUPPORTED);
  }

  return dispatch(unsubscribeEvent(event));
};
