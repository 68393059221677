import { createSelector } from 'reselect';
import { mainModuleSelector, ssoModuleSelector } from './modulesSelectors';
import { isDirectResponseMarketingRegionSelector } from './localeSelector';
import { AppIdEnum } from '../enums/main';
import { Roles } from '../enums/sso';

export const getIsCoachBannerVisibleSelector = createSelector(
  mainModuleSelector,
  ssoModuleSelector,
  isDirectResponseMarketingRegionSelector,
  (main, sso, isDirectResponseMarketingRegion): boolean => {
    const { appId } = main;
    const { userInfo, coachInfo } = sso;

    if (isDirectResponseMarketingRegion) return false;

    // app id validation
    if ([AppIdEnum.shac, AppIdEnum.coo].includes(appId)) {
      return false;
    }

    if (
      userInfo?.roleList.includes(Roles.coach) ||
      !coachInfo?.email ||
      coachInfo?.coachType === Roles.CORP
    ) {
      return false;
    }

    return true;
  },
);
