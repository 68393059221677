/** @jsx h */
import noop from 'lodash/noop';
import { FunctionalComponent, h } from 'preact';
import { IItemModel } from '../../../../models/Item';
import AppLink from '../../../appLink';
import { Primary } from '../../desktopNavStyled';

export interface IPrimaryButtonProps {
  item: IItemModel;
  onClick?(event: Event): void;
}

const PrimaryButton: FunctionalComponent<IPrimaryButtonProps> = ({ item, onClick }) => {
  const { anchorText } = item;

  return (
    <AppLink item={item} onClick={onClick}>
      <Primary>{anchorText}</Primary>
    </AppLink>
  );
};

PrimaryButton.defaultProps = {
  onClick: noop,
};

export default PrimaryButton;
