import { bindActionCreators, Store } from 'redux';
import { ISsoActionCreators, ISsoActions } from '../../actions/interfaces/ISsoAction';
import { IUserBase } from '../../interfaces/IUserBase';
import { IConfigSso, ISsoModel } from '../../interfaces/ISso';
import { IUserInfo } from '../../models/UserInfo';
import ssoActions from '../../actions/ssoActions';
import { LoggedStatus } from '../../enums/sso';
import { modulesByKeySelector } from '../../selectors/modulesSelectors';
import { groupsSelector } from '../../selectors/groupsSelectors';
import { ISsoAccount } from '../../models/SsoAccount';
import { log } from '../../utils/logging';

export interface ISsoModule {
  getSsoConfig(): Promise<IConfigSso>;
  updateConfig(updatedConfig: IConfigSso): Promise<IConfigSso>;
  updateConfigWithSideEffects(updatedConfig: IConfigSso): Promise<IConfigSso>;
  updateUserInfo(userInfo: IUserInfo): Promise<IUserInfo | undefined>;
  updateCoachInfo(coachInfo: IUserBase): Promise<IUserBase | undefined>;
  updateAccessToken(accessToken: string, idToken?: string): Promise<IConfigSso>;
  updateUserLoginStatus(userLoginStatus: LoggedStatus): Promise<IConfigSso>;
  updateAccountList(accounts: ISsoAccount[]): Promise<IConfigSso>;
  getUserEntitlements(): Promise<IConfigSso>;
}

export const subscribeToStore = ({ dispatch, getState, subscribe }: Store): void => {
  const { updateHasGroups } = bindActionCreators<ISsoActionCreators, ISsoActions>(
    ssoActions,
    dispatch,
  );

  subscribe(() => {
    const state = getState();
    const sso = modulesByKeySelector(state, 'sso') as ISsoModel;
    const { groupsItemList, hasLoaded } = groupsSelector(state);
    const { hasGroups } = sso;

    // updates the has groups flag based from the groups-api result
    if (hasGroups !== !!groupsItemList.length && hasLoaded)
      updateHasGroups(!!groupsItemList.length);
  });
};

export const getSsoModule = (store: Store): ISsoModule => {
  const { dispatch, getState } = store;
  const {
    updateConfig,
    updateConfigWithSideEffects,
    updateUserInfo,
    updateCoachInfo,
    updateAccessToken,
    updateUserLoginStatus,
    updateAccountList,
    getUserEntitlements,
  } = bindActionCreators<ISsoActionCreators, ISsoActions>(ssoActions, dispatch);

  subscribeToStore(store);

  return {
    getSsoConfig() {
      log('sso', 'getSsoConfig');
      const sso = modulesByKeySelector(getState(), 'sso') as ISsoModel;

      return Promise.resolve(sso.toConfig());
    },
    async updateConfig(updatedConfig) {
      log('sso', 'updateConfig', updatedConfig);
      const { payload: sso } = await updateConfig(updatedConfig);

      return sso.toConfig();
    },
    async updateConfigWithSideEffects(updatedConfig) {
      log('sso', 'updateConfigWithSideEffects', updatedConfig);
      const { payload: sso } = await updateConfigWithSideEffects(updatedConfig);

      return sso.toConfig();
    },
    async updateUserInfo(userInfo) {
      log('sso', 'updateUserInfo', userInfo);
      const { payload } = await updateUserInfo(userInfo);

      return payload;
    },
    async updateCoachInfo(coachInfo) {
      log('sso', 'updateCoachInfo', coachInfo);
      const { payload } = await updateCoachInfo(coachInfo);

      return payload;
    },
    async updateAccessToken(accessToken, idToken) {
      log('sso', 'updateAccessToken', accessToken, idToken);
      const { payload: sso } = await updateAccessToken(accessToken, idToken);

      return sso.toConfig();
    },
    async updateUserLoginStatus(userLoginStatus) {
      log('sso', 'updateUserLoginStatus', userLoginStatus);
      const { payload: sso } = await updateUserLoginStatus(userLoginStatus);

      return sso.toConfig();
    },
    async updateAccountList(accounts: ISsoAccount[]) {
      log('sso', 'updateAccountList', accounts);
      const { payload: sso } = await updateAccountList(accounts);

      return sso.toConfig();
    },
    async getUserEntitlements() {
      log('sso', 'getUserEntitlements');
      const sso = modulesByKeySelector(getState(), 'sso') as ISsoModel;
      const { payload: updatedSso } = await getUserEntitlements(sso, true);

      return updatedSso.toConfig();
    },
  };
};
