import styled from 'styled-components';

const Digits3 = styled.p`
  font-size: 20px;
  font-weight: 300;
  line-height: 1.5;
  letter-spacing: 0.2px;
`;

export default Digits3;
