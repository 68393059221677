/** @jsx h */
import { FunctionalComponent, h, Fragment } from 'preact';
import { useCallback, useState } from 'preact/hooks';
import { connect } from 'react-redux';
import {
  NavItem,
  NavItemText,
} from '../../../../containers/mobileNavigation/mobileNavigationStyled';
import { IMenuModel, SubMenuBehaviorTypes } from '../../../../models/Menu';
import { IMenuItemModel } from '../../../../models/Menu';
import { Arrow, RightArrow, CollapseContainer } from './menuStyled';
import { setActiveMenuMobile } from '../../../../actions/headerActions';
import SideNavItem from '../../../sideNavItem';

export interface IMenuProps {
  item: IMenuItemModel;
  setActiveMenu: typeof setActiveMenuMobile;
}

export const Menu: FunctionalComponent<IMenuProps> = ({ item, setActiveMenu }) => {
  const [isSubMenuOpen, setIsSubMenuOpen] = useState<boolean>(false);
  const {
    parentItem: { anchorText } = {},
    menuItemList,
    isSubMenu,
    subMenuBehavior,
  } = item as IMenuModel;

  const isCollapsible = subMenuBehavior === SubMenuBehaviorTypes.accordion;
  const handleClickMenu = useCallback(() => {
    if (isCollapsible) {
      setIsSubMenuOpen(!isSubMenuOpen);
    } else {
      setActiveMenu(item as IMenuModel);
    }
  }, [isCollapsible, isSubMenuOpen, item, setActiveMenu]);

  return (
    <Fragment>
      <NavItem isBold={!isSubMenu} onClick={handleClickMenu}>
        <NavItemText dangerouslySetInnerHTML={{ __html: anchorText }} />
        {isCollapsible ? <Arrow isOpen={isSubMenuOpen} /> : <RightArrow />}
      </NavItem>
      {isCollapsible && isSubMenuOpen && menuItemList && (
        <CollapseContainer>
          {menuItemList.map((menuItem) => (
            <Fragment key={menuItem.id}>
              <SideNavItem item={menuItem} />
            </Fragment>
          ))}
        </CollapseContainer>
      )}
    </Fragment>
  );
};

const mapDispatchToProps = {
  setActiveMenu: setActiveMenuMobile,
};

export default connect(null, mapDispatchToProps)(Menu);
