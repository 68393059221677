import styled from 'styled-components';
import { bindActionCreators } from 'redux';
import { store } from '../../configureStore';
import { TARGET } from '../../enums/common';
import { IItemModel, LinkLocations } from '../../models/Item';
import * as tealiumActions from '../../actions/tealiumActions';
import { ITealiumThunkActions } from '../../actions/interfaces/ITealiumActions';
import { navigationRedirectActionCreator } from '../../actions/navigationActions';
import { INavigationActionCreators } from '../../actions/interfaces/INavigationAction';
import { TopNavComponents } from '../../enums/tealium';

const { dispatch } = store;
const { sendLinkTrackingEvent, redirect } = bindActionCreators<
  INavigationActionCreators,
  ITealiumThunkActions
>(
  {
    sendLinkTrackingEvent: tealiumActions.sendLinkTrackingEvent,
    redirect: navigationRedirectActionCreator,
  },
  dispatch,
);

export interface IAppLinkProps {
  item: IItemModel;
  tealiumComponent?: TopNavComponents;
  onClick?(event: Event): void;
  onClose?(): void;
}

export const handleOnClick = ({
  item,
  tealiumComponent = TopNavComponents.NAVBAR_POPUP,
  onClick,
  onClose,
}: IAppLinkProps) => async (event: Event): Promise<void> => {
  event.preventDefault();
  event.stopPropagation();

  await sendLinkTrackingEvent(item, tealiumComponent);

  if (!!onClick) {
    onClick(event);
  } else {
    redirect(item.parsedLink, item.linkLocation === LinkLocations.sameWindow);
  }

  if (!!onClose) {
    onClose();
  }
};

const AppLink = styled.a.attrs(({ item, tealiumComponent, onClick, onClose }: IAppLinkProps) => ({
  href: item.parsedLink,
  onClick: handleOnClick({ item, tealiumComponent, onClick, onClose }),
  isClickable: item.parsedLink || !!onClick,
  target: item.linkLocation === LinkLocations.sameWindow ? TARGET.SELF : TARGET.BLANK,
}))`
  pointer-events: ${({ isClickable }) => (isClickable ? 'all' : 'none')};
  color: ${({ theme }) => theme.darkGray};
  cursor: pointer;
  text-decoration: none;
  &:hover {
    color: ${({ theme }) => theme.oneAppBlue};
  }
  &:focus {
    outline: 5px auto -webkit-focus-ring-color;
  }
`;

export default AppLink;
