import { SupportedLanguages } from '../enums/locale';
import { resolveRelativeUrl } from '../utils/device';

import caIcon from '../assets/ca.svg';
import frIcon from '../assets/fr.svg';
import ukIcon from '../assets/uk.svg';
import usIcon from '../assets/us.svg';

export const LocaleIcons = {
  [SupportedLanguages.EN_US]: `${resolveRelativeUrl(usIcon)}`,
  [SupportedLanguages.ES_US]: `${resolveRelativeUrl(usIcon)}`,
  [SupportedLanguages.FR_FR]: `${resolveRelativeUrl(frIcon)}`,
  [SupportedLanguages.FR_CA]: `${resolveRelativeUrl(caIcon)}`,
  [SupportedLanguages.EN_GB]: `${resolveRelativeUrl(ukIcon)}`,
  [SupportedLanguages.EN_CA]: `${resolveRelativeUrl(caIcon)}`,
};
