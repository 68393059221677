/** @jsx h */
import { FunctionalComponent, h } from 'preact';
import { connect } from 'react-redux';
import SideNavItem from '../../../sideNavItem';
import { IMenuLocationModel } from '../../../../models/MenuLocation';
import { MenuNavigationContainer } from './menuNavigationStyled';
import { modulesByKeySelector } from '../../../../selectors/modulesSelectors';
import { AppState } from '../../../../interfaces/appState';
import { ISsoModel } from '../../../../interfaces/ISso';

export interface IMenuNavigationProps {
  menuLocation: IMenuLocationModel;
  sso: ISsoModel;
  onClose?: () => void;
}

const MenuNavigation: FunctionalComponent<IMenuNavigationProps> = ({
  menuLocation: { menuItemList },
  sso,
  onClose,
}) => {
  const { userInfo } = sso;
  return (
    <MenuNavigationContainer shouldHavePadding={!!userInfo?.guid}>
      {menuItemList.map((menu) => (
        <SideNavItem item={menu} isBold key={menu.id} onClose={onClose} />
      ))}
    </MenuNavigationContainer>
  );
};

const mapStateToProps = (state: AppState) => ({
  sso: modulesByKeySelector(state, 'sso'),
});

export default connect(mapStateToProps)(MenuNavigation);
