import styled from 'styled-components';
import { Media } from '../../styles/media';
import { SubNavItem } from '../desktopNav/items/subNavLink/subNavLinkStyled';
const { desktopUp, desktopDown, tabletUp } = Media;

export const Container = styled.div`
  background: ${({ theme }) => theme.pearlGray};
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  max-width: 100%;
  overflow-x: scroll;
  border-bottom: ${({ theme }) => `solid 1px ${theme.whisperGray}`};

  ${tabletUp`
    justify-content: center;
  `};

  ${desktopUp`
    overflow-x: visible;
  `};
`;

export const SubNav = styled.ul`
  display: grid;
  grid-gap: 24px;
  grid-auto-flow: column;
  list-style: none;
  padding: 0 24px;
  margin: 0px auto;
  width: fit-content;
  grid-auto-columns: max-content;
  span {
    margin: 0;
  }
  ${tabletUp`
    margin: 0;
  `};
`;

export const SubNavHeader = styled.div`
  padding-right: 24px;
  ${desktopDown`
    padding: 0 24px;
  `};
  height: 70%;
  display: flex;
  align-items: center;
  border-right: 1px solid ${({ theme }) => theme.lighterGray};
  ${SubNavItem} {
    color: ${({ theme }) => theme.darkGray};
    font-weight: bold;
  }
  &:hover ${SubNavItem} {
    color: ${({ theme }) => theme.oneAppBlue};
    font-weight: bold;
  }
`;
