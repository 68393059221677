import styled from 'styled-components';
import { Media } from '../../../styles/media';

const { tablet } = Media;

const Editorial1 = styled.h2`
  font-size: 26px;
  font-weight: 300;

  ${tablet`
    font-size: 40px;
  `};
`;

export default Editorial1;
