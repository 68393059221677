import algoliasearch, { SearchClient } from 'algoliasearch/lite';
import {
  MultipleQueriesQuery,
  MultipleQueriesResponse,
  SearchResponse,
  multipleQueries,
} from '@algolia/client-search';
import { ALGOLIA } from '../../enums/algolia';
import { loadFromConfig } from '../../utils/configLoader';
import SearchResponseModel from '../../models/SearchResponse';
import { IHit } from '../../interfaces/ISearch';

let algoliaClient: SearchClient | null = null;
const { APP_ID, API_KEY } = loadFromConfig(ALGOLIA.CONFIG_ID);
const ERROR_ALGOLIA = 'Something went wrong while consuming Algolia service';

export const getAlgoliaClient = (): SearchClient => {
  if (!APP_ID || !API_KEY) throw new Error('Algolia config params were not provided');

  if (algoliaClient === null) {
    algoliaClient = algoliasearch(APP_ID, API_KEY);
  }

  return algoliaClient;
};

export function getCustomAlgoliaClient(): SearchClient {
  const searchClient = getAlgoliaClient();
  const customSearchClient = {
    search(
      requests: Array<MultipleQueriesQuery>,
    ):
      | Promise<MultipleQueriesResponse<SearchResponse>>
      | Readonly<Promise<MultipleQueriesResponse<SearchResponse>>> {
      if (requests.every(({ params }: MultipleQueriesQuery) => !params?.query)) {
        return Promise.resolve({
          results: requests.map(() => ({
            hits: [],
            nbHits: 0,
            hitsPerPage: 0,
            page: 0,
            nbPages: 0,
            processingTimeMS: 0,
            exhaustiveNbHits: false,
            query: '',
            params: '',
          })) as Array<SearchResponse>,
        } as MultipleQueriesResponse<SearchResponse>);
      }

      return searchClient.search(requests);
    },
  };

  return customSearchClient as SearchClient;
}

export const executeMultipleQueries = async (
  queries: MultipleQueriesQuery[],
): Promise<Array<SearchResponse<IHit>>> => {
  try {
    const searchClient = getAlgoliaClient();
    const multipleQueriesClient = multipleQueries(searchClient);
    const { results } = await multipleQueriesClient<IHit>(queries);
    return results.map((result) => SearchResponseModel.createFromRaw(result));
  } catch (e) {
    console.error(`${ERROR_ALGOLIA}: ${(e as Error).message}`);
    return [];
  }
};

export default {
  executeMultipleQueries,
  getAlgoliaClient,
  getCustomAlgoliaClient,
};
