import { createContext, h, FunctionalComponent, VNode } from 'preact';
import { useContext } from 'preact/hooks';
import { capitalizeArray, getOnlyCharactersArray, getSuffixBySeparator } from '../utils/string';
import { getFeatureFlagValue } from '../utils/featureFlags';
import { FEATURE_FLAGS } from '../enums/featureFlags';

export interface IE2ETestingContext {
  testIdPrefix: string;
  testIdSuffix: string;
}

export interface IE2ETestingProvider {
  children: VNode;
  testIdPrefix?: string;
  testIdSuffix?: string;
}

export const E2E = {
  project: {
    suffix: 'unif',
  },
  section: {
    topNav: 'topNav',
    subNav: 'subNav',
    footer: 'footer',
  },
  subsection: {
    footer: {
      faq: 'faq',
      social: 'social',
      top: 'topLinks',
      content: 'content',
      bottom: 'bottomLinks',
      app: 'appLinks',
    },
  },
  component: {
    icon: 'icon',
    link: 'link',
    button: 'btn',
    tabs: 'tabs',
    dropdown: 'dropdown',
    menu: 'menu',
    menuItem: 'menuItem',
    text: 'text',
    container: 'container',
  },
};

const Context = createContext({} as IE2ETestingContext);
export const useE2ETesting = (): IE2ETestingContext => useContext(Context);

export const E2ETestingProvider: FunctionalComponent<IE2ETestingProvider> = ({
  children,
  testIdPrefix = '',
  testIdSuffix = '',
}) => {
  return <Context.Provider value={{ testIdPrefix, testIdSuffix }}>{children}</Context.Provider>;
};

const SEPARATOR = '_';
export const getTestID = (prefix?: string, section?: string, suffix?: string) => {
  if (!getFeatureFlagValue(FEATURE_FLAGS.IS_TEST_IDS_ENABLED_FLAG)) return;

  let testId = prefix || '';

  if (section) {
    testId = testId.concat(`${SEPARATOR}${section}`);
  }

  if (suffix) {
    testId = testId.concat(`${SEPARATOR}${suffix}`);
  }

  return testId;
};

export const getDataTestIdSuffix = (itemName?: string) => {
  if (itemName) {
    const itemNameSuffix = getSuffixBySeparator(itemName, '-');
    const suffixNormalized = capitalizeArray(getOnlyCharactersArray(itemNameSuffix), true);

    return suffixNormalized.join('');
  }
  return '';
};
