/** @jsx h */
import { FunctionalComponent, h } from 'preact';
import { connect } from 'react-redux';
import { setOpenSsoAccount } from '../../../../actions/headerActions';
import { AppState } from '../../../../interfaces/appState';
import { IItem } from '../../../../models/Item';
import { shouldShowSsoAccountsSelector } from '../../../../selectors/topNavSelectors';
import { ContainerSwitch, Link } from './accountSwitchStyled';

export interface IAccountSwitchProps {
  item: IItem;
  setOpenSsoAccount: typeof setOpenSsoAccount;
  showSsoAccounts: boolean;
}

export const AccountSwitch: FunctionalComponent<IAccountSwitchProps> = ({
  item,
  setOpenSsoAccount,
  showSsoAccounts,
}) => {
  return (
    <ContainerSwitch>
      <Link isSubItem onClick={() => setOpenSsoAccount(!showSsoAccounts)}>
        {item.anchorText}
      </Link>
    </ContainerSwitch>
  );
};

const mapStateToProps = (state: AppState) => ({
  showSsoAccounts: shouldShowSsoAccountsSelector(state),
});

export default connect(mapStateToProps, { setOpenSsoAccount })(AccountSwitch);
