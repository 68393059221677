import { IAction, ThunkResult } from './IAction';
import { ITranslationModel } from '../../models/Translation';

const TRANSLATION_PREFIX = 'TRANSLATION';
export const SET_TRANSLATIONS = `${TRANSLATION_PREFIX}/SET_TRANSLATIONS`;

export interface ISetTranslationsAction extends IAction {
  payload: ITranslationModel;
}

export interface IGetTranslations {
  (): ThunkResult<ISetTranslationsAction>;
}

export type TranslationActions = ISetTranslationsAction;
