import { Record } from 'immutable';
import { IModelStatic } from '../interfaces/IModel';
import AppContext, { IAppContext, IAppContextModel } from './AppContext';
import Item, { IItemModel, IRawItem } from './Item';
import RichText, { IRichTextModel, IRichText } from './RichText';
import { IMenuItem, MenuItemTypes } from '../interfaces/IMenuItem';

export interface IFeaturedProduct extends IMenuItem {
  title: string;
  appContextList?: Array<IAppContextModel>;
  parentItem?: IItemModel;
  text: IRichTextModel;
  menuImageUrl: string;
}

export interface IRawFeatureProduct {
  id: string;
  title: string;
  locale?: string;
  __typename?: string;
  appContextList?: Array<IAppContext>;
  parentItem?: IRawItem;
  richText: IRichText;
  menuImageUrl: string;
}

export interface IFeaturedProductModel extends Record<IFeaturedProduct>, IFeaturedProduct {}

const defaultProps = {
  id: '',
  title: '',
  locale: '',
  appContextList: [],
  parentItem: undefined,
  menuImageUrl: '',
  text: RichText.createFromRaw(),
  __typename: MenuItemTypes.featuredProduct,
};

const FeaturedProduct: IModelStatic<IFeaturedProductModel, IRawFeatureProduct> = class
  extends Record<IFeaturedProduct>(defaultProps, 'FeaturedProduct')
  implements IFeaturedProductModel {
  static createFromRaw(rawFeaturedProduct: IRawFeatureProduct): IFeaturedProductModel {
    const { appContextList: rawAppContextList = [], parentItem: rawParentItem, richText: rawText } =
      rawFeaturedProduct || {};

    const appContextList = rawAppContextList.map((appContext) =>
      AppContext.createFromRaw(appContext),
    );
    const text = RichText.createFromRaw(rawText);

    const parentItem = rawParentItem ? Item.createFromRaw(rawParentItem) : undefined;

    return new this({ ...(rawFeaturedProduct || {}), appContextList, parentItem, text });
  }
};

export default FeaturedProduct;
