import styled from 'styled-components';
import { Body3, Body4 } from '../../../../shared/typography';

export const Image = styled.img`
  max-width: 177px;
`;

export const Description = styled(Body4)`
  color: ${({ theme }) => theme.medGray};
  width: 193px;
`;

export const Link = styled(Body3)`
  color: ${({ theme }) => theme.oneAppBlue};
`;

export const Container = styled.div`
  a {
    cursor: pointer;
    position: relative;
    flex-direction: column;
    display: flex;
  }
`;
