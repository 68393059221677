import { Record } from 'immutable';
import get from 'lodash/get';

import { HIT_TYPES, VIDEO_PROGRAM_TYPES } from '../enums/algolia';
import AssociatedContent, { IAssociatedContentModel } from './AssociatedContent';
import { IHit } from '../interfaces/ISearch';
import { IRawMpxVideo } from '../interfaces/IWorkout';
import Program, { IProgramModel } from './Program';
import { IModelStatic } from '../interfaces/IModel';
import {
  IRawRecipe,
  IRecipeComposedItem,
  IRecipeWorksWellItem,
  IMindsetPlate,
  IContainerEquivalentItem,
  IRawVideoContainerItem,
  IVideoDetailsModalRecipeSection,
  IRawVideoDetailsContentGroupOne,
  IRawVideoGroupDetailsItem,
} from '../interfaces/IRecipe';

export interface IRecipe extends IHit {
  brandCode: string;
  description: string;
  groupName: string;
  id: number;
  imageUrl: string;
  position: number;
  queryID: string;
  subNavSlug: string;
  title: string;
  slug: string;
  type: string;
  associatedContent?: IAssociatedContentModel;
  program?: IProgramModel;
  video: IRawMpxVideo;

  recipeWorksWellFor?: IRecipeComposedItem<IRecipeWorksWellItem>;
  toBeMindsetPlateIt?: IMindsetPlate;
  veganContainerEquivalents?: IRecipeComposedItem<IContainerEquivalentItem>;
  containerEquivalents?: IRecipeComposedItem<IContainerEquivalentItem>;
}

const defaultProps: IRecipe = {
  brandCode: '',
  description: '',
  groupName: '',
  id: 0,
  imageUrl: '',
  objectID: '',
  position: 0,
  queryID: '',
  slug: '',
  subNavSlug: '',
  title: '',
  type: VIDEO_PROGRAM_TYPES.RECIPE,
  program: undefined,
  associatedContent: undefined,
  video: {
    brandCode: '',
    guid: '',
    slug: '',
    title: '',
    videoTypes: [],
  },
  __hitType: HIT_TYPES.RECIPE,
  recipeWorksWellFor: undefined,
  toBeMindsetPlateIt: undefined,
  veganContainerEquivalents: undefined,
  containerEquivalents: undefined,
};

export interface IRecipeModel extends Record<IRecipe>, IRecipe {}

const Recipe: IModelStatic<IRecipeModel, IRawRecipe> = class
  extends Record<IRecipe>(defaultProps, 'Recipe')
  implements IRecipeModel {
  static createFromRaw(rawRecipe?: IRawRecipe): IRecipeModel {
    const {
      brandCode,
      description,
      groupName,
      id,
      imageUrl,
      mpxVideo,
      objectID,
      program,
      resources,
      resourceGroupTitle,
      slug,
      subNavSlug,
      title,
      videoDetailsModalContentGroupOne,
      videoDetailsModalRecipeSection,
      __position: position,
      __queryID: queryID,
    } = rawRecipe || {};

    return new this({
      brandCode: brandCode || '',
      description: description || '',
      groupName: groupName || '',
      id: Number(id || 0),
      imageUrl: imageUrl || '',
      objectID: objectID || '',
      position: Number(position || 0),
      queryID: queryID || '',
      title: title || '',
      slug: slug || '',
      subNavSlug: subNavSlug || '',
      recipeWorksWellFor: {
        headlineText: get(videoDetailsModalContentGroupOne, 'title', ''),
        items: this.createRecipeWorksWellFor(videoDetailsModalContentGroupOne),
      },
      containerEquivalents: {
        headlineText: get(videoDetailsModalRecipeSection, 'containerRowOneTitle', ''),
        items: this.createRecipeContainerEquivalents(
          'containerRowOne',
          videoDetailsModalRecipeSection,
        ),
      },
      veganContainerEquivalents: {
        headlineText: get(videoDetailsModalContentGroupOne, 'containerRowTwoTitle', ''),
        items: this.createRecipeContainerEquivalents(
          'containerRowTwo',
          videoDetailsModalRecipeSection,
        ),
      },
      toBeMindsetPlateIt: {
        description: get(videoDetailsModalRecipeSection, 'nutritionText', ''),
        title: get(videoDetailsModalRecipeSection, 'nutritionHeadline', ''),
        url: get(videoDetailsModalRecipeSection, 'nutritionIcon', ''),
      },
      associatedContent: AssociatedContent.createFromRaw({
        links: resources || [],
        title: resourceGroupTitle || '',
      }),
      video: {
        brandCode: get(mpxVideo, 'brandCode', ''),
        guid: get(mpxVideo, 'guid', ''),
        slug: get(mpxVideo, 'slug', ''),
        title: get(mpxVideo, 'title', ''),
        videoTypes: get(mpxVideo, 'videoTypes', []),
      },
      program: program ? Program.createFromRaw(program) : undefined,
    });
  }

  static createRecipeWorksWellFor(
    recipes?: IRawVideoDetailsContentGroupOne,
  ): Array<IRecipeWorksWellItem> {
    if (!recipes) {
      return [];
    }

    const items = get(recipes, 'items', []) as Array<IRawVideoGroupDetailsItem>;
    return items.map((item) => ({
      title: get(item, 'title', ''),
      url: get(item, 'recipeIconImage', ''),
    }));
  }

  static createRecipeContainerEquivalents(
    container: string,
    recipes?: IVideoDetailsModalRecipeSection,
  ): Array<IContainerEquivalentItem> {
    if (!recipes) {
      return [];
    }

    const items = get(recipes, container, []) as Array<IRawVideoContainerItem>;
    return items.map((item) => ({
      color: get(item, 'color', ''),
      colorName: get(item, 'colorName', ''),
      id: `${get(item, 'size', '')}-${get(item, 'color', '')}`,
      size: get(item, 'size', ''),
    }));
  }
};

export default Recipe;
