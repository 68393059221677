import { combineReducers } from 'redux';
import { AppState } from '../interfaces/appState';
import { eventReducer } from './eventReducer';
import { groupsReducer } from './groupsReducer';
import { modulesReducer } from './modulesReducer';
import { topNavReducer } from './topNavReducer';
import { waiverReducer } from './waiverReducer';
import { entitlementReducer } from './entitlementReducer';
import { translationReducer } from './translationReducer';
import { vhsReducer } from './vhsReducer';
import { videoPlayerReducer } from './videoPlayerReducer';

const rootReducer = combineReducers<AppState>({
  entitlements: entitlementReducer,
  events: eventReducer,
  groups: groupsReducer,
  modules: modulesReducer,
  topNav: topNavReducer,
  waiver: waiverReducer,
  vhs: vhsReducer,
  translation: translationReducer,
  videoPlayer: videoPlayerReducer,
});

export default rootReducer;
