import { Record } from 'immutable';
import { PlaceholderEnum } from '../models/Placeholder';
import ItemModel, { IItemModel } from '../models/Item';
import { IMenuLocationModel } from '../models/MenuLocation';
import MenuModel, { IMenuItemModel, IMenuModel } from '../models/Menu';
import { TopNavTypes } from '../enums/topNav';

import TopNavMenu from '../components/desktopNav/menuLocations/topNavMenu';
import TopNavIcons from '../components/desktopNav/menuLocations/topNavIcons';
import TopNavAccount from '../components/desktopNav/menuLocations/topNavAccount';
import HamburgerIcon from '../components/desktopNav/items/hamburgerIcon';
import Logo from '../components/desktopNav/items/logo';
import UserAvatar from '../components/userAvatar';
import SelectedLocale from '../components/selectedLocale';
import NavLink from '../components/desktopNav/items/navLink';
import Menu from '../components/desktopNav/items/topNavMenu';
import Cart from '../components/cart';
import Search from '../components/search';
import LoginDropdown from '../components/desktopNav/items/loginDropdown';
import TopNavSignUpButton from '../components/desktopNav/items/topNavSignUpButton';
import TopNavLoginButton from '../components/desktopNav/items/topNavLoginButton';

export type TopNavLocationComponent = typeof TopNavMenu | typeof TopNavIcons | typeof TopNavAccount;
export type TopNavItemComponent =
  | typeof NavLink
  | typeof Menu
  | typeof Cart
  | typeof SelectedLocale
  | typeof Search
  | typeof UserAvatar;

interface ITopNavLocationMapper {
  [recordName: string]: TopNavLocationComponent;
}

interface IItemTypeFactoryMapper {
  [itemTypeName: string]: TopNavItemComponent;
}

interface ITopNavFactory {
  mapMenuLocation(menuLocation: IMenuLocationModel): TopNavLocationComponent;
  mapNavItem(item: IMenuItemModel): TopNavItemComponent;
}

const TopNavFactory = (): ITopNavFactory => {
  const topNavItemMapper: IItemTypeFactoryMapper = {
    [MenuModel.displayName]: Menu,
    [ItemModel.displayName]: NavLink,
    [PlaceholderEnum.cart]: Cart,
    [PlaceholderEnum.locale]: SelectedLocale,
    [PlaceholderEnum.search]: Search,
    [PlaceholderEnum.user]: UserAvatar,
    [PlaceholderEnum.logo]: Logo,
    [PlaceholderEnum.hamburger]: HamburgerIcon,
    [PlaceholderEnum.loginDropdown]: LoginDropdown,
    [PlaceholderEnum.signUpButton]: TopNavSignUpButton,
    [PlaceholderEnum.loginButton]: TopNavLoginButton,
  };

  const topNavLocationMapper: ITopNavLocationMapper = {
    [TopNavTypes.MAIN]: TopNavMenu,
    [TopNavTypes.LEFT]: TopNavIcons,
    [TopNavTypes.RIGHT]: TopNavAccount,
  };

  return {
    mapMenuLocation({ slug }) {
      return topNavLocationMapper[slug];
    },
    mapNavItem(item) {
      const itemAsItemModel = item as IItemModel;
      const itemAsMenuModel = item as IMenuModel;
      const itemType = (itemAsItemModel.placeholder && itemAsItemModel.placeholder?.type) || '';
      const menuType =
        (itemAsMenuModel.parentItem?.placeholder && itemAsMenuModel.parentItem?.placeholder.type) ||
        '';
      const recordType = Record.getDescriptiveName(item);

      return (
        topNavItemMapper[itemType] || topNavItemMapper[menuType] || topNavItemMapper[recordType]
      );
    },
  };
};

export default TopNavFactory;
