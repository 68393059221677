export enum BODRoutes {
  DOWNLOAD_APP = '/download-app',
  LESSONS = '/lessons',
  OFFERS_BOD_FULL_PATH = '/plans/offers_bod',
  OTHER = '/other',
  PROGRAMS = '/programs',
  REGISTER = '/register',
  REGISTER_FREE = '/register-free',
  UPGRADE = '/upgrade',
  WORKOUTS = '/workouts',
  RECIPES = '/recipes',
  PROGRAM_MATERIALS = '/program-materials',
  BODI = '/bodi',
}

export enum SearchRoutes {
  SEARCH = '/search',
}

export enum TBBRoutes {
  ENROLLMENT = '/enrollment',
  SHOP = '/shop',
}

export enum BBRoutes {
  BOD_CHECKOUT = '/bodcheckout',
}

export const PERPETUATED_URL_PARAM_WHITELIST = ['/login', '/register'];
