import { Record } from 'immutable';
import { IModelStatic } from '../interfaces/IModel';

export interface IEvent {
  eventName: string;
  callBack(params?: unknown): void;
}

const defaultProps = {
  eventName: '',
  callBack: () => undefined,
};

export interface IEventModel extends Record<IEvent>, IEvent {}

const Event: IModelStatic<IEventModel, IEvent> = class
  extends Record<IEvent>(defaultProps, 'Event')
  implements IEventModel {
  static createFromRaw(rawEvent: IEvent): IEventModel {
    return new this(rawEvent);
  }
};

export default Event;
