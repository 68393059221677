/** @jsx h */
import { FunctionalComponent, h } from 'preact';
import { NavItem } from '../../../../containers/mobileNavigation/mobileNavigationStyled';
import { IItemModel, ItemTypes } from '../../../../models/Item';
import { IMenuItemModel } from '../../../../models/Menu';
import { ItemIcon, AnchorText } from '../../../desktopNav/desktopNavStyled';
import AppLink from '../../../../components/appLink';

export interface INavLinkProps {
  item: IMenuItemModel;
  isBold?: boolean;
  onClose?(): void;
}

const NavLink: FunctionalComponent<INavLinkProps> = ({ item, isBold = false, onClose }) => {
  const itemModel = item as IItemModel;
  const { anchorText, itemType, menuImageUrl } = itemModel;

  return (
    <AppLink item={itemModel} onClose={onClose}>
      <NavItem isBold={itemType === ItemTypes.featuredLink || isBold} shouldNotJustify>
        {menuImageUrl && <ItemIcon alt={anchorText} src={menuImageUrl} hasContent={!!anchorText} />}
        <AnchorText dangerouslySetInnerHTML={{ __html: anchorText }} />
      </NavItem>
    </AppLink>
  );
};

export default NavLink;
