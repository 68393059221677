import styled from 'styled-components';
import { ITypography } from '../../interfaces/typography';
import Body1, { Body1Template } from '../body1';

export const BodyMedTemplate: ITypography = {
  ...Body1Template,
  fontSize: '16px',
  letterSpacing: '0.02em',
};

const { fontSize, lineHeight, letterSpacing } = BodyMedTemplate;

type TextProps = {
  isBold: boolean;
};

const BodyMed = styled(Body1)<TextProps>`
  font-weight: ${({ isBold }) => (isBold ? '700' : '400')};
  font-style: normal;
  line-height: ${lineHeight};
  letter-spacing: ${letterSpacing};
  font-size: ${fontSize};
`;

export default BodyMed;
