import { IVideoPlayerState } from '../reducers/videoPlayerReducer';
import {
  SET_VIDEO_PLAYER_OPEN,
  SET_VIDEO_PLAYER_PROPS,
  SET_VIDEO_PLAYER_ACTIONS_MAP,
  SET_IS_WAIVER_CHECKED,
  SET_IS_WAIVER_REQUIRED,
  SET_IS_WAIVER_LOADING,
  SET_IS_RATING_MODAL_OPEN,
  SET_IS_PROMO_SCREEN_OPEN,
} from './interfaces/IVideoPlayerActions';

export type IVideoPlayerAction = { type: string; payload: unknown };

export const setVideoPlayerOpen = (value: boolean): IVideoPlayerAction => ({
  type: SET_VIDEO_PLAYER_OPEN,
  payload: value,
});

export const setVideoPlayerProps = (
  value: IVideoPlayerState['playerProps'],
): IVideoPlayerAction => ({
  type: SET_VIDEO_PLAYER_PROPS,
  payload: value,
});

export const setVideoPlayerActionsMap = (
  value: IVideoPlayerState['actionsMap'],
): IVideoPlayerAction => {
  return {
    type: SET_VIDEO_PLAYER_ACTIONS_MAP,
    payload: value,
  };
};

export const setIsWaiverChecked = (value: IVideoPlayerState['isWaiverChecked']) => {
  return { type: SET_IS_WAIVER_CHECKED, payload: value };
};

export const setIsWaiverRequired = (value: IVideoPlayerState['isWaiverRequired']) => {
  return { type: SET_IS_WAIVER_REQUIRED, payload: value };
};

export const setIsWaiverLoading = (value: IVideoPlayerState['isWaiverLoading']) => {
  return { type: SET_IS_WAIVER_LOADING, payload: value };
};

export const setIsRatingModalOpen = (value: IVideoPlayerState['isRatingModalOpen']) => {
  return { type: SET_IS_RATING_MODAL_OPEN, payload: value };
};

export const setIsPromoScreenOpen = (value: IVideoPlayerState['isPromoScreenOpen']) => {
  return { type: SET_IS_PROMO_SCREEN_OPEN, payload: value };
};
