import { modulesByKeySelector } from '../selectors/modulesSelectors';
import TranslationsClient from '../gateways/appsync/translationsClient';
import { ILocaleModel } from '../models/Locale';
import { ITranslationItems, ITranslationModel } from '../models/Translation';
import { IGetTranslations, SET_TRANSLATIONS } from './interfaces/ITranslation';

const DEFAULT_FILTER_SLUGS = ['bodi'];

export const getTranslations: IGetTranslations = () => async (dispatch, getState) => {
  const { selectedLocale } = modulesByKeySelector(getState(), 'locale') as ILocaleModel;

  const translations = await TranslationsClient(selectedLocale).get({
    slugs: DEFAULT_FILTER_SLUGS,
  });

  const formattedData = translations[0]?.meta_box.translationGroups.map(
    ({ title, translations }) => ({
      title,
      translations: translations.reduce(
        (previousValue, currentValue) => ({
          ...previousValue,
          [currentValue.key]: currentValue.value,
        }),
        {},
      ),
    }),
  ) as ITranslationItems[];

  const payload = {
    translationGroups: formattedData,
  } as ITranslationModel;

  return dispatch({
    type: SET_TRANSLATIONS,
    payload,
  });
};
