import { Record } from 'immutable';
import { Reducer } from 'redux';
import { GroupActions, SET_USER_GROUPS_MENU } from '../actions/interfaces/IGroupAction';
import { IItemModel } from '../models/Item';

export interface IGroupsState {
  readonly groupsItemList: Array<IItemModel>;
  readonly hasLoaded: boolean;
}

export interface IGroupsStateRecord extends Record<IGroupsState>, IGroupsState {}

export const GroupState = Record<IGroupsState>({
  groupsItemList: [],
  hasLoaded: false,
});

const initialState = GroupState();

export const groupsReducer: Reducer<IGroupsStateRecord, GroupActions> = (
  state = initialState,
  action,
) => {
  const { type, payload } = action;

  switch (type) {
    case SET_USER_GROUPS_MENU: {
      const groups = payload;
      return state.set('groupsItemList', groups).set('hasLoaded', true);
    }
    default:
      return state;
  }
};
