/** @jsx h */
import { FunctionalComponent, h } from 'preact';
import { Suspense } from 'preact/compat';

export interface IDynamicImport {
  bundleName: string;
}

const DynamicImport: FunctionalComponent<IDynamicImport> = ({ children, bundleName }) => {
  console.info(`Unification: Loading bundle ${bundleName}`);
  return <Suspense fallback={<div />}>{children}</Suspense>;
};

export default DynamicImport;
