import { ActionCreator } from 'redux';
import { SupportedEvents } from '../enums/event';
import { SupportedLanguages } from '../enums/locale';
import MenuData from '../gateways/menuData/menuData';
import { IDefaultAction } from '../interfaces/common';
import { IMenuModel } from '../models/Menu';
import { IMenuLocationModel } from '../models/MenuLocation';
import { ThunkResult, ThunkResultDispatch } from './interfaces/IAction';
import { ILocaleModel } from '../models/Locale';
import {
  ISetActiveMenuMobileAction,
  ISetMenusAction,
  ISetOpenSsoAccountsAction,
  ISetNextLocaleChangeModal,
} from './interfaces/IHeaderAction';

const HEADER_PREFIX = 'HEADER';
export const SET_MENUS = `${HEADER_PREFIX}/SET_MENUS`;
export const SET_ACTIVE_MENU_MOBILE = `${HEADER_PREFIX}/SET_ACTIVE_MENU_MOBILE`;
export const SET_OPEN_SSO_ACCOUNTS = `${HEADER_PREFIX}/SET_OPEN_SSO_ACCOUNTS`;
export const SET_NEXT_LOCALE_CHANGE_MODAL = `${HEADER_PREFIX}/SET_NEXT_LOCALE_CHANGE_MODAL`;

export const setMenus = (menus: Array<IMenuLocationModel>): ISetMenusAction => ({
  event: {
    preEventAction: SupportedEvents.onLoadingStart,
    postEventAction: SupportedEvents.onLoadingEnd,
  },
  type: SET_MENUS,
  payload: menus,
});

export const setActiveMenuMobile = (menu: IMenuModel): ISetActiveMenuMobileAction => ({
  type: SET_ACTIVE_MENU_MOBILE,
  payload: menu,
});

export const setOpenSsoAccount = (open: boolean): ISetOpenSsoAccountsAction => ({
  type: SET_OPEN_SSO_ACCOUNTS,
  payload: open,
});

export const setNextLocaleChangeModal = (
  nextLocale: ILocaleModel | null = null,
): ISetNextLocaleChangeModal => {
  return {
    type: SET_NEXT_LOCALE_CHANGE_MODAL,
    payload: nextLocale,
  };
};

export const getMenusActionCreator: ActionCreator<ThunkResult<ISetMenusAction>> = (
  selectedLocale: SupportedLanguages,
) => {
  return async (dispatch: ThunkResultDispatch<ISetMenusAction>) => {
    const menuLocationList = await MenuData.get(selectedLocale);

    return dispatch(setMenus(menuLocationList));
  };
};

export type HeaderActions =
  | ISetMenusAction
  | ISetActiveMenuMobileAction
  | IDefaultAction
  | ISetOpenSsoAccountsAction;
