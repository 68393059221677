import head from 'lodash/head';
import { Record } from 'immutable';
import { IHit, IRawViolator } from '../interfaces/ISearch';
import { IModelStatic } from '../interfaces/IModel';
import { HIT_TYPES } from '../enums/algolia';
import { loadFromConfig } from '../utils/configLoader';
import { Roles } from '../enums/sso';
import Violator, { IViolatorModel } from './Violator';

const { TBB } = loadFromConfig('APP_DOMAIN_LIST');

export interface IProduct extends IHit {
  categories: Array<string>;
  imageUrl: string;
  longDescription: string;
  price?: {
    [key: string]: Array<number | string>;
  };
  title: string;
  redirectUrl: string;
  role?: string;
  violator?: IViolatorModel;
}

export interface IRawProduct extends IHit {
  filters?: {
    ByParentCategory?: string | Array<string>;
  };
  cdpLongDescription?: string;
  landingPageUrl?: string;
  objectID: string;
  price?: {
    [key: string]: Array<number | string>;
  };
  productImages?: {
    productCDPImageUrl: string;
  };
  title: string;
  customerTypesForView?: string;
  violator?: {
    meta_box?: IRawViolator | Array<IRawViolator>;
  };
}

export interface IProductModel extends Record<IProduct>, IProduct {
  getPriceInfo(role: Roles): [number | undefined, number | undefined];
}

const defaultProps: IProduct = {
  categories: [],
  imageUrl: '',
  objectID: '',
  position: 0,
  queryID: '',
  price: undefined,
  role: undefined,
  redirectUrl: '',
  title: '',
  longDescription: '',
  violator: undefined,
  __hitType: HIT_TYPES.PRODUCT,
};

const Product: IModelStatic<IProductModel, IRawProduct> = class
  extends Record<IProduct>(defaultProps, 'Product')
  implements IProductModel {
  static createFromRaw(rawProduct?: IRawProduct): IProductModel {
    const {
      productImages,
      landingPageUrl,
      cdpLongDescription,
      customerTypesForView: role,
      filters = {},
      violator: rawViolator,
    } = rawProduct || {};

    const imageUrl = productImages?.productCDPImageUrl || '';
    const redirectUrl = `${TBB}${landingPageUrl}`;
    const categories =
      filters && Array.isArray(filters?.ByParentCategory)
        ? (filters?.ByParentCategory as Array<string>)
        : [filters?.ByParentCategory];

    const prices =
      rawProduct?.price &&
      Object.entries(rawProduct?.price).reduce(
        (actual, [key, value]) =>
          Array.isArray(value) ? { ...actual, [key]: value } : { ...actual, [key]: [value] },
        {},
      );

    const violator =
      rawViolator?.meta_box && Array.isArray(rawViolator?.meta_box)
        ? (head(rawViolator?.meta_box) as IRawViolator)
        : (rawViolator?.meta_box as IRawViolator);

    return new this({
      ...rawProduct,
      longDescription: cdpLongDescription,
      categories: categories as Array<string>,
      price: prices,
      redirectUrl,
      role,
      imageUrl,
      violator: violator && Violator.createFromRaw({ ...violator, isTBBViolator: true }),
      __hitType: HIT_TYPES.PRODUCT,
    });
  }

  getPriceInfo(role: Roles = Roles.registeredUser): [number | undefined, number | undefined] {
    const { price = {} } = this;
    const [regularPriceText, salePriceText] = price[role] || price[Roles.registeredUser] || [];

    const validatePrice = (priceText: typeof regularPriceText) =>
      !isNaN(+priceText) ? +priceText : undefined;

    const productPrice = validatePrice(regularPriceText);
    const salePrice = salePriceText !== regularPriceText ? validatePrice(salePriceText) : undefined;

    return [productPrice, salePrice];
  }
};

export default Product;
