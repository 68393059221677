import { IVHSModel } from '../models/Vhs';
import VHSClient from '../gateways/vhs/vhsClient';
import { modulesByKeySelector } from '../selectors/modulesSelectors';
import {
  IGetVideoHistoryActionCreator,
  ISetShouldLoadHistoryAction,
  ISetVideoHistoryAction,
  SET_SHOULD_LOAD_HISTORY,
  SET_VIDEO_HISTORY,
} from './interfaces/IVHSAction';
import { ISsoModel } from '../interfaces/ISso';
import { IUserInfoModel } from '../models/UserInfo';
import { IMainModel } from 'src/models/Main';

export const setVideoHistory = (videoHistoryList: Array<IVHSModel>): ISetVideoHistoryAction => ({
  type: SET_VIDEO_HISTORY,
  payload: videoHistoryList,
});

export const setShouldLoadVideoHistory = (value: boolean): ISetShouldLoadHistoryAction => ({
  type: SET_SHOULD_LOAD_HISTORY,
  payload: value,
});

export const getVideoHistoryActionCreator: IGetVideoHistoryActionCreator = () => async (
  dispatch,
  getState,
) => {
  const {
    accessToken = '',
    userInfo: { guid: userId } = {} as IUserInfoModel,
  } = modulesByKeySelector(getState(), 'sso') as ISsoModel;

  const { versionName } = modulesByKeySelector(getState(), 'main') as IMainModel;

  const VhsResponse = await VHSClient(userId).getVideoHistory(accessToken, versionName || '');
  return dispatch(setVideoHistory(VhsResponse));
};
