import styled from 'styled-components';
import closeIcon from '../../assets/close.svg';
import { Backdrop } from '../../containers/topNav/topNavStyled';
import ButtonFill from '../../shared/buttons/buttonFill';
import ButtonFloating from '../../shared/buttons/buttonFloating';

import { Media } from '../../styles/media';
import { resolveRelativeUrl } from '../../utils/device';

const { mobile, tabletUp, desktopDown } = Media;

export const PromoBackdrop = styled(Backdrop)`
  z-index: 100000;
`;

export const PromoScreenContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0px 24px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  position: fixed;
  z-index: 100000;
  text-align: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: radial-gradient(60% 30% at -2% 3%, #197bb8 -5%, #183a62 53%, #161718 121%);

  ${desktopDown`
    background: radial-gradient(55% 12% at -5% 2%,#197bb8 -18%,#183a62 70%,#161718 170%);
  `};

  ${mobile`
    background: radial-gradient(50% 7% at 0% 1%,#197bb8 39%,#183a62 97%,#161718 160%);
  `};
`;

export const TopBar = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  margin: 3.5rem;
  margin-bottom: 0;

  ${mobile`
    margin: 1.5rem;
  `};
`;

export const PositionedCloseIcon = styled.img.attrs({
  src: `${resolveRelativeUrl(closeIcon)}`,
})`
  cursor: pointer;
  height: 1.1rem;
  width: 1.1rem;

  &:focus {
    outline: none;
  }
`;

export const Logo = styled.img`
  width: 6.25rem;
  height: 2.5rem;

  ${mobile`
    width: 3.75rem;
    height: 1.5rem;
  `};
`;

export const Header = styled.h1`
  color: ${({ theme }) => theme?.whiteBG};
  font-size: 3.375rem;
  font-weight: 1.43em;
  margin-bottom: 1.2rem;
  line-height: 2.75rem;
  letter-spacing: 0.2px;

  ${desktopDown`
    margin-top: 6rem;
  `};

  ${mobile`
    font-size: 2.25rem;
    font-weight: 900;
    text-align: left;
    max-width: 20rem;
    margin-top: 0;
  `};
`;

export const Content = styled.section`
  display: flex;
  flex-direction: column;
  padding: 0px 3.5rem;

  ${mobile`
    padding: 0px 1.5rem;
    margin-top: 1.875rem;
  `};
`;

export const Description = styled.h2`
  font-size: 1.25rem;
  font-weight: 400;
  color: ${({ theme }) => theme?.whiteBG};
  max-width: 40.625rem;
  margin-bottom: 0;
  line-height: 1.75rem;
  letter-spacing: 0.2px;
  align-self: center;

  ${desktopDown`
    margin-bottom: 4rem;
  `};

  ${mobile`
    text-align: justify;
    font-size: 0.875rem;
    line-height: 1.5rem;
    margin-bottom: 0;
  `};
`;

export const Footer = styled.section`
  display: flex;
  flex-direction: column;
  align-self: center;
  margin: 1rem 0;

  ${desktopDown`
    margin: 6rem 0;
  `};

  ${mobile`
    flex-direction: row;
    background-color: #202223;
    width: 100%;
    height: 100%;
    margin-bottom: 0;
    margin-top: 2rem;
    padding: 1.5rem;
    justify-content: center;
  `};
`;

export const SubmitButton = styled(ButtonFill)`
  font-size: 1.125rem;
  margin-bottom: 1rem;
  letter-spacing: 0.07rem;

  ${tabletUp`
    font-size: 1.125rem;
    padding: 1rem 1.7rem;
  `};

  ${mobile`
    margin-bottom: 0;
    font-size: 1rem;
    padding: 0.8rem 1.9rem;
    letter-spacing: 0;
  `};
`;

export const MainContent = styled.section`
  display: flex;
  justify-content: center;
  margin-top: 2.1rem;
`;

export const Cards = styled.div<{ columns: number }>`
  display: grid;
  grid-template-columns: ${({ columns }) => `repeat(${columns}, 1fr)`};
  grid-gap: ${({ theme }) => theme?.gutterMedium};
  max-width: fit-content;

  ${mobile`
    grid-template-columns: repeat(2, 1fr);
  `};
`;

export const DismissButton = styled(ButtonFloating)`
  color: ${({ theme }) => theme?.buttersGray || 'white'};

  ${tabletUp`
    font-size: 1.25rem;
    font-weight: 400;
  `};

  ${mobile`
    font-size: 0.875rem;
    font-weight: 400;
  `};
`;
