/** @jsx h */
import { FunctionalComponent, h } from 'preact';
import { useTranslation } from 'react-i18next';
import {
  Body,
  Button,
  ContentContainer,
  Footer,
  VideoWaiverStyled,
  VideoWaiverContent,
  Divider,
} from './videoWaiverStyled';
import { useState } from 'preact/hooks';
import VideoWaiverCheckbox from './videoWaiverCheckbox';
import { VideoWaiverLocale } from './locale';

export interface IVideoWaiver {
  waiverAgreementBody: string;
  onWaiverDeclined: () => void;
  onWaiverAccepted: () => void;
}

const VideoWaiver: FunctionalComponent<IVideoWaiver> = ({
  waiverAgreementBody,
  onWaiverDeclined,
  onWaiverAccepted,
}) => {
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const { t } = useTranslation();
  const onCheckChange = (isChecked: boolean) => {
    setIsCheckboxChecked(isChecked);
  };

  return (
    <VideoWaiverStyled>
      <ContentContainer>
        <VideoWaiverContent>
          <Body>
            <div
              dangerouslySetInnerHTML={{
                __html: waiverAgreementBody || t(VideoWaiverLocale.waiverAgreementBody),
              }}
            />
            <VideoWaiverCheckbox isChecked={isCheckboxChecked} onChange={onCheckChange}>
              {t(VideoWaiverLocale.waiverAgreementCheckboxLabel)}
            </VideoWaiverCheckbox>
          </Body>
          <Footer>
            <Divider />
            <Button small disabled={!isCheckboxChecked} onClick={onWaiverAccepted}>
              {t(VideoWaiverLocale.waiverAgreementAcceptButtonLabel)}
            </Button>
            <Button small onClick={onWaiverDeclined}>
              {t(VideoWaiverLocale.waiverAgreementDeclineButtonLabel)}
            </Button>
          </Footer>
        </VideoWaiverContent>
      </ContentContainer>
    </VideoWaiverStyled>
  );
};

export default VideoWaiver;
