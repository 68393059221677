/** @jsx h */
import { FunctionalComponent, h } from 'preact';
import { Configure, InstantSearch } from 'react-instantsearch-dom';
import { connect } from 'react-redux';
import noop from 'lodash/noop';

import { ISearchState } from '../../interfaces/ISearchState';
import { getAlgoliaClient, getCustomAlgoliaClient } from '../../gateways/algolia';
import { AppState } from '../../interfaces/appState';
import { ISsoModel } from '../../interfaces/ISso';
import { modulesByKeySelector } from '../../selectors/modulesSelectors';
import { ALGOLIA_ANALYTICS_TAGS } from '../../utils/constants';

const searchClient = getAlgoliaClient();
const customSearchClient = getCustomAlgoliaClient();

export interface IInstantSearchWrapperProps {
  createURL?: (state: ISearchState) => string;
  indexName: string;
  onSearchStateChange?: (state: ISearchState) => void;
  searchState?: ISearchState;
  withCustomClient?: boolean;
  sso: ISsoModel;
}

export const InstantSearchWrapper: FunctionalComponent<IInstantSearchWrapperProps> = ({
  children,
  createURL = noop,
  indexName,
  onSearchStateChange = noop,
  searchState,
  withCustomClient = false,
  sso: { userLoginStatus },
}) => {
  const analyticsTags = [userLoginStatus, ALGOLIA_ANALYTICS_TAGS.platform];

  return (
    <InstantSearch
      createURL={createURL}
      indexName={indexName}
      onSearchStateChange={onSearchStateChange}
      searchClient={withCustomClient ? customSearchClient : searchClient}
      searchState={searchState}
    >
      <Configure analyticsTags={analyticsTags} clickAnalytics />
      {children}
    </InstantSearch>
  );
};

const mapStateToProps = (state: AppState) => ({
  sso: modulesByKeySelector(state, 'sso'),
});

export default connect(mapStateToProps)(InstantSearchWrapper);
