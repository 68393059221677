import styled from 'styled-components';

export const GlobalStyle = styled.div`
  font-family: 'Lato', sans-serif;

  * {
    font-family: 'Lato', sans-serif;
  }

  [type='button'] {
    -webkit-appearance: none;
  }

  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
