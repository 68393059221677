import styled from 'styled-components';
import { Media } from '../../../styles/media';

const { tablet } = Media;

const NAV2 = styled.span`
  font-size: 12px;
  font-weight: 400;

  ${tablet`
    font-size: 10px;
  `};
`;

export default NAV2;
