import { SupportedLanguages } from '../enums/locale';
import { EN_US } from './en-US';
import { EN_CA } from './en-CA';
import { EN_GB } from './en-GB';
import { ES_US } from './es-US';
import { FR_FR } from './fr-FR';
import { FR_CA } from './fr-CA';

export const LOCALE_RESOURCES = {
  [SupportedLanguages.EN_US]: {
    translation: EN_US,
  },
  [SupportedLanguages.EN_CA]: {
    translation: EN_CA,
  },
  [SupportedLanguages.EN_GB]: {
    translation: EN_GB,
  },
  [SupportedLanguages.ES_US]: {
    translation: ES_US,
  },
  [SupportedLanguages.FR_FR]: {
    translation: FR_FR,
  },
  [SupportedLanguages.FR_CA]: {
    translation: FR_CA,
  },
};
