/** @jsx h */
import { FunctionalComponent, h, VNode } from 'preact';
import { theme } from '../../../../src/styles/theme';
import { SpinnerCircle } from './spinnerStyled';
import { CSSObject } from 'styled-components';

export interface ISize {
  small?: boolean;
  large?: boolean;
}

export interface ISpinner extends ISize {
  color?: string;
  style?: TemplateStringsArray | CSSObject;
}

export function getModifier({ small, large }: ISize): number {
  // This modifier is responsible for setting the width and height of each arm as well as the
  // positioning of them away form the axis. They were carefully selected via trial and error to
  // create the correct icon.
  if (small) return 0.422;
  if (large) return 1;

  return 0.615;
}

export function getLoadingAnimationElements(): VNode[] {
  const divs = [];
  for (let i = 0; i < 12; i += 1) {
    divs.push(<div key={`empty-div-${i}`} />);
  }
  return divs;
}

const Spinner: FunctionalComponent<ISpinner> = ({
  small,
  large,
  color = theme.oneAppBlue50,
  style,
}) => {
  return (
    <SpinnerCircle modifier={getModifier({ small, large })} color={color} style={style}>
      {getLoadingAnimationElements()}
    </SpinnerCircle>
  );
};

export default Spinner;
