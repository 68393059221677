import { LABEL_SEPARATOR } from '../../enums/algolia';

export function getNameFromLabel(label: string, position = 1): string {
  const splits = label.split(LABEL_SEPARATOR);
  return splits.length > 1 ? splits[position] : label;
}

export function removeAttributeFromLabel(label: string): string {
  const substrings = label.split(':');
  if (substrings.length > 1) {
    return substrings.slice(1).join(':').trim();
  }
  return substrings[0];
}
