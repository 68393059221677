import {
  IUpdateDeviceAction,
  IInitializeModulesActionCreator,
  IInternalInitializationActionCreator,
  INITIALIZE_MODULES,
  INTERNAL_INITIALIZE_MODULES,
  IUpdateIsDebugEnabledAction,
  IUpdateMainModuleActionCreator,
  IUpdateOnFocusActionCreator,
  IUpdateRouteAction,
  IUpdateVersionNameActionCreator,
  UPDATE_DEVICE,
  UPDATE_IS_DEBUG_ENABLED,
  UPDATE_MAIN_MODULE,
  UPDATE_ON_FOCUS,
  UPDATE_ROUTE,
  UPDATE_VERSION_NAME,
} from './interfaces/IModulesActions';
import {
  getAllFromStorage,
  mergeAllModules,
  mergeFromStorage,
  saveAllToStorage,
  triggerSideEffectActions,
  triggerSideEffectEvents,
} from '../services/modulesService';
import { DeviceTypes } from '../utils/device';
import Main from '../models/Main';
import VersionNameClient from '../gateways/versionName/versionNameClient';

export const internalInitialization: IInternalInitializationActionCreator = () => async (
  dispatch,
) => {
  const modulesFromStorage = await getAllFromStorage();
  const modulesFromConfig = Main.getInitialConfig();
  const resultModules = mergeAllModules(modulesFromConfig, modulesFromStorage);

  return dispatch({
    type: INTERNAL_INITIALIZE_MODULES,
    payload: resultModules,
  });
};

export const initializeModules: IInitializeModulesActionCreator = (initialConfig) => async (
  dispatch,
) => {
  const modulesFromStorage = await getAllFromStorage();
  const resultModules = mergeAllModules(initialConfig, modulesFromStorage);
  await saveAllToStorage(resultModules);
  triggerSideEffectActions(resultModules, dispatch);

  return dispatch({
    type: INITIALIZE_MODULES,
    payload: resultModules,
  });
};

export const updateMainModule: IUpdateMainModuleActionCreator = (updatedConfig) => (
  dispatch,
  getState,
) => {
  const { modules: modulesFromState } = getState();
  const resultModules = mergeAllModules(updatedConfig, modulesFromState);
  return dispatch({
    type: UPDATE_MAIN_MODULE,
    payload: resultModules,
  });
};

export const updateOnFocus: IUpdateOnFocusActionCreator = () => async (dispatch, getState) => {
  const { modules: modulesFromState, events } = getState();
  const modulesFromStorage = await getAllFromStorage();
  const resultModules = mergeFromStorage(modulesFromStorage, modulesFromState);
  await saveAllToStorage(resultModules);
  triggerSideEffectEvents(modulesFromStorage, modulesFromState, events);

  return dispatch({
    type: UPDATE_ON_FOCUS,
    payload: resultModules,
  });
};

export const updateDevice = (deviceType: DeviceTypes): IUpdateDeviceAction => ({
  type: UPDATE_DEVICE,
  payload: deviceType,
});

export const updateRoute = (route: string): IUpdateRouteAction => ({
  type: UPDATE_ROUTE,
  payload: route,
});

export const updateIsDebugEnabled = (newValue: boolean): IUpdateIsDebugEnabledAction => ({
  type: UPDATE_IS_DEBUG_ENABLED,
  payload: newValue,
});

export const getVersionNameCreator: IUpdateVersionNameActionCreator = () => async (dispatch) => {
  const versionName: string = await VersionNameClient.get();

  return dispatch({
    type: UPDATE_VERSION_NAME,
    payload: versionName,
  });
};
