import { Record } from 'immutable';
import { Reducer } from 'redux';
import { IVHSModel } from '../models/Vhs';
import {
  SET_SHOULD_LOAD_HISTORY,
  SET_VIDEO_HISTORY,
  VHSActions,
} from '../actions/interfaces/IVHSAction';

export interface IVHSState {
  readonly videoHistoryList: Array<IVHSModel>;
  readonly shouldLoadHistory: boolean;
}

export interface IVHSStateRecord extends Record<IVHSState>, IVHSState {}

export const VHSState = Record<IVHSState>({
  videoHistoryList: [],
  shouldLoadHistory: true,
});

const initialState = VHSState();

export const vhsReducer: Reducer<IVHSStateRecord, VHSActions> = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_VIDEO_HISTORY: {
      return state
        .set('videoHistoryList', payload as Array<IVHSModel>)
        .set('shouldLoadHistory', false);
    }

    case SET_SHOULD_LOAD_HISTORY: {
      return state.set('shouldLoadHistory', payload as boolean);
    }
    default:
      return state;
  }
};
