import get from 'lodash/get';
import { ISearch, ISearchModel } from '../models/Search';
import { modulesByKeySelector } from '../selectors/modulesSelectors';
import {
  IClearRecentSearchesActionCreator,
  IGetTopResultActionCreator,
  ISetIsUnavailableAction,
  IStoreSearchActionCreator,
  IUpdateConfigActionCreator,
  UPDATE_SEARCH_CONFIG,
  UPDATE_TOP_RESULT,
  IS_UNAVAILABLE,
  IS_FREE_REG,
  ISetVideoData,
  SET_VIDEO_DATA,
  STORE_SEARCH,
  CLEAR_RECENT_SEARCHES,
  IPlayOrRedirectVideoActionCreator,
} from './interfaces/ISearchActions';
import { recentSearchesSelector } from '../selectors/searchSelector';
import { IHit, IRecentSearch } from '../interfaces/ISearch';
import { executeMultipleQueries } from '../gateways/algolia';
import { ILocaleModel } from '../models/Locale';
import { getTopResultQueries } from '../utils/algolia';
import { IVideo } from '../interfaces/IVideo';
import { NO_ACTION } from './interfaces/IAction';
import { TEN_MINUTE_TRAINER } from '../enums/algolia';
import { navigationRedirectActionCreator } from './navigationActions';

// Actions requiring SearchClient.set
export const SAVE_TO_STORE_ACTIONS = [UPDATE_SEARCH_CONFIG, STORE_SEARCH, CLEAR_RECENT_SEARCHES];

const mergeSearchModule = (search: ISearchModel, newData: ISearch): ISearchModel =>
  search.mergeFromConfig(newData);

export const storeSearch: IStoreSearchActionCreator = (value: string, tab: string) => (
  dispatch,
  getState,
) => {
  const searchState = modulesByKeySelector(getState(), 'search') as ISearchModel;
  const recentSearches = recentSearchesSelector(getState());
  const currentSearch = recentSearches.find(
    ({ value: recentSearchValue }: IRecentSearch) => recentSearchValue === value,
  );

  if (currentSearch) {
    currentSearch.popularity += 1;
  } else {
    recentSearches.push({
      popularity: 1,
      tab,
      value,
    });
  }

  return dispatch({
    type: STORE_SEARCH,
    payload: mergeSearchModule(searchState, { recentSearches } as ISearchModel),
  });
};

export const clearRecentSearches: IClearRecentSearchesActionCreator = () => (dispatch) => {
  return dispatch({
    type: CLEAR_RECENT_SEARCHES,
  });
};

export const getTopResult: IGetTopResultActionCreator = (query: string) => async (
  dispatch,
  getState,
) => {
  const locale = modulesByKeySelector(getState(), 'locale') as ILocaleModel;
  const queries = getTopResultQueries(locale.selectedLocale, query);
  const searchResponse = await executeMultipleQueries(queries);

  const topResultIndex = searchResponse.find((response) => !!response.hits.length);
  const topResult = get(topResultIndex, 'hits[0]') as IHit;

  return dispatch({
    type: UPDATE_TOP_RESULT,
    payload: topResult,
  });
};

export const updateConfig: IUpdateConfigActionCreator = (search) => (dispatch, getState) => {
  const searchState = modulesByKeySelector(getState(), 'search') as ISearchModel;

  return dispatch({
    type: UPDATE_SEARCH_CONFIG,
    payload: mergeSearchModule(searchState, search),
  });
};

export const setIsUnavailable = (payload: boolean): ISetIsUnavailableAction => ({
  type: IS_UNAVAILABLE,
  payload,
});

export const setIsFree = (payload: boolean): ISetIsUnavailableAction => ({
  type: IS_FREE_REG,
  payload,
});

export const setVideoData = (payload: IVideo | null): ISetVideoData => ({
  type: SET_VIDEO_DATA,
  payload,
});

export const playOrRedirect: IPlayOrRedirectVideoActionCreator = (
  purchaseUrl,
  isUnavailable,
  workout,
  tealiumData,
) => (dispatch) => {
  const { isBODi, program, id, video, title, trainer } = workout;
  const { brandCode = '' } = program || {};
  const { guid = '' } = video || {};
  const redirect = (url: string) => dispatch(navigationRedirectActionCreator(url));

  if (!purchaseUrl) {
    return dispatch(
      setVideoData({
        isBODi,
        brandCode: isBODi ? TEN_MINUTE_TRAINER : brandCode,
        videoGuid: isBODi ? id : guid,
        shouldAutoPlay: true,
        initialOpenState: true,
        analyticsData: tealiumData,
        title,
        trainer,
      }),
    );
  }

  if (isUnavailable) return dispatch(setIsUnavailable(true));

  redirect(purchaseUrl);

  return {
    type: NO_ACTION,
  };
};

export default {
  storeSearch,
  clearRecentSearches,
  getTopResult,
  updateConfig,
  setIsUnavailable,
  setIsFree,
  setVideoData,
};
