import { bindActionCreators, Store } from 'redux';
import { ISearchActionCreators, ISearchActions } from '../../actions/interfaces/ISearchActions';
import { IConfigSearch, ISearchModel } from '../../models/Search';
import { modulesByKeySelector } from '../../selectors/modulesSelectors';
import searchActions from '../../actions/searchActions';
import { log } from '../../utils/logging';

export interface ISearchModule {
  getSearchConfig(): Promise<IConfigSearch>;
  updateConfig(searchConfig: IConfigSearch): Promise<IConfigSearch>;
}

export const getSearchModule = (store: Store): ISearchModule => {
  const { dispatch, getState } = store;

  const { updateConfig } = bindActionCreators<ISearchActionCreators, ISearchActions>(
    searchActions,
    dispatch,
  );

  return {
    getSearchConfig() {
      log('search', 'getSearchConfig');
      const search = modulesByKeySelector(getState(), 'search') as ISearchModel;

      return Promise.resolve(search.toConfig());
    },
    async updateConfig(updatedConfig) {
      log('search', 'updateConfig', updatedConfig);
      const { payload: search } = await updateConfig(updatedConfig);

      return search.toConfig();
    },
  };
};
