import LZString from 'lz-string';
import { ICacheData } from '../interfaces/ICacheData';

const STORAGE_TEST = '__storage_test__';

export const isLocalStorageAvailable = (): boolean => {
  try {
    const storage = window.localStorage;

    storage.setItem(STORAGE_TEST, STORAGE_TEST);
    storage.removeItem(STORAGE_TEST);

    return true;
  } catch (e) {
    return false;
  }
};

export const saveToLocalStorage = <T>(key: string, value: ICacheData<T>): void => {
  if (isLocalStorageAvailable()) {
    localStorage.setItem(key, LZString.compress(JSON.stringify(value)));
  }
};

export const loadFromLocalStorage = <T>(key: string): ICacheData<T> | null => {
  const data = localStorage.getItem(key);
  if (isLocalStorageAvailable() && data != null) {
    const item = LZString.decompress(data);
    if (item !== null) {
      return JSON.parse(item);
    }
  }
  return null;
};

export const removeFromLocalStorage = (key: string): void => {
  if (isLocalStorageAvailable()) {
    localStorage.removeItem(key);
  }
};
