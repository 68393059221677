/** @jsx h */
import { FunctionalComponent, h } from 'preact';
import {
  Background,
  Close,
  Container,
  WhiteBox,
  PromoText,
  GrayBox,
  PromoButton,
} from './promoModalStyled';

export interface IPromoModalProps {
  isOpen: boolean;
  onClose: () => void;
  promoCodeText: string;
  promoText: string;
  promoUrl: string;
}

export const PromoModal: FunctionalComponent<IPromoModalProps> = ({
  isOpen,
  onClose,
  promoCodeText,
  promoText,
  promoUrl,
}) => (
  <Background isOpen={isOpen}>
    <Container>
      <WhiteBox>
        <Close onClick={onClose} />
        <PromoText>{promoText}</PromoText>
      </WhiteBox>
      <GrayBox>
        <PromoButton
          onClick={() => {
            window.location.href = promoUrl;
          }}
        >
          {promoCodeText}
        </PromoButton>
      </GrayBox>
    </Container>
  </Background>
);
