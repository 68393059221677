import styled from 'styled-components';

export const Digits2Template = {
  fontSize: '24px',
  fontWeight: '300',
  lineHeight: '1.5',
  letterSpacing: '0.2px',
};

const { fontSize, fontWeight, lineHeight, letterSpacing } = Digits2Template;

type TextProps = {
  bold: boolean;
  defaultLetterSpacing: boolean;
};

const Digits2 = styled.p<TextProps>`
  font-size: ${fontSize};
  font-weight: ${({ bold }) => (bold ? 'bold' : `${fontWeight}`)};
  line-height: ${lineHeight};
  letter-spacing: ${({ defaultLetterSpacing }) => (defaultLetterSpacing ? 0 : `${letterSpacing}`)};
`;

export default Digits2;
