import { Record } from 'immutable';
import { Reducer } from 'redux';
import { ITranslationItems } from '../models/Translation';
import { TranslationActions, SET_TRANSLATIONS } from '../actions/interfaces/ITranslation';

export interface ITranslationState {
  readonly translationGroups: Array<ITranslationItems>;
}

export interface ITranslationStateRecord extends Record<ITranslationState>, ITranslationState {}

export const TranslationState = Record<ITranslationState>({
  translationGroups: [],
});

const initialState = TranslationState();

export const translationReducer: Reducer<ITranslationStateRecord, TranslationActions> = (
  state = initialState,
  action,
) => {
  const { type, payload } = action;

  switch (type) {
    case SET_TRANSLATIONS: {
      return state.set('translationGroups', payload?.translationGroups);
    }
    default:
      return state;
  }
};
