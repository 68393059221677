import styled from 'styled-components';
import { Intro1Template } from '../../shared/typography/intro1';
import { Media } from '../../styles/media';
import { ButtonStroke } from '../../shared/buttons';

const { desktop, tabletUp } = Media;

export const Body = styled.div`
  flex: 1 1 auto;
  line-height: 1.5;
  margin-top: 10px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  p:first-of-type {
    margin-top: 0;
  }

  &.decline {
    flex: 0 1 auto;
  }

  label {
    color: inherit;
  }
`;

export const Button = styled(ButtonStroke)`
  font-size: ${Intro1Template.fontSize};
  text-transform: uppercase;
  padding-top: 9px;
  padding-bottom: 7px;
  width: 100%;

  &:first-of-type {
    margin-bottom: 20px;
  }
`;

export const CheckboxContainer = styled.div`
  padding-top: 16px;
`;

export const ContentContainer = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 0 30px 30px;
  background-color: ${({ theme }) => theme.oneAppBlue};

  ${desktop`
      max-width: 710px;
      max-height: 600px;
      padding: 0 50px 30px;
  `};

  ${tabletUp`
      max-width: 710px;
      max-height: 600px;
      padding: 0 50px 30px;
  `};
`;

export const Divider = styled.hr`
  margin: 14px 0 30px;
  border: 0;
  height: 1px;
  background: rgba(255, 255, 255, 0.3);
`;

export const Footer = styled.div`
  flex: 0 0 auto;
`;

export const VideoWaiverContent = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.whiteBG};
`;

export const VideoWaiverStyled = styled.div`
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  ${tabletUp`
    align-items: center;
  `};
`;
