import styled, { css, CSSObject } from 'styled-components';
import AppLink from '../../../appLink';
import { Body2Template } from '../../../../shared/typography/body2';

export const StyledAppLink = styled(AppLink)`
  color: ${({ theme }) => theme.darkGray};
  display: flex;
  flex-direction: row;
  align-items: center;

  ${({ isSubItem, theme }) =>
    isSubItem &&
    css`
      ${Body2Template as CSSObject}
      color: ${theme.medGray};
      margin: 0;
    `};

  &:focus,
  &:hover {
    color: ${({ theme }) => theme.oneAppBlue};
  }
`;
