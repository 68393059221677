/** @jsx h */
import { FunctionalComponent, h } from 'preact';
import { connect } from 'react-redux';
import { IItemModel } from '../../../../models/Item';
import { ItemIcon, ItemLink, ItemLinkText, ItemContainer } from '../../desktopNavStyled';
import { logoutUser } from '../../../../actions/ssoActions';

export interface ILogoutProps {
  item: IItemModel;
  logoutUser: typeof logoutUser;
}

export const Logout: FunctionalComponent<ILogoutProps> = ({ item, logoutUser }) => {
  const { anchorText, menuImageUrl } = item;

  return (
    <ItemContainer>
      <ItemLink item={item} onClick={logoutUser}>
        {menuImageUrl && <ItemIcon alt={anchorText} src={menuImageUrl} hasContent={!!anchorText} />}
        <ItemLinkText>{anchorText}</ItemLinkText>
      </ItemLink>
    </ItemContainer>
  );
};

export default connect(null, { logoutUser })(Logout);
