import Locale, { ILocale, ILocaleModel } from '../../models/Locale';
import {
  getLocaleByGeolocationOrDefault,
  getLocaleFromBrowser,
  getLocaleFromDashedLanguage,
} from '../../utils/locale';
import CrossDomainStorageClient from '../crossDomainStorageClient';
import { getLocation } from '../../services/geoLocationService';
import { ICrossDomainStorageUser } from '../interfaces/ICrossDomainStorage';

const LOCALE_KEY = 'locale';

const { get, set, del } = CrossDomainStorageClient<ILocale>();
const geolocationPromise = getLocation();

const LocaleClient: ICrossDomainStorageUser<ILocaleModel> = {
  get: async () => {
    const storageLocale = await get(LOCALE_KEY);
    const geolocation = await geolocationPromise;
    const selectedLocale =
      storageLocale?.selectedLocale ||
      getLocaleFromBrowser() ||
      getLocaleByGeolocationOrDefault(geolocation);
    const undashedLocale = getLocaleFromDashedLanguage(selectedLocale);

    return Locale.createFromRaw({
      selectedLocale: undashedLocale,
      geolocation: geolocation,
    });
  },
  set: (locale) => set(LOCALE_KEY, locale.serialize()),
  del: () => del(LOCALE_KEY),
};

export default LocaleClient;
