import styled from 'styled-components';

export const Intro1Template = {
  fontSize: '18px',
  fontWeight: '300',
  lineHeight: '1.33em',
  letterSpacing: '0.025em',
};

const { fontSize, fontWeight, lineHeight, letterSpacing } = Intro1Template;

const Intro1 = styled.p`
  font-size: ${fontSize};
  font-weight: ${fontWeight};
  line-height: ${lineHeight};
  letter-spacing: ${letterSpacing};
`;

export default Intro1;
