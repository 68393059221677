import { IUtagData } from '../../interfaces/ITealium';
import { ISearchState } from '../../interfaces/ISearchState';

export const PLAY_BUTTON_TEMPLATE = ({
  contentGroup,
  from,
  groupName,
  guid,
  objectID,
  position,
  programName,
  queryID,
  searchState,
  railName,
  railPosNum,
  railItemNum,
  state,
  tabName,
  title,
}: IUtagData): IUtagData => ({
  site_subsection_1: {
    program_name_slug: programName,
  },
  site_subsection_2: {
    from: tabName,
  },
  page_type: {
    from,
  },
  page_subgroup: {
    rail_name_slug: groupName,
  },
  page_name: {
    program_name: programName,
    tab_name: tabName,
  },
  event_data: {
    event_data: JSON.stringify({
      content_group: contentGroup,
      item_position: position,
      objectID,
      rail_name: railName,
      rail_pos_num: railPosNum,
      rail_item_num: railItemNum,
      video_guid: guid,
    }),
  },
  event_name: {
    title: title,
    state,
  },
  search_refiners: {
    search_state: JSON.stringify({ ...(searchState as ISearchState), queryID }),
  },
});
