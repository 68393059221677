import styled from 'styled-components';
import Button1Stroke from '../button1Stroke';
import { Media } from '../../../styles/media';

const { tablet } = Media;

type ButtonProps = {
  secondary: string;
};

const Button1Tertiary = styled(Button1Stroke)<ButtonProps>`
  padding: 0;
  border: none;
  box-shadow: none;
  color: ${({ secondary, theme }) => (secondary ? 'white' : theme.oneAppBlue)};
  height: auto;
  text-align: ${(props) => {
    if (props.right) return 'right';
    if (props.left) return 'left';
    return 'center';
  }};

  &:hover,
  a&:hover,
  &:focus,
  &:active {
    box-shadow: none;
    background-color: transparent;
  }

  &:disabled {
    &,
    &:hover {
      background-color: transparent;
    }
  }

  ${tablet`
    padding: 0;
  `};
`;

export default Button1Tertiary;
