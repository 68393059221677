/** @jsx h */
import { FunctionalComponent, h } from 'preact';
import { useCallback } from 'preact/hooks';
import { connect } from 'react-redux';
import { NavItem } from '../../../../containers/mobileNavigation/mobileNavigationStyled';
import { IItemModel } from '../../../../models/Item';
import { IMenuItemModel } from '../../../../models/Menu';
import { ItemIcon } from '../../../desktopNav/desktopNavStyled';
import { ISideNavItemProps } from '../../../sideNavItem';
import { logInUser } from '../../../../actions/ssoActions';

export interface ILogInProps extends ISideNavItemProps {
  item: IMenuItemModel;
  logInUser: typeof logInUser;
}

export const LogIn: FunctionalComponent<ILogInProps> = ({ item, logInUser }) => {
  const { anchorText, menuImageUrl } = item as IItemModel;

  const onLogin = useCallback(
    (event: Event) => {
      event.preventDefault();

      logInUser();
    },
    [logInUser],
  );

  return (
    <NavItem shouldNotJustify isBold onClick={onLogin}>
      {menuImageUrl && <ItemIcon alt={anchorText} src={menuImageUrl} hasContent={!!anchorText} />}
      {anchorText}
    </NavItem>
  );
};

export default connect(null, { logInUser })(LogIn);
