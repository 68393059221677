import styled, { CSSObject } from 'styled-components';
import { Body2Template } from '../../../../shared/typography/body2';
import { Body1Template } from '../../../../shared/typography/body1';

interface ISubNavItemProps {
  isActive: boolean;
  isLink: boolean;
  'data-testid': string;
}

export const SubNavItem = styled.div.attrs<ISubNavItemProps>(({ isLink }) => ({
  as: isLink ? 'li' : 'span',
}))`
  ${({ isActive }) => (isActive ? Body1Template : Body2Template) as CSSObject};
  align-items: center;
  width: max-content;
  color: ${({ theme, isActive }) => (isActive ? theme.darkGray : theme.medGray)};
  display: flex;
  justify-content: space-between;
  &:hover {
    color: ${({ theme }) => theme.oneAppBlue};
  }
`;

export const SubNavItemText = styled.span``;
