import HttpMethod from '../../enums/httpMethod';
import { loadFromConfig } from '../../utils/configLoader';
import { request } from '../restClient';

const versionNameURL = loadFromConfig('VERSION_NAME_URL');

const VersionNameClient = {
  get: async (): Promise<string> =>
    request<never, string>({
      method: HttpMethod.GET,
      path: versionNameURL,
      parseResponse: false,
    })
      .then((response) => {
        const parsed = String(response.json).replace(/(\r\n|\n|\r)/gm, '');
        return parsed;
      })

      .catch(() => {
        return '';
      }),
};

export default VersionNameClient;
