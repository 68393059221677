import { Action, ActionCreatorsMapObject } from 'redux';
import { ISsoModel } from '../../interfaces/ISso';
import { IDefaultAction } from '../../interfaces/common';
import { IItemModel } from '../../models/Item';
import { INoAction, ThunkResult } from '../interfaces/IAction';

export const SET_USER_GROUPS_MENU = 'GROUPS/USER_GROUPS_MENU';
export interface ISetGroupsAction extends Action<typeof SET_USER_GROUPS_MENU> {
  payload: Array<IItemModel>;
}

export interface IGroupActions extends ActionCreatorsMapObject {
  getUserGroupsActionCreator(sso: ISsoModel): Promise<ISetGroupsAction>;
}

export interface ISetGroupActionCreator {
  (sso: ISsoModel): ThunkResult<ISetGroupsAction | INoAction>;
}

export interface IGroupsActionCreators extends ActionCreatorsMapObject {
  getUserGroupsActionCreator: ISetGroupActionCreator;
}

export type GroupActions = ISetGroupsAction | IDefaultAction;
