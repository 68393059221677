/** @jsx h */
import { FunctionalComponent, h } from 'preact';
import { useMemo } from 'preact/hooks';
import { MenuItemTypes } from '../../interfaces/IMenuItem';
import { IItemModel } from '../../models/Item';
import { IMenuItemModel, IMenuModel } from '../../models/Menu';
import { ActiveMenuText, ActiveMenuImage, Container } from './activeMenuItemStyled';

export interface IActiveMenuItemProps {
  activeMenuItem: IMenuItemModel;
}

export interface IITextAndImage {
  anchorText: string;
  menuImageUrl: string;
}

const getImageAndText = (item: IMenuItemModel): IITextAndImage => {
  switch (item.__typename) {
    case MenuItemTypes.menu: {
      const { parentItem: { anchorText = '', menuImageUrl = '' } = {} } = item as IMenuModel;

      return { anchorText, menuImageUrl };
    }

    case MenuItemTypes.item: {
      const { anchorText, menuImageUrl } = item as IItemModel;

      return { anchorText, menuImageUrl };
    }

    default: {
      return { anchorText: '', menuImageUrl: '' };
    }
  }
};

const ActiveMenuItem: FunctionalComponent<IActiveMenuItemProps> = ({ activeMenuItem }) => {
  const { anchorText, menuImageUrl } = useMemo(() => getImageAndText(activeMenuItem), [
    activeMenuItem,
  ]);

  return (
    <Container>
      {menuImageUrl ? (
        <ActiveMenuImage src={menuImageUrl} />
      ) : (
        <ActiveMenuText>{anchorText}</ActiveMenuText>
      )}
    </Container>
  );
};

export default ActiveMenuItem;
