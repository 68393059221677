import { createSelector } from 'reselect';
import { AppState } from '../interfaces/appState';
import { mainModuleSelector, modulesByKeySelector } from './modulesSelectors';
import { ICartModel } from '../models/Cart';
import { IMainModel } from '../models/Main';
import { AppIdEnum } from '../enums/main';

const cartSelector = (state: AppState): ICartModel =>
  modulesByKeySelector(state, 'cart') as ICartModel;

export const hasLoadedCartSelector = createSelector(
  cartSelector,
  mainModuleSelector,
  ({ hasLoaded }: ICartModel, { appId }: IMainModel): boolean =>
    (appId === AppIdEnum.bod && hasLoaded) || appId !== AppIdEnum.bod,
);
