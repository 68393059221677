/** @jsx h */
import { FunctionalComponent, h, VNode } from 'preact';
import { Container, Box, ErrorMessage, Label, ChildWrapper } from './checkboxStyled';

export interface ICheckbox {
  className?: string;
  isDisabled?: boolean;
  error?: VNode;
  isFullWidthChild?: boolean;
  isChecked?: boolean;
  label?: VNode;
  noPadding?: boolean;
  onChange?: (isChecked: boolean) => void;
  wasTouched?: boolean;
}

const Checkbox: FunctionalComponent<ICheckbox> = ({
  children,
  className,
  isDisabled,
  error,
  isFullWidthChild,
  isChecked,
  label,
  noPadding,
  onChange,
  wasTouched,
}) => {
  const handleChange = () => {
    if (onChange && !isDisabled) {
      onChange(!isChecked);
    }
  };

  return (
    <Container
      noPadding={noPadding}
      className={className}
      disabled={isDisabled}
      onClick={handleChange}
    >
      <Box checked={isChecked} disabled={isDisabled} />
      {!!label && <Label>{label}</Label>}
      <ChildWrapper fullWidthChild={isFullWidthChild}>{children}</ChildWrapper>
      {!!error && wasTouched && <ErrorMessage>{error}</ErrorMessage>}
    </Container>
  );
};

export default Checkbox;
