import styled, { css, DefaultTheme } from 'styled-components';
import { Intro1 } from '../../shared/typography';
import { Media } from '../../styles/media';
import Close from '../../assets/close.svg';
import { resolveRelativeUrl } from '../../utils/device';

const { desktopUp, tabletUp } = Media;

export const Background = styled.div`
  background-color: ${({ theme }) => theme.medGray};
  left: 0;
  opacity: ${({ isVisible }) => (isVisible ? '1' : '0')};
  position: fixed;
  top: 0;
  transition: opacity 0.8s ease, transform 0.8s ease;
  width: ${({ isVisible }) => (isVisible ? '100%' : 'auto')};
  z-index: ${({ isVisible }) => (isVisible ? '300' : '-1')};

  ${tabletUp`
    ${css<{ isVisible: boolean }>`
      position: ${({ isVisible }) => (isVisible ? 'absolute' : 'relative')};
      width: 100%;
    `}
  `};
`;

export const Container = styled.div`
  background-color: ${({ theme }) => theme.whiteBG};
  border-top: ${({ theme }) => `4px solid ${theme.oneAppBlue}`};
  border-top-left-radius: 30px;
  padding: 13px 0;

  ${tabletUp`
      padding: 15px 0;
      border-top: none;
      border-top-left-radius: 0;
      width: 100%;
  `};
`;

export const Input = styled(Intro1).attrs({ as: 'input' })`
  appearance: none;
  background-color: transparent;
  border: none;
  box-shadow: none;
  caret-color: ${({ theme }) => theme.oneAppBlue};
  color: ${({ theme }) => theme.darkGray};
  height: 100%;
  margin-bottom: 0;
  margin-right: 26px;
  outline: 0;
  padding: 0;
  width: 100%;

  &::-ms-clear,
  &::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
  }

  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    display: none;
  }

  &::-webkit-input-placeholder,
  &::-moz-placeholder,
  &:-ms-input-placeholder,
  &:-moz-placeholder {
    color: ${({ theme }) => theme.disabledGray};
  }

  &::placeholder {
    color: ${({ theme }) => theme.disabledGray};
  }

  ${tabletUp`
    ${css<DefaultTheme>`
      border-bottom: solid 1px ${({ theme }) => theme.lighterGray};
    `};
  `};
`;

export const Reset = styled.div`
  cursor: pointer;
  height: 18px;
  line-height: 0;
  position: absolute;
  right: 0;
  width: 18px;
  z-index: 1;

  ${tabletUp`
    right: 6px;
  `};
`;

export const ResetIcon = styled.img.attrs({ src: `${resolveRelativeUrl(Close)}` })`
  height: 18px;
  vertical-align: unset;
  width: 18px;
`;

export const Row = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.whiteBG};
  display: flex;
  margin: 0 25px;
  padding-right: 30px;
  position: relative;

  ${desktopUp`
    height: 34px;
    margin: 0 auto;
    width: 945px;
    box-sizing: border-box;
  `};
`;
